import React from 'react';
import { Icon } from '@chakra-ui/icons';
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	InputGroup,
	InputRightElement,
	InputLeftElement,
	Image,
	InputProps,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

type CustomInputPropType = {
	error?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	name: string;
	type?: 'text' | 'email' | 'password';
	placeholder: string;
	label?: string;
	value?: string;
	src?: string;
};

export const CustomInput = ({
	error,
	onChange,
	type,
	label,
	name,
	placeholder,
	value,
	src,
	...restProps
}: CustomInputPropType & InputProps) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const _handleTogglePasswordVisibility = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};
	return (
		<FormControl isInvalid={!!error}>
			{!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
			<InputGroup>
				{src && (
					<InputLeftElement
						px={2}
						pointerEvents='none'
						// eslint-disable-next-line react/no-children-prop
						children={<Image src={src} w={'4rem'} />}
					/>
				)}
				<Input
					{...restProps}
					onChange={onChange}
					borderColor='loopGrey.300'
					borderRadius={5}
					placeholder={placeholder}
					name={name}
					fontSize='sm'
					value={value}
					_placeholder={{
						color: 'placeholderText',
						fontSize: 'sm',
					}}
					type={isPasswordVisible ? 'text' : type}
				/>
				{type === 'password' && (
					<InputRightElement
						onClick={_handleTogglePasswordVisibility}
						cursor='pointer'
						// eslint-disable-next-line react/no-children-prop
						children={isPasswordVisible ? <Icon as={BsEyeSlash} /> : <Icon as={BsEye} />}
					/>
				)}
			</InputGroup>

			{!!error && <FormErrorMessage fontSize='xs'>{error}</FormErrorMessage>}
		</FormControl>
	);
};
