/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Link as RLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { countries } from 'countries-list';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { AuthLayout } from 'Layouts/AuthLayout';
import { PhoneNumberInput } from 'Components/FormInput/PhoneNumberInput';
import 'Extensions/Methods';
import { AppRoutes } from 'Routes/AppRoutes';
import { ICountry } from 'Types/Interface/ICountry';
import { INewUserRequestbody, IOtpVerificationRequestBody } from 'Types/Interface/IUser';
import { initialValues, validationSchema } from './utils';
import { Box, Flex, Heading, VStack, Text, Select, Checkbox, Button, Link, Divider, IconButton, useDisclosure, chakra, Stack } from '@chakra-ui/react';
import { useSendVerification, useToast, useSocialLogin } from 'Hooks';
import { GoogleLogin, GoogleLoginResponseOffline } from 'react-google-login';
import {  GoogleIcon, LoopVectorIcon } from 'Components/Icons';
// import { ReactFacebookLoginInfo } from 'react-facebook-login/dist/facebook-login-render-props';
import { TermsOfService } from './TermsOfService';
import { useDefaultErrorHandler } from 'Utils/errorHandling';


const getCountryData = (countries:Array<ICountry>, countryName: string): ICountry | undefined => {
	return countries.find((country) => country.name === countryName);
};

export const RegisterPage = () => {
	const formData = sessionStorage.getItem('registration_details') ?? JSON.stringify({});
	const parsedFormData = JSON.parse(formData) as INewUserRequestbody;
	const navigate = useNavigate();
	const {  handleGoogleLogin } = useSocialLogin(); // return this (handleFacebookLogin),
	const [countryList, setCountryList] = useState<Array<ICountry>>();
	const [countryData, setCountryData] = useState<ICountry>();
	const { mutate: sendVerificationCode , isLoading, isSuccess, isError, error} = useSendVerification();
	const { alert } = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isChecked, setIsChecked] = useState(true);
	
	const { handleChange, values, setFieldValue, handleSubmit, errors } =
		useFormik({
			initialValues: Object.keys(parsedFormData).length ? parsedFormData :  initialValues,
			validationSchema,
			onSubmit: (values) => 
			{
				values.countryCode = `+${countryData?.phone}`;	
				sessionStorage.setItem('registration_details', JSON.stringify(values));
				
				const countryInfo = {
					countryCode: countryData?.phone.toInternationalPhoneNumber(),
					countryName: countryData?.name
				};

				
				if(countryInfo.countryCode){
					const phoneVerificationRequestBody : IOtpVerificationRequestBody = {
						phoneNumber : values.phoneNumber.toInternationalPhoneNumber(),
						countryCode : countryInfo?.countryCode?.toInternationalPhoneNumber(),
					};
					sendVerificationCode(phoneVerificationRequestBody); 
				}			
			},
		});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onGoogleSuccess = (response: any) => {
		// TODO : add proper types
		const { tokenObj, profileObj } = response;
		handleGoogleLogin(tokenObj?.id_token, profileObj?.email);
	};
	const onFailure = (response: GoogleLoginResponseOffline) => {
		console.error('error', response);
	};

	// const responseFacebook = (userInfo: ReactFacebookLoginInfo) => {
	// 	handleFacebookLogin(userInfo?.accessToken);
	// };

	const _handleCheckbox = () => {
		setIsChecked(!isChecked);
	};

	useEffect(()=> {
		if(isChecked){
			onClose();
		}
	}, [isChecked]);
 
	useEffect(() => {
		setCountryList(Object.values(countries));
	}, []);

	useEffect(() => {
		if (isSuccess){
			alert('success', `Token was sent to ${values.phoneNumber}`);
			navigate(AppRoutes.verify);
		}
	}, [isSuccess]);

	useDefaultErrorHandler(isError, error);
	
	useEffect(() => {
		if(values.country && countryList){
			const countryData = getCountryData(countryList, values.country);
			setCountryData(countryData);
		}
	},[values.country, countryList]);

	return (
		<AuthLayout>
			<Stack
				alignItems={['flex-start', 'center']}
				h={['auto', 'auto']}
				justify={['center', 'center']}
				w={['auto','100%']}
				bgColor={['#FAFAFA', 'white']}
				border={['2px solid rgba(88, 96, 104, 0.07)', 'none']}
				borderRadius={['20px', 'none']}
				flex={1}
				overflow={'auto'}
				position={['absolute', 'relative']}
				left={0}
				right={0}
				m={['15px', '0']}
				py={['30px', 0]}
			>
				<LoopVectorIcon display={['flex', 'none']} pl={['5', '0']}   />
				<Box 
					p={['5', '10']}
					borderRadius="10px"
					boxShadow={['none','0px 0px 90px rgba(0, 0, 0, 0.1)']}
					w={['100%','auto']}
				>
					<Heading alignSelf="flex-start" fontSize="xl" mb="4">
					Sign up
					</Heading>
					<Text fontSize="sm" mb="10">
						FIll this sign up form to create a new account
					</Text>
					<VStack spacing={3} mb={'10px'} alignItems="flex-start">
						<CustomInput
							onChange={handleChange}
							name="firstName"
							type="text"
							placeholder="First name"
							error={errors.firstName}
							value={values.firstName}
						/>
						<CustomInput
							onChange={handleChange}
							name="lastName"
							type="text"
							placeholder="Last name"
							error={errors.lastName}
							value={values.lastName}
						/>
						<CustomInput
							onChange={handleChange}
							name="email"
							type="text"
							placeholder="Email"
							error={errors.email}
							value={values.email}
						/>
						<Select
							placeholder="Country"
							name="country"
							onChange={handleChange}
							borderColor='loopGrey.300'
							color={'placeholderText'}
							error={errors.country}
							value={values.country}
						>
							{countryList?.map((country) => (
								<option key={country.name} value={country.name}>
									{country.name}
								</option>
							))}
						</Select>
						<CustomInput
							onChange={handleChange}
							name="password"
							type="password"
							placeholder="Password"
							error={errors.password}
							value={values.password}
						/>
						<PhoneNumberInput
							value={values.phoneNumber}
							onChange={(value) => setFieldValue('phoneNumber', value)}
							error={errors.phoneNumber}
						/>
						<Checkbox py={'10px'} onChange={_handleCheckbox} isChecked={isChecked} >
							<Text onClick={onOpen} fontSize="xs">I agree to all <chakra.span color={'darkCoral'} textDecoration={'underline'}> terms and conditions</chakra.span> </Text>
						</Checkbox>
						<Button isDisabled={!isChecked} type="button" variant="primary" w="full" isLoading={isLoading} onClick={() => handleSubmit()}>
							Sign up
						</Button>
					</VStack>
					<Flex mb="2" alignItems="center">
						<Text fontSize="xs">Already a user? </Text>
						<Link
							as={RLink}
							to={AppRoutes.login}
							ml="1"
							fontSize="xs"
							fontWeight={600}
							color="darkCoral"
						>
							Login
						</Link>
					</Flex>
					<Flex my={'20px'} justifyContent={'space-between'} alignItems={'center'}>
						<Divider w={['150px','195px']} />
						<Text mx={['9px','20px']}> OR </Text>
						<Divider w={['150px','195px']} />
					</Flex>
					
					<Flex 
						border={'0.5px solid rgba(88, 96, 104, 0.50)'} 
						borderRadius={'100px'} 
						h={['48px','50px']} w={'100%'}
						justifyContent={'center'}
						alignItems={'center'}
						cursor={'pointer'}
					>
						<GoogleLogin
							clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
							render={(renderProps) => (
								<Flex justifyContent={'center'} alignItems={'center'}>
									<IconButton
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
										borderRadius='50%'
										h='26px'
										mr='10px'
										aria-label={'google'}
										icon={<GoogleIcon h='100%' />}
									/>
									<Text onClick={renderProps.onClick}>Continue using Google</Text>
								</Flex>
							)}
							onSuccess={onGoogleSuccess}
							onFailure={onFailure}
							cookiePolicy={'single_host_origin'}
						/>
					</Flex>
					

					{/* <FacebookLogin
						appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
						callback={responseFacebook}
						fields='name,email,picture'
						scope="public_profile,email"
						render={(renderProps: RenderProps) => (
							<IconButton
								onClick={renderProps.onClick}
								borderRadius='50%'
								h='30px'
								mr='4'
								aria-label={'facebook'}
								icon={<FacebookIcon h='100%' />}
							/>
						)}
					/> */}
				</Box>
			</Stack>
			<TermsOfService handleCheckbox={_handleCheckbox} isOpen={isOpen}  onClose={onClose }/>
		</AuthLayout>
	);
};
