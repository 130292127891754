export const CheckboxTheme = {
	variants: {
		primary: {
			control: {
				position: 'relative',
				border: '0.1em solid',
				borderRadius: 'full',
				borderColor: '#586068',
				backgroundColor: 'white',
				backgroundClip: 'padding-box',
				fontSize: 'sm',
				_checked: {
					border: '0.1em solid transparent',
					backgroundColor: 'loop.500',
					_hover: {
						border: '0.1em solid transparent',
						backgroundColor: 'loop.500',
						_before: {
							content: '\'\'',
							position: 'absolute',
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
							zIndex: -1,
							margin: '-0.1em',
							borderRadius: 'inherit',
							border: '0.1em solid transparent',
							backgroundColor: 'loop.500',
						}
					}
				},
				_focus: {
					outline: 'none',
					boxShadow: 'none',
				}
			}
		}
	},
};

    