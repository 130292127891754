import React from 'react';
import { Link as RLink, useLocation } from 'react-router-dom';
import {
	CSSObject,
	Link, useMediaQuery,
} from '@chakra-ui/react';

interface INavbarLink {
  label: string;
  path: string;
  linkColorMode?: 'light' | 'black'
  color?: string;
  sx?: CSSObject;
}

export const NavbarLink = ({ label, path, linkColorMode, sx }: INavbarLink) => {
	const location = useLocation();
	const isActive = location.pathname === path;
	const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');

	return (
		<Link
			key={label}
			as={RLink}
			to={path}
			// ml="1"
			fontSize="14px"
			fontWeight={isActive ? 700 : 400}
			color={linkColorMode  === 'light' ? 'white' : '#000'}
			{...!isLargerThan1000 && ({color: '#000'})}
			width="max-content"
			// mr="3"
			position="relative"
			_hover={{
				outline: 'none',
				textDecoration: 'none',
				_after: {
					width: '100%'
				}
			}}
			_after={{
				content: '""',
				position: 'absolute',
				bottom: -1,
				left: 0,
				right: 0,
				height: 0.5,
				width: isActive ? '100%' : '0%',
				background: 'loop.500',
				transition: '0.3s ease',
			}}
			sx={sx}
		>
			{label}
		</Link>
	);
};
