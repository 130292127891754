import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/BriefCaseIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const BriefCaseIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const BriefCaseIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M17.3054 3.81305H13.0508L12.84 2.36542C12.7477 1.73233 12.1957 1.25488 11.5559 1.25488H7.4441C6.8043 1.25488 6.2523 1.73233 6.16008 2.36549L5.94926 3.81305H1.69467C0.760223 3.81305 0 4.57328 0 5.50769V16.0513C0 16.9857 0.760223 17.746 1.69467 17.746H17.3054C18.2398 17.746 19 16.9857 19 16.0513V5.50769C19 4.57328 18.2398 3.81305 17.3054 3.81305ZM7.26609 2.52655C7.27886 2.43875 7.35541 2.37254 7.44414 2.37254H11.556C11.6447 2.37254 11.7213 2.43878 11.734 2.52655L11.9214 3.81305H7.07872L7.26609 2.52655ZM17.8824 16.0513C17.8824 16.3695 17.6235 16.6283 17.3054 16.6283H1.69467C1.3765 16.6283 1.11762 16.3695 1.11762 16.0513V10.7244C1.6094 11.1086 2.2273 11.3384 2.89832 11.3384H7.26461V11.8848C7.26461 12.0912 7.37835 12.2808 7.56044 12.3779L9.23701 13.272C9.3192 13.3158 9.40956 13.3378 9.5 13.3378C9.5904 13.3378 9.6808 13.3159 9.76299 13.272L11.4396 12.3779C11.6217 12.2808 11.7354 12.0912 11.7354 11.8848V11.3385H16.1017C16.7727 11.3385 17.3906 11.1086 17.8824 10.7245V16.0513ZM8.38227 11.5495V9.93521H10.6178V11.5495L9.5 12.1457L8.38227 11.5495ZM17.8824 8.44011C17.8824 9.42199 17.0836 10.2208 16.1017 10.2208H11.7354V9.37634C11.7354 9.06774 11.4852 8.81751 11.1766 8.81751H7.82344C7.51483 8.81751 7.26461 9.06771 7.26461 9.37634V10.2208H2.89832C1.91644 10.2208 1.11762 9.42202 1.11762 8.44015V5.50769C1.11762 5.2293 1.31582 4.99632 1.57848 4.94244C1.616 4.93472 1.65486 4.93071 1.69464 4.93071H17.3053C17.3451 4.93071 17.3839 4.93476 17.4214 4.94244C17.6841 4.99636 17.8823 5.22933 17.8823 5.50769V8.44011H17.8824Z" fill={color}/>
		</Icon>
	);
};