import React from 'react';

import { Flex, useMediaQuery } from '@chakra-ui/react';
import { Products } from 'Components/Products/products';
import { ProductNavigation } from '../Components/ProductNavigation';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { MobileProductsSlider } from '../Components/MobileDiscoverProductsSlider';
import { useGetAllProductsQuery } from 'services';

export const DiscoverProducts = () => {
	const params = {pageNumber:1, pageSize:4, categoryId: ''};
	const {data, isLoading} = useGetAllProductsQuery(params);
	const navigate = useNavigate();
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	return (
		<Flex
			direction={'column'}
			// py={{ xxl: 6 }}
		>
			<ProductNavigation
				onClickLink={() => {
					navigate(AppRoutes.allProducts);
				}}
				title='Discover Products'
				linkText='see all products'
			/>
			{isLargerThan768 && <Products products={data?.data} isLoading={isLoading} />}
			{!isLargerThan768 && <MobileProductsSlider products={data?.data} isLoading={isLoading} />}
		</Flex>
	);
};
