import { Box, Button, chakra, Flex } from '@chakra-ui/react';
import { ConfirmPayoutRequestIcon } from 'Components/Icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';


export const PayoutRequestSuccess = () => {
	const navigate = useNavigate();
	const _handlecompletePayoutRequest = () => {
		navigate(AppRoutes.dashboard);
	};

	return (
		<Box>
			<chakra.header>
				<Flex direction={'column'} justifyContent={'flex-start'} fontSize={'md'}>
					<Flex justifyContent={'center'} mt={'12'}>
						<ConfirmPayoutRequestIcon />
					</Flex>
					<Box mt={'8'} mb={'6'} fontSize={'xl'} textAlign={'center'} fontWeight={'semibold'}>
						Request Successful
					</Box>
					<Box textAlign={'center'} mb={'10'}>
						Your request is successfully done, your profile will be updated as soon as it is completed.
					</Box>
					<Button variant='primary' w='100%' onClick={_handlecompletePayoutRequest}>
						Done
					</Button>
				</Flex>
			</chakra.header>
		</Box>
	);
};
