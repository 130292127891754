import React from 'react';
import { Divider, Flex, Heading, Stack, Text, Switch } from '@chakra-ui/react';

export const Content = () => {
	return (
		<>
			<Stack spacing={8} w="100%" flex={1}>
				<Stack spacing={2}>
					<Flex flex={1} justifyContent="space-between">
						<Heading fontSize='sm' color="#264653">Share my activity on Facebook</Heading>
						<Switch size='lg' variant='primary'/>
					</Flex>
					<Text w="60%">Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut </Text>
				</Stack>
				<Divider />
				<Stack spacing={2}>
					<Flex flex={1} justifyContent="space-between">
						<Heading fontSize='sm' color="#264653">Include my profile in surveys</Heading>
						<Switch size='lg' variant='primary' />
					</Flex>
					<Text w="60%">Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut </Text>
				</Stack>
				<Divider />
				<Stack spacing={2}>
					<Flex flex={1} justifyContent="space-between">
						<Heading fontSize='sm' color="#264653">Allow app to access my location</Heading>
						<Switch size='lg' variant='primary' />
					</Flex>
					<Text w="60%">Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut </Text>
				</Stack>
				<Divider />
			</Stack>
		</>
	);
};
