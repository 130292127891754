import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Button, Flex, IconButton } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'Hooks';
import React from 'react';
import { navigateSurvey, QuestionNavigationDirection, setSection } from 'store/slices';

export const SurveyNavigation = () => {
	const {
		activeSurvey: {
			currentQuestion,
			survey: { questions },
		},
		isCurrentAnswerValid,
		section,
	} = useAppSelector((state) => state.survey);
	const dispatch = useAppDispatch();

	const navigateSurveyQuestion = (dir: QuestionNavigationDirection) => {
		let index: number;
		if (dir === 'NEXT') {
			index = Math.min(currentQuestion + 1, questions.length - 1);
		} else {
			index = Math.max(currentQuestion - 1, 0);
		}
		dispatch(navigateSurvey({ index, dir }));
	};

	return (
		<>
			{section === 'surveySharingSettings' ? (
				<Button fontSize={'sm'} fontWeight={'500'} onClick={() => dispatch(setSection('questions'))}>
					{'< Return to questions'}
				</Button>
			) : section === 'brandSharingSettings' ? (
				<Button fontSize={'sm'} fontWeight={'500'} onClick={() => dispatch(setSection('surveySharingSettings'))}>
					{'< Return to data sharing permissions'}
				</Button>
			) : (
				<Flex>
					<IconButton
						onClick={() => navigateSurveyQuestion('PREV')}
						disabled={currentQuestion === 0}
						w='30px'
						h='30px'
						bg='white'
						mr={2}
						aria-label='previous'
						icon={<ChevronDownIcon />}
					/>
					<IconButton
						onClick={() => navigateSurveyQuestion('NEXT')}
						disabled={!isCurrentAnswerValid || currentQuestion === questions.length - 1}
						w='30px'
						h='30px'
						bg='white'
						aria-label='next'
						icon={<ChevronUpIcon />}
					/>
				</Flex>
			)}
		</>
	);
};
