import {
	Box,
	Button,
	chakra,
	Flex,
	HStack,
	IconButton,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Progress,
	Spinner,
	Stack,
	Text,
} from '@chakra-ui/react';
import { ConfirmPayoutRequestIcon, ExclamationIcon } from 'Components/Icons';
import { useAppDispatch } from 'Hooks';
import React from 'react';
import { useEffect } from 'react';
import { useGetPayoutAmountQuery } from 'services';
import { setTiliaPaymentMethod } from 'store/slices';
import { IOncompletePayload } from 'Types/Interface/ITilia';

interface IPayoutProps {
	isLoading: boolean;
	handleSubmit: () => void;
	amount: number;
	amountLnt: number;
	onCompleteCallbackPayload: IOncompletePayload | null;
	isLoadingRedirect: boolean;
	paymentMethodId?: string;
}

export const Payout = ({
	isLoading,
	isLoadingRedirect,
	onCompleteCallbackPayload,
	paymentMethodId,
	amount,
	amountLnt,
	handleSubmit,
}: IPayoutProps) => {
	const dispatch = useAppDispatch();
	const {
		data,
		refetch,
		isLoading: isLoadingFees,
	} = useGetPayoutAmountQuery({ amount: amount * 100, paymentMethodId });

	useEffect(() => {
		if (paymentMethodId) {
			refetch();
		}
	}, [amount, paymentMethodId, refetch]);

	useEffect(() => {
		dispatch(setTiliaPaymentMethod(onCompleteCallbackPayload));
	}, [onCompleteCallbackPayload]);

	return (
		<Box>
			{isLoadingRedirect && (
				<Progress
					size={'xs'}
					isIndeterminate
					position={'fixed'}
					top={0}
					left={0}
					right={0}
					zIndex={10000}
					colorScheme={'red'}
				/>
			)}
			<chakra.header>
				{!onCompleteCallbackPayload && <Box id='tilia-widget-wrapper'></Box>}
				{paymentMethodId && (
					<Flex h={'1000px'} direction={'column'} justifyContent={'flex-start'} fontSize={'md'}>
						<Flex justifyContent={'center'} mt={'12'}>
							<ConfirmPayoutRequestIcon />
						</Flex>
						<Box mt={'8'} mb={'3'} fontSize={'xl'} textAlign={'center'} fontWeight={'semibold'}>
							Confirm Request
						</Box>
						<Box textAlign={'center'} mb={'8'}>
							You requested a withdrawal of {amountLnt} LNT to your paypal account
						</Box>
						<Stack spacing={4} mb={'10'} gap={'3'}>
							<Flex justifyContent={'space-between'}>
								<Text color={'brand.black'}>USD estimate</Text>
								<Text color={'brand.black'}>${amount}</Text>
							</Flex>
							<Flex justifyContent={'space-between'}>
								<Text color={'brand.black'}>Fees</Text>
								{isLoadingFees && <Spinner color='loop.500' />}
								{!isLoadingFees && (
									<HStack>
										<Popover arrowSize={6} placement={'bottom-end'} offset={[8, 6]}>
											<PopoverTrigger>
												<Box pos='relative'>
													<IconButton
														aria-label='Notification'
														w='max-content'
														icon={<ExclamationIcon />}
														p={0}
														display='flex'
														alignItems='center'
														justifyContent='center'
													/>
												</Box>
											</PopoverTrigger>
											<PopoverContent borderRadius={'10px'} px={1} height={'auto'} width={'120px'} fontSize={'0.6rem'}>
												<PopoverArrow />
												<PopoverBody px={1}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</PopoverBody>
											</PopoverContent>
										</Popover>
										<Text color={'brand.black'}>${(data?.data?.fee_amount || 0) / 100}</Text>
									</HStack>
								)}
							</Flex>
							<Flex justifyContent={'space-between'}>
								<Text color={'brand.black'}>Estimated Received</Text>
								{isLoadingFees && <Spinner color='loop.500' />}
								{!isLoadingFees && <Text color={'brand.black'}>${(data?.data?.payout_amount || 0) / 100}</Text>}
							</Flex>
						</Stack>
						<Button variant='primary' w='100%' onClick={() => handleSubmit()} isLoading={isLoading}>
							Confirm Request
						</Button>
					</Flex>
				)}
			</chakra.header>
		</Box>
	);
};
