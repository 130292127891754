import React from 'react';
import { 
	BadgeIcon,
	BellIcon,
	MoneyIcon,
	ShieldIcon,
	TicketIcon,
	UnlockIcon
} from 'Components/Icons';
import { IAccountSettingCard } from 'Constants';
import { Icon } from '@chakra-ui/icons';
import { AiOutlineEye, AiOutlineSetting } from 'react-icons/ai';

export const myAccountSectionDetails: IAccountSettingCard[] = [
	{
		title: 'Which details can be edited?',
		icon: <UnlockIcon boxSize={5}/>,
		description: `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
        minim veniam, quis nostrud exercitation ulla mco laboris nisi ut
        aliquip ex ea commodo con sequat`,
		iconBgColor: '#FFF3DF'
	},
	{
		title: 'What info is shared with others?',
		icon: <BadgeIcon boxSize={5}/>,
		description: `Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
        minim veniam, quis nostrud exercitation ulla mco laboris nisi ut
        aliquip ex ea commodo con sequat`,
		iconBgColor: '#FFEEEC'
	},
	
];

export const loginAndSecurityDetails: IAccountSettingCard[] = [
	{
		title: 'Keeping your account secure',
		icon: <ShieldIcon boxSize={5}/>,
		description: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo con sequat.',
		iconBgColor: '#DDFFF0'
	},
];

export const preferenceDetails: IAccountSettingCard[] = [
	{
		title: 'Customize your account set up',
		icon: <Icon as={AiOutlineSetting} boxSize={5}/>,
		description: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo con sequat.',
		iconBgColor: '#F6F6F6'
	},
];

export const privacyAndSharingDetails: IAccountSettingCard[] = [
	{
		title: 'Curate what you share',
		icon: <Icon as={AiOutlineEye} boxSize={5}/>,
		description: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo con sequat.',
		iconBgColor: '#DAF4FF'
	},
];

export const paymentAndPayoutDetails: IAccountSettingCard[] = [
	{
		title: 'Manage your payments',
		icon: <MoneyIcon boxSize={5}/>,
		description: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo con sequat.',
		iconBgColor: '#E9F9FF'
	},
];

export const creditsAndCouponDetails: IAccountSettingCard[] = [
	{
		title: 'The more you refer, the more you earn',
		icon: <TicketIcon boxSize={5}/>,
		description: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo con sequat.',
		iconBgColor: '#FFD7D7'
	},
];

export const notificationDetails: IAccountSettingCard[] = [
	{
		title: 'Control how messages is being sent to you',
		icon: <BellIcon boxSize={5}/>,
		description: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo con sequat.',
		iconBgColor: '#FFEED1',
	},
];