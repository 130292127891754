import { Stack, Skeleton, useMediaQuery } from '@chakra-ui/react';
import React from 'react';

export const SkeletonLoader = () => {
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	return (
		<Stack
			direction='row'
			spacing={8}
		>{ 	new Array(4).fill('a').map((_,index) => (
				isLargerThan768 ? <Skeleton 
					key={index}
					h={['250px', '250px', null, '300px']}
					w={['180px', '180px', null, 'full']}
				/> : 
					<Skeleton 
						key={index}
						h={['250px', '250px', null, '300px']}
						minW={['180px', '180px', null, 'full']}
					/> 
			))
			}
		</Stack>
	);
};