import { AuthContext } from 'context/AuthContext';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';

type AuthenticatedRouteType = {
  component: React.ReactNode;
};

export const AuthenticatedRoute = ({
	component,
}: AuthenticatedRouteType) => {
	const auth = useContext(AuthContext);
	if(!auth.isAuthenticated()) {
		return <Navigate to={AppRoutes.landingpage} />;
	}

	return( <>
		{component}
	</>);
};
