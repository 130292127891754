import React from 'react';
import {
	Box,
	Flex,
	Image,
	Heading,
	Stack,
	Text,
	Button
} from '@chakra-ui/react';
import {
	ListItem,
	UnorderedList,
} from '@chakra-ui/react';

export interface ISolution {
  heading: string;
  description: string;
  list: string[];
}

interface IMarketingSolutionLayoutProps {
  image: string;
  solution: ISolution;
  layout?: 'LR' | 'RL'
}

export const MarketingSolutionLayout = ({
	image,
	solution,
	layout = 'LR'
}: IMarketingSolutionLayoutProps) => {
	return (
		<Flex justifyContent="space-between" direction={layout === 'LR' ? ['column',null,'row'] : ['column',null,'row-reverse']}>
			<Box w={['100%', '45%']} >
				<Image w={['100%', '100%']} src={image} />
			</Box>
			<Stack w={['100%', '45%']} mt={[8, 8, 0]} spacing={4}>
				<Text>Loop for business</Text>
				<Heading fontSize={['2xl', '4xl']}>{solution.heading}</Heading>
				<Text lineHeight={8} fontSize='sm'>{solution.description}</Text>
				<UnorderedList >
					{solution.list.map((text, index) => (
						<ListItem fontSize='sm' mb={4} ml={4}  key={text + index}>{text}</ListItem>
					))}
				</UnorderedList>
				<Stack 
					direction={['column', 'row']}
				>
					<Button
						variant='primary'
						mr={[0,5]}
						w={['200px', 'auto']}
						h={['50px',  'auto']}
					>
						Get Started
					</Button>
					<Button
						variant='secondary'
						w={['200px', 'auto']}
						h={['50px',  'auto']}	
					>
						Contact Us
					</Button>
				</Stack>

			</Stack>
		</Flex>
	);
};