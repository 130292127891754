/* eslint-disable no-restricted-globals */
import React from 'react';
import { ErrorBoundary as ErrorBoundaryPackage } from 'react-error-boundary';

const CustomErrorComponent = () => (
	<h3>Something went wrong, please report error</h3>
);

const errorHandler = (error: unknown) => {
	// eslint-disable-next-line no-console
	console.error(error);
};

interface IErrorBoundaryProps {
  children: React.ReactNode;
  onError?: (error: Error) => void;
}

export const ErrorBoundary = ({ children, onError }: IErrorBoundaryProps) => (
	<ErrorBoundaryPackage FallbackComponent={CustomErrorComponent} onError={onError || errorHandler}>
		{children}
	</ErrorBoundaryPackage>
);

export default ErrorBoundary;
