import { useMutation } from 'react-query';
import { uploadImage, uploadUserPhoto } from 'Api/api';

export const useImageUpload = () => {
	return useMutation((data: FormData) => uploadImage(data));
};

export const useUpdateUserAvatar = () => {
	return useMutation((data: FormData) => uploadUserPhoto(data));

};
