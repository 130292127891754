import React from 'react';
import { Flex, Heading, VStack, Link, Box, Text, Grid, useMediaQuery, Divider } from '@chakra-ui/react';
import { InternetIcon, NavigationIcon } from 'Components/Icons';
import { LandingPageFooterLinks } from 'Constants/FooterLinksConstants';
import { FooterSectionMobile } from './FooterSectionMobile';

export enum BgEnum {
	landing = 'landing',
	dashboard = 'dashboard',
}
export interface IFooterSectionProps {
	bgType?: BgEnum;
	hasBorder?: boolean;
}

interface IFooterBg {
	[BgEnum.landing]: string;
	[BgEnum.dashboard]: string;
}
export const footerBg: IFooterBg = {
	landing: '#ECEFF2',
	dashboard: '#fff',
};

export const FooterSection = ({
	bgType = BgEnum.landing,
	hasBorder
}: IFooterSectionProps) => {
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	if(!isLargerThan768) return <FooterSectionMobile  bgType={BgEnum.landing}/>;

	return (
		<Box bg={`${footerBg[bgType]}`}>
			<Flex
				py='16'
				px={{base:'8', lg:'14', '2xl': '2'}}
				maxW={'1440px'} m={'0 auto'}
				// bg={`${footerBg[bgType]}`}
				flexWrap='wrap'
				direction={['column-reverse', 'column-reverse', 'row']}
			>
				<Divider 
					borderColor={'#C3C5C7'} 
					variant={'solid'} 
					size={'1px'}
					mb={16}
					display={hasBorder == true ? 'block' : 'none'}
				/>
				<VStack spacing={5} flex={[1, 0.5]} pl={{lg: 10,'2xl':20}} alignItems='flex-start'>
					<Heading color='darkCoral' fontSize={{md: 'lg', lg: '2xl'}}>
						Loop
					</Heading>
					<Link d='flex' fontSize={{md:'10px', lg:'14px'}} alignItems='center'>
						<NavigationIcon mr='2' /> United States
					</Link>
					<Link d='flex' alignItems='center' fontSize={{md:'10px', lg:'14px'}} >
						<InternetIcon mr='2' /> English
					</Link>
					<Box></Box>
					<Text>Loop &copy; {new Date().getFullYear()}</Text>
				</VStack>
				<Grid
					templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
					justifyContent='space-between'
					flex={1}
				>
					{LandingPageFooterLinks.map((link) => (
						<VStack
							spacing={5}
							key={link.title}
							alignItems='flex-start'
							mr={[10, 8, 0]}
							mb={[10, 10, 0]}
						>
							<Heading fontSize={{md:'12px', lg:'16px'}} >{link.title}</Heading>

							{link.links.map((link) => (
								<Link fontSize={{md:'10px', lg:'14px'}}  key={link.label}>{link.label}</Link>
							))}
						</VStack>
					))}
				</Grid>
			</Flex>
		</Box>
	);
};
