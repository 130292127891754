import React, { useEffect, useRef, useState } from 'react';
import { Box, Progress, Text } from '@chakra-ui/react';
import { useAppSelector } from 'Hooks';


export const SurveyProgressIndicator = () => {
	const { activeSurvey, navigationDirection } = useAppSelector((state) => state.survey);
	const {
		survey: {
			questions
		},
		currentQuestion
	} = activeSurvey;
	const totalQuestions = questions.length;
	const percentage = +(100 / totalQuestions).toFixed(2);
	const [progress, setProgress] = useState((currentQuestion + 1 ) * percentage);
	const increaseProgress = () => {
		setProgress((prev) => Math.min(prev + percentage, 100));
	};

	const decreaseProgress = () => {
		setProgress((prev) => Math.max(prev - percentage, 0));
	};

	const isJustMount = useRef(true);

	useEffect(() => {
		if(!isJustMount.current){
			if(navigationDirection === 'NEXT') {
				increaseProgress();

			} else {
				decreaseProgress();
			}
		}
		isJustMount.current = false;
	}, [currentQuestion]);
	return (
		<Box>
			<Text mb={3}>
        Questions {currentQuestion + 1} of {totalQuestions}
			</Text>
			<Progress
				w={[40,72]}
				size="xs"
				value={progress}
				variant="primary"
				borderRadius="full"
			/>
		</Box>
	);
};
