import { AppRoutes } from 'Routes/AppRoutes';

export const Navbarlinks: { label: string; path: string }[] = [
	{
		label: 'Brands',
		path: AppRoutes.brands,
	},
	{
		label: 'How it works',
		path: AppRoutes.howItWorks,
	},
	{
		label: 'Loop app',
		path: AppRoutes.loopApp,
	},
	{
		label: 'Help',
		path: AppRoutes.help,
	},
	{
		label: 'For business',
		path: AppRoutes.business,
	},
];