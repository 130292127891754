import React from 'react';
import {
	Heading,
	Text,
	Image,
	VStack,
} from '@chakra-ui/react';

import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { Carousels } from 'Constants/ImageSliderConstant';

export const HowItWorksMobile = () => {
	return (
		<MotionBox
			drag="x"
			dragConstraints={{ right: 0, left: -922 }}
			display={'flex'}
		>
			{Carousels.map((Carousel, index) => {
				return (
					<MotionBox 
						w={['74vw', '48vw']} 
						mr={4} key={index} 
						border={'0.5px solid #cbcfd3'}>
						<Image w={'xl'} src={Carousel.mobileSrc} />
						<VStack alignItems={'flex-start'} px={'6'} py={'8'} >
							<Heading fontSize={'xl'}>{Carousel.label}</Heading>
							<Text 
								w={'auto'}
								fontSize={'sm'}
								lineHeight={'28px'}
								color={'#586068'}
							>
								{Carousel.text}
							</Text>
						</VStack>
					</MotionBox>
				);
			})}
		</MotionBox>
	);
};
