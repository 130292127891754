import { Select } from '@chakra-ui/react';
import { useAppSelector } from 'Hooks';
import React from 'react';
import { GenderEnum } from 'Types/Enums/UserPreferenceEnums';
import { IEditProfileDetail } from './FullNameEdit';

export const GenderEdit = ({ onChange, inputName }: IEditProfileDetail) => {
	const { userInfo } = useAppSelector((state) => state.authUser);

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		onChange(inputName, value);
	};

	return (
		<Select
			onChange={handleChange}
			defaultValue={userInfo?.gender}
			placeholder='Select option'
			name={inputName}
		>
			<option value={GenderEnum.Male}>Male</option>
			<option value={GenderEnum.Female}>Female</option>
			<option value={GenderEnum.NoInput}>Prefer not to say</option>
			<option value={GenderEnum.Others}>Others</option>
		</Select>
	);
};
