import React from 'react';
import { Navbar } from 'Pages/Dashboard/Components/Navbar/Navbar';
import { Box } from '@chakra-ui/react';
import { ScrollUpBtn } from 'Components/ScrollUpBtn';
import { Footer } from 'Pages/LandingPage/HomeSection/sections/Footer';

interface IBrandsLayoutProps {
	JumbotronSection: React.ReactNode,
	children: React.ReactNode;
	linkColorMode?: 'light' | 'black'
}

export const BrandsLayout = ({
	JumbotronSection, 
	children, 
	linkColorMode 
}:IBrandsLayoutProps) => {
	
	return (
		<Box overflowX="hidden">
			<Box 
				py="8" 
				px={['8', 6, null, '20']} 
				bgColor= '#FFF6F5'
				bgSize="cover"
				height={['40%','30%','60%']}
			>
				<Navbar linkColorMode={linkColorMode} inputlinkColorMode={'light'}/>
				{JumbotronSection}
			</Box>
			<Box maxW={'1440px'} m={'0 auto'} px={['8', 6, null, '20']}>
				{children}
			</Box>
		
			<Footer/>
			<ScrollUpBtn />		
		</Box>
	);
};