import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	ModalContentProps,
} from '@chakra-ui/react';

interface ICustomModalProps {
    content: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
	hideFooter?: boolean;
	isCentered?: boolean;
	size?: string;
	modalContentStyles?: ModalContentProps
}

export const CustomModal = ({content, isOpen, onClose, hideFooter, size = 'xl', isCentered, modalContentStyles}:ICustomModalProps) => {
	return (
		<Modal size={size} isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
			<ModalOverlay />
			<ModalContent h={'auto'} padding={0} borderRadius={1} {...modalContentStyles}>
				<ModalCloseButton bg="white" borderRadius="50%" zIndex="overlay" />
				<ModalBody padding={0} overflow="auto" className='custom-modal'>
					{content}
				</ModalBody>

				{!hideFooter && <ModalFooter/>}
			</ModalContent>
		</Modal>
	);
};