import { Flex, Stack, Image, VStack, Heading, Text, Button, useMediaQuery, chakra } from '@chakra-ui/react';
import React from 'react';
import EarnPointToSpend from 'Assets/Images/EarnPointToSpend.png';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';

export const EarnToSpend = () => {
	const navigate  = useNavigate();
	const [isLargerThan768] = useMediaQuery('(min-width: 900px)');
	return(
		<Flex direction={['column', null, null,  'row']}  justifyContent={'space-between'} >
			<Stack align={'start'} spacing={'30px'} mt={['null','30px']}>
				<VStack align={'start'} spacing={'20px'}>
					<Heading fontSize={['24px','40px']} w={['full','430px']}>Earn points to spend <chakra.span color={'darkCoral'}>Anywhere.</chakra.span> </Heading>
					<Text fontSize={['16px','18px']} w={['auto', null, null, '480px', '527px']} >
                    The brands partnering with Loop have questions and want to connect with you to serve you better, hear your input and reward you for your help.
					</Text>
					{ !isLargerThan768 && <Image src={EarnPointToSpend} boxSize={'100%'}  objectFit={'contain'} />}
				</VStack>
				<VStack align={'start'} spacing={'20px'}>
					<Heading fontSize={['18px','20px']}>Earn on every action.</Heading>
					<Text  fontSize={['16px','18px']} w={['auto', null, null, '480px', '527px']} >
					We want to reward you for every interaction you have and all information you share! Your feedback will help the brands you already buy from better their product and service for you and others.
					</Text>
				</VStack>
				<VStack align={'start'} spacing={'20px'}>
					<Heading fontSize={'20px'}>Spend Anywhere</Heading>
					<Text fontSize={['16px','18px']} w={['auto', null, null, '480px', '527px']}>
					The points you earn on Loop are basically cash with a direct value in USD - withdraw and spend on anything, anywhere.
					</Text>
				</VStack>
				<Button variant={'secondary'} onClick={() => navigate(AppRoutes.register)} w={['150px','170px']} h={['50px','60px']}>Sign up now!</Button>
			</Stack>
			{isLargerThan768 && <Image 
				src={EarnPointToSpend} 
				boxSize={{ base: '100%', md: '100%', lg: '40%' }}
				mt={{md:'30px'}}
				objectFit="cover"
			/>}
		</Flex>
	);
};