import { Heading } from '@chakra-ui/react';
import React from 'react';

interface ICountUpProps {
  end: number;
  speed?: number;
  postfix?: string;
  fontSize?: string[];
}

export const CountUp = ({
	end,
	speed = 50,
	postfix,
	fontSize,
}: ICountUpProps) => {
	const [value, setValue] = React.useState(0);

	const increment = () => {
		if (value < end) {
			setValue((prev) => prev + 1);
		}
	};

	React.useEffect(() => {
		const timerId = setInterval(() => increment(), speed);
		return () => clearInterval(timerId);
	});

	return (
		<Heading fontSize={fontSize}>
			{value}
			{postfix}
		</Heading>
	);
};
