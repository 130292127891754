/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';

import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	useMediaQuery,
} from '@chakra-ui/react';
import { BsFilter } from 'react-icons/bs';
import { useGetAllProductsQuery, useSearchBrandAndProductsQuery } from 'services';
import { useGetCategoriesQuery } from 'services';
import { IProduct } from 'Types/Interface/IProduct';
import { AllProductsGallery } from 'Components/Products/AllProductsGallery';
import { Autocomplete, IAutocompleteItem } from 'Components/Autocomplete';
import { useDebounce } from 'Hooks';
import { AppRoutes } from 'Routes/AppRoutes';
import { IBrand } from 'Types/Interface/IBrand';
import { useNavigate } from 'react-router-dom';

const CONTROLLED_MAX_PAGE_SIZE = 200;
const CONTROLLED_MIN_PAGE_SIZE = 10;

export const AllProductsCategories = () => {
	const navigate = useNavigate();
	const [searchString, setSearchString] = useState('');
	const debounceQuery = useDebounce<string>(searchString, 1000);
	const { data: brandsAndProducts, isSuccess: isSearchSuccess, isLoading: isSearchLoading, isFetching:isSearchFetching } = useSearchBrandAndProductsQuery(debounceQuery);
	const [searchResult, setSearchResult] = useState<IAutocompleteItem[]>([]);


	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(CONTROLLED_MIN_PAGE_SIZE);
	const [activeCategory, setActiveCategory] = useState('');
	const params = { pageNumber, pageSize, categoryId: activeCategory };
	const { data, isLoading, isFetching, refetch } = useGetAllProductsQuery(params);
	const { data: categories } = useGetCategoriesQuery();
	const [products, setProducts] = useState<IProduct[]>([]);
	const [isLargerThan768] = useMediaQuery('(min-width: 984px)');
	const isHasMoreData = data ? data?.pageNumber < data?.totalNoOfPages : false;

	useEffect(() => {
		if (data) {
			setProducts(data.data);
		}
	}, [data, activeCategory, refetch]);

	const _handleShowMoreItems = () => {
		setPageNumber((prevPage) => prevPage + 1);
		if (activeCategory ===  '') {
			setPageSize(CONTROLLED_MAX_PAGE_SIZE);
			setPageNumber(1);
		}
	};

	const _handleClick = (id: string) => {
		setActiveCategory(id);
	};

	const onClickAll = () => {
		setActiveCategory('');
		setPageSize(CONTROLLED_MIN_PAGE_SIZE);
		setPageNumber(1);
		refetch();
	};

	const handleSelect = (item: IAutocompleteItem) => {
		const selectedItem = item as unknown as IBrand;
		const isBrand = selectedItem?.products;
		if(isBrand){
			navigate(AppRoutes.brand.replace(':brandId', item.value));
		}else {
			navigate(AppRoutes.productPage.replace(':productId', item.value));
		}
	};

	useEffect(() => {
		if (activeCategory === '' && data) {
			setProducts(data.data);
		}
	}, [activeCategory]);

	useEffect(() => {
		if(isSearchSuccess){
			const products = brandsAndProducts?.data?.products?.map((item) => ({
				...item,
				value: item.id,
				label: item.name,
				imageUrl: item.images[0]?.imageUrl
			})) as IAutocompleteItem[];

			setSearchResult(products);
		}
	}, [brandsAndProducts, isSearchSuccess]);

	return (
		<Stack spacing={8}>
			<Flex gap={2} mt={[8, 8, null]} alignItems={'center'} w="100%">
				<Menu>
					{!isLargerThan768 ? (
						<MenuButton
							as={Button}
							_focus={{ outline: 'none' }}
							border={'1px solid #C1C8CF'}
							borderRadius={'5px'}
							px={2}
							py={2}
							bgColor={'#FF6F61'}
							color={'white'}
						>
							<BsFilter fontSize="1.7em" />
						</MenuButton>
					) : (
						<MenuButton
							as={Button}
							rightIcon={<BsFilter />}
							_focus={{ outline: 'none' }}
							border={'1px solid #C1C8CF'}
							borderRadius={'10px'}
							px={6}
							py={3}
						>
							Filter
						</MenuButton>
					)}
					<MenuList>
						<MenuItem onClick={onClickAll}>All</MenuItem>
						{categories?.data.map((category) => {
							return (
								<MenuItem
									key={category.id}
									onClick={() => _handleClick(category.id)}
								>
									{category.name}
								</MenuItem>
							);
						})}
					</MenuList>
				</Menu>
				{!isLargerThan768 ? (
					<Autocomplete
						onChange={setSearchString}
						inputlinkColorMode='light'
						hideToggleButton
						data={searchResult}
						isLoading={isSearchLoading || isSearchFetching}
						onSelect={handleSelect}
					/>
				) : (
					<>
						<Button
							my={[8, 8, 12]}
							ml={10}
							fontSize="sm"
							fontWeight="500"
							color={activeCategory === 'All' ? '#121B26' : '#A0A8AF'}
							_focus={{
								color: '#121B26',
								borderBottom: '2px solid #FF6F61',
								borderRadius: '0',
								// margin: '0',
							}}
							onClick={onClickAll}
						>
							All
						</Button>
						{categories?.data.map((category) => (
							<Button
								key={category.id}
								my={[8, 8, 12]}
								ml={6}
								fontSize="sm"
								fontWeight="500"
								color={activeCategory === category.id ? '#121B26' : '#A0A8AF'}
								_focus={{
									color: '#121B26',
									borderBottom: '2px solid #FF6F61',
									borderRadius: '0',
									// margin: '0',
								}}
								onClick={() => _handleClick(category.id)}
							>
								{category.name}
							</Button>
						))}
					</>
				)}
			</Flex>
			<AllProductsGallery
				products={products}
				isLoading={isLoading || isFetching}
			/>
			{isHasMoreData && (
				<Button
					variant={'secondary'}
					onClick={_handleShowMoreItems}
					w={{ xxl: '52' }}
					h={{ xxl: '10' }}
					alignSelf={'center'}
					my={16}
					_focus={{ outline: 'none' }}
					isLoading={isLoading || isFetching}
				>
					Load More
				</Button>
			)}
		</Stack>
	);
};
