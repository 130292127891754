import React from 'react';
import { 
	Text, 
	chakra,  
	Table, 
	TableContainer, 
	Tbody, 
	Td, 
	Tr, 
	Image,
	Stack,
	VStack, 
} from '@chakra-ui/react';
import {IBrand } from 'Types/Interface/IBrand';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { useGetBrandScanHistoryQuery} from 'services';
import { useParams } from 'react-router-dom';

interface IBrandSummaryProps {
	// Brands?: IBrand[];
	data: IJsonResponse<IBrand> | undefined;
	shouldIgnoreParentPadding: boolean;
}

export const BrandSummary = ({ data, shouldIgnoreParentPadding}:IBrandSummaryProps) => {
	const location = useParams();
	const brandId = location?.brandId || ''; 
	const {data: brandHistory} = useGetBrandScanHistoryQuery(brandId);
	return (
		<Stack
			direction={['column','row']}
			justify={'center'}
			spacing={0}
			align={'center'}
			{...(shouldIgnoreParentPadding && { px: ['0',null, 6,'20'] })}
		>
			<VStack
				justifyContent={'center'}
				alignItems={'center'}
				spacing={10}
				w={['100%', '50%']}
				h={['400px','500px']}
				bg={'#F9F9F9'}
			>
				<Text 
					ml={-12}
					fontSize={['18px','xl']}
					fontWeight={600}
					color={'#586068'}
					textAlign={'left'}
					_after={{
						content: '" "',
						display: 'flex',
						borderBottom:'2.5px solid #586068',
						width: '25%',
						mt: '10px'
					}}
				>
					Your <chakra.span fontWeight={'600'} color={'darkCoral'}>Loop </chakra.span>
					with {data?.data?.name}
				</Text>
				<TableContainer>
					<Table size={'lg'} variant='unstyle'>
						<Tbody color={'#586068'} fontSize={'sm'}>
							<Tr>
								<Td fontWeight={500} color={'#586068'}>Number of surverys:</Td>
								<Td textDecoration={'underline'} fontWeight={600} color={'darkCoral'}>{brandHistory?.data.surveyCount}</Td>
							</Tr>
							<Tr>
								<Td fontWeight={500} color={'#586068'}>Total Scanned:</Td>
								<Td textDecoration={'underline'} fontWeight={600} color={'darkCoral'}>{brandHistory?.data.totalScanned}</Td>
							</Tr>
							<Tr>
								<Td fontWeight={500} color={'#586068'}>Total Earned:</Td>
								<Td textDecoration={'underline'} fontWeight={600} color={'darkCoral'}>{`${brandHistory?.data.totalEarned} LNT`}</Td>
							</Tr>
						</Tbody>
					</Table>
				</TableContainer>
			</VStack>
			<Image 
				w={['100%', '50%']}
				h={['400px','500px']}
				objectFit={'cover'}
				src={data?.data.imageUrl}  
			/>
		</Stack>
	);
};