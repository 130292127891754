import React from 'react';

import {
	Flex,
	Heading,
	VStack,
	Text
} from '@chakra-ui/react';

import { IJumbotronContents } from 'Types/Interface/ILandingPage';

interface IJumbotronProps{
	contents: IJumbotronContents[]
	justify: 'center' | 'flex-start'
	textAlign: 'center' | 'left'
}

export const BrandsJumbotronSection = ({ contents, justify='center', textAlign='center' }: IJumbotronProps) => {
	return (
		<Flex
			alignItems={['flex-start', 'center']}
			flexDirection={{ base: 'column-reverse', md: 'row' }}
			justifyContent={[justify]}
			my={{base:'20',md:'24', xxl:'24'}}
		>
			{contents.map(content => {
				return(
					<VStack
						alignItems={['center', 'center', 'Flex-start']}
						spacing={10}
						textAlign={['left', 'center', 'center']}
						key={content.heading}
					>
						<VStack  spacing={4} >
							<Heading fontSize={{ base: '2xl',  tablet: '2xl', xxl: '4xl' }}
								color="black" 
								fontWeight={'700'}
								lineHeight={['8', '61px']}
								letterSpacing={'-0.03em'}
								alignSelf={['flex-start', 'center', justify, ]}
							>
								{content.heading}
							</Heading>
							<Text fontSize={{base:'xs', sm: 'sm', tablet: 'sm', xxl:'sm' }} 
								lineHeight={[6, 7]} w={{base:'2xs',sm:'xs',tablet:'404px',xl:'404px'}} 
								fontWeight={'400'}
								color="black"
								textAlign={['left', 'center', textAlign]}
								alignSelf={['flex-start', 'center', 'center']}
							>
								{content.text}
							</Text>
						</VStack>
					</VStack>
				);
			})}	
		</Flex>
	);
};
