import { Avatar, Box, Button, Flex, Input, Stack} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import {useAppDispatch,useAppSelector,useToast,useUpdateRegisteredUserProfile} from 'Hooks';
import { useAvatarUpload } from 'Hooks/useAvatarUpload';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { setUserInfo } from 'store/slices';
import { IUserInfo, IUserProfile } from 'Types/Interface/IUser';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useRefreshUserProfile } from 'Utils/useRefreshUserProfile';
import { AccountDetail } from './AccountDetail/AccountDetail';
import { KycStatus } from './KycStatus';

export type FormType = 'fullname' | 'gender' | 'phone' | 'email';

export type InputName =
  | 'avatarUrl'
  | 'dateOfBirth'
  | 'emailAddress'
  | 'fullName'
  | 'gender'
  | 'phoneNumber'
  | 'genderName'
  | 'lastName'
  | 'firstName';

interface IAccountDetail {
  label: string;
  formType?: FormType;
  defaultValue: string | number;
  inputName: InputName;
}

export const AccountDetailList = () => {
	const {
		mutate: updateUserProfile,
		isLoading,
		isSuccess,
		isError,
		error,
	} = useUpdateRegisteredUserProfile();

	const {
		handleImageUpload, 
		isImageUploadError, 
		isImageUploadSuccess, 
		imageUploadError, 
		imageUploadLoading
	} = useAvatarUpload();
	
	const { refreshUserProfile } = useRefreshUserProfile();
	const { alert } = useToast();
	const dispatch = useAppDispatch();
	const [activeForm, setActiveForm] = useState('');
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const { userInfo } = useAppSelector((state) => state.authUser);
	const inititialValues = useMemo(() => userInfo, [userInfo]);

	const { values, setFieldValue, handleSubmit } = useFormik({
		initialValues: { ...inititialValues } as IUserInfo,
		onSubmit: async (values) => {
			const data = {
				firstName: values.firstName,
				lastName: values.lastName,
				emailAddress: values.emailAddress,
				dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DDTHH:mm:ss'),
				phoneNumber: values.phoneNumber,
				gender: values.gender,
				avatar: values.avatarUrl
				// genderName: '',
			} as unknown as IUserProfile;
			updateUserProfile(data);
		},
	});

	const handleChange = (inputName: InputName, value: string | number) => {
		setFieldValue(inputName, value);
	};

	const onSubmit = () => {
		handleSubmit();
	};

	const handleActiveForm = (value: string) => {
		setActiveForm(value);
	};

	const accountDetails: Array<IAccountDetail> = [
		{
			label: 'Full name',
			formType: 'fullname',
			defaultValue: `${userInfo?.firstName} ${values?.lastName}`,
			inputName: 'fullName',
		},
		{
			label: 'Gender',
			formType: 'gender',
			defaultValue: values?.gender ?? '',
			inputName: 'gender',
		},
		{
			label: 'Phone number',
			formType: 'phone',
			defaultValue: values?.phoneNumber ?? '',
			inputName: 'phoneNumber',
		},
		{
			label: 'Email',
			formType: 'email',
			defaultValue: values?.emailAddress ?? '',
			inputName: 'emailAddress',
		},
	];

	useEffect(() => {
		if (isSuccess) {
			alert('success', 'Profile updated successfully');
			dispatch(setUserInfo(values));
		}
		if(isImageUploadSuccess) {
			alert('success', 'Profile picture updated successfully');
			refreshUserProfile();
		}
	}, [isSuccess, isImageUploadSuccess]);
	
	useDefaultErrorHandler(isError, error);
	useDefaultErrorHandler(isImageUploadError, imageUploadError);

	return (
		<Stack spacing={10} flex={1}>
			<Flex alignItems={'center'}>
				<Avatar
					src={userInfo?.avatarUrl}
					name={`${userInfo?.firstName} ${userInfo?.lastName}`}
					objectFit="cover"
					size="xl"
				/>
				<Input
					type="file"
					display="none"
					onChange={handleImageUpload}
					ref={fileInputRef}
				/>
				<Button
					w={'100px'} 
					h={'40px'} 
					_hover={{bg: 'darkCoral', color: '#FFF'}}
					bg={'#FFFFFF'} 
					fontSize={'16px'}
					fontWeight={600}
					boxShadow={'0px 6px 15px 0px rgba(88, 96, 104, 0.20)'}
					color={'darkCoral'}
					cursor={'pointer'}
					isLoading={imageUploadLoading}
					ml={'20px'}
					onClick={() => fileInputRef && fileInputRef.current?.click()}
				>Change</Button>
			</Flex>
			
			{accountDetails.map(({ label, defaultValue, inputName, formType }) => (
				<Box key={formType}>
					<AccountDetail
						label={label}
						placeholder={label}
						inputName={inputName}
						defaultValue={defaultValue}
						onChange={handleChange}
						onSubmit={onSubmit}
						isLoading={isLoading && (inputName === activeForm)}
						isSuccess={isSuccess}
						handleActiveForm={handleActiveForm}
						activeForm={activeForm}
					/>
				</Box>
			))}
			<KycStatus/>
		</Stack>
	);
};
