import React from 'react';
import { Text, Stack, Button } from '@chakra-ui/react';
import { RequestSuccessfulIcon } from 'Components/Icons';

interface IEmailVerificationModalContentSuccessProps {
	onClose: () => void;
}

export const EmailVerificationModalContentSuccess = ({ onClose }: IEmailVerificationModalContentSuccessProps) => {
	return (
		<Stack py={10} px={['20px', '30px']} spacing={5} alignItems={'center'}>
			<RequestSuccessfulIcon boxSize={14} />
			<Text fontSize={['16px', '22px']} fontWeight={'600'}>
				Verification successful
			</Text>
			<Text fontSize={['12px', '14px']} textAlign={'center'} pb={8}>
				Your email verification is successfully done, your profile will be updated soon.
			</Text>
			<Button variant={'primary'} width={'100%'} height={['50px','60px']} fontSize={['16px', '18px']} onClick={onClose}>
				Done
			</Button>
		</Stack>
	);
};
