/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, HStack, IconButton, Image, Stack, Switch, Text } from '@chakra-ui/react';
import { IFeedbackDto, IQuestion } from 'Types/Interface/ISurvey';
import EnterIcon from 'Assets/svg/enter-key.svg';
import { SurveyQuestionType } from 'Types/Enums';
import { FreeText, MultiSelect, SingleSelect, StarRating } from './QuestionComponents';
import { FormikErrors, useFormik } from 'formik';
import { useAppSelector, useAppDispatch, usePressEnter } from 'Hooks';
import { PrivacySelection } from './PrivacySelection';
import { SurveyCompleted } from './SurveyCompleted';
import {
	navigateSurvey,
	setIsSurveyComplete,
	setIsCurrentAnswerValid,
	setSection,
	setQrCodeFromDynamicLink,
	setActiveSurvey,
	setFeedbackPoints,
} from 'store/slices';
import { useUpdateFeedbackMutation } from 'services';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { BrandLoop } from './BrandLoop';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';
import { isLastSurveySection } from 'Utils/surveyUtils';

interface ISurveyQuestionProps {
	question: IQuestion;
	ratingValue?: number;
}
interface IFormValues {
	freeText: string;
	ratingValue: number;
	singleSelect: string;
	multiSelect: string[];
	privacyLevelId: string;
	allowBrandContact: boolean;
	userBrandPermissions?: IUserBrandPermission;
}

export const SurveyQuestion = ({ question }: ISurveyQuestionProps) => {
	const [addFeedback, { isLoading, isError, error, isSuccess: isSuccessAddingFeedback, data }] =
		useUpdateFeedbackMutation();
	const {
		activeSurvey,
		activeSurvey: { currentQuestion, survey, responses, surveyQRWebCodeId },
		section,
		surveyCode,
		codeType,
	} = useAppSelector((state) => state.survey);
	const { userCoords } = useAppSelector((state) => state.authUser);

	const isLastSection = isLastSurveySection();

	const dispatch = useAppDispatch();
	const [isAllSurveyDataFilled, setIsAllSurveyDataFilled] = useState(false);
	const isLastQuestion = currentQuestion + 1 === survey.questions.length;

	const { handleChange, setFieldValue, values, errors, isValid, isSubmitting, handleSubmit, resetForm } =
		useFormik<IFormValues>({
			initialValues: {
				freeText: '',
				singleSelect: '',
				ratingValue: 0,
				multiSelect: [],
				privacyLevelId: '',
				allowBrandContact: true,
			},
			validateOnMount: true,
			validate: (values) => {
				const errors: FormikErrors<IFormValues> = {};
				const requiredMessage = '* This answer is required';

				if (section === 'surveySharingSettings') {
					if (!values.privacyLevelId || values.privacyLevelId === '') {
						errors.privacyLevelId = '* Please select a privacy level';
					}
				} else if (section === 'questions') {
					if (
						question.questionType == SurveyQuestionType.FreeText &&
						(!values.freeText || values.freeText.length === 0)
					) {
						errors.freeText = requiredMessage;
					}

					if (question.questionType == SurveyQuestionType.Rating && !values.ratingValue) {
						errors.ratingValue = requiredMessage;
					}

					if (
						question.questionType == SurveyQuestionType.SingleSelection &&
						(!values.singleSelect || values.singleSelect.length === 0)
					) {
						errors.singleSelect = requiredMessage;
					}

					if (
						question.questionType == SurveyQuestionType.MultipleSelection &&
						(!values.multiSelect || values.multiSelect.length === 0)
					) {
						errors.multiSelect = requiredMessage;
					}
				}

				dispatch(setIsCurrentAnswerValid(Object.keys(errors).length === 0));
				return errors;
			},
			onSubmit: (values, { setSubmitting }) => {
				if (!isLastSection && section === 'surveySharingSettings') {
					dispatch(setSection('brandSharingSettings'));
					setSubmitting(false);
					return;
				}

				const surveyResponse = {
					[SurveyQuestionType.FreeText]: {
						questionId: question.id,
						stringValue: values.freeText,
					},
					[SurveyQuestionType.Rating]: {
						questionId: question.id,
						ratingValue: values.ratingValue,
					},
					[SurveyQuestionType.SingleSelection]: {
						questionId: question.id,
						selectedOptionValues: [values.singleSelect],
					},
					[SurveyQuestionType.MultipleSelection]: {
						questionId: question.id,
						selectedOptionValues: values.multiSelect,
					},
				};

				const feedback: IFeedbackDto = {
					isSurveyComplete: isLastSection,
					code: surveyCode,
					responses: [surveyResponse[question.questionType]],
					currentQuestion: currentQuestion < survey.questions.length - 1 ? currentQuestion + 1 : currentQuestion,
					lat: userCoords?.lat, // TODO: get coords
					long: userCoords?.long,
					codeType: codeType,
					surveyQRWebCodeId: surveyQRWebCodeId,
					privacyLevelId: values.privacyLevelId,
					allowBrandContact: values.allowBrandContact,
					brandPermission: values.userBrandPermissions,
				};

				if (section === 'questions') {
					// still answering questions
					addFeedback(feedback).then(() => {
						const updatedResponses = [...responses];
						updatedResponses[currentQuestion] = {
							...updatedResponses[currentQuestion],
							stringValue: values.freeText,
							ratingValue: values.ratingValue,
							selectedOptionValues:
								question.questionType === SurveyQuestionType.SingleSelection
									? [{ id: values.singleSelect }]
									: values.multiSelect.map((value) => ({ id: value })),
						};
						dispatch(setActiveSurvey({ ...activeSurvey, responses: updatedResponses }));

						if (isLastQuestion) {
							dispatch(setSection('surveySharingSettings'));
						}
					});
				} else if (isLastSection) {
					addFeedback(feedback);
					setIsAllSurveyDataFilled(true);
					dispatch(setQrCodeFromDynamicLink(''));
				}

				setSubmitting(false);
			},
		});

	usePressEnter(handleSubmit);

	const errorComponent = (
		<Box color={'kyc.red'} textAlign={'right'} className='error'>
			{section === 'surveySharingSettings' ? (
				<>{errors.privacyLevelId}</>
			) : section === 'questions' ? (
				<>
					{question.questionType === SurveyQuestionType.FreeText && <>{errors.freeText}</>}
					{question.questionType === SurveyQuestionType.SingleSelection && <>{errors.singleSelect}</>}
					{question.questionType === SurveyQuestionType.MultipleSelection && <>{errors.multiSelect}</>}
					{question.questionType === SurveyQuestionType.Rating && <>{errors.ratingValue}</>}
				</>
			) : (
				<></>
			)}
		</Box>
	);

	const questionComponent = {
		[SurveyQuestionType.FreeText]: <FreeText onChange={handleChange} name={'freeText'} value={values.freeText} />,
		[SurveyQuestionType.Rating]: (
			<StarRating
				onChange={(ratingValue) => setFieldValue('ratingValue', ratingValue)}
				value={values.ratingValue}
				options={question.questionOptions}
			/>
		),
		[SurveyQuestionType.SingleSelection]: (
			<SingleSelect
				questionOptions={question.questionOptions}
				onChange={(id) => setFieldValue('singleSelect', id)}
				selectedValue={values.singleSelect}
			/>
		),
		[SurveyQuestionType.MultipleSelection]: (
			<MultiSelect
				questionOptions={question.questionOptions}
				onChange={(ids) => setFieldValue('multiSelect', ids)}
				value={values.multiSelect}
			/>
		),
	};

	useEffect(() => {
		if (isSuccessAddingFeedback) {
			resetForm();
			const index = Math.min(currentQuestion + 1, survey.questions.length - 1);
			dispatch(navigateSurvey({ index, dir: 'NEXT' }));
		}
		if (isAllSurveyDataFilled && isSuccessAddingFeedback) {
			dispatch(setFeedbackPoints(data ?? 0));
			dispatch(setIsSurveyComplete(true));
		}
	}, [isSuccessAddingFeedback, isAllSurveyDataFilled]);

	useDefaultErrorHandler(isError, error);

	useDefaultErrorHandler(isError, error);

	useEffect(() => {
		const currentResponse = responses[currentQuestion];

		if (question.questionType === SurveyQuestionType.FreeText) {
			setFieldValue('freeText', currentResponse?.stringValue || '');
		} else if (question.questionType === SurveyQuestionType.SingleSelection) {
			setFieldValue('singleSelect', currentResponse?.selectedOptionValues?.[0]?.id || '');
		} else if (question.questionType === SurveyQuestionType.MultipleSelection) {
			const result = currentResponse?.selectedOptionValues?.map((value) => value?.id) || [];
			setFieldValue('multiSelect', result);
		} else if (question.questionType === SurveyQuestionType.Rating) {
			setFieldValue('ratingValue', currentResponse?.ratingValue || 0);
		}
	}, [currentQuestion, activeSurvey, responses]);

	const isCompleteSurvey = isAllSurveyDataFilled && isSuccessAddingFeedback;
	return (
		<Box w={['90%', '90%', '650px', '650px']}>
			{isCompleteSurvey ? (
				<SurveyCompleted />
			) : (
				<Box mb={['16']}>
					<Stack spacing={8}>
						{section === 'surveySharingSettings' ? (
							<>
								<PrivacySelection
									privacyLevelId={values.privacyLevelId}
									onChange={(value) => setFieldValue('privacyLevelId', value)}
								/>
								<Flex alignItems={'flex-start'} mt='1'>
									<Switch
										isChecked={values.allowBrandContact}
										size='lg'
										variant='primary'
										onChange={(event) => setFieldValue('allowBrandContact', event.target.checked)}
									/>
									<Text fontSize={'md'} marginLeft={'4'}>
										Allow {survey.product.brand.name} to contact me for further information about my feedback for{' '}
										{survey.product.name}.
									</Text>
								</Flex>
								<Text fontSize={'md'} color={'placeholderText'}>
									By clicking the submit button, you agree to share the above information with Loop and the associated
									brands
								</Text>
								{Object.keys(errors).length > 0 && errorComponent}
							</>
						) : section === 'brandSharingSettings' ? (
							<BrandLoop
								brand={activeSurvey.survey.product.brand}
								onSubmit={(permission) => {
									setFieldValue('userBrandPermissions', permission);
									handleSubmit();
								}}
								isLoading={isLoading}
							/>
						) : (
							<>
								<Heading color={'blackTextColor.100'} size='lg'>
									{question.title}
								</Heading>
								<Box>{questionComponent[question.questionType]}</Box>
								{errorComponent}
							</>
						)}

						{section !== 'brandSharingSettings' && (
							<HStack spacing={4} display={['none', 'flex']}>
								<Button
									isLoading={isLoading}
									disabled={!isValid || isSubmitting}
									onClick={() => handleSubmit()}
									variant='primary'
								>
									{isLastSection ? 'Finish' : 'Next'}
								</Button>
								<HStack>
									<Text>or press enter </Text>
									<Image src={EnterIcon} />
								</HStack>
							</HStack>
						)}

						{section !== 'brandSharingSettings' && (
							<Flex alignSelf={'flex-end'} pt={16} display={['flex', 'none']}>
								<IconButton
									aria-label={'next step'}
									fontSize={'35px'}
									icon={<ChevronRightIcon color={'white'} />}
									isRound
									bg={'loop.500'}
									size={'lg'}
									isLoading={isLoading}
									onClick={() => handleSubmit()}
								></IconButton>
							</Flex>
						)}
					</Stack>
				</Box>
			)}
		</Box>
	);
};
