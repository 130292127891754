import { 
	UserPreferenceFormName, 
	Gender, 
	Ethnicity, 
	Children, 
	Industry, 
	EducationEnum, 
	Religion, 
	Sexuality, 
	PoliticsEnum, 
	HabitsEnum } from 'Types/Enums';

type Currency = 'EUR' | 'USD' | 'NGN' | 'JPY' | 'GBP';

export const currencyFormatter = (price:number, currency: Currency) => {
	return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, maximumFractionDigits: 2 }).format(price);
};

const RATE = 0.01;
export const convertLNTToUsd = (lnt: number) => lnt * RATE;
export const convertUSDToLNT = (usd: number) => Math.ceil(usd / RATE);

export const getAuthToken = () => localStorage.getItem('accessToken');
export const clearBrowserStorage = () => {
	localStorage.clear();
};

const genderValue: Record<string, string> = {
	[Gender.Male]: 'Male',
	[Gender.Female]: 'Female',
	[Gender.NoInput]: 'NoInput',
	[Gender.NonBinary]: 'Non-binary',
	[Gender.Others]: 'Others',
	[Gender.NA] : 'NA'
};
const ethnicityValue: Record<string, string> = {
	[Ethnicity.AmericanIndian]: 'American Indian',
	[Ethnicity.BlackAfrican]: 'BlackAfrican',
	[Ethnicity.EastAsian]: 'East Asian',
	[Ethnicity.Latino]: 'Latino',
	[Ethnicity.White]: 'White',
	[Ethnicity.SouthAsian]: 'SouthAsian',
	[Ethnicity.NoInput]: 'NoInput',
	[Ethnicity.Others]: 'Others',
	[Ethnicity.NA]: 'NA',
};
const childrenValue: Record<string, string> = {
	[Children.No]: 'No',
	[Children.Yes]: 'Yes',
	[Children.NoInput]: 'NoInput',
	[Children.NA]: 'NA'
};
const habitsValue: Record<string, string> = {
	[HabitsEnum.No]: 'No',
	[HabitsEnum.Yes]: 'Yes',
	[HabitsEnum.NoInput]: 'NoInput',
	[HabitsEnum.NA]: 'NA'
};
const industryValue: Record<string, string> = {
	[Industry.InformationTechnology]: 'Information Technology',
	[Industry.Legal]: 'Legal',
	[Industry.Medical]: 'Medicine',
	[Industry.Others]: 'Others',
	[Industry.NA]: 'NA'
};
const educationValue: Record<string, string> = {
	[EducationEnum.Highschool]: 'Highschool',
	[EducationEnum.Postgrad]: 'Postgrad',
	[EducationEnum.Undergrad]: 'Undergrad',
	[EducationEnum.NoInput]: 'NoInput',
	[EducationEnum.NA]: 'NA'
};
const religionValue: Record<string, string> = {
	[Religion.Agnostic]: 'Agnostic',
	[Religion.Atheist]: 'Atheist',
	[Religion.Buddist]: 'Buddist',
	[Religion.Catholic]: 'Catholic',
	[Religion.Christian]: 'Christian',
	[Religion.Hindu]: 'Hindu',
	[Religion.Jewish]: 'Jewish',
	[Religion.Muslim]: 'Muslim',
	[Religion.NoInput]: 'NoInput',
	[Religion.Others]: 'Others',
	[Religion.NA]: 'NA'
};
// const yesNoValue: Record<string, string> = {
// 	[TrueFalse.False]: 'No',
// 	[TrueFalse.True]: 'Yes',
// };
const sexualityValue: Record<string, string> = {
	[Sexuality.Gay]: 'Gay',
	[Sexuality.BiSexual]: 'BiSexual',
	[Sexuality.Straight]: 'Straight',
	[Sexuality.Lesbian]: 'Lesbian',
	[Sexuality.NoInput]: 'NoInput',
	[Sexuality.NA]: 'NA'
};

const politicsValue: Record<string, string> = {
	[PoliticsEnum.Liberal] : 'Liberal',
	[PoliticsEnum.Moderate] : 'Moderate',
	[PoliticsEnum.Conservative] : 'Conservative',
	[PoliticsEnum.NoInput]: 'NoInput',
	[PoliticsEnum.Others]: 'Others',
	[PoliticsEnum.NA]: 'NA'
};

export const getValueName = (title: UserPreferenceFormName, value?: string | number) => {
	switch (title) {
	case UserPreferenceFormName.Gender:
		return genderValue[value as string];		
	case UserPreferenceFormName.Ethnicity:
		return ethnicityValue[value as string];		
	case UserPreferenceFormName.Children:
		return childrenValue[value as string];		
	case UserPreferenceFormName.Hometown:
		return value;		
	case UserPreferenceFormName.Age:
		return value;
	case UserPreferenceFormName.Industry:
		return industryValue[value as string];		
	case UserPreferenceFormName.Education:
		return educationValue[value as string];		
	case UserPreferenceFormName.Sexuality:
		return sexualityValue[value as string];		
	case UserPreferenceFormName.School_location:
		return value;		
	case UserPreferenceFormName.ReligionName:
		return value;		
	case UserPreferenceFormName.Religion:
		return religionValue[value as string];		
	case UserPreferenceFormName.Politics:
		return politicsValue[value as string];		
	case UserPreferenceFormName.Alchohol:
		return habitsValue[value as string];		
	case UserPreferenceFormName.Drugs:
		return habitsValue[value as string];		
	case UserPreferenceFormName.Marijuana:
		return habitsValue[value as string];		
	case UserPreferenceFormName.Smoke:
		return habitsValue[value as string];		
	case UserPreferenceFormName.PoliticsName:
		return value;		
	default:
		return null;
	}
};
