import { useEffect, useState } from 'react';


const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

type Dimension = {
    width: number,
	height: number,
}

export const useWindowDimension = () : Dimension => {
	const [windowDimensions, setWindowDimensions] = useState<Dimension>(getWindowDimensions());
	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return windowDimensions;
};