import React, { useEffect } from 'react';
import {
	Stack,
	Flex,
	Text,
	Button,
	Divider,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	ModalFooter,
	HStack
} from '@chakra-ui/react';
import { UserPreferenceFormName } from 'Types/Enums';
import { userPreferenceQuestions } from 'Data/UserPreferenceQuestions';
import { StepItem } from 'Pages/RegisterPage/Steps';
import { IUserPreferenceQuestion } from 'Types/Interface/IUserPreferenceQuestion';
import { getValueName } from 'Utils';

interface IPreferenceItemProps {
  title: UserPreferenceFormName;
  value?: string | number;
  onSave?: () => void;
  actionLabel?: string;
  onChange: (name:string, val:string|number) => void;
  isLoading?: boolean;
  canClose?: boolean;
}

export const PreferenceItem = ({
	title,
	value,
	onSave,
	actionLabel,
	onChange,
	isLoading,
	canClose
}: IPreferenceItemProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const selectedQuestion = userPreferenceQuestions.find(
		(item) => item.name === title,
	);

	const handleChange = (val:string| number) => {
		onChange(title,val);
	};

	const handleSave = () => {
		onSave && onSave();
		
	};

	useEffect(() => {
		if(canClose){
			onClose();
		}
	},[canClose]);

	return (
		<Stack>
			<Flex flex={1} justifyContent="space-between">
				<Text
					textTransform="capitalize"
					color="#586068"
					fontSize="md"
					fontWeight="bold"
				>
					{title.split('_').join(' ')}
				</Text>
				<Button
					color="loop.500"
					_hover={{
						textDecoration: 'underline',
					}}
					onClick={onOpen}
				>
					{actionLabel || 'Edit'}
				</Button>
			</Flex>
			<Text>{getValueName(title, value)}</Text>
			<Divider />
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<StepItem
							data={selectedQuestion as IUserPreferenceQuestion}
							stepsLeft={0}
							value={value || ''}
							onChange={handleChange}
							hideIcon
							activeColor='loop.500'
						/>
					</ModalBody>
					<ModalFooter justifyContent="flex-start" mt={5}>
						<HStack spacing={4}>
							<Button 
								variant="primary"
								onClick={handleSave}
								isLoading={isLoading}>Save</Button>
							<Button variant='unstyled' color="#ADB3B9"
								_hover={{
									color:'black'
								}}
								onClick={onClose}>Cancel</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Stack>
	);
};
