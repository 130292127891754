import React from 'react';

type Option = {
    label: string;
    value: string | number ;
}

export enum userPereferenceQuestionType {
    optionSelect = 10,
    freeText = 20,
}

type fields = string[]
export interface IUserPreferenceQuestion {
    question: string;
    options?: Option[];
    icon: React.ReactNode;
    questionType?: userPereferenceQuestionType;
    inputFields?: fields,
    name: string
}
