import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/BabyStrollerIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const BabyStrollerIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const BabyStrollerIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M18.3804 4.54265H15.489C15.1485 4.54265 14.8694 4.81956 14.8694 5.16223V8.2601H8.67369V1.44477C8.67369 1.10422 8.39678 0.825195 8.05411 0.825195C5.89951 0.825047 3.86619 1.68034 2.36048 3.18601C-0.878003 6.41856 -0.782076 11.7297 2.63524 14.8225C2.24251 15.865 2.59353 17.0914 3.56957 17.7503C4.68627 18.504 6.23517 18.2318 7.01164 17.0816C7.28699 16.6737 7.43458 16.1934 7.43458 15.695H8.67373C8.67373 17.4806 10.4955 18.6639 12.1173 17.9787C13.3717 17.4489 13.9369 16.0215 13.4685 14.8179C15.11 13.3401 16.1087 11.2226 16.1087 8.87968V5.7818H18.3805C18.7226 5.7818 19 5.50441 19 5.16223C19 4.82004 18.7226 4.54265 18.3804 4.54265ZM7.43454 2.09248V7.38388L3.69508 3.64438C4.777 2.742 6.07782 2.21497 7.43454 2.09248ZM2.81874 4.5205L6.55832 8.26007H1.26721C1.39186 6.88009 1.93072 5.58531 2.81874 4.5205ZM4.95624 16.9341C4.26782 16.9338 3.71723 16.3804 3.71708 15.695C3.71753 14.9808 4.3053 14.4563 4.95624 14.4558C5.28795 14.4559 5.59451 14.5885 5.82044 14.81C5.97243 14.9589 6.05845 15.1127 6.12332 15.293C6.40895 16.0563 5.83443 16.9338 4.95624 16.9341ZM11.152 16.9341C10.2756 16.9337 9.69876 16.0578 9.98491 15.293C10.3469 14.2872 11.6376 14.1852 12.169 15.0041C12.2032 15.0544 12.2829 15.1612 12.3377 15.3345C12.5785 16.0999 12.0088 16.9339 11.152 16.9341ZM12.7431 13.8022C11.5593 12.7966 9.7716 13.1324 9.01005 14.4558H7.09874C6.34134 13.1387 4.5598 12.7915 3.35853 13.8042C2.18481 12.6946 1.41917 11.1743 1.26706 9.49506C1.46114 9.49506 14.6475 9.49506 14.8415 9.49506C14.6895 11.1864 13.9199 12.698 12.7431 13.8022Z" fill={color}/>
		</Icon>
	);
};