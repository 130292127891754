import React from 'react';
// import { ICollection } from 'Types/Interface/ICollection';
import { BrandGalleryItems } from './BrandGalleryItems';
import { Flex } from '@chakra-ui/react';
import { IBrand } from 'Types/Interface/IBrand';

interface IBrandGalleryProps {
  brands?: IBrand[];
  visibility: boolean;
  isLoading?: boolean
}

export const BrandGallery = ({ isLoading, brands, visibility }: IBrandGalleryProps ) => {

	return (
		<Flex >
			<BrandGalleryItems 
				items={brands}
				visibility={visibility} 
				isLoading={isLoading}
			/>
		</Flex>
	);
};
