import { IBrandsCategory } from 'Types/Interface/ILandingPage';

export const AllBrandsCategories: IBrandsCategory[] = [
	{
		label: 'All',
	},
	{
		label: 'Beauty',
	},
	{
		label: 'Cars',
	},
	{
		label: 'Clothing',
	},
	{
		label: 'Dietery',
	},
	{
		label: 'Fitness',
	},
	{
		label: 'Games',
	},
	{
		label: 'Household',
	},
	{
		label: 'Kids',
	},
	{
		label: 'Laundry',
	},
	{
		label: 'Menswear',
	},
	{
		label: 'Nutrition',
	},
];
