import React from 'react';
import { Faqs } from 'Constants/FaqConstant';
import { Flex, 
	Heading, 
	Accordion, 
	AccordionItem, 
	AccordionButton, 
	Box, AccordionIcon, AccordionPanel } from '@chakra-ui/react';

export const Faq = () => {
	return(
		<Flex direction={'column'} mb={{base:5}} px={[2, '150px']}>
			<Heading 
				textAlign='left'
				ml={4}
				mb={'8'}
				fontSize={'18px'}
			>	
			Frequently asked questions
			</Heading>
			<Accordion allowToggle>
				{Faqs.map(faq => {
					return (
						<AccordionItem key={faq.id}>
							<AccordionButton
								_hover={{outline: 'none'}}
								py={6}
								border={'none'}
							>
								<Box 
									as="span" 
									flex='1' 
									textAlign='left'
									color={'#121B26'}
									fontSize={'16px'}
									fontWeight={500}
									pr={10}
								>
									{faq.title}
								</Box>
								<AccordionIcon  />
							</AccordionButton>
							<AccordionPanel
								pb={4}
								fontSize={'12px'}
								fontWeight={400}
								color={'#586068'}
								textAlign={'justify'}
								lineHeight={'8'}
							>
								{faq.subtext}
							</AccordionPanel>
						</AccordionItem>
					);
				})}
			</Accordion>
		</Flex>	
	);
};