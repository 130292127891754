import React from 'react';
import { IBrandIcon } from 'Types/Interface/IBrandIcon';
import { 
	ArchibaldIcon, 
	CadburyIcon, 
	CocacolaIcon, 
	NikeIcon, 
	SephoraIcon, 
	ZaraIcon, 
	RichardJamesIcon 
} from 'Components/Icons';
export const brandIcons: IBrandIcon[] = [
	{
		label: 'Richard James',
		icon: <RichardJamesIcon/>,
		key: 1,
	},
	{
		label: 'Nike',
		icon: <NikeIcon/>,
		key: 2,
	},
	{
		label: 'Cadbury',
		icon: <CadburyIcon/>,
		key: 3,
	},
	{
		label: 'Zara',
		icon: <ZaraIcon/>,
		key: 4,
	},
	{
		label: 'Sephora',
		icon: <SephoraIcon/>,
		key: 5,
	},
	{
		label: 'Cocacola',
		icon: <CocacolaIcon/>,
		key: 6,
	},
	{
		label: 'Archibald',
		icon: <ArchibaldIcon/>,
		key: 7,
	},
];