import React from 'react';
import { DashBoardLayout } from 'Layouts';
import { useParams } from 'react-router-dom';
import {
	theme,
	Spacer,
	ListItem,
	useMediaQuery,
	Flex,
	Spinner,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	ListIcon,
	List,
	Text,
	Divider,
	VStack,
	TableContainer,
	Table,
	Tbody,
	Td,
	Tr
} from '@chakra-ui/react';
import { ProductCarousel } from 'Pages/ExplorePage/sections/ProductCarousel';
// import { IProduct } from 'Types/Interface/IProduct';
import { useGetProductDetailsQuery, useGetProductScanHistoryQuery } from 'services';
import { ScrollUpBtn } from 'Components/ScrollUpBtn';
import { Description } from './Description';
import { IProductDetails } from 'Types/Interface/IProduct';
import { BsDot, BsSquareFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';

export interface IRouteMatch {
  productId: string;
}

export const ProductPage = () => {
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const location = useParams();
	const productId = location?.productId || '';
	const navigate = useNavigate();
	const {data:productHistory} = useGetProductScanHistoryQuery(productId);
	const { data, isLoading } = useGetProductDetailsQuery(productId);

	return (
		<>
			{	isLoading ? 
				<Flex 
					justifyContent={'center'}
					alignItems={'center'}
					padding='6' 
					boxShadow='lg' 
					bg='white'
					h={'100vh'}
					w={'full'}
				>
					<Spinner size='xl' color='darkCoral' />
				</Flex> :
				<DashBoardLayout>
					<Breadcrumb display={['none', 'flex']} my={8} spacing='8px' separator={<BsDot color='blackTextColor.200' />}>
						<BreadcrumbItem>
							<BreadcrumbLink 
								fontSize={'xs'} 
								onClick={()=> navigate(AppRoutes.home)}
								color={'blackTextColor.200'}
								_hover={{
									textDecoration: 'none'
								}}
							>
								Home
							</BreadcrumbLink>
						</BreadcrumbItem>

						<BreadcrumbItem>
							<BreadcrumbLink 
								fontSize={'xs'} 
								onClick={()=> navigate(AppRoutes.allProducts)}
								color={'blackTextColor.200'}
								_hover={{
									textDecoration: 'none'
								}}
							>
								Products
							</BreadcrumbLink>
						</BreadcrumbItem>

						<BreadcrumbItem isCurrentPage>
							<BreadcrumbLink 
								fontSize={'xs'} 
								color={'loopGrey.700'}
								_hover={{
									textDecoration: 'none'
								}}
							>
								{data?.name}
							</BreadcrumbLink>
						</BreadcrumbItem>
					</Breadcrumb>
					<Description isMobile={isMobile} {...(isMobile && { shouldIgnoreParentPadding: true })} data={data as IProductDetails} productId={productId} />
					<Spacer h={14} />
					<Divider />
					<VStack align={'flex-start'} py={[5,10]} spacing={5} >
						<Text fontSize={['14px','24px']} color={'brand.black'}>Features</Text>
						{data?.features?.map((feature) => (
							<List spacing={3} key={feature} >
								<ListItem display={'flex'} alignItems={'center'} justifyContent={'center'} fontSize={['12px','md']} fontWeight={'400'}   color={'loopGrey.500'}>
									<ListIcon w={'5px'} h={'5px'} as={BsSquareFill} borderRadius={'1px'}  color='loop.500' />
									<Text textTransform={'capitalize'}>{feature}</Text>
								</ListItem>
							</List>
						))}
					</VStack>
					<Divider />
					<Divider />
					<VStack  align={'flex-start'} py={10} spacing={5}>
						<Text fontSize={['14px','24px']} color={'brand.black'}>Your history with {data?.brand?.name}</Text>
						<TableContainer >
							<Table ml={'-10px'} style={{borderCollapse:'separate', borderSpacing:'0 1.5em'}} size={'sm'} variant='unstyled'>
								<Tbody >
									<Tr>
										<Td fontSize={['12px','16px']} color={'loopGrey.500'} >Number of surveys:</Td>
										<Td fontSize={['12px','16px']} fontWeight={400}  color={'darkCoral'}>{productHistory?.data?.surveyCount}</Td>
									</Tr>
									<Tr>
										<Td fontSize={['12px','16px']} color={'loopGrey.500'} >Total Scanned:</Td>
										<Td fontSize={['12px','16px']} fontWeight={400}  color={'darkCoral'}>{productHistory?.data?.totalScanned}</Td>
									</Tr>
									<Tr>
										<Td fontSize={['12px','16px']} color={'loopGrey.500'} >Total Earned:</Td>
										<Td fontSize={['12px','16px']} fontWeight={400} textDecoration={'underline'} color={'darkCoral'}>{`${productHistory?.data?.totalEarned} LNT`}</Td>
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
					</VStack>
					{/* <Divider /> */}
					{!!data?.brand?.products?.length && (
						<>
							<Spacer h={8}/>
							<ProductCarousel
								title={`More products from ${data?.brand?.name}`}
								products={data?.brand?.products}
							/>
						</>
					)}
					<ScrollUpBtn />
				</DashBoardLayout>
			}
		</>
	);
};
