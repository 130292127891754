import React from 'react';
import { useToast as useToaster, Flex, Button, Text } from '@chakra-ui/react';

type Status = 'info' | 'warning' | 'success' | 'error';

type StatusStyleProps = {
  bg: string;
  borderColor: string;
  color: string;
};

const BG_COLOUR: Record<Status, StatusStyleProps> = {
	error: {
		bg: '#FFFFFF',
		borderColor: 'error',
		color: 'brand.black',
	},
	success: {
		bg: '#FFFFFF',
		borderColor: 'success',
		color: 'brand.black',
	},
	info: {
		bg: '#FFFFFF',
		borderColor: '#3182CE',
		color: 'brand.black',
	},
	warning: {
		bg: '#FFFFFF',
		borderColor: 'warning',
		color: 'brand.black',
	},
};

export const useToast = (time?: number) => {
	const toast = useToaster();

	const alert = (status: Status, message?: string) => {
		return toast({
			title: message,
			isClosable: true,
			duration: time || 3000,
			position: 'top-left',
			render: ({ onClose }) => (
				<Flex
					color={BG_COLOUR[status].color}
					p={3}
					bg={BG_COLOUR[status].bg}
					borderLeftColor={BG_COLOUR[status].borderColor}
					borderLeftWidth={3}
					justifyContent="space-between"
					alignItems="center"
					borderRadius="5px"
					borderLeftRadius={0}
					boxShadow="0px 15px 30px rgba(0, 0, 0, 0.08)"
				>
					<Text>{message}</Text>
					<Button
						onClick={onClose}
						variant="outline"
						p={2}
						px={4}
						borderRadius={4}
						ml={3}
					>
            Ok
					</Button>
				</Flex>
			),
		});
	};

	return { alert };
};
