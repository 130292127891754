import SephoraLogo from 'Assets/svg/Sephora-logo.svg';
import makeup4everLogo from 'Assets/svg/makeup4everLogo.svg';
import FentyLogo from 'Assets/svg/FentyLogo.svg';
import ChanelLogo from 'Assets/svg/chanelLogo.svg';
import ChanelModel from 'Assets/Images/ChanelModel.png';
import FentyModel from 'Assets/Images/FentyModel.png';
import Makeup4evaModel from 'Assets/Images/Makeup4evaModel.png';
import { ICollection } from 'Types/Interface/ICollection';

export const Beauty:ICollection[] = [
	{
		label:'Chanel',
		coverImage: ChanelModel,
		brandLogo: ChanelLogo,
		brandName: 'Chanel',
	},
	{
		label:'Fenty',
		coverImage: FentyModel,
		brandLogo: FentyLogo,
		brandName: 'Fenty',
	},
	{
		label:'Makeup4eva',
		coverImage: Makeup4evaModel,
		brandLogo: makeup4everLogo,
		brandName: 'Makeup4eva',
	},
	{
		label:'Sephora',
		coverImage: FentyModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
	},
];