import { Divider, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { useGetAllScanHistoryQuery } from 'services';
import { SummaryLabelEnum } from './Summary';
import { SummaryItem } from './SummaryItem';

export const SummaryMobile = () => {
	const { data, isLoading } = useGetAllScanHistoryQuery();
	return (
		<Stack
			borderWidth={1}
			borderRadius={'10px'}
			spacing={8}
			justify={'center'}
			h={'auto'}
		>
			{/* <Select 
				placeholder='Last 7 days ' 
				w={'100px'}  
				size={'xs'}
				variant={'outline'}
				bg={'rgba(217, 217, 217, 0.06)'}
				border={'1px solid'}
				// borderColor={'rgba(255, 111, 97, 0.5)'}
				borderRadius={'5px'}
				alignSelf={'flex-end'}
				my={4} mx={3}
			>
				<option value='option1'>Option 1</option>
				<option value='option2'>Option 2</option>
				<option value='option3'>Option 3</option>
			</Select> */}
			<Flex
				justifyContent={'center'}
				alignItems={'center'}
				py={6}
				
			>
				<SummaryItem
					title={SummaryLabelEnum.TotalEarned}
					value={(data?.data.totalEarned || 0).toLocaleString() + ' LNT'}
					isLoading={isLoading}
					fontSize={'25px'}
				/>
			</Flex>
			<Flex 
				padding={4}
				py={6}
				borderBottomRadius={'10px'}
				borderColor={'#FFEEEC'}
				background={'#FFEEEC'}
				justifyContent={'space-between'}
				alignItems={'center'}
				wrap={'wrap'}
			>
				<SummaryItem
					title={SummaryLabelEnum.TotalScanned}
					value={data?.data.totalScanned || 0}
					isLoading={isLoading}
					fontSize={'14px'} />
				<Divider h={'45px'} orientation='vertical'  borderColor={'#FF6F61'}  />
				<SummaryItem
					title={SummaryLabelEnum.SurveyCount}
					value={data?.data.surveyCount || 0}
					isLoading={isLoading}
					fontSize={'14px'} />
				<Divider h={'45px'} orientation='vertical'  borderColor={'#FF6F61'}  />
				<SummaryItem
					title={SummaryLabelEnum.TotalBrand}
					value={data?.data.totalBrand || 0}
					isLoading={isLoading}
					fontSize={'14px'}
				/>
			</Flex>
		</Stack>
	);
};