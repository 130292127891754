import React, { useEffect } from 'react';
import { Image, Text, Flex, Box, Spinner, chakra } from '@chakra-ui/react';
import { currencyFormatter } from 'Utils';
import { useNavigate } from 'react-router-dom';
import { IOngoingFeedBack } from 'Types/Interface/ISurvey';
import { useContinueSurveyMutation } from 'services';
import { useDispatch } from 'react-redux';
import { setActiveSurvey, setCodeType, setSurveyCode } from 'store/slices';
import { CodeType } from 'Types/Enums';
import { OngoingSurveyProgressIndicator } from './OngoingSurveyProgress';
import { AppRoutes } from 'Routes/AppRoutes';
import { useDefaultErrorHandler } from 'Utils/errorHandling';

interface IOngoingSurveyProps {
  survey: IOngoingFeedBack;
}

export const OngoingSurvey = ({ survey }: IOngoingSurveyProps) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [getSurvey, { data, isSuccess, isLoading, isError, error }] =
    useContinueSurveyMutation();

	useDefaultErrorHandler(isError, error);

	const handleNavigate = () => {
		getSurvey(survey.qrCode);
	};

	useEffect(() => {
		if (isSuccess && data?.data) {
			dispatch(setActiveSurvey(data?.data));
			dispatch(setSurveyCode(survey.qrCode));
			dispatch(setCodeType(CodeType.qrCode));
			setTimeout(() => {
				navigate(AppRoutes.survey.replace(':webcode', survey.qrCode));
			}, 500);
		}
	}, [isSuccess, data]);

	return (
		<Box h={'100%'} onClick={handleNavigate} cursor="pointer">
			<Box
				h={['250px', null, null, '300px']}
				w={['190px', null, null, 'full']}
				position="relative"
				borderRadius="lg"
				overflow="hidden"
			>
				<Image
					src={survey?.product?.images[0]?.imageUrl}
					boxSize="100%"
					objectFit="cover"
				/>
				{isLoading && (
					<Flex
						justifyContent="center"
						alignItems="center"
						position="absolute"
						top={0}
						left={0}
						right={0}
						bottom={0}
						bg="rgba(0,0,0,0.5)"
					>
						<Spinner color="loop.500" size="xl" />
					</Flex>
				)}
				<Box pos={'absolute'} bottom={'5%'} left={'5%'} w="90%">
					<OngoingSurveyProgressIndicator
						currentQuestion={survey?.currentQuestion}
						totalQuestion={survey?.totalQuestions}
					/>
				</Box>
			</Box>
			<Flex alignItems={'flex-start'} py={4} direction={'column'}>
				<Text
					fontSize={{ base: 'sm', md: 'sm', xl: 'md' }}
					fontWeight={'500'}
					cursor={'pointer'}
					textAlign="left"
					w={'auto'}
					mb={1}
					color={'#000'}
				>
					{survey.product.name}
				</Text>
				<Text
					fontSize={{ base: 'sm', tablet: 'sm', xxl: 'xs' }}
					fontWeight={'400'}
					cursor={'pointer'}
					textAlign="left"
					mb={1}
				>
          By{' '}
					<chakra.span textDecor={'underline'}>
						{survey.product.brand.name}
					</chakra.span>
				</Text>
				<Text
					fontSize={{ base: 'sm', tablet: 'sm', xxl: 'sm' }}
					fontWeight={'bold'}
					cursor={'pointer'}
					textAlign="left"
				>
					{currencyFormatter(survey?.product?.price, 'USD')}
				</Text>
			</Flex>
		</Box>
	);
};
