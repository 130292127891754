import React from 'react';

import { JumbotronSection } from '../Components/JumbotronSection';
import { HowItWorksJumbotronSectionContents } from 'Constants/JumbotronConstant';
import { EarnSection } from './sections/EarnSection';
import { Brands } from './sections/Brands';
import { ReviewCallToActionSection } from './sections/ReviewCallToActionSection';
import { FaqSection } from './sections/FaqSection';
import { LandingPageLayout } from 'Layouts/LandingPageLayout';

export const HowItWorksPage = () => {
	return (
		<LandingPageLayout 
			JumbotronSection={
				<JumbotronSection contents={HowItWorksJumbotronSectionContents}/> 
			}
		>
			<EarnSection />
			<Brands />
			<ReviewCallToActionSection />
			<FaqSection />
		</LandingPageLayout>
	);
};