import React from 'react';

import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Flex,
	Heading,
	Box,
} from '@chakra-ui/react';
import { Faqs } from 'Constants/FaqConstant';

export const FaqSection = () => {
	return (
		<Flex direction={'column'} >
			<Heading 
				textAlign='center'
				mb={'16'}
				fontSize={{ base: 'xl', tablet: '2xl', xxl: '4xl' }}
			>	
			Frequently asked questions
			</Heading>
			<Accordion px={{base:0, xxl:28}} allowToggle>
				{Faqs.map(faq => {
					return (
						<AccordionItem 
							key={faq.id}
							mb={6}
							border={'none'}	
						>
							<AccordionButton
								_hover={{outline: 'none'}}
								py={[4,8]}
								border={'1px solid #E1E7EA'}
								borderRadius={'10px'}
							>
								<Box 
									flex='1' 
									textAlign='left'
									fontWeight={'600'}
									color={'#121B26'}
									fontSize={{ base: 'xs', tablet: 'sm' }}
								>
									{faq.title}
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel 
								fontSize={{ base: 'sm', tablet: 'sm' }}
								fontWeight={400}
								bgColor={'#FFFFFF'}
								color={'#586068'}
								boxShadow={'0px 50px 100px rgba(0, 0, 0, 0.05)'}
								lineHeight={'8'}
								textAlign={'justify'}
								pb={4} py={'6'}
							>
								{faq.subtext}
							</AccordionPanel>
						</AccordionItem>
					);
				})}
			</Accordion>
		</Flex>	
	);
}; 