import * as React from 'react';
import { chakra, ImageProps, forwardRef } from '@chakra-ui/react';
import logo from '../../Assets/svg/UserIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const UserIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const UserIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M11.8687 8.09077C11.8223 8.04334 11.7669 8.00562 11.7058 7.97981C11.6446 7.954 11.579 7.94061 11.5126 7.94043C11.4463 7.94025 11.3806 7.95328 11.3193 7.97876C11.258 8.00424 11.2024 8.04165 11.1558 8.08883C11.1091 8.13601 11.0723 8.192 11.0475 8.25355C11.0227 8.31509 11.0104 8.38096 11.0113 8.44731C11.0122 8.51366 11.0263 8.57916 11.0528 8.64C11.0793 8.70084 11.1176 8.7558 11.1656 8.80167C11.5898 9.2191 11.9263 9.71704 12.1555 10.2663C12.3846 10.8155 12.5018 11.405 12.5 12.0002C12.5 12.6117 10.7471 13.5002 8.00003 13.5002C5.25298 13.5002 3.50002 12.6113 3.50002 11.9992C3.49834 11.4081 3.61391 10.8226 3.84004 10.2765C4.06618 9.73045 4.39838 9.23465 4.81742 8.81782C4.86482 8.77161 4.90257 8.71645 4.92848 8.65554C4.95439 8.59463 4.96795 8.52918 4.96837 8.46299C4.96879 8.39679 4.95606 8.33118 4.93092 8.26994C4.90579 8.20871 4.86874 8.15307 4.82193 8.10627C4.77513 8.05946 4.71949 8.02241 4.65826 7.99727C4.59702 7.97214 4.5314 7.95941 4.46521 7.95983C4.39902 7.96025 4.33357 7.97381 4.27266 7.99972C4.21174 8.02563 4.15658 8.06338 4.11037 8.11077C3.59809 8.62037 3.19197 9.22653 2.91556 9.89415C2.63914 10.5618 2.4979 11.2776 2.50002 12.0002C2.50002 13.624 5.33353 14.5002 8.00003 14.5002C10.6665 14.5002 13.5 13.624 13.5 12.0002C13.5023 11.2727 13.3591 10.5522 13.0789 9.88082C12.7988 9.20947 12.3873 8.60087 11.8687 8.09077Z"
				fill={color}
			/>
			<path
				d="M8 8.5C8.69223 8.5 9.36892 8.29473 9.9445 7.91014C10.5201 7.52556 10.9687 6.97893 11.2336 6.33939C11.4985 5.69985 11.5678 4.99612 11.4327 4.31718C11.2977 3.63825 10.9644 3.01461 10.4749 2.52513C9.98539 2.03564 9.36175 1.7023 8.68282 1.56725C8.00388 1.4322 7.30015 1.50152 6.66061 1.76642C6.02107 2.03133 5.47444 2.47993 5.08986 3.05551C4.70527 3.63108 4.5 4.30777 4.5 5C4.50107 5.92793 4.87016 6.81754 5.52631 7.47369C6.18246 8.12983 7.07207 8.49893 8 8.5ZM8 2.5C8.49445 2.5 8.9778 2.64662 9.38893 2.92133C9.80005 3.19603 10.1205 3.58648 10.3097 4.04329C10.4989 4.50011 10.5484 5.00277 10.452 5.48773C10.3555 5.97268 10.1174 6.41814 9.76777 6.76777C9.41814 7.1174 8.97268 7.3555 8.48773 7.45196C8.00277 7.54843 7.50011 7.49892 7.04329 7.3097C6.58648 7.12048 6.19603 6.80005 5.92133 6.38893C5.64662 5.9778 5.5 5.49445 5.5 5C5.50078 4.3372 5.76442 3.70177 6.2331 3.2331C6.70177 2.76442 7.3372 2.50078 8 2.5Z"
				fill={color}
			/>
		</Icon>
	);
};

export const UserIconOutlineSvg = ({
	size = '20px',
	color = '#586068',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			{...props}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.774658 18.3335C0.774658 18.7937 1.14738 19.1668 1.60799 19.1668C2.0686 19.1668 2.44132 18.7937 2.44132 18.3335C2.44132 15.3928 4.13424 12.7894 6.77304 11.5583C7.68628 12.1518 8.77271 12.5002 9.94051 12.5002C11.1127 12.5002 12.2033 12.1494 13.1187 11.5518C13.9065 11.9236 14.6365 12.424 15.2441 13.0312C16.6609 14.4484 17.4413 16.3315 17.4413 18.3335C17.4413 18.7937 17.814 19.1668 18.2747 19.1668C18.7353 19.1668 19.108 18.7937 19.108 18.3335C19.108 15.8864 18.1542 13.585 16.4224 11.8528C15.843 11.273 15.1695 10.7759 14.4469 10.366C15.2754 9.35858 15.7738 8.06993 15.7738 6.66683C15.7738 3.45028 13.1567 0.833496 9.94051 0.833496C6.72437 0.833496 4.10718 3.45028 4.10718 6.66683C4.10718 8.06652 4.60355 9.35202 5.42839 10.3584C2.56772 11.969 0.774658 14.9783 0.774658 18.3335ZM9.94051 2.50016C12.2379 2.50016 14.1072 4.36947 14.1072 6.66683C14.1072 8.96419 12.2379 10.8335 9.94051 10.8335C7.64315 10.8335 5.77384 8.96419 5.77384 6.66683C5.77384 4.36947 7.64315 2.50016 9.94051 2.50016Z"
				fill={color}
			/>
		</Icon>
	);
};
