import React from 'react';
import { Box } from '@chakra-ui/react';
import { FooterSection } from 'Pages/LandingPage/Components/FooterSection';
import { Navbar } from 'Pages/LandingPage/Components';
import { ScrollUpBtn } from 'Components/ScrollUpBtn';

interface IBrandsGalleryLayoutProps {
	JumbotronSection: React.ReactNode,
	children: React.ReactNode;
	linkColorMode?: 'light' | 'black'
}

export const BrandsGalleryLayout = ({
	JumbotronSection, 
	children, 
	linkColorMode 
}:IBrandsGalleryLayoutProps) => {
	return (
		<Box overflowX="hidden">
			<Box 
				py="8" px={['8', '20']} 
				bgColor= '#FFF6F5'
				bgSize="cover"
				height={['40%','30%','60%']}
			>
				<Navbar linkColorMode={linkColorMode}/>
				{JumbotronSection}
			</Box>
			<Box maxW={'1440px'} m={'0 auto'}  px={['8', '20']}>
				{children}
			</Box>
		
			<FooterSection hasBorder={false} />	
			<ScrollUpBtn />		
		</Box>
	);
};