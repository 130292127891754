export enum SurveyStatus {
    NotAnswered = 0,
    Answered = 1
}

export enum SurveyQuestionType {
    FreeText = 10,
    Rating = 20,
    SingleSelection = 30,
    MultipleSelection = 40,
}

export enum CodeType {
    qrCode=1,
    Webcode=2
}