export enum Gender {
  NoInput = 10,
  Male = 20,
  Female = 30,
  NonBinary = 40,
  Others = 50,
  NA =  0
}

export enum Sexuality {
  NoInput = 1,
  Straight = 2,
  Gay = 3,
  Lesbian = 4,
  BiSexual = 5,
  NA = 0
}

export enum Ethnicity {
  NoInput = 1,
  AmericanIndian = 2,
  BlackAfrican = 3,
  EastAsian = 4,
  Latino = 5,
  White = 6,
  SouthAsian = 7,
  Others = 8,
  NA = 0
}

export enum Children {
  NoInput = 1,
  Yes = 2,
  No = 3,
  NA = 0
}

export enum Industry {
  NoInput = 1,
  Others = 2,
  InformationTechnology = 3,
  Medical = 4,
  Legal = 5,
  NA = 0
}

export enum Religion {
  NoInput = 1,
  Buddist,
  Catholic,
  Christian,
  Hindu,
  Jewish,
  Muslim,
  Atheist,
  Agnostic,
  Others,
  NA = 0
}

export enum EducationEnum {
  NoInput = 1,
  Highschool,
  Undergrad,
  Postgrad,
  NA = 0
}

export enum GenderEnum {
  NA = 0,
  Male = 20,
  Female = 30,
  NonBinary = 40,
  Others = 50,
  // Transfeminine = 60,
  // TransMan = 70,
  // CisMan = 80,
  // other = 90,
  NoInput = 10,
}

export enum UserPreferenceFormName {
  Gender = 'gender',
  Ethnicity = 'ethnicity',
  Children = 'children',
  Education = 'education',
  Religion = 'religion',
  Politics = 'politics',
  Hometown = 'hometown',
  School_location = 'school_location',
  Sexuality = 'sexuality',
  Workplace = 'workplace',
  Industry = 'industry',
  Alchohol = 'alchohol',
  Smoke = 'smoke',
  Marijuana = 'marijuana',
  Drugs = 'drugs',
  AvatarUrl = 'avatarUrl',
  GenderName = 'genderName',
  ReligionName = 'religionName',
  PoliticsName = 'politicsName',
  Age =  'age'
}

export enum TrueFalse {
  True = 1,
  False = 0,
}

export enum PoliticsEnum {
  NA = 0,
  NoInput = 1,
  Liberal,
  Moderate,
  Conservative,
  Others,
}

export enum HabitsEnum {
  NA = 0,
  NoInput = 1,
  Yes = 2,
  No = 3,
}
