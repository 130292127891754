import React, { useEffect, useState } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { IProductImage } from 'Types/Interface/IProduct';
import { Carousel } from './Carousel';

interface IProductImageViewerProps {
  images: IProductImage[];
  isMobile?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProductImageViewer = ({ images , isMobile}: IProductImageViewerProps) => {
	const [currentImage, setCurrentImage] = useState<IProductImage>(images[0]);

	const handleClick = (image: IProductImage) => {
		setCurrentImage(image);
	};

	useEffect(() => {
		if (images.length) {
			setCurrentImage(images[0]);
		}
	}, [images]);

	return <>
		{!isMobile  ? (
			<Flex
				gap={4}
				flex={1}
				direction={['column-reverse', 'column-reverse', 'column-reverse', 'row']}
			>
				<Flex
					gap={2}
					display={['none', 'flex']}
					direction={['row', null, null, 'column']}
				>
					{images?.map((image) => (
						<Image
							cursor="pointer"
							onClick={() => handleClick(image)}
							border="1px solid #E1E7EA"
							h="28"
							w="24"
							objectFit='cover'
							key={image?.id}
							src={image?.imageUrl}
							opacity={currentImage?.id === image?.id ? 1 : 0.5}
							_hover={{
								opacity: 1,
								transition: 'all .5s ease',
							}}
						/>
					))}
				</Flex>

				<Box
					w={['100%',null, '80%']} h={['auto', null, '550px']}  m="0 auto" bgColor={'#f9f9f9'}>
					<Image maxH="550px" objectFit='cover' boxSize='100%' src={currentImage?.imageUrl} />
				</Box>
			</Flex>
		) : (
			!!images.length && <Carousel images={images} />
		)}
	</>;
};
