import React, { useState, useEffect } from 'react';
import { useCombobox } from 'downshift';
import {
	Box,
	Input,
	List,
	ListItem,
	Avatar,
	Flex,
	Spinner,
	IconButton,
	InputGroup,
	InputLeftElement,
} from '@chakra-ui/react';
import { ArrowDownIcon, Search2Icon } from '@chakra-ui/icons';

export interface Item {
  value: string;
  label: string;
}

export interface IAutocompleteItem {
  label: string;
  imageUrl?: string;
  logoUrl?: string;
  value: string;
}

interface IAutoCompleteProps {
  data?: IAutocompleteItem[];
  onSelect?: (inputValue: IAutocompleteItem) => void;
  isLoading?: boolean;
  defaultValue?: IAutocompleteItem;
  hideToggleButton?: boolean;
  placeholder?: string;
  selectedItem?: IAutocompleteItem | null;
  onChange: (inputValue:string) => void;
  inputlinkColorMode?: 'light' | 'black';
}

export const Autocomplete = ({
	data,
	onSelect,
	isLoading,
	hideToggleButton,
	placeholder = 'Search for brands, products etc.',
	selectedItem,
	onChange,
	inputlinkColorMode
}: IAutoCompleteProps) => {
	const [inputItems, setInputItems] = useState(data);

	// const [selectedItem, setSelectedItem] =
	//   useState<IAutocompleteItem | undefined | null>(defaultValue);

	useEffect(() => {
		setInputItems(data);
	}, [data]);

	const {
		isOpen,
		getMenuProps,
		getToggleButtonProps,
		getInputProps,
		getComboboxProps,
		highlightedIndex,
		getItemProps,
	} = useCombobox({
		items: inputItems || [],
		selectedItem,
		onInputValueChange: ({ inputValue }) => {
			inputValue && onChange(inputValue);
			setInputItems(
				data?.filter(({label}) =>
					inputValue && label.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		},
		onSelectedItemChange: ({ selectedItem }) => {
			// setSelectedItem(selectedItem);
			return onSelect && onSelect(selectedItem as IAutocompleteItem);
		},
		itemToString: (item: Item | null) => (item ? item.label : ''),
	});

	return (
		<Box position='relative' w='100%'>
			<Flex w="100%">
				<Box
					{...getComboboxProps()}
					d="flex"
					alignItems="center"
					borderRadius="md"
					w="100%"
				>
					<InputGroup
						w="100%"
					>
						<InputLeftElement
							pointerEvents="none"
							// eslint-disable-next-line react/no-children-prop
							children={<Search2Icon w="13px" h="13px" color={inputlinkColorMode  === 'light' ? '#586068' : '#F2F2F2'} />}
						/>
						<Input
							{...getInputProps()}
							placeholder={placeholder}
							border="none"
							focusBorderColor="none"
							bg={inputlinkColorMode  === 'light' ? 'rgba(242, 242, 242, 0.5)' : 'rgba(236, 239, 242, 0.35)'}
							w="100%"
							_placeholder={{
								color: inputlinkColorMode  === 'light' ? '#586068' : '#F2F2F2',
								fontSize: '13px',
							}}
						/>

					</InputGroup>
				</Box>
				{!hideToggleButton && (
					<IconButton
						aria-label="toggle dropdown"
						ml="2"
						{...getToggleButtonProps()}
						icon={<ArrowDownIcon />}
					/>
				)}
			</Flex>
			<List
				bg="white"
				borderRadius="4px"
				border={isOpen && '1px solid rgba(0,0,0,0.1)'}
				boxShadow="6px 5px 8px rgba(0,50,30,0.02)"
				spacing={3}
				{...getMenuProps({
					style: { maxHeight: (isOpen && 200) || 0, overflowY: 'scroll' },
				})}
				position='absolute'
				left={0}
				right={0}
				top={10}
				zIndex='modal'
			>
				{isOpen ? (
					inputItems && inputItems?.length > 0 ? (
						inputItems?.map((item: IAutocompleteItem, index: number) => (
							<ListItem
								px={2}
								py={2}
								borderBottom="1px solid rgba(0,0,0,0.01)"
								bg={highlightedIndex === index ? 'gray.100' : 'inherit'}
								key={item.value}
								{...getItemProps({ item, index })}
								value={item.value}
								cursor='pointer'
							>
								<Flex alignItems="center" fontSize="sm">
									<Avatar
										src={item.imageUrl || item.logoUrl}
										mr={2}
										size="sm"
										name={item.label}
									/>
									{item.label}
								</Flex>
							</ListItem>
						))
					) : (
           
						<ListItem
							px={2}
							py={2}
							borderBottom="1px solid rgba(0,0,0,0.01)"
							textAlign="center"
							bg="gray.100"
							fontSize="sm"
						>
							{ isLoading ? <Spinner size="sm" color="brand.500" /> : 'No Item'} 
						</ListItem>
					)
				) : null}
			</List>
		</Box>
	);
};
