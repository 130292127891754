import GirlWithEarBud from 'Assets/Images/AccessoriesCategory.png';
import Kids from 'Assets/Images/kidsCategory.png';
import Fitness from 'Assets/Images/FitnessCategory.png';
import Cat from 'Assets/Images/PetsCategory.png';
import { ICollection } from 'Types/Interface/ICollection';

export const Categories:ICollection[] = [
	{
		label:'Kids',
		coverImage: Kids,
		brandLogo: '',
		brandName: 'Kids',
	},
	{
		label:'Pets',
		coverImage: Cat,
		brandLogo: '',
		brandName: 'Pets',
	},
	{
		label:'Accessories',
		coverImage: GirlWithEarBud,
		brandLogo: '',
		brandName: 'Accessories',
	},
	{
		label:'Fitness',
		coverImage: Fitness,
		brandLogo: '',
		brandName: 'Fitness',
	},
];