import React from 'react';
import { Box } from '@chakra-ui/react';
import { Navbar } from 'Pages/LandingPage/Components';
import { FadeOnScroll } from 'Pages/LandingPage/Components/FadeOnScroll';
import { ScrollUpBtn } from 'Components/ScrollUpBtn';
import { Footer } from 'Pages/LandingPage/HomeSection/sections/Footer';
export interface ILandingPageLayoutChild {
  shouldIgnoreParentPadding?: boolean;
}

interface ILandingPageLayoutProps {
  JumbotronSection: React.ReactNode;
  children: React.ReactNode;
  disableGradientBackground?: boolean;
  linkColorMode?: 'light' | 'black';
}

export const LandingPageLayout = ({
	JumbotronSection,
	children,
	linkColorMode,
}: ILandingPageLayoutProps) => {
	return (
		<Box overflowX="hidden">
			<Box
				py="8"
				px={['5', '20',]}
				bg= {'linear-gradient(180deg, rgba(255, 111, 97, 0.18) -97.87%, rgba(255, 111, 97, 0.00) 100%)'}
				bgPosition="center"
				bgRepeat="no-repeat"
				bgSize="cover"
				minHeight={{ base: '70vh', tablet: '50vh', xxl: '50vh' }}
			>
				<Navbar linkColorMode={linkColorMode} />
				<Box maxW={'1440px'} m={'0 auto'}>{JumbotronSection}</Box>
			</Box>

			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					if (child.props.shouldIgnoreParentPadding) {
						return (
							<Box  maxW={'1440px'} m={'0 auto'} mb={['50px', '100px']}>
								<FadeOnScroll>{child}</FadeOnScroll>
							</Box>
						);
					}

					return (
						<Box maxW={'1440px'} m={'0 auto'} mb={['50px', '100px']} px={['8', '6', '20']}>
							<FadeOnScroll>{child}</FadeOnScroll>
						</Box>
					);
				}
				return child;
			})}

			<Footer />
			<ScrollUpBtn />
		</Box>
	);
};
