import React from 'react';
import { Link } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

interface ISeeAllLinkProps {
    onClickLink: () => void;
  }

export const SeeAllLink = ({onClickLink}:ISeeAllLinkProps) => {
	return (
		<Link
			fontSize={{ base: 'sm', tablet: 'sm', xxl: '16px' }}
			fontWeight={'semibold'}
			color='darkCoral'
			cursor={'pointer'}
			mb={4}
			onClick={onClickLink}
		>
			See all
			<ArrowForwardIcon ml={2} h={10} />
		</Link>
	);
};