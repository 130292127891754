import React, { useEffect, useState } from 'react';
import { Box, Flex, Select, SimpleGrid,Text } from '@chakra-ui/react';
import { ICountry } from 'Types/Interface/ICountry';
import { countries } from 'countries-list';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './utils';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { setPaymentMethod } from 'store/slices';
import { useAppDispatch } from 'Hooks';
import valid from 'card-validator';
import { PaymentModalContainer } from './PaymentModalContainer';
import { v4 as uuidv4 } from 'uuid';
import { cardIcons } from 'Constants';
interface IPaymentModalContentProps{
	onClose: () => void;
}

export const PaymentModalContent = ({onClose}:IPaymentModalContentProps) => {
	const [countryList, setCountryList] = useState<Array<ICountry>>();
	const dispatch = useAppDispatch();
	const { handleChange, values, handleSubmit, errors }= useFormik({
		initialValues: initialValues,
		validationSchema,
		onSubmit: (values) => {
			const data = {...values, id: uuidv4()};
			dispatch(setPaymentMethod(data));
			onClose();
		}
	});

	useEffect(() => {
		setCountryList(Object.values(countries));
	}, []);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const creditCard = valid.number(values.cardNumber);
		if(creditCard.card){
			values.cardType = creditCard.card.type;
		}
		handleChange(event);
	};

	return (
		<PaymentModalContainer handleSubmit={handleSubmit} onClose={onClose}>
			<SimpleGrid columns={2} spacing={5} mb={4}>
				<Box>
					<Text className='modal-text'>First Name</Text>
					<CustomInput
						onChange={onChange}
						name="firstName"
						type="text"
						placeholder=""
						error={errors.firstName}
						value={values.firstName}					
					/>
				</Box>
				<Box>
					<Text className='modal-text'>Last Name</Text>
					<CustomInput
						onChange={handleChange}
						name="lastName"
						type="text"
						placeholder=""
						error={errors.lastName}
						value={values.lastName}
					/>
				</Box>
			</SimpleGrid>
			<Flex direction={'column'} mb={4}>
				<Text className='modal-text'>Card Details</Text>
				<Box mb={4} >
					<CustomInput
						onChange={onChange}
						name="cardNumber"
						type="text"
						placeholder="Card Number"
						error={errors.cardNumber}
						value={values.cardNumber}
						src={cardIcons[values.cardType]}
					/>
				</Box>
				<SimpleGrid columns={2} spacing={5} mb={4}>
					<CustomInput
						onChange={handleChange}
						name="expiryDate"
						type="text"
						placeholder="Expiry Date 01/20"
						error={errors.expiryDate}
						value={values.expiryDate}
					/>
					<CustomInput
						onChange={handleChange}
						name="cvv"
						type="text"
						placeholder="CVV"
						error={errors.cvv}
						value={values.cvv}
					/>
				</SimpleGrid>
			</Flex>
			<SimpleGrid columns={1} spacing={5} mb={4}>
				<Text className='modal-text'>Address</Text>
				<CustomInput
					onChange={handleChange}
					name="address"
					type="text"
					placeholder=""
					error={errors.address}
					value={values.address}
				/>
			</SimpleGrid>
			<SimpleGrid columns={2} spacing={5} mb={4}>
				<Box>
					<Text className='modal-text'>City</Text>
					<CustomInput
						onChange={handleChange}
						name="city"
						type="text"
						placeholder=""
						error={errors.city}
						value={values.city}
					/>
				</Box>
				<Box>
					<Text className='modal-text'>State</Text>
					<CustomInput
						onChange={handleChange}
						name="state"
						type="text"
						placeholder=""
						error={errors.state}
						value={values.state}
					/>
				</Box>
			</SimpleGrid>
			<SimpleGrid columns={2} spacing={5} mb={10}>
				<Box>
					<Text className='modal-text'>Zip Code</Text>
					<CustomInput
						onChange={handleChange}
						name="zipCode"
						type="text"
						placeholder=""
						error={errors.zipCode}
						value={values.zipCode}
					/>
				</Box>
				<Box>
					<Text className='modal-text'>Country</Text>
					<Select 
						placeholder='Select country'
						name="country"
						onChange={handleChange}
						error={errors.country}
						value={values.country}
					>
						{countryList?.map((country) => (
							<option key={country.name} value={country.name}>
								{country.name}
							</option>
						))}
					</Select>
				</Box>
			</SimpleGrid>
		</PaymentModalContainer>
	);
};