import { CodeType } from './../Types/Enums/SurveyEnums';
import { createApi } from '@reduxjs/toolkit/query/react';
import { TagType } from 'Types/Enums';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { INotification } from 'Types/Interface/INotification';
import {
	IActiveSurvey,
	IAllScanHistory,
	IFeedbackDto,
	IOngoingFeedBack,
	ISurvey,
	ICodeScanResponse,
} from 'Types/Interface/ISurvey';
import { baseQuery } from './history';
import { IPaginatedResponse } from './types';

type DynamicLinkQrCodeDto = {
	link: string;
};

export const surveyApi = createApi({
	reducerPath: 'surveyApi',
	baseQuery,
	tagTypes: [TagType.Notifications, TagType.Ongoing],
	endpoints: (builder) => ({
		getNotifications: builder.query<IPaginatedResponse<INotification[]>, { pageNumber?: number; pageSize?: number }>({
			query: ({ pageNumber, pageSize }) => `/Notification?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			keepUnusedDataFor: 0,
			providesTags: [TagType.Notifications],
		}),
		getUnreadNotifications: builder.query<
			IPaginatedResponse<INotification[]>,
			{ pageNumber?: number; pageSize?: number }
		>({
			query: ({ pageNumber, pageSize }) =>
				`/Notification?isRead=${false}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
			keepUnusedDataFor: 0,
			providesTags: [TagType.Notifications],
		}),
		getOngoingSurvey: builder.query<IJsonResponse<IOngoingFeedBack[]>, void>({
			query: () => '/feedback/ongoing',
			providesTags: [TagType.Ongoing],
		}),
		readAllNotifications: builder.mutation<unknown, void>({
			query() {
				return {
					url: '/Notification/read-all',
					method: 'POST',
				};
			},
			invalidatesTags: [TagType.Notifications],
		}),
		scanCode: builder.mutation<IJsonResponse<ICodeScanResponse>, { code: string; codeType: CodeType }>({
			query: ({ code, codeType }) => `survey/scan/${code}?codeType=${codeType}`,
			invalidatesTags: [TagType.Notifications, TagType.Ongoing],
		}),
		getSurveyBySurveyId: builder.query<IJsonResponse<ISurvey>, string>({
			query: (surveyId) => `survey/${surveyId}`,
			keepUnusedDataFor: 0,
		}),
		getSurveyByCode:builder.query<IJsonResponse<ISurvey>, string>({
			query: (code) => `survey/code/${code}`,
			keepUnusedDataFor: 0,
		}),
		updateFeedback: builder.mutation<number, IFeedbackDto>({
			query(body) {
				return {
					url: 'feedback/addupdatefeedback',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: [TagType.Notifications, TagType.Ongoing],
		}),
		getQrCodeFromDynamicLink: builder.mutation<unknown, DynamicLinkQrCodeDto>({
			query(body) {
				return {
					url: 'survey/dynmaiclinkqrCode',
					method: 'POST',
					body,
				};
			},
		}),
		getAllScanHistory: builder.query<IJsonResponse<IAllScanHistory>, void>({
			query: () => 'survey/allscanhistory',
		}),
		continueSurvey: builder.mutation<IJsonResponse<IActiveSurvey>, string>({
			query(qrCode) {
				return {
					url: `survey/continue/qrCode/${qrCode}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const {
	useUpdateFeedbackMutation,
	useGetOngoingSurveyQuery,
	useGetSurveyBySurveyIdQuery,
	useGetQrCodeFromDynamicLinkMutation,
	useGetNotificationsQuery,
	useReadAllNotificationsMutation,
	useContinueSurveyMutation,
	useGetUnreadNotificationsQuery,
	useGetAllScanHistoryQuery,
	useScanCodeMutation,
	useGetSurveyByCodeQuery
} = surveyApi;
