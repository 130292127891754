import { useAppSelector } from 'Hooks';

export function isLastSurveySection() {
	const {
		activeSurvey: { survey },
		section,
	} = useAppSelector((state) => state.survey);

	const { userInfo } = useAppSelector((state) => state.authUser);

	const userHasBrandPermissions = userInfo?.userBrandPermissions.some(
		(permission) => permission.isBrandLooped && permission.brandId === survey.product.brand.id,
	) || false;

	return section === 'brandSharingSettings' || (userHasBrandPermissions && section === 'surveySharingSettings');
}
