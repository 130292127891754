import React from 'react';

import { ICollection } from 'Types/Interface/ICollection';
import { GalleryItem } from './GalleryItem';
import { SwipeableGalleryContainer } from 'Pages/LandingPage/Components/SwipeableGalleryContainer';
import { Box } from '@chakra-ui/react';

interface IGalleryProps {
  collection: ICollection[];
  visibility: true | false;
  display: 'none' | 'block';
  onClickImage: () => void;
}

export const GalleryMobile = ({
	collection,
	visibility,
	onClickImage,
	display,
}: IGalleryProps) => {
	return (
		<SwipeableGalleryContainer>
			{collection.map((collection, index) => (
				<Box key={index} w={['50vw']} mr={4} display={['block', display]}>
					<GalleryItem
						key={collection.brandName + index}
						item={collection}
						visibility={visibility}
						onClickImage={onClickImage}
					/>
				</Box>
			))}
		</SwipeableGalleryContainer>
	);
};
