import React from 'react';
import { ICollection } from 'Types/Interface/ICollection';
import { GalleryItem } from './GalleryItem';
import { GalleryLayout } from  'Layouts/GalleryLayout';

interface IGalleryProps {
  collection: ICollection[];
  visibility: true | false;
  display: 'none' | 'block';
  onClickImage: () => void
}

export const Gallery = ({ collection, visibility, onClickImage, display }: IGalleryProps ) => {
	return (
		<GalleryLayout display={display}>
			{collection.map((collection, index) => (
				<GalleryItem 
					key={collection.brandName + index} 
					item={collection} 
					visibility={visibility} 
					onClickImage={onClickImage} 
				/>
			))}
		</GalleryLayout>
	);
};
