import Artisan from 'Assets/Images/Artisan.png';
import SephoraModel from 'Assets/Images/SephoraModel.png';
import Drinks from 'Assets/Images/Drinks.png';
import Shoes from 'Assets/Images/Shoes.png';
import ArchibaldLogo from 'Assets/svg/ArchibaldLogo.svg';
import NestleLogo from 'Assets/svg/nestleLogo.svg';
import NikeSwoosh from 'Assets/svg/nikeSwoosh.svg';
import SephoraLogo from 'Assets/svg/Sephora-logo.svg';
import { ICollection } from 'Types/Interface/ICollection';

export const AllBrands:ICollection[] = [
	{
		label:'Archibald',
		coverImage: Artisan,
		brandLogo: ArchibaldLogo,
		brandName: 'Archibald',
		category: 'Beauty'
	},
	{
		label:'Nestle',
		coverImage: Drinks,
		brandLogo: NestleLogo,
		brandName: 'Nestle',
		category: 'Cars'
	},
	{
		label:'Nike',
		coverImage: Shoes,
		brandLogo: NikeSwoosh,
		brandName: 'Nike',
		category: 'Clothing'
	},
	{
		label:'Sephora',
		coverImage: SephoraModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
		category:'Beauty'
	},
	{
		label:'Archibald',
		coverImage: Artisan,
		brandLogo: ArchibaldLogo,
		brandName: 'Archibald',
		category: 'Beauty'
	},
	{
		label:'Nestle',
		coverImage: Drinks,
		brandLogo: NestleLogo,
		brandName: 'Nestle',
		category: 'Cars'
	},
	{
		label:'Nike',
		coverImage: Shoes,
		brandLogo: NikeSwoosh,
		brandName: 'Nike',
		category: 'Clothing'
	},
	{
		label:'Sephora',
		coverImage: SephoraModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
		category:'Beauty'
	},
	{
		label:'Archibald',
		coverImage: Artisan,
		brandLogo: ArchibaldLogo,
		brandName: 'Archibald',
		category: 'Beauty'
	},
	{
		label:'Nestle',
		coverImage: Drinks,
		brandLogo: NestleLogo,
		brandName: 'Nestle',
		category: 'Cars'
	},
	{
		label:'Nike',
		coverImage: Shoes,
		brandLogo: NikeSwoosh,
		brandName: 'Nike',
		category: 'Clothing'
	},
	{
		label:'Sephora',
		coverImage: SephoraModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
		category:'Beauty'
	},
	{
		label:'Nestle',
		coverImage: Drinks,
		brandLogo: NestleLogo,
		brandName: 'Nestle',
		category: 'Nutrition'
	},
	{
		label:'Nike',
		coverImage: Shoes,
		brandLogo: NikeSwoosh,
		brandName: 'Nike',
		category: 'Menswear'
	},
	{
		label:'Sephora',
		coverImage: SephoraModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
		category:'Laundry'
	},
	{
		label:'Archibald',
		coverImage: Artisan,
		brandLogo: ArchibaldLogo,
		brandName: 'Archibald',
		category: 'Kids'
	},
	{
		label:'Nestle',
		coverImage: Drinks,
		brandLogo: NestleLogo,
		brandName: 'Nestle',
		category: 'Household'
	},
	{
		label:'Nike',
		coverImage: Shoes,
		brandLogo: NikeSwoosh,
		brandName: 'Nike',
		category: 'Games'
	},
	{
		label:'Sephora',
		coverImage: SephoraModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
		category:'Fitness'
	},
	{
		label:'Nestle',
		coverImage: Drinks,
		brandLogo: NestleLogo,
		brandName: 'Nestle',
		category: 'Nutrition'
	},
	{
		label:'Nike',
		coverImage: Shoes,
		brandLogo: NikeSwoosh,
		brandName: 'Nike',
		category: 'Menswear'
	},
	{
		label:'Sephora',
		coverImage: SephoraModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
		category:'Laundry'
	},
	{
		label:'Archibald',
		coverImage: Artisan,
		brandLogo: ArchibaldLogo,
		brandName: 'Archibald',
		category: 'Kids'
	},
	{
		label:'Nestle',
		coverImage: Drinks,
		brandLogo: NestleLogo,
		brandName: 'Nestle',
		category: 'Household'
	},
	{
		label:'Nike',
		coverImage: Shoes,
		brandLogo: NikeSwoosh,
		brandName: 'Nike',
		category: 'Games'
	},
	{
		label:'Sephora',
		coverImage: SephoraModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
		category:'Fitness'
	},
];