import { getTiliaRedirectUrl } from 'Api';
import { useCallback, useRef, useState, useEffect } from 'react';
import { IError } from 'Types/Interface';
import { IOncompletePayload } from 'Types/Interface/ITilia';

const WIDGET_SRC = 'https://web.tilia-inc.com/ui/v1/widget';

type UseTiliaArg = {
	flow: string;
};

export const useTilia = ({ flow }: UseTiliaArg) => {
	const widgetScriptLoadingRef = useRef(false);
	const widgetScriptLoadedRef = useRef(false);
	const [isLoadingRedirect, setIsLoadingRedirect] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState<Error>();
	const [onCompleteCallbackPayload, setOnCompleteCallbackPayload] = useState<IOncompletePayload | null>(null);

	const loadWidgetScript = useCallback(() => {
		if (widgetScriptLoadingRef.current || widgetScriptLoadedRef.current) return;
		widgetScriptLoadingRef.current = true;
		const widget = document.createElement('script');
		widget.src = WIDGET_SRC;
		widget.onload = () => {
			widgetScriptLoadedRef.current = true;
			widgetScriptLoadingRef.current = false;
			setIsLoadingRedirect(true);
		};
		document.body.appendChild(widget);
	}, [widgetScriptLoadingRef, widgetScriptLoadedRef, isLoadingRedirect]);

	useEffect(() => {
		if (isLoadingRedirect) {
			setIsError(false);
			setError(undefined);
		}
	}, [isLoadingRedirect]);

	useEffect(() => {
		setIsError(!!error);
	}, [error]);

	const initTiliaWidget = useCallback(async () => {
		try {
			if (!widgetScriptLoadedRef.current) {
				throw new Error('Widget script not loaded.');
			}
			setIsLoadingRedirect(true);
			setOnCompleteCallbackPayload(null);
			const response = await getTiliaRedirectUrl();

			window.Tilia.execute({
				rootId: 'tilia-widget-wrapper',
				flow: flow,
				redirect: response,
				onComplete: (e: IOncompletePayload) => setOnCompleteCallbackPayload(e),
				onError: (err: IError) => console.error('onErrorHandler:', err),
				onReady: () => setIsLoadingRedirect(false),
				debug: false,
				resize: true,
				theme: {
					backgroundColor: '#FFFFFF',
					primaryColor: '#FF6F61',
				},
			});			
		} catch (err) {
			setIsLoadingRedirect(false);
			setError(err as Error);
		}
	}, [widgetScriptLoadedRef, isLoadingRedirect]);

	return {
		widgetScriptLoadingRef,
		widgetScriptLoadedRef,
		loadWidgetScript,
		isLoadingRedirect,
		isError,
		error,
		initTiliaWidget,
		onCompleteCallbackPayload,
	};
};
