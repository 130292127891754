export const ProgressTheme = {
	baseStyle: {
		
	},
	variants: {
		primary: {
			filledTrack: {
				bg: '#FF6F61'
			},
			track: {
				bg: '#EDDCDB'
			}
		}
	}
};
