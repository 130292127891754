import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IUserProfile } from 'Types/Interface/IUser';
import { DEFAULT_ERROR_MESSAGE, ErrorType, getErrorMsg } from './api';
import { routes } from './apiRoutes';
import { reqClient } from './reqClient';

export const updateRegisteredUserProfile = async (
	data: IUserProfile
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => { // TODO : add proper type
	try {
		const response = await reqClient.put(routes.userProfile, data);

		if (response.data.status) {
			return response.data;
		} else {
			throw new Error(DEFAULT_ERROR_MESSAGE);
		}
	} catch (error) {
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
};

export const getQrCode = async (
	link: string
): Promise<IJsonResponse> => {
	try {
		const response = await reqClient.post(
			routes.dynmaiclinkqrCode,
			{link}
		);
		return response.data;
		
	} catch (error) {
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
};