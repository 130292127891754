/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { VStack, Stack, Text, Flex, Button, HStack, Box, useDisclosure, Progress } from '@chakra-ui/react';
import { KycVerifiedIcon, KycUnverifiedIcon, KycDeniedIcon, RequestSuccessfulIcon } from 'Components/Icons';
import { useGetKycStatusQuery } from 'services';
import { useTilia } from 'Hooks';
import { RewardModal } from '../../Rewards/Components/RewardModal';
import { useDefaultErrorHandler } from 'Utils/errorHandling';

interface IRequestSuccessProps {
	onClose: () => void;
}

export const RequestSuccess = ({ onClose }: IRequestSuccessProps) => {
	return (
		<VStack py={'50px'} spacing={3} align={'center'} justifyContent={'center'}>
			<RequestSuccessfulIcon w={['46px', '56px']} h={['46px', '56px']} />
			<Text fontSize={['16px', '22px']} fontWeight={600}>
				Request Successful
			</Text>
			<Text fontSize={['12px', '14px']} textAlign={'center'} pb={'50px'} w={'400px'}>
				Your request is successfully done, your profile will be updated as soon as it is completed.
			</Text>
			<Button variant={'primary'} w={['280px', '400px']} h={'40px'} onClick={onClose}>
				Done
			</Button>
		</VStack>
	);
};

export const KycStatus = () => {
	const flow = 'kyc';
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data: kycStatus, isError, error } = useGetKycStatusQuery();
	const isVerificationDisabled = !kycStatus?.data || kycStatus?.data.isValid || kycStatus.data?.isProcessing;
	const {
		loadWidgetScript,
		isLoadingRedirect,
		initTiliaWidget,
		onCompleteCallbackPayload,
		isError: isWidgetError,
		error: widgetError,
	} = useTilia({ flow });

	useDefaultErrorHandler(isError, error);
	useDefaultErrorHandler(isWidgetError, widgetError, onClose);

	useEffect(() => {
		loadWidgetScript();
	}, []);

	const handleClick = () => {
		onOpen();
		initTiliaWidget();
	};

	return (
		<Stack spacing={3}>
			<Flex alignItems='center' justifyContent='space-between'>
				<Text fontWeight='semibold'>KYC Verification</Text>
				<Button
					color={kycStatus?.data?.isValid ? 'loopGrey.700' : 'loop.500'}
					disabled={isVerificationDisabled}
					_hover={
						isVerificationDisabled
							? {}
							: { textDecoration: 'underline',}
					}
					onClick={handleClick}
				>
					{kycStatus?.data?.canResubmit ? 'Reverify' : 'Verify'}
				</Button>
			</Flex>
			{kycStatus && (
				<HStack spacing={1}>
					{!kycStatus.data ? (
						<>
							<KycDeniedIcon />
							<Text fontWeight='semibold' color='kyc.red'>
								Failed to retrieve KYC status
							</Text>
						</>
					) : kycStatus.data?.isValid ? (
						<>
							<KycVerifiedIcon />
							<Text fontWeight='semibold' color='kyc.green'>
								Verified
							</Text>
						</>
					) : kycStatus.data?.isNoData ? (
						<>
							<KycUnverifiedIcon />
							<Text fontWeight='semibold' color='kyc.yellow'>
								KYC Not Completed
							</Text>
						</>
					) : kycStatus.data?.isProcessing ? (
						<>
							<KycUnverifiedIcon />
							<Text fontWeight='semibold' color='kyc.yellow'>
								KYC Check Processing
							</Text>
						</>
					) : (
						<>
							<KycDeniedIcon />
							<Text fontWeight='semibold' color='kyc.red'>
								Denied
							</Text>
						</>
					)}
				</HStack>
			)}
			<RewardModal
				content={
					<>
						{isLoadingRedirect && (
							<Progress
								size='xs'
								isIndeterminate
								position='fixed'
								top={0}
								left={0}
								right={0}
								zIndex='10000'
								colorScheme='red'
							/>
						)}
						{onCompleteCallbackPayload ? <RequestSuccess onClose={onClose} /> : <Box id='tilia-widget-wrapper' />}
					</>
				}
				isOpen={isOpen}
				onClose={onClose}
				modalContentStyles={{ borderRadius: 10, h: '635px' }}
			/>
		</Stack>
	);
};
