import { notificationType } from 'Types/Interface/INotification';
import rewardIcon from 'Assets/svg/rewardIcon.svg';
import FeedbackIcon from 'Assets/svg/FeedbackIcon.svg';
import AccountVerification from 'Assets/svg/AccountVerification.svg';
import scanNotificationIcon from 'Assets/svg/ScanNotificationIcon.svg';
import Preferences from 'Assets/svg/Preferences.svg';

export const notificationIcons: Record<string, string> = {
	[notificationType.Feedback]: FeedbackIcon,
	[notificationType.NewQR]: scanNotificationIcon,
	[notificationType.AccountPreference]: Preferences,
	[notificationType.AccountVerification]: AccountVerification,
	[notificationType.AccountWithdraw]: rewardIcon
};