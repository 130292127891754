import { IPayoutAmount } from './../Types/Interface/ITilia';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IPaymentMethods, IPayoutDto, ITiliaKycStatus } from 'Types/Interface/ITilia';
import { baseQuery } from './history';
import { IPaginatedResponse } from './types';
import { IHistory } from 'Types/Interface/IHistory';

export const tiliaApi = createApi({
	reducerPath: 'tiliaApi',
	baseQuery,
	tagTypes: ['payoutRequests'],
	endpoints: (builder) => ({
		getLoopRedirectUrl: builder.query<IJsonResponse<string>, void>({
			query: () => '/tilia/getLoopAccountRedirectUrl',
		}),

		getUserRedirectUrl: builder.query<IJsonResponse<string>, void>({
			query: () => '/tilia/getUserRedirectUrl',
		}),

		getPaymentMethodsDetails: builder.query<IJsonResponse<IPaymentMethods[]>, void>({
			query: () => '/tilia/getPaymentMethods',
		}),

		getPayoutAmount: builder.query<IJsonResponse<IPayoutAmount>, { amount: number; paymentMethodId?: string }>({
			query: ({ amount, paymentMethodId }) =>
				`/tilia/getPayoutAmount?amount=${amount}&paymentMethodId=${paymentMethodId || ''}`,
		}),

		requestPayout: builder.mutation<unknown, IPayoutDto>({
			query(body) {
				return {
					url: '/tilia/requestPayout',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['payoutRequests'],
		}),

		getKycStatus: builder.query<IJsonResponse<ITiliaKycStatus>, void>({
			query: () => '/tilia/getKyc',
		}),

		getPayoutRequests: builder.query<IPaginatedResponse<IHistory[]>, { pageNumber: number; pageSize: number }>({
			query: ({ pageNumber, pageSize }) => `/reward/withdrawals?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			providesTags: ['payoutRequests'],
		}),

		confirmWithdrawalLimits: builder.mutation<IJsonResponse<string>, {amount:number, currency: string}>({
			query({amount, currency}) {
				return {
					url: `reward/confirmWithdrawalLimit?amount=${amount}&currency=${currency}`,
					method: 'GET',
				};
			}
		})
	}),
});

export const {
	useGetLoopRedirectUrlQuery,
	useGetUserRedirectUrlQuery,
	useGetPaymentMethodsDetailsQuery,
	useRequestPayoutMutation,
	useGetKycStatusQuery,
	useGetPayoutAmountQuery,
	useGetPayoutRequestsQuery,
	useConfirmWithdrawalLimitsMutation
} = tiliaApi;
