/* eslint-disable @typescript-eslint/no-empty-function */
import { reqClient } from 'Api/reqClient';
import { useAppDispatch } from 'Hooks';
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { setUserInfo } from 'store/slices';
import { IUserInfo } from 'Types/Interface/IUser';

export interface IAuthenticateUserResponse {
  accessToken: string;
  expiresIn: number;
}

interface IContextValues {
  setAuthInfo: (data: IAuthenticateUserResponse) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
  user: IUserInfo | null;
  setAuthUserDetails: (data: IUserInfo) => void;
}

const initialValues = {
	setAuthInfo: () => {},
	setAuthUserDetails: () => {},
	logout: () => {},
	isAuthenticated: () => false,
	user: null,
};

interface IAuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<IContextValues>(initialValues);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: IAuthProviderProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const accessToken = localStorage.getItem('accessToken') ?? '';
	const userInfo = localStorage.getItem('userDetails') ?? null;
	const expiresIn = localStorage.getItem('expiresIn') ?? '';
	const [authState, setAuthState] = useState<IAuthenticateUserResponse | null>({
		accessToken,
		expiresIn: parseInt(expiresIn),
	});
	const [userDetails, setUserDetails] = useState<IUserInfo | null>(
		userInfo ? JSON.parse(userInfo) : null
	);

	const setBearerToken = async (token?: string | null) => {
		if (token) {
			localStorage.setItem('accessToken', token);
		}
    
		const accessToken = token || (localStorage.getItem('accessToken'));
    
		if (accessToken) {
			reqClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
		}
	};

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			reqClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
		}
	},[]);
  

	const setAuthInfo = ({
		accessToken,
		expiresIn,
	}: IAuthenticateUserResponse) => {
		localStorage.setItem('accessToken', accessToken);
		localStorage.setItem('expiresIn', expiresIn.toString());

		setBearerToken(accessToken);

		setAuthState({
			accessToken,
			expiresIn,
		});
	};

	const setAuthUserDetails = (userDetails: IUserInfo) => {
		localStorage.setItem('userDetails', JSON.stringify(userDetails));
		setUserDetails(userDetails);
		dispatch(setUserInfo(userDetails));
	};

	const logout = () => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('userInfo');
		localStorage.removeItem('expiresAt');
		setAuthState(null);
		navigate(AppRoutes.login);
	};

	const isAuthenticated = () => {	
		if (!authState?.accessToken || !authState?.expiresIn) {
			return false;
		}
		return true;
	};

	return (
		<Provider
			value={{
				user: userDetails,
				setAuthInfo,
				logout,
				isAuthenticated,
				setAuthUserDetails
			}}
		>
			{children}
		</Provider>
	);
};

export { AuthContext, AuthProvider };
