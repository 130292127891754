import { useFormik } from 'formik';
import { IUserInfo } from 'Types/Interface/IUser';
import { useAddUserProfileQuestion, useAppSelector} from 'Hooks';
import { UserPreferenceFormName } from 'Types/Enums';
import { useDispatch } from 'react-redux';
import { setUserInfo } from 'store/slices';


export const useUserBasicProfile = () => {
	const {userInfo} = useAppSelector(state => state.authUser);
	const dispatch = useDispatch();
	const { mutate: addUserProfileQuestion, isLoading, isSuccess, isError, error } = useAddUserProfileQuestion();
	const { setFieldValue, values, handleSubmit } = useFormik({
		initialValues: {
			[UserPreferenceFormName.Gender]: userInfo?.gender,
			[UserPreferenceFormName.Ethnicity]: userInfo?.ethnicity,
			[UserPreferenceFormName.Children]: userInfo?.children,
			[UserPreferenceFormName.Education]: userInfo?.education,
			[UserPreferenceFormName.Religion]: userInfo?.religion,
			[UserPreferenceFormName.Politics]: userInfo?.politics,
			[UserPreferenceFormName.Hometown]: userInfo?.homeTown,
			[UserPreferenceFormName.School_location]: userInfo?.schoolLocation,
			[UserPreferenceFormName.Sexuality]: userInfo?.sexuality,
			[UserPreferenceFormName.Workplace]: '',
			[UserPreferenceFormName.Industry]: userInfo?.industry,
			[UserPreferenceFormName.Alchohol]: userInfo?.alchohol ? + userInfo?.alchohol : 0,
			[UserPreferenceFormName.Smoke]: userInfo?.smoke ? + userInfo?.smoke : 0,
			[UserPreferenceFormName.Marijuana]: userInfo?.marijuana ? +userInfo?.marijuana : 0,
			[UserPreferenceFormName.Drugs]: userInfo?.drugs ? +userInfo?.drugs : 0,
			[UserPreferenceFormName.AvatarUrl]: userInfo?.avatarUrl,
			[UserPreferenceFormName.GenderName]: userInfo?.genderName,
			[UserPreferenceFormName.ReligionName]: userInfo?.religionName,
			[UserPreferenceFormName.Age]: userInfo?.age
		} as { [key: string]: number | string }, // TODO: create type for this formstate
		enableReinitialize: true,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onSubmit: (values) => {
			const data = {
				gender: values.gender,
				genderName: values.genderName,
				children: values.children,
				ethnicity: values.ethnicity,
				education: values.education,
				religion: values.religion,
				politics: values.politics,
				homeTown: values.hometown,
				schoolLocation: values.school_location,
				smoke: values.smoke,
				alchohol: values.alchohol,
				marijuana: values.marijuana,
				drugs: values.drugs,
				industry: values.industry,
				sexuality: values.sexuality,
				age: values.age,
				avatarUrl: values.avatarUrl
			} as IUserInfo;
			addUserProfileQuestion(data);
			dispatch(setUserInfo(data));
		},
	});

	return { setFieldValue, values, handleSubmit, isLoading,isSuccess, isError, error };
};
