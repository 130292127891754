
export enum notificationType{
    AccountVerification = 1,
    AccountPreference = 2,
    NewQR = 3,
    Feedback = 4,
    AccountWithdraw = 5,
}
export interface INotification{
    id: string,
    userId: number,
    notificationType: notificationType,
    message: string,
    title: string,
    isRead: boolean,
    dateCreated: string
}
