import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/HomeIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const HomeIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const HomeIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M12.9556 17.812H6.04436C5.26279 17.8411 4.50133 17.5605 3.92543 17.0313C3.34954 16.5021 3.00574 15.767 2.96874 14.9858V11.2808C2.96874 11.1233 3.03129 10.9723 3.14264 10.8609C3.25399 10.7496 3.40502 10.687 3.56249 10.687C3.71996 10.687 3.87098 10.7496 3.98233 10.8609C4.09368 10.9723 4.15624 11.1233 4.15624 11.2808V14.9858C4.19273 15.4522 4.41122 15.8854 4.7645 16.1921C5.11778 16.4987 5.5775 16.654 6.04436 16.6245H12.9556C13.4225 16.654 13.8822 16.4987 14.2355 16.1921C14.5888 15.8854 14.8072 15.4522 14.8437 14.9858V11.2808C14.8437 11.1233 14.9063 10.9723 15.0176 10.8609C15.129 10.7496 15.28 10.687 15.4375 10.687C15.595 10.687 15.746 10.7496 15.8573 10.8609C15.9687 10.9723 16.0312 11.1233 16.0312 11.2808V14.9858C15.9942 15.767 15.6504 16.5021 15.0745 17.0313C14.4986 17.5605 13.7372 17.8411 12.9556 17.812ZM17.6403 9.9211C17.696 9.8659 17.7401 9.80023 17.7703 9.72788C17.8004 9.65553 17.8159 9.57792 17.8159 9.49954C17.8159 9.42115 17.8004 9.34355 17.7703 9.27119C17.7401 9.19884 17.696 9.13317 17.6403 9.07797L9.92155 1.35922C9.86635 1.30357 9.80068 1.2594 9.72833 1.22926C9.65598 1.19911 9.57837 1.18359 9.49999 1.18359C9.42161 1.18359 9.344 1.19911 9.27165 1.22926C9.19929 1.2594 9.13362 1.30357 9.07843 1.35922L1.35968 9.07797C1.24787 9.18978 1.18506 9.34142 1.18506 9.49954C1.18506 9.65765 1.24787 9.80929 1.35968 9.9211C1.47148 10.0329 1.62312 10.0957 1.78124 10.0957C1.93935 10.0957 2.091 10.0329 2.2028 9.9211L9.49999 2.61797L16.7972 9.9211C16.8524 9.97675 16.918 10.0209 16.9904 10.0511C17.0627 10.0812 17.1404 10.0967 17.2187 10.0967C17.2971 10.0967 17.3747 10.0812 17.4471 10.0511C17.5194 10.0209 17.5851 9.97675 17.6403 9.9211Z" fill={color}/>
		</Icon>
	);
};