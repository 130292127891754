import React, { useEffect, useState } from 'react';
import { DashboardSettingLayout } from 'Layouts';
import { SplitLayout } from 'Pages/Dashboard/Components/SplitLayout';
import {
	Button,
	Stack,
	Text,
	Input,
	InputGroup,
	InputRightAddon,
	FormErrorMessage,
	FormControl,
	useDisclosure,
} from '@chakra-ui/react';
import { useAppSelector, useTilia } from 'Hooks';
import { convertLNTToUsd, convertUSDToLNT } from 'Utils';
import { useConfirmWithdrawalLimitsMutation, useGetPaymentMethodsDetailsQuery, useRequestPayoutMutation } from 'services';
import { useFormik } from 'formik';
import { Payout } from '../AccountSettings/sections/PaymentAndPayout/Payout';
import { RewardModal } from './Components/RewardModal';
import { WithdrawalHistory } from './Components/WithdrawalHistory';
import { PayoutRequestSuccess } from '../AccountSettings/sections/PaymentAndPayout/PayoutRequestSuccess';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { useDefaultErrorHandler } from 'Utils/errorHandling';

export const RedeemReward = () => {
	const navigate = useNavigate();
	const flow = 'payout';
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { userSummary } = useAppSelector((state) => state.authUser);
	const [requestPayout, { error, isError, isLoading, isSuccess, reset }] = useRequestPayoutMutation();
	const { data: paymentMethodsResponse } = useGetPaymentMethodsDetailsQuery();
	const {
		loadWidgetScript,
		isLoadingRedirect,
		initTiliaWidget,
		onCompleteCallbackPayload,
		widgetScriptLoadingRef,
		widgetScriptLoadedRef,
		isError: isWidgetError,
		error: widgetError,
	} = useTilia({ flow });
	const [sourcePaymentMethodId, setSourcePaymentMethodId] = useState('');
	const destinationPaymentMethodId = useAppSelector((state) => state.authUser.tiliaPaymentMethods?.id);
	const sourcePaymentMethodDetails = paymentMethodsResponse?.data.filter((item) =>
		item.display_string.includes('Default USD Wallet'),
	);
	const [
		confirmLimit, 
		{	 data, 
			isSuccess:isWithdrawalLimitSuccess, 
			isError:isWithdrawalLimitError, 
			error:withdrawalLimitError, 
			isLoading:isconfirmLimit
		}] = useConfirmWithdrawalLimitsMutation( );

	useDefaultErrorHandler(isError, error, onClose);
	useDefaultErrorHandler(isWidgetError, widgetError, onClose);
	useDefaultErrorHandler(isWithdrawalLimitError, withdrawalLimitError);

	useEffect(() => {
		loadWidgetScript();
	}, [widgetScriptLoadingRef, widgetScriptLoadedRef, isLoadingRedirect]);

	const initializePayout = () => {
		confirmLimit({amount: values.amount, currency: values.currency});
	};

	useEffect(() => {
		if(isWithdrawalLimitSuccess && data){
			onOpen();
			initTiliaWidget();
		}
		
	},[isWithdrawalLimitSuccess, data]);

	const handleClose = () => {
		if (isSuccess) {
			navigate(AppRoutes.dashboard);
		} else {
			onClose();
		}
	};

	useEffect(() => {
		sourcePaymentMethodDetails?.map((item) => {
			setSourcePaymentMethodId(item.id);
		});
	}, [sourcePaymentMethodDetails]);

	useEffect(() => {
		if (isSuccess && !isOpen) {
			reset();
		}
	}, [isSuccess, isOpen]);

	const { handleChange, handleSubmit, errors, values } = useFormik({
		initialValues: {
			amount: 0,
			currency: 'USD',
		},
		onSubmit: (values) => {
			const payoutData = {
				...values,
				source_payment_method_id: sourcePaymentMethodId,
				destination_payment_method_id: destinationPaymentMethodId,
			};
			requestPayout(payoutData);
		},
	});

	const isTiliaModal = onCompleteCallbackPayload ? false : true;
	const isRequestConfirmation = onCompleteCallbackPayload && !isSuccess;
	return (
		<DashboardSettingLayout>
			<SplitLayout display='block' width={'55%'}>
				<Stack spacing={8} mr={0}>
					<Text fontSize={['14px', '16px']} color={'brand.black'}>
						You have earned {userSummary?.totalBalance} LNT which is equivalent to about{' '}
						{convertLNTToUsd(userSummary?.totalBalance ?? 0).toFixed(2)} USD <br /> How much would you like to withdraw?
					</Text>
					<FormControl isInvalid={!!errors.amount}>
						<InputGroup>
							<Input
								onChange={handleChange}
								value={values.amount}
								name='amount'
								type='number'
								placeholder='Enter amount'
								width={['335px', '410px']}
								height={['50px', '60px']}
							/>
							<InputRightAddon bg='#F8F8F8' color='rgba(18, 27, 38, 0.6)' height={['50px', '60px']}>
								USD
							</InputRightAddon>
						</InputGroup>
						{!!errors.amount && <FormErrorMessage fontSize='xs'>{errors.amount}</FormErrorMessage>}
					</FormControl>
					<Button variant='primary' isLoading={isconfirmLimit} w={['152px', '280px']} h={['50px', '60px']} onClick={initializePayout}>
						Withdraw
					</Button>
				</Stack>
				<WithdrawalHistory />
			</SplitLayout>
			<RewardModal
				content={
					isSuccess ? (
						<PayoutRequestSuccess />
					) : (
						<Payout
							handleSubmit={handleSubmit}
							isLoading={isLoading}
							amount={values.amount}
							amountLnt={convertUSDToLNT(values.amount)}
							onCompleteCallbackPayload={onCompleteCallbackPayload}
							isLoadingRedirect={isLoadingRedirect}
							paymentMethodId={destinationPaymentMethodId}
						/>
					)
				}
				isOpen={isOpen}
				onClose={handleClose}
				modalContentStyles={{
					borderRadius: 10,
					h: isTiliaModal ? '560px' : isRequestConfirmation ? '510px' : '390px',
					w: isTiliaModal ? '600px' : '420px',
				}}
			/>
		</DashboardSettingLayout>
	);
};
