import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { accountSettingCards } from 'Constants';
import { SettingCard } from '../components';

export const MainMenu = () => {
	return (
		<SimpleGrid
			columns={[1,1,2,3]}
			gap={5}
		>
			{
				accountSettingCards.map((item) => (
					<SettingCard key={item.title} data={item}/>
				))
			}
		</SimpleGrid>
	);
};
