import React, { useEffect, useState } from 'react';
import { Checkbox, Stack } from '@chakra-ui/react';
import { IQuestionOption } from 'Types/Interface/ISurvey';
import { FadeInContainer } from '../FadeInContainer';

interface IMultiSelectProps {
	questionOptions: IQuestionOption[];
	onChange: (id: string[]) => void;
	value: string[];
}

export const MultiSelect = ({ questionOptions, onChange, value }: IMultiSelectProps) => {
	const [ids, setIds] = useState<string[]>(value);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = event.target;
		if (checked) {
			setIds((prevIds) => [...prevIds, value]);
		} else {
			setIds((prevIds) => prevIds.filter((id) => id !== value));
		}
	};

	useEffect(() => {
		setIds(value);
	}, [value]);

	useEffect(() => {
		onChange(ids);
	}, [ids]);

	return (
		<FadeInContainer>
			<Stack spacing={8}>
				{questionOptions.map(({ id, text }) => (
					<Checkbox
						value={id}
						onChange={handleCheckboxChange}
						key={id}
						variant='primary'
						size='lg'
						isChecked={ids.includes(id)}
					>
						{text}
					</Checkbox>
				))}
			</Stack>
		</FadeInContainer>
	);
};
