import { IEarnSteps } from 'Types/Interface/ILandingPage';
import Scanner from 'Assets/svg/Scanner.svg';
import Chat from 'Assets/svg/Chat.svg';
import MoneyBag from 'Assets/svg/MoneyBag.svg';

export const steps:IEarnSteps[] = [
	{
		src: Scanner,
		title: 'Scan',
		subtext: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna.'
	},
	{
		src: Chat,
		title: 'Answer',
		subtext: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna.'
	},
	{
		src: MoneyBag,
		title: 'Earn',
		subtext: 'Lorem ipsum dolor sit amet, consectetur adip iscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna.'
	}
]; 