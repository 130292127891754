import React from 'react';

import { 
	Heading,
	Flex,
	Button,
	Stack,
	Spacer
}from '@chakra-ui/react';

export interface IActionBtn {
	text: string;
	onClick: () => void;
}
interface ICallToActionProps {
	heading: string;
	actionBtnLeft: IActionBtn
	actionBtnRight: IActionBtn
}

export const CallToActionSection = ({heading, actionBtnLeft, actionBtnRight}: ICallToActionProps) => {
	return (
		<Flex 
			flexDirection={{base:'column', tablet:'row', xxl:'row'}}
			justifyContent={'space-between'} 
			alignItems={'center'} 
			py={16}
			px={{base:'9',tablet:'12', xxl:'20'}}
			my={20}
			border='1px'
			borderWidth='solid'
			borderColor={'darkCoral'}
			rounded={16}
		>
			<Heading 
				fontSize={{ base: '2xl', tablet: '2xl', xxl: '4xl' }}
				fontWeight={'600'}
				mb={['4']}
				textAlign={['center','center','left']}
				w={['100%','80%', '', '50%']}
			>
				{heading}
			</Heading>
			<Stack direction={['column', 'row']} spacing='4'>
				<Spacer/>
				<Button 
					variant={'primary'}
					onClick={actionBtnLeft.onClick} 	
					w={{xxl:'40'}}
					h={{xxl:'14'}}>
					{actionBtnLeft.text}
					
				</Button>
				<Button 
					variant={'secondary'}
					onClick={actionBtnRight.onClick} 	
					w={{xxl:'36'}}
					h={{xxl:'14'}}
				>
					{actionBtnRight.text}
					
				</Button>
			</Stack>	
		</Flex>
	);
};