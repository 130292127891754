import React, { useEffect, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { useWindowDimension } from 'Hooks';

interface ISlideLayoutProps {
  children: React.ReactNode;
}

export const SwipeableGalleryContainer = ({ children }: ISlideLayoutProps) => {
	const [width, setWidth] = useState(0);
	const carousel = useRef<HTMLDivElement>(null);
	const dimension = useWindowDimension();

	useEffect(() => {
		if (carousel.current) {
			setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
		}
	}, [dimension]);

	return (
		<Flex
			justifyContent={'space-between'}
			alignItems={'center'}
			ref={carousel}
			w={'100%'}
			// overflow={'hidden'}
			position="relative"
			display={['flex', 'block']}
			cursor="move"
		>
			<MotionBox
				drag="x"
				dragConstraints={{ right: 0, left: -width }}
				display={'flex'}
			>
				{children}
			</MotionBox>
		</Flex>
	);
};
