import React, { useEffect } from 'react';
import { Text, Stack, Button } from '@chakra-ui/react';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { EmailVerificationIcon } from 'Components/Icons/EmailVerificationIcon';
import { useFormik } from 'formik';
import { useSendEmailOtpMutation } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import * as Yup from 'yup';

interface IEmailVerificationModalContentEmailProps {
	onChange: (value: string) => void;
}

export const EmailVerificationModalContentEmail = ({ onChange }: IEmailVerificationModalContentEmailProps) => {
	const [sendEmailOtp, { isSuccess, isLoading, isError, error }] = useSendEmailOtpMutation();
	
	useDefaultErrorHandler(isError, error);

	const { values, handleChange, handleSubmit, errors } = useFormik({
		initialValues: { email: '' },
		validationSchema: Yup.object().shape({
			email: Yup.string().email().required('Required')
		}),
		onSubmit: async (values) => {
			sendEmailOtp(values.email);
		},
	});

	useEffect(() => {
		if (isSuccess){
			onChange(values.email);
		}
	},[isSuccess]);

	return (
		<Stack py={10} px={['20px', '30px']} spacing={5} alignItems={'center'}>
			<EmailVerificationIcon boxSize={['40px', '60px']} />
			<Text fontSize={['16px','30px']} fontWeight={'600'}>
				Verify code
			</Text>
			<Text fontSize={['12px','16px']} textAlign={'center'} pb={5}>
				Enter your email address below to verify your account to earn cashbacks
			</Text>
			<CustomInput
				onChange={handleChange}
				name='email'
				type='email'
				placeholder='Your email address'
				error={errors.email}
				h={['50px','60px']}
			/>
			<Button
				variant={'primary'}
				w={'100%'}
				h={['50px','60px']}
				fontSize={['14px', '18px']}
				isLoading={isLoading}
				onClick={() => handleSubmit()}
			>
				Continue
			</Button>
		</Stack>
	);
};
