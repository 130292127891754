type Feature = 'message' | 'reminder' | 'promo' | 'account_support'

interface INotificationFeature {header:string; subHeader:string, feature?:Feature}

export const NotificationSections: INotificationFeature[] = [
	{
		header:'Messages',
		subHeader:'Receive messages from brands about products...',
		feature: 'message'
	},
	{
		header:'Reminders',
		subHeader:'Receive survey reminders, requests to write a review, product notices, and other reminders related to your activities on Loop',
		feature: 'reminder',
	},
	{
		header:'Promotions and tips',
		subHeader:'Receive coupons, promotions, surveys, product updates, and inspiration from Loop and Loop’s partners',
		feature: 'promo'
	},
	{
		header:'Account support',
		subHeader:'Receive coupons, promotions, surveys, product updates, and inspiration from Loop and Loop’s partners',
		feature: 'account_support'
	},
];