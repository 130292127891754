import React from 'react';
import { Flex, Heading, Text, Image, VStack, HStack, Box } from '@chakra-ui/react';
import AppleDownload from 'Assets/svg/AppleDownload.svg';
import GoogleDownload from 'Assets/svg/GoogleDownload.svg';
import iphoneMockup from 'Assets/Images/iPhoneMockup.png';

export const CTASection = () => {
	return (
		<Flex 
			direction={['column', 'row']}
			justifyContent={'center'}
			alignItems={'center'}
			bgColor={'#F6F6F6'}
			borderRadius={'10px'}
			h={['545px', '425px']}
			w={'100%'}
		>
			<VStack spacing={'20px'} align={['center',null, null, null,'start']} textAlign={['center', 'left']} justify={'center'}  >
				<Heading fontWeight={600} fontSize={['24px', null, null, '30px']} w={['301px', null, null, null, '460px']}>
					Loop app coming soon to mobile
				</Heading>
				<Text fontSize={['16px', '18px']} w={['auto', null, '301px', null, '450px']}>
					iOS Dec. 2023   |  Android Dec. 2023
				</Text>
				<HStack >
					<HStack justify={'center'} bg={'#FFF'} w={['146px', null, null, null, '176px']} h={['40px','60px']}>
						<Image src={AppleDownload} />
						<Box>
							<Text fontSize={['12px','15px']}>Download in</Text>
							<Text fontSize={['12px','15px']}fontWeight={600}>App store</Text>
						</Box>
					</HStack>
					<HStack justify={'center'} bg={'#FFF'} w={['146px', null, null, null, '176px']} h={['40px','60px']}>
						<Image src={GoogleDownload}  />
						<Box>
							<Text fontSize={['12px','15px']}>Download in</Text>
							<Text fontSize={['12px','15px']} fontWeight={600} > Play store</Text>
						</Box>
					</HStack>

				</HStack>
			</VStack>
			<Image src={iphoneMockup} w={['385px', null, null, null, 'auto']} h={['291px', '425px']} objectFit={'contain'} />
		</Flex>
	);
};