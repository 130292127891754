import React from 'react';
import { privacyAndSharingDetails } from 'Constants';
import {  DashboardSettingLayout } from 'Layouts';
import { DashboardLayoutWithDescription } from 'Pages/Dashboard/Components/LayoutWithDescription';
import { Content } from './Content';

export const PrivacyAndSharing = () => {
	return (
		<DashboardSettingLayout>
			<DashboardLayoutWithDescription
				content={<Content/>}
				description={privacyAndSharingDetails}/>
		</DashboardSettingLayout>
	);
};
