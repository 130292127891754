import React, { Fragment, useState, useMemo } from 'react';
import { 
	Divider, 
	Heading, 
	Stack, 
	Text, 
	Button, 
	Flex, 
	Image, 
	IconButton, 
	Menu, 
	MenuButton, 
	MenuItem, 
	MenuList, 
	Tag
} from '@chakra-ui/react';
import { CustomModal } from 'Components';
import { useDisclosure } from '@chakra-ui/react';
import { PaymentModalContent } from './PaymentModalContent';
import { useAppSelector } from 'Hooks';
import { BsThreeDots } from 'react-icons/bs';
import { cardIcons } from 'Constants';
import { useFormik } from 'formik';
import { useAppDispatch } from 'Hooks';
import { deletePaymentMethod, setDefaultPaymentMethod } from 'store/slices';
import { IPaymentMethods } from 'Types/Interface/IUser';

export const Content = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {paymentMethods} = useAppSelector(state => state.authUser);
	const inititialValues = useMemo(() => paymentMethods, [paymentMethods]);
	const [defaultCard, setDefaultCard] = useState(false);
	const [selectedCard, setSelectedCard] = useState('');
	const dispatch = useAppDispatch();

	const { handleSubmit } = useFormik({
		initialValues: {...inititialValues},
		onSubmit: () => {
			dispatch(setDefaultPaymentMethod ({
				default: defaultCard,
				firstName: '',
				lastName: '',
				cardNumber: '',
				cardType: '',
				expiryDate: '',
				cvv: '',
				address: '',
				city: '',
				state: '',
				zipCode: '',
				country: '',
				id: selectedCard
			}));
		}
	});

	const handleClick = () => {
		onOpen();
	};

	const handleDefault = (value:string) => {
		setSelectedCard(value);
		setDefaultCard(!defaultCard);
		handleSubmit();
	};

	const handleDeletePaymentMethod = (value:IPaymentMethods) => {
		dispatch(deletePaymentMethod(value));
	};

	return (
		<>
			<Stack spacing={8} w="100%" flex={1}>
				<Flex direction={'column'} >
					<Heading fontSize="sm" color="#264653" mb={4}>
          Payment methods
					</Heading>
					<Text mb={4}>
          Add a payment method using our secure payment system and make
          purchases
					</Text>
					{paymentMethods?.map((card) => {
						return (
							<Flex justifyContent={'space-between'} alignItems={'flex-start'} mb={4} key={card.id}>

								<Flex>
									<Image 
										src={cardIcons[card.cardType]} 
										w={'3rem'} h={'2rem'}
										border={'1px solid rgba(88, 96, 104, 0.3)'}
										borderRadius={'3px'}
										px={3} mr={4}
									/>
									<Flex direction={'column'}>
										<Text 
											textTransform={'capitalize'} 
											fontWeight={600} 
											mb={2}
											fontSize={'md'}
										>
											{card.cardType}&#183;&#183;&#183;&#183;
											{card.cardNumber.slice(-4)}
										</Text>
										<Text fontSize={'xs'} fontWeight={400}>
											Expiration: {card.expiryDate}
										</Text>
									</Flex>
								</Flex>
								{ card.default &&  (<Tag 
									bg={'#D3D3D3'} 
									color='#FFFFFF' 
									borderRadius={'3px'}
									fontSize={'x-small'}
									textTransform={'uppercase'}
								> 
									Default
								</Tag>)}
								<Menu>
									<MenuButton
										border={'1px solid #F28482'}
										borderRadius={'3px'} p={2}
										as={IconButton}
										aria-label='Options'
										icon={<BsThreeDots color='#BBBBBB' />}
										variant='outline'
									/>
									<MenuList w={'100%'} minW={0}>
										<MenuItem onClick={() => handleDefault(card.id)} >
											{card.default === true ? 'remove Default' : 'make default' }
										</MenuItem>
										<MenuItem onClick={() => handleDeletePaymentMethod(card) }>
										Delete
										</MenuItem>
									</MenuList>
								</Menu>
							</Flex>
						);
					})}
					<Button variant="primary" fontWeight="normal" w="max-content" onClick={handleClick}>
          Add payment method
					</Button>
				</Flex>
				<Divider />
				<Stack spacing={4}>
					<Heading fontSize="sm" color="#264653">
          Payout methods
					</Heading>
					<Text>
          To get paid, you need to set up a payout method Loop releases payouts
          about 24 hours after a user redeems his points. The time it takes for
          the funds to appear in your account depends on your payout method.{' '}
					</Text>
					<Button variant="primary" fontWeight="normal" w="max-content">
          Add payout method
					</Button>
				</Stack>
				<Divider />
			</Stack>
			<CustomModal
				content={
					<PaymentModalContent onClose={onClose} />
				}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	);
};
