import React from 'react';
import {
	Flex,
	Heading,
	VStack,
	Text,
	Image,
	Box,
	Button,
	chakra,
} from '@chakra-ui/react';
import QrCode from 'Assets/svg/QrCode.svg';
import GuyWithPhone from 'Assets/Images/GuyWithPhone.png';

export const CallToAction = () => {
	return (
		<Flex
			direction={['column-reverse', null, 'column-reverse', 'row', 'row']}
			justifyContent={'space-between'}
			alignItems={'flex-start'}
			py={{ base: 8, tablet: 16, xxl: 16 }}
			mt={{ base: 0, tablet: 10 }}
			mb={16}
		>
			<VStack align={'self-start'} spacing={'4'}>
				<Heading
					fontSize={{ base: '2xl', tablet: '2xl', xxl: '5xl' }}
					fontWeight={'600'}
					color="#2B2B2B"
					mb={8}
					mt={6}
				>
					<chakra.span display={['none',null,null, 'block']}>
            Experience the <br />
            better way to <br />
            Review.
					</chakra.span>
					<chakra.span display={['inline',null,null, 'none']}>
            Experience the better way to Review.
					</chakra.span>
				</Heading>
				<Button
					variant={'primary'}
					w={'12rem'}
					fontSize={'16px'}
					display={['block', 'none']}
				>
          Get the app
				</Button>
				<Text
					fontSize={{ sm: 'xs', tablet: 'md' }}
					fontWeight={400}
					color={'#586068'}
					display={['none', 'block']}
				>
          Get the loop app on your phone
				</Text>
				<Box
					border={'1px solid #586068'}
					borderRadius={'5px'}
					display={['none', 'block']}
				>
					<Image p={'3'} src={QrCode} />
				</Box>
			</VStack>
			<Image src={GuyWithPhone} w={['100%', null, null, '50%', null, '60%']} />
		</Flex>
	);
};
