// import { signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { loginWithFacebook, loginWithGoogle } from 'Api/login.api';
import { AuthContext, IAuthenticateUserResponse } from 'context/AuthContext';
import { useToast } from './useToast';
import { AppRoutes } from 'Routes/AppRoutes';
import { useNavigate } from 'react-router-dom';
import {
	useVerifyFacebookAccountMutation,
	useVerifyGmailAccountMutation,
} from 'services';

export const useSocialLogin = () => {
	const auth = useContext(AuthContext);
	const [fbAuthData, setFbAuthData] = useState<IAuthenticateUserResponse>();
	const [googleAuthData, setGoogleAuthData] = useState<IAuthenticateUserResponse>();
	const { alert } = useToast();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [gmailUserEmail, { data: gmailData, isSuccess: isGmailSuccess }] = useVerifyGmailAccountMutation();
	const [facebookeUserAccessToken, { data: fbData, isSuccess: isFaceBookSuccess }] =
    useVerifyFacebookAccountMutation();

	useEffect(() => {
		if (gmailData?.data) {
			alert('success', 'Login was successful');
			setTimeout(() => {
				navigate(AppRoutes.home);
			}, 500);
		} else if (fbData?.data) {
			alert('success', 'Login was successful');
			setTimeout(() => {
				navigate(AppRoutes.home);
			}, 500);
			
		} else if((isFaceBookSuccess || isGmailSuccess) && (!gmailData?.data || !fbData?.data)) {
			navigate(AppRoutes.introduction);
		}
	}, [gmailData, fbData, isGmailSuccess, isFaceBookSuccess]);

	useEffect(() => {
		if (googleAuthData?.accessToken) {
			auth.setAuthInfo(googleAuthData as IAuthenticateUserResponse);
		}
		if (fbAuthData?.accessToken) {
			auth.setAuthInfo(fbAuthData as IAuthenticateUserResponse);
		}
	},[fbAuthData, googleAuthData]);

	const handleFacebookLogin = async (accessToken: string) => {
		setIsLoading(true);
		try {
			facebookeUserAccessToken(accessToken);
			const response = await loginWithFacebook(accessToken ?? '');
			setFbAuthData(response);
			setIsLoading(false);
			auth.setAuthInfo(response);
		} catch (error) {
			setIsLoading(false);
			console.error('error', error);
			alert('error', 'Something went wrong');
		}
	};

	const handleGoogleLogin = async (idToken: string, email: string) => {
		setIsLoading(true);
		try {
			setIsLoading(true);
			gmailUserEmail(email);
			const loginWithGoogleResponse = await loginWithGoogle(idToken ?? '');
			setGoogleAuthData(loginWithGoogleResponse);
			setIsLoading(false);
			auth.setAuthInfo(loginWithGoogleResponse);
		} catch (error) {
			setIsLoading(false);
			alert('error', 'Something went wrong');
		}
	};

	return { handleFacebookLogin, handleGoogleLogin, isLoading };
};
