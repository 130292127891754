import React, { useEffect } from 'react';
import { SummaryItem } from './SummaryItem';
import { useGetAllScanHistoryQuery } from 'services';
import { PieChartIcon, BarcodeScanIcon, CommentIcon, ClickIcon } from 'Components/Icons';
import { Divider, Flex, Stack } from '@chakra-ui/react';

export enum SummaryLabelEnum {
  TotalEarned = 'TOTAL EARNED',
  TotalScanned = 'TOTAL SCANNED',
  TotalBrand = 'BRANDS LOOPED',
  SurveyCount = 'SURVEYS ANSWERED',
}

export const Summary = () => {
	const { data, isLoading, refetch } = useGetAllScanHistoryQuery();

	useEffect(() => {
		refetch();
	},[]);
	
	return (
		<Stack 
			borderWidth={1}
			padding={10}
			py={16}
			borderRadius={'10px'}
			borderColor={'#FFEEEC'}
			background={'#FFEEEC'}
		>
			{/* <Select 
				placeholder='Last 7 days ' 
				w={'150px'}  
				alignSelf={'flex-end'}
				size={'sm'}
				variant={'outline'}
				bg={'#fff'}
				border={'1px solid'}
				borderColor={'rgba(255, 111, 97, 0.5)'}
				borderRadius={'3px'}
				mt={'-40px'} mb={'30px'}

			>
				<option value='option1'>Option 1</option>
				<option value='option2'>Option 2</option>
				<option value='option3'>Option 3</option>
			</Select> */}
			<Flex
				justifyContent={'space-between'}
				h={'117px'}
			>
				<SummaryItem
					title={SummaryLabelEnum.TotalEarned}
					value={(data?.data.totalEarned || 0).toLocaleString() + ' LNT'}
					icon={<PieChartIcon height={'25px'} w={'25px'} />}
					isLoading={isLoading} />
				<Divider orientation='vertical'  borderColor={'#FF6F61'}  />
				<SummaryItem
					title={SummaryLabelEnum.TotalScanned}
					value={data?.data.totalScanned || 0}
					icon={<BarcodeScanIcon height={6} w={6} />}
					isLoading={isLoading} />
				<Divider orientation='vertical'  borderColor={'#FF6F61'}  />
				<SummaryItem
					title={SummaryLabelEnum.SurveyCount}
					value={data?.data.surveyCount || 0}
					icon={<CommentIcon height={6} w={6} />}
					isLoading={isLoading} />
				<Divider orientation='vertical'  borderColor={'#FF6F61'}  />
				<SummaryItem
					title={SummaryLabelEnum.TotalBrand}
					value={data?.data.totalBrand || 0}
					icon={<ClickIcon height={6} w={6} />}
					isLoading={isLoading}
				/>
			</Flex>
		</Stack>
	);
};
