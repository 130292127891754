import React from 'react';
import {
	Flex,
	Heading,
	VStack,
	Text,
	Button,
	chakra,
	Stack,
} from '@chakra-ui/react';

import logo from 'Assets/svg/main.svg';


export const JumbotronSection = () => {
	return (
		<Flex
			alignItems="center"
			flexDirection={{ base: 'column', md: 'row' }}
			justifyContent={'space-between'}
			mt={[28, null, null, 10]}
		>
			<VStack
				w={['100%', '', '50%']}
				alignItems={'flex-start'}
				spacing={5}
				textAlign={'left'}
			>
				<Heading fontWeight={'bold'} fontSize={{ base: '3xl', tablet: '2xl', xxl: '5xl' }}>
          Boost your business <br /> with <chakra.span color={'#FF6F61'}>LOOP.</chakra.span>
				</Heading>
				<Text fontSize={{ base: 'sm', tablet: '16px' }} lineHeight={8} w={['90%', '100%']} pb={6}>
				Attract, convert, and retain customers with real life data and performance-driven surveys that will drive your decision making
				</Text>
				<Stack 
					direction={['column', 'row']}
				>
					<Button
						variant='primary'
						mr={[0,5]}
						w={['200px', 'auto']}
						h={['50px',  'auto']}
					>
						Get Started
					</Button>
					<Button
						variant='secondary'
						w={['200px', 'auto']}
						h={['50px',  'auto']}	
					>
						Contact Us
					</Button>
				</Stack>

			</VStack>
			<Flex w={['100%', '', '45%']} justifyContent={['center', 'flex-end']}>
				<chakra.img ml={[10, 0]} src={logo} w="90%" display={['none', 'flex']} />
			</Flex>
		</Flex>
	);
};
