import React from 'react';
import { Dashboard } from 'Pages/Dashboard';
import { LoginAndSecurity } from 'Pages/Dashboard/sections/AccountSettings/sections';
import { Notifications } from 'Pages/Dashboard/sections/AccountSettings/sections/Notifications';
import { PaymentAndPayout } from 'Pages/Dashboard/sections/AccountSettings/sections/PaymentAndPayout';
import { Preferences } from 'Pages/Dashboard/sections/AccountSettings/sections/Preferences';
import { PrivacyAndSharing } from 'Pages/Dashboard/sections/AccountSettings/sections/PrivacyAndSharing';
import { Referral } from 'Pages/Dashboard/sections/AccountSettings/sections/Referral';
import { AppRoutes } from './AppRoutes';
import { ProductPage } from 'Pages/ProductPage';
import { Explore } from 'Pages/ExplorePage/Explore';
import { BrandPage } from 'Pages/BrandPage/BrandPage';
import { NewSurvey } from 'Pages/Survey';
import { Home as AuthHomePage } from 'Pages/HomePage/Home';
import { RedeemReward } from 'Pages/Dashboard/sections/Rewards/RedeemReward';
import { AllBrands } from 'Pages/AllBrands/AllBrands';
import { AllProducts } from 'Pages/AllProducts/AllProducts';
import { NotificationPage } from 'Pages/NotificationPage/NotificationPage';
import { AllWithdrawalHistory } from 'Pages/Dashboard/sections/Rewards/AllWithdrawalHistory';
import { BrandLoopDetails } from 'Pages/Dashboard/sections/BrandLoops/BrandLoopDetails';
import { BrandLoops } from 'Pages/Dashboard/sections';

export const authRoutes = [
	{
		path: AppRoutes.accountDashboard,
		element: <Dashboard />,
	},
	{
		path: AppRoutes.security,
		element: <LoginAndSecurity />,
	},
	{
		path: AppRoutes.privacy,
		element: <PrivacyAndSharing />,
	},
	{
		path: AppRoutes.paymentAndPayout,
		element: <PaymentAndPayout />,
	},
	{
		path: AppRoutes.referral,
		element: <Referral />,
	},
	{
		path: AppRoutes.notification,
		element: <Notifications />,
	},
	{
		path: AppRoutes.preference,
		element: <Preferences />,
	},
	{
		path: AppRoutes.productPage,
		element: <ProductPage />,
	},
	{
		path: AppRoutes.home,
		element: <AuthHomePage />,
	},
	{
		path: AppRoutes.survey,
		element: <NewSurvey />,
	},
	{
		path: AppRoutes.explore,
		element: <Explore />,
	},
	{
		path: AppRoutes.brand,
		element: <BrandPage />,
	},
	{
		path: AppRoutes.reward,
		element: <RedeemReward />,
	},
	{
		path: AppRoutes.allBrands,
		element: <AllBrands />,
	},
	{
		path: AppRoutes.allProducts,
		element: <AllProducts />,
	},
	{
		path: AppRoutes.notifications,
		element: <NotificationPage />,
	},
	{
		path: AppRoutes.withdrawalHistory,
		element: <AllWithdrawalHistory />,
	},
	{
		path: AppRoutes.brandLoops,
		element: <BrandLoops />,
	},
	{
		path: AppRoutes.brandLoop,
		element: <BrandLoopDetails />,
	},
];
