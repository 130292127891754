import * as yup from 'yup';
import { Stack, Box, chakra, Text, Button, Flex } from '@chakra-ui/react';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { useFormik } from 'formik';
import { useAppDispatch } from 'Hooks';
import { Navbar } from 'Pages/LandingPage/Components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { setWebCodeFromEnterCode } from 'store/slices';

export const EnterCodePage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const handleScan = () => {
		navigate(AppRoutes.dynamicLinkProduct);
	};

	const { handleChange, handleSubmit, errors } = useFormik({
		initialValues: {
			webCode: '',
		},
		validationSchema: yup.object().shape({
			webCode: yup.string().required('webcode required').min(6)
		}),
		onSubmit: ({ webCode }) => {
			dispatch(setWebCodeFromEnterCode(webCode));
			handleScan();
		},
	});
	
	return (
		<Box>
			<Box
				py="5"
				px={['5', null, 8, '20']}
			>
				<Navbar linkColorMode={'black'}  />
			</Box>
			<Stack align={['flex-start', 'center']} pt={'50px'} spacing={'30px'} bg={'#FFF8F8'} h={'100vh'}  px={['20px', null, 6, '20']} >
				<Text fontWeight={600} fontSize={['18px', '30px']} color={'LoopGrey.500'}>Welcome to <chakra.span color={'darkCoral'}>Loop</chakra.span></Text>
				<Text fontSize={['16px', '18px']} >
					We help you earn for leaving feedback on products you purchase from the brands you love.
				</Text>
				<Text fontSize={['16px', '18px']}>
					Please enter the web-code next to the QR-code to claim your cashback.
				</Text>
				<Flex direction={['column', 'row']} w={['100%', 'auto']}>
					<CustomInput
						bgColor={'#FFF'}
						w={['100%', '700px']}
						h={'60px'}
						borderColor={'#FFF'}
						borderRadius={['10px','5px']}
						placeholder='Enter webcode'
						onChange={handleChange}
						name='webCode'
						error={errors.webCode}
						_focus={{
							outline: 'none',
						}}
					/>
				
					<Button  
						variant={'primary'} 
						w={['120px', '200px']} 
						h={['50px', '60px']} 
						borderRadius={['10px', '5px']} 
						onClick={() => handleSubmit()}
						fontSize={['14px', '18px']}
						mt={['30px', 0]}
					>
						Scan Code
					</Button>
				</Flex>
				
			</Stack>
		</Box>
	);
};