import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalContentProps } from '@chakra-ui/react';

interface IRewardModalProps {
	content: React.ReactNode;
	isOpen: boolean;
	onClose: () => void;
	isCentered?: boolean;
	modalContentStyles?: ModalContentProps;
}

export const RewardModal = ({ content, isOpen, onClose, isCentered, modalContentStyles }: IRewardModalProps) => {
	return (
		<Modal size={'lg'} isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
			<ModalOverlay />
			<ModalContent h={'560px'} w={'600px'} padding={0} borderRadius={1} {...modalContentStyles}>
				<ModalCloseButton bg='white' borderRadius='50%' zIndex='overlay' />
				<ModalBody px={['10px', '30px']} overflow='auto' className='custom-modal'>
					{content}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
