import { ILoginDetails } from 'Types/Interface/IUser';
import * as Yup from 'yup';


export const validationSchema = Yup.object().shape({
	phoneNumber: Yup.string().min(3).required('Required'),
	password: Yup.string().min(3).required('Required'),
});

export const initialValues = {
	phoneNumber: '',
	password: '',
} as ILoginDetails;

