import { IFooterLink } from 'Types/Interface/IFooterLink';

export const LandingPageFooterLinks: IFooterLink[] = [
	{
		title: 'Company',
		links: [{
			label: 'About us',
			path: ''
		}, {
			label: 'Customers',
			path: ''
		}, {
			label: 'Blog',
			path: ''
		}, {
			label: 'Careers',
			path: ''
		}
		],
	},
	{
		title: 'Developers',
		links: [
			{
				label: 'Documentation',
				path: ''
			},
			{
				label:  'Api reference',
				path: ''
			},
			{
				label:  'Api status',
				path: ''
			}
		],
	},
	{
		title: 'Resources',
		links: [
			{
				label: 'Support',
				path: ''
			},
			{
				label: 'Guides',
				path: ''
			},
			{
				label: 'Licences',
				path: ''
			},
			{
				label:  'Privacy & terms',
				path: ''
			}

		]
	},
	{
		title: 'Contact',
		links: [
			{
				label: 'Hello@feedloop.com',
				path: ''
			},
			{
				label: 'Facebook',
				path: ''
			},
			{
				label: 'Instagram',
				path: ''
			},
			{
				label: 'Twitter',
				path: ''
			},
		],
	},
];
