import { Flex } from '@chakra-ui/react';
import React from 'react';

type SplitScreenPropTypes = {
  children: [React.ReactNode, React.ReactNode];
  leftWeight?: number;
  rightWeight?: number;
  hideLeft?: boolean;
};

export const SplitScreen = ({
	children,
	leftWeight = 1,
	rightWeight = 1,
	hideLeft,
}: SplitScreenPropTypes) => {
	const [left, right] = children;
	return (
		<Flex  h="100vh" overflow="auto" data-testid="splitscreen-parent" bg={'white'}>
			
			<Flex flex={leftWeight} data-testid="left-child" {...!hideLeft && {display: 'none' }}>
				{left}
			</Flex>
		
			<Flex flex={rightWeight} data-testid="right-child">
				{right}
			</Flex>
		</Flex>
	);
};
