import React from 'react';
import { PhoneNumberInput } from 'Components/FormInput/PhoneNumberInput';
import { useAppSelector } from 'Hooks';
import { InputName } from '../../AccountDetailList';

interface IPhoneEdit {
	onChange: (inputName: InputName, value: string | number) => void;
	inputName: InputName;
  }

export const PhoneEdit = ({ onChange, inputName}: IPhoneEdit) => {
	const { userInfo } = useAppSelector((state) => state.authUser);

	const handleChange = (value: string) => {
		onChange(inputName, value);
	};
	return (
		<PhoneNumberInput
			value={userInfo?.phoneNumber ?? ''}
			onChange={(value) => handleChange(value)}
		/>
	);
};
