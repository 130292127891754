import React from 'react';
import { IProduct } from 'Types/Interface/IProduct';
// import { ProductItems } from './productItems';
import { Box, Skeleton } from '@chakra-ui/react';
import { SwipeableGalleryContainer } from 'Pages/LandingPage/Components/SwipeableGalleryContainer';
import { ProductItems } from 'Components/Products/productItems';
import { SeeAllLink } from 'Pages/LandingPage/Components/SeeAllLink';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';

interface IProductsProps {
  products?: IProduct[];
  isLoading?: boolean;
}

export const MobileProductsSlider = ({
	products,
	isLoading,
}: IProductsProps) => {
	const navigate = useNavigate();
	return (
		<>
			<SwipeableGalleryContainer>
				{isLoading
					? new Array(4)
						.fill('_')
						.map((_, index) => (
							<Skeleton w={'250px'} mr={2} height="250px" key={index} />
						))
					: products?.map((product: IProduct) => (
						<Box key={product.id} w={'70vw'} mr={[4]}>
							<ProductItems key={product.id} product={product} />
						</Box>
					))}
			</SwipeableGalleryContainer>
			<SeeAllLink
				onClickLink={() => navigate(AppRoutes.allProducts)}
			/>
		</>
	);
};
