import { Box, useRadio} from '@chakra-ui/react';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomRadioButton = (props:any) => { // TODO: add proper type
	const { getInputProps, getCheckboxProps } = useRadio(props);

	const input = getInputProps();
	const checkbox = getCheckboxProps();

	return (
		<Box as='label'>
			<input {...input} />
			<Box
				{...checkbox}
				cursor='pointer'
				borderWidth='1px'
				borderRadius='20px'
				bg={'#F6F6F6'}
				// boxShadow='md'
				_checked={{
					bg: 'loop.600',
					color: 'white',
					// borderColor: '#264653',
				}}
				// _focus={{
				// 	boxShadow: 'outline',
				// }}
				px={5}
				py={5}
				h={['auto','90px']}
			>
				{props.children}
			</Box>
		</Box>
	);
};
