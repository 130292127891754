import React from 'react';
import { IProduct } from 'Types/Interface/IProduct';
import { ProductItems } from './productItems';
import { GalleryLayout } from 'Layouts/GalleryLayout';
import { Skeleton } from '@chakra-ui/react';

interface IProductsProps {
  products?: IProduct[];
  isLoading: boolean;
}

export const Products = ({products, isLoading}:IProductsProps ) => {
	return (
		<GalleryLayout display='block'>
			{isLoading
				? new Array(10)
					.fill('_')
					.map((_, index) => <Skeleton height="150px" key={index} />)
				: products?.map((product: IProduct) => (
					<ProductItems 
						key={product.id} 
						product={product}  
					/>
				))}
		</GalleryLayout>
	);
};
