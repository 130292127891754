import React from 'react';
import { Button, Input, Stack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { usePressEnter } from 'Hooks';
import { useCodeScan } from 'Hooks/useCodeScan';
import { CodeType } from 'Types/Enums';

interface ISurveyModalProp {
	onClose: () => void;
}

export const SurveyModal = ({ onClose }: ISurveyModalProp) => {
	const { handleScan, isLoading } = useCodeScan({ onScanComplete: onClose });

	const { handleChange, handleSubmit } = useFormik({
		initialValues: {
			webCode: '',
		},
		onSubmit: ({ webCode }) => {
			handleScan(webCode, CodeType.Webcode);
		},
	});

	usePressEnter(handleSubmit);

	return (
		<Stack p={5} spacing={'28px'}>
			<Text fontSize={'16px'} fontWeight={500} color={'#121B26'}>
				Enter a webcode
			</Text>
			<Input
				variant='flushed'
				placeholder='Webcode'
				onChange={handleChange}
				name='webCode'
				_focus={{
					outline: 'none',
				}}
			/>
			<Button variant={'primary'} isLoading={isLoading} onClick={() => handleSubmit()} borderRadius={'10px'}>
				Submit
			</Button>
		</Stack>
	);
};
