import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/Sunshine.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const DrugIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const SunshineIcon = ({
	size = '20px',
	// color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M9.99824 15.83C13.2182 15.83 15.8285 13.2197 15.8285 9.99971C15.8285 6.77974 13.2182 4.16943 9.99824 4.16943C6.77827 4.16943 4.16797 6.77974 4.16797 9.99971C4.16797 13.2197 6.77827 15.83 9.99824 15.83Z" fill="#F9AE00"/>
			<path d="M10 2.62863C9.8964 2.62863 9.79704 2.58748 9.72379 2.51422C9.65053 2.44096 9.60938 2.34161 9.60938 2.23801V0.390625C9.60938 0.287025 9.65053 0.187668 9.72379 0.114411C9.79704 0.041155 9.8964 0 10 0C10.1036 0 10.203 0.041155 10.2762 0.114411C10.3495 0.187668 10.3906 0.287025 10.3906 0.390625V2.23801C10.3906 2.34161 10.3495 2.44096 10.2762 2.51422C10.203 2.58748 10.1036 2.62863 10 2.62863Z" fill="#FDD83B"/>
			<path d="M10 20.0002C9.8964 20.0002 9.79704 19.9591 9.72379 19.8858C9.65053 19.8126 9.60938 19.7132 9.60938 19.6096V17.7622C9.60938 17.6586 9.65053 17.5593 9.72379 17.486C9.79704 17.4127 9.8964 17.3716 10 17.3716C10.1036 17.3716 10.203 17.4127 10.2762 17.486C10.3495 17.5593 10.3906 17.6586 10.3906 17.7622V19.6096C10.3906 19.7132 10.3495 19.8126 10.2762 19.8858C10.203 19.9591 10.1036 20.0002 10 20.0002Z" fill="#FDD83B"/>
			<path d="M6.12067 3.66857C6.05204 3.66864 5.9846 3.65062 5.92514 3.61633C5.86569 3.58204 5.81632 3.5327 5.782 3.47326L4.85833 1.87334C4.83227 1.8289 4.81527 1.77974 4.80829 1.7287C4.80131 1.67766 4.8045 1.62574 4.81766 1.57594C4.83083 1.52613 4.85372 1.47942 4.88501 1.4385C4.9163 1.39758 4.95538 1.36325 5 1.33749C5.04461 1.31173 5.09388 1.29505 5.14496 1.28841C5.19605 1.28177 5.24794 1.28531 5.29766 1.2988C5.34738 1.3123 5.39393 1.3355 5.43465 1.36706C5.47536 1.39862 5.50943 1.43793 5.53489 1.48271L6.4586 3.08259C6.49287 3.14195 6.51092 3.20928 6.51094 3.27782C6.51095 3.34636 6.49294 3.41369 6.45869 3.47306C6.42445 3.53244 6.37519 3.58175 6.31586 3.61606C6.25653 3.65037 6.18921 3.66847 6.12067 3.66853V3.66857Z" fill="#FDD83B"/>
			<path d="M14.8069 18.7127C14.7382 18.7128 14.6708 18.6948 14.6113 18.6605C14.5519 18.6262 14.5025 18.5768 14.4682 18.5174L13.5445 16.9175C13.5189 16.8731 13.5022 16.824 13.4955 16.7732C13.4888 16.7223 13.4922 16.6706 13.5055 16.6211C13.5188 16.5715 13.5417 16.5251 13.5729 16.4844C13.6041 16.4437 13.6431 16.4095 13.6875 16.3839C13.7319 16.3582 13.781 16.3416 13.8318 16.3349C13.8827 16.3282 13.9344 16.3316 13.9839 16.3449C14.0335 16.3581 14.0799 16.381 14.1206 16.4123C14.1613 16.4435 14.1955 16.4824 14.2211 16.5269L15.1448 18.1267C15.1791 18.1861 15.1971 18.2534 15.1971 18.322C15.1971 18.3905 15.1791 18.4578 15.1449 18.5172C15.1106 18.5766 15.0614 18.6259 15.002 18.6602C14.9427 18.6945 14.8754 18.7126 14.8069 18.7127Z" fill="#FDD83B"/>
			<path d="M3.27752 6.50952C3.20907 6.50952 3.14183 6.49145 3.0826 6.45714L1.48271 5.53342C1.43793 5.50796 1.39862 5.47389 1.36706 5.43318C1.3355 5.39247 1.3123 5.34591 1.2988 5.2962C1.28531 5.24648 1.28177 5.19458 1.28841 5.1435C1.29505 5.09241 1.31173 5.04314 1.33749 4.99853C1.36325 4.95392 1.39758 4.91484 1.4385 4.88355C1.47942 4.85225 1.52613 4.82937 1.57594 4.8162C1.62574 4.80303 1.67766 4.79984 1.7287 4.80682C1.77974 4.8138 1.8289 4.83081 1.87334 4.85686L3.47322 5.78053C3.54776 5.82353 3.60601 5.88993 3.63893 5.96943C3.67185 6.04893 3.67761 6.13707 3.6553 6.22017C3.63299 6.30328 3.58386 6.37669 3.51555 6.42901C3.44724 6.48133 3.36356 6.50963 3.27752 6.50952Z" fill="#FDD83B"/>
			<path d="M18.3222 15.1952C18.2537 15.1952 18.1865 15.1772 18.1272 15.1428L16.5274 14.2192C16.4376 14.1674 16.3722 14.082 16.3453 13.982C16.3185 13.8819 16.3326 13.7753 16.3844 13.6856C16.4362 13.5958 16.5215 13.5304 16.6216 13.5035C16.7216 13.4767 16.8283 13.4908 16.918 13.5426L18.5179 14.4663C18.5924 14.5093 18.6506 14.5757 18.6835 14.6552C18.7164 14.7347 18.7222 14.8228 18.6999 14.9059C18.6776 14.989 18.6285 15.0624 18.5602 15.1147C18.4919 15.167 18.4082 15.1953 18.3222 15.1952Z" fill="#FDD83B"/>
			<path d="M2.23801 10.3906H0.390625C0.287025 10.3906 0.187668 10.3495 0.114411 10.2762C0.041155 10.203 0 10.1036 0 10C0 9.8964 0.041155 9.79704 0.114411 9.72379C0.187668 9.65053 0.287025 9.60938 0.390625 9.60938H2.23801C2.34161 9.60938 2.44096 9.65053 2.51422 9.72379C2.58748 9.79704 2.62863 9.8964 2.62863 10C2.62863 10.1036 2.58748 10.203 2.51422 10.2762C2.44096 10.3495 2.34161 10.3906 2.23801 10.3906Z" fill="#FDD83B"/>
			<path d="M19.6091 10.3906H17.7617C17.6581 10.3906 17.5588 10.3495 17.4855 10.2762C17.4122 10.203 17.3711 10.1036 17.3711 10C17.3711 9.8964 17.4122 9.79704 17.4855 9.72379C17.5588 9.65053 17.6581 9.60938 17.7617 9.60938H19.6091C19.7127 9.60938 19.8121 9.65053 19.8853 9.72379C19.9586 9.79704 19.9997 9.8964 19.9997 10C19.9997 10.1036 19.9586 10.203 19.8853 10.2762C19.8121 10.3495 19.7127 10.3906 19.6091 10.3906Z" fill="#FDD83B"/>
			<path d="M1.68009 15.1952C1.59406 15.1953 1.51041 15.167 1.44212 15.1147C1.37383 15.0624 1.32472 14.989 1.30242 14.9059C1.28011 14.8228 1.28587 14.7347 1.31878 14.6552C1.35169 14.5757 1.40992 14.5093 1.48443 14.4663L3.08431 13.5426C3.12874 13.5169 3.17778 13.5003 3.22864 13.4936C3.2795 13.4869 3.33118 13.4903 3.38073 13.5035C3.43028 13.5168 3.47673 13.5397 3.51743 13.571C3.55813 13.6022 3.59227 13.6411 3.61792 13.6856C3.64357 13.73 3.66022 13.779 3.66691 13.8299C3.67361 13.8807 3.67022 13.9324 3.65694 13.982C3.64367 14.0315 3.62076 14.078 3.58953 14.1187C3.5583 14.1594 3.51936 14.1935 3.47493 14.2192L1.87505 15.1429C1.8158 15.1772 1.74855 15.1952 1.68009 15.1952Z" fill="#FDD83B"/>
			<path d="M16.7232 6.50952C16.6371 6.50963 16.5534 6.48133 16.4851 6.42901C16.4168 6.37669 16.3677 6.30328 16.3454 6.22017C16.3231 6.13707 16.3288 6.04893 16.3618 5.96943C16.3947 5.88993 16.4529 5.82353 16.5275 5.78053L18.1273 4.85686C18.1718 4.83081 18.2209 4.8138 18.272 4.80682C18.323 4.79984 18.3749 4.80303 18.4247 4.8162C18.4746 4.82937 18.5213 4.85225 18.5622 4.88355C18.6031 4.91484 18.6374 4.95392 18.6632 4.99853C18.689 5.04314 18.7056 5.09241 18.7123 5.1435C18.7189 5.19458 18.7154 5.24648 18.7019 5.2962C18.6884 5.34591 18.6652 5.39247 18.6336 5.43318C18.6021 5.47389 18.5628 5.50796 18.518 5.53342L16.9181 6.45714C16.8589 6.49145 16.7916 6.50952 16.7232 6.50952Z" fill="#FDD83B"/>
			<path d="M5.19495 18.7127C5.12641 18.7126 5.0591 18.6945 4.99977 18.6602C4.94043 18.6259 4.89117 18.5766 4.85693 18.5172C4.82269 18.4578 4.80467 18.3905 4.80469 18.322C4.8047 18.2534 4.82275 18.1861 4.85702 18.1267L5.7807 16.5269C5.8325 16.4371 5.91782 16.3717 6.01789 16.3449C6.11796 16.318 6.22459 16.3321 6.31431 16.3839C6.40403 16.4357 6.4695 16.521 6.49632 16.6211C6.52314 16.7211 6.5091 16.8278 6.4573 16.9175L5.53359 18.5173C5.49928 18.5767 5.44992 18.6261 5.39047 18.6604C5.33102 18.6947 5.26359 18.7127 5.19495 18.7127Z" fill="#FDD83B"/>
			<path d="M13.8825 3.66857C13.8139 3.66851 13.7466 3.65041 13.6873 3.6161C13.6279 3.58179 13.5787 3.53247 13.5444 3.4731C13.5102 3.41373 13.4922 3.34639 13.4922 3.27786C13.4922 3.20932 13.5103 3.14199 13.5445 3.08263L14.4682 1.48271C14.4937 1.43793 14.5278 1.39862 14.5685 1.36706C14.6092 1.3355 14.6557 1.3123 14.7055 1.2988C14.7552 1.28531 14.8071 1.28177 14.8582 1.28841C14.9092 1.29505 14.9585 1.31173 15.0031 1.33749C15.0477 1.36325 15.0868 1.39758 15.1181 1.4385C15.1494 1.47942 15.1723 1.52613 15.1855 1.57594C15.1986 1.62574 15.2018 1.67766 15.1948 1.7287C15.1879 1.77974 15.1708 1.8289 15.1448 1.87334L14.2211 3.47322C14.1868 3.53267 14.1374 3.58202 14.078 3.61632C14.0185 3.65061 13.9511 3.66863 13.8825 3.66857Z" fill="#FDD83B"/>
		</Icon>
	);
};