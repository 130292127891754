import React from 'react';
import { Flex } from '@chakra-ui/react';
import { SurveyProgressIndicator } from './SurveyProgress';
import { SurveyNavigation } from './SurveyNavigation';
import { useAppSelector } from 'Hooks';

export const FooterSection = () => {
	const { isSurveyComplete, section } = useAppSelector((state) => state.survey);
	return isSurveyComplete ? null : (
		<Flex bg='#ECEFF2' h='70px' px={['6', '16']} alignItems='center' justifyContent='space-between'>
			{section === 'questions' && <SurveyProgressIndicator />}
			<SurveyNavigation />
		</Flex>
	);
};
