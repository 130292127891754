import { Stack, Text, chakra, Flex, Image, Button } from '@chakra-ui/react';
import React from 'react';
import { IProduct } from 'Types/Interface/IProduct';
import LoopSvg from 'Assets/svg/LoopSvg.svg';
import CollabIcon from 'Assets/svg/CollabIcon.svg';
import { AppRoutes } from 'Routes/AppRoutes';
import { useNavigate } from 'react-router-dom';


interface IHeaderProps{
    data?: IProduct;
	reward?: number;
}

export const DynamicProductPageContents = ({data}:IHeaderProps) => {
	const navigate = useNavigate();
	const handleLogin = () => {
		navigate(AppRoutes.login);
	};

	return (
		<Stack spacing={8}>
			<Text fontSize={['18px','24px']} fontWeight={400} pt={['20px','50px']}>
				{data?.brand.name} has teamed up with <chakra.span color={'darkCoral'}>Loop</chakra.span> to get your feedback on{' '}
				<chakra.span fontWeight={600} textDecoration={'underline'}>
					{data?.name}
				</chakra.span>
			</Text>
			<Flex justifyContent={'space-evenly'} alignItems={'center'} bg={['none','#F9F9F9']} h={'150px'}>
				<Image src={LoopSvg} boxSize={'50px'} objectFit={'contain'} />
				<Image src={CollabIcon} boxSize={'21px'} />
				<Image src={data?.brand.logoUrl} boxSize={'90px'} objectFit={'contain'} />
			</Flex>
			<Stack justify={'flex-start'} spacing={'30px'}>
				<Text fontSize={'16px'} color={'#2B2B2B'}>
					Your response will only be shared with <chakra.span textDecor={'underline'}>{data?.brand.name}.</chakra.span> You can choose what level of information you want
					to share.
				</Text>
				<Text fontSize={'16px'} color={'#2B2B2B'}>
					Thank you for scanning, please Login or Register to attach this QR code to your account.
				</Text>
			</Stack>
			<Button
				variant='primary'
				w={'335px'}
				h={'50px'}
				borderRadius={['10px', '5px']}
				px={20}
				onClick={handleLogin}
			>
				Login / Signup
			</Button>
		</Stack>
	);
};