import React, { useState } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Avatar,
	Box,
	Button,
	Heading,
	HStack,
	Image,
	Stack,
	Text,
	theme,
	useMediaQuery,
} from '@chakra-ui/react';
import loopIcon from 'Assets/svg/loopIcon2.svg';
import connectLeft from 'Assets/svg/ConnectLeft.svg';
import connectRight from 'Assets/svg/ConnectRight.svg';

import { useAppSelector } from 'Hooks';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';
import { BrandPermissions } from './BrandPermissions';
import { convertToOrdinal } from 'Utils/convertToOrdinal';
import { IBrand } from 'Types/Interface/IBrand';

interface IBrandLoopProps {
	brand: IBrand;
	onSubmit: (permissions?: IUserBrandPermission) => void;
	isLoading: boolean;
}

export const BrandLoop = ({ brand, onSubmit, isLoading }: IBrandLoopProps) => {
	const { activeSurvey } = useAppSelector((state) => state.survey);
	const { userInfo } = useAppSelector((state) => state.authUser);

	const [permissions, setPermissions] = useState<IUserBrandPermission>({
		userId: userInfo?.id ?? '',
		brandId: brand?.id ?? '',
		isBrandLooped: true,
		allowContactViaEmail: true,
		allowContactViaSms: true,
		allowContactViaWhatsapp: true,
		allowAdTargeting: true,
		allowViewPurchaseHistory: true,
		allowContactViaLoop: true,
	});

	const [isUserSharingData, setIsUserSharingData] = useState<boolean>(false);

	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const avatarSize = isMobile ? 'md' : 'lg';

	const handleSubmit = (shareData: boolean) => {
		setIsUserSharingData(shareData);
		onSubmit(shareData ? permissions : undefined);
	};

	return (
		<>
			<Stack spacing={[8, 5]} mb={['0', '38px']}>
				<HStack pb={['2', '5']}>
					<Avatar
						src={userInfo?.avatarUrl}
						name={`${userInfo?.firstName} ${userInfo?.lastName}`}
						objectFit='cover'
						size={avatarSize}
					/>
					<Image src={connectLeft} />
					<Image src={loopIcon} h={[6, 8]} />
					<Image src={connectRight} />
					<Image src={brand.logoUrl} objectFit='contain' maxH={['40px', '60px']} />
				</HStack>

				<HStack>
					<Heading fontSize={['xl', '3xl']} color={'blackTextColor.100'}>
						Your{' '}
						<Text as='span' color={'lightCoral'}>
							Loop
						</Text>{' '}
						with {brand.name}
					</Heading>
				</HStack>

				<Box fontSize={['md', 'lg']}>
					<Text as='span'>This is </Text>
					{activeSurvey.brandCodeScansCount <= 1 && <Text as='span'>the first time you are interacting</Text>}
					{activeSurvey.brandCodeScansCount > 1 && (
						<Text as='span'>
							your{' '}
							<Text as='span' color={'lightCoral'}>
								{convertToOrdinal(activeSurvey.brandCodeScansCount)} interaction
							</Text>{' '}
						</Text>
					)}
					<Text as='span'>
						with {brand.name}. Would you like to join their Loop and share your contact information with them?
					</Text>
				</Box>

				<Text fontSize={['md', 'lg']}>
					You may adjust your contact preferences and participation in their Loop at any time.
				</Text>

				<Accordion variant={'primary'} allowMultiple>
					<AccordionItem>
						<AccordionButton>Contact preferences</AccordionButton>

						<AccordionPanel>
							<BrandPermissions brandName={brand.name} permissions={permissions} onUpdate={setPermissions} />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Stack>

			<Stack spacing={4} direction={['column', 'row']}>
				<Button
					variant='primary'
					w={['100%', '150px']}
					h={['48px', '60px']}
					fontSize={['sm', 'md']}
					onClick={() => {
						handleSubmit(true);
					}}
					isLoading={isUserSharingData && isLoading}
					isDisabled={!isUserSharingData && isLoading}
				>
					Yes
				</Button>
				<Button
					variant='grey'
					w={['100%', '242px']}
					h={['48px', '60px']}
					fontSize={['sm', 'md']}
					onClick={() => {
						handleSubmit(false);
					}}
					isLoading={!isUserSharingData && isLoading}
					isDisabled={isUserSharingData && isLoading}
				>
					Not right now
				</Button>
			</Stack>
		</>
	);
};
