import React from 'react';

import {
	Flex,
	Heading,
	VStack,
	Text,
	Image,
} from '@chakra-ui/react';

import QrCode from 'Assets/svg/QrCode.svg';
import { IJumbotronContents } from 'Types/Interface/ILandingPage';
import { Pointer } from 'Components/Pointer';

interface IJumbotronProps{
	contents: IJumbotronContents[]
}

export const JumbotronSection = ({ contents }: IJumbotronProps) => {
	return (
		<Flex
			alignItems={['center', 'flex-start']}
			flexDirection={{ base: 'column-reverse', md: 'row' }}
			justifyContent={['space-between']}
			mt={{base:'28',md:'24', xxl:'32'}}
			maxW={'1440px'} m={'32 auto'}
			px={{'2xl':'20'}}
		>
			{contents.map(content => {
				return(
					<VStack
						alignItems={['center', 'flex-start']}
						spacing={10}
						textAlign={['left', 'left', 'left']}
						key={content.heading}
					>
						<VStack alignItems={['flex-start', 'flex-start', 'flex-start']} spacing={4} >
							<Heading fontSize={{ base: '2xl', tablet: '2xl', xxl: '5xl' }}
								color="white" 
								w={['90%', '615.45px', '615.45px']}
								fontWeight={'700'}
								lineHeight={['8', '61px']}
								letterSpacing={'-0.03em'}
							>
								{content.heading}
							</Heading>
							<Text fontSize={{ sm: 'xs', tablet: '16px', xxl:'16px' }} 
								lineHeight={6} w={{base:'2xs', sm:'xs',tablet:'604px',xl:'604px'}} 
								color="white"
							>
								{content.text}
							</Text>
						</VStack>
						<VStack 
							alignItems={['center', 'flex-start', 'flex-start']} 
							spacing={3}
							visibility={['hidden', 'visible']}
						>
							<Text fontSize={{ sm: 'xs', tablet: 'xs', xxl:'xs' }} 
								color="white" 
								fontWeight="500"
							>
								{content.subText}
							</Text>
							<Image src={QrCode}/>
						</VStack>
						<Pointer />
					</VStack>
				);
			})}	
		</Flex>
	);
};
