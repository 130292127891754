import React from 'react';
import { Box, Divider, HStack, Stack, Switch, Text } from '@chakra-ui/react';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';
import { useAppSelector } from 'Hooks';

interface BrandPermissionsProps {
	brandName: string;
	permissions: IUserBrandPermission;
	isDisabled?: boolean;
	onUpdate: (updatedPermissions: IUserBrandPermission) => void;
}

export const BrandPermissions: React.FC<BrandPermissionsProps> = ({ brandName, permissions, isDisabled = false, onUpdate }) => {
	const { userInfo } = useAppSelector((state) => state.authUser);
	
	const handlePermissionChange = (fieldName: string, value?: boolean) => {
		const updatedPermissions = {
			...permissions,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[fieldName]: value ?? !(permissions as any)[fieldName],
		};

		onUpdate(updatedPermissions);
	};

	return (
		<Stack spacing={'5'} fontSize={['sm', null, 'md']}>
			<Text>Your privacy is paramount to us, we will only allow communication as per your selected settings.</Text>

			<Box>
				<Text as='span' textColor={'loopGrey.400'} opacity={'25%'}>
					Email:
				</Text>{' '}
				<Text as='span' textColor={'loopGrey.500'} opacity={'70%'}>
					{userInfo?.emailAddress}
				</Text>
			</Box>

			<HStack justifyContent={'space-between'}>
				<Text>Sign up to mailing list</Text>
				<Switch
					variant='primary'
					isChecked={permissions.allowContactViaEmail}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowContactViaEmail', event.target.checked)}
				/>
			</HStack>
			<Divider />

			<Box>
				<Text as='span' textColor={'loopGrey.400'} opacity={'25%'}>
					Tel:
				</Text>{' '}
				<Text as='span' textColor={'loopGrey.500'} opacity={'70%'}>
					{userInfo?.phoneNumber}
				</Text>
			</Box>

			<HStack justifyContent={'space-between'}>
				<Text>Receive SMS messages</Text>
				<Switch
					variant='primary'
					isChecked={permissions.allowContactViaSms}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowContactViaSms', event.target.checked)}
				/>
			</HStack>

			<HStack justifyContent={'space-between'}>
				<Text>Receive WhatsApp messages</Text>
				<Switch
					variant='primary'
					isChecked={permissions.allowContactViaWhatsapp}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowContactViaWhatsapp', event.target.checked)}
				/>
			</HStack>
			<Divider />

			<Text textColor={'loopGrey.500'} opacity={'70%'}>
				More settings
			</Text>

			<HStack justifyContent={'space-between'}>
				<Text>
					Allow {brandName} to use the above information for ad-targeting through platforms such as Facebook, Instagram
					and TikTok?
				</Text>
				<Switch
					variant='primary'
					isChecked={permissions.allowAdTargeting}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowAdTargeting', event.target.checked)}
				/>
			</HStack>

			<HStack justifyContent={'space-between'}>
				<Text>
					Allow {brandName} to see all your purchase history with {brandName}?
				</Text>
				<Switch
					variant='primary'
					isChecked={permissions.allowViewPurchaseHistory}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowViewPurchaseHistory', event.target.checked)}
				/>
			</HStack>

			<HStack justifyContent={'space-between'}>
				<Text>Allow {brandName} to contact you on Loop for more paid feedback and special offers?</Text>
				<Switch
					variant='primary'
					isChecked={permissions.allowContactViaLoop}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowContactViaLoop', event.target.checked)}
				/>
			</HStack>
			<Divider />
		</Stack>
	);
};
