import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/CigaretteIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const CigaretteIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const CigaretteIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M15.6602 10.6133C15.6602 10.9207 15.9094 11.1699 16.2168 11.1699C16.5242 11.1699 16.7734 10.9207 16.7734 10.6133C16.7734 8.1084 15.4722 7.1325 14.3242 6.27148C13.2489 5.46502 12.3203 4.76855 12.3203 2.82031C12.3203 2.5129 12.0711 2.26367 11.7637 2.26367C11.4563 2.26367 11.207 2.5129 11.207 2.82031C11.207 5.3252 12.5082 6.3011 13.6562 7.16211C14.7315 7.96857 15.6602 8.66504 15.6602 10.6133Z" fill={color}/>
			<path d="M16.7734 2.82031C16.7734 2.5129 16.5242 2.26367 16.2168 2.26367C15.9094 2.26367 15.6602 2.5129 15.6602 2.82031C15.6602 5.21543 16.3013 6.17712 16.8669 7.02555C17.3913 7.81223 17.8867 8.55527 17.8867 10.6133C17.8867 10.9207 18.1359 11.1699 18.4434 11.1699C18.7508 11.1699 19 10.9207 19 10.6133C19 8.21817 18.3589 7.25648 17.7932 6.40805C17.2688 5.62137 16.7734 4.87832 16.7734 2.82031Z" fill={color}/>
			<path d="M18.4434 12.2832H0.556641C0.249227 12.2832 0 12.5324 0 12.8398V16.1797C0 16.4871 0.249227 16.7363 0.556641 16.7363H18.4434C18.7508 16.7363 19 16.4871 19 16.1797V12.8398C19 12.5324 18.7508 12.2832 18.4434 12.2832ZM15.6602 13.3965V15.623H7.83008V13.3965H15.6602ZM1.11328 13.3965H6.7168V15.623H1.11328V13.3965ZM17.8867 15.623H16.7734V13.3965H17.8867V15.623Z" fill={color}/>
		</Icon>
	);
};