import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/LoopIconV2.svg';

export const LoopV2Icon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img  src={logo} ref={ref} {...props} />;
});
