import React from 'react';
import { Flex, Box, CSSObject} from '@chakra-ui/react';

interface ISplitLayoutProps {
	children: [React.ReactNode, React.ReactNode];
	sx?: CSSObject;
	width: string;
	display: string;
}

export const SplitLayout = ({ display, children, sx, width }: ISplitLayoutProps) => {
	const [left, right] = children;
	return (
		<Flex
			justifyContent='space-between'
			sx={sx}
			direction={['column','row']}
		>
			<Box w={['100%',null, null,width]} pb={['40px', 0]}>{left}</Box>
			<Box display={[display, null, null, 'block']}>{right}</Box>
		</Flex>
	);
};
