import React from 'react';
import { DataTable } from 'Components/DataTable';
import { Column } from 'react-table';
import { useGetPayoutRequestsQuery } from 'services';
import { Box, useMediaQuery, VStack, theme, Image, Flex, Text, TableCaption } from '@chakra-ui/react';
import WithdrawalIcon from 'Assets/svg/WithdrawalIcon.svg';
import { IHistory } from 'Types/Interface/IHistory';
import { formatDateShort, TransactionStatusComponent } from 'Utils/dataTableUtils';
import useNavigation from 'Utils/navigationUtils';

export const WithdrawalHistory = () => {
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const params = { pageNumber: 1, pageSize: 5 };
	const { data, isLoading, isFetching } = useGetPayoutRequestsQuery(params);
	const newData = data?.data?.map((item) => ({
		...item,
		id: item.id,
	}));

	const transactionCount = newData?.length ?? 0;
	const { navigateToWithdrawalHistoryTable } = useNavigation();

	const columns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'dateCreated',
				Cell: (item) => (
					<Box color={'brand.black'} fontWeight={400} fontSize={'14px'}>
						{formatDateShort(item.row.original.dateCreated)}
					</Box>
				),
			},
			{
				Header: 'Amount',
				accessor: 'points',
				Cell: (item) => (
					<Box color={'brand.black'} fontWeight={400} fontSize={'14px'} textAlign={'left'}>
						{item.row.original.points} LNT
					</Box>
				),
			},
			{
				id: 'status',
				Header: 'Status',
				accessor: 'status',
				Cell: (item) => <TransactionStatusComponent status={item.row.original.status} />,
			},
		],
		[],
	);

	const mobileColumns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				accessor: 'dateCreated',
				Cell: (item) => (
					<Flex alignItems={'center'}>
						<Image w={'40px'} height={'40px'} src={WithdrawalIcon} mr={'10px'} />
						<VStack align={'flex-start'} spacing={'5px'}>
							<Text fontSize='14px'>Payment</Text>
							<Box fontSize='12px' color='loopGrey.800'>
								{formatDateShort(item.row.original.dateCreated)}
							</Box>
						</VStack>
					</Flex>
				),
			},
			{
				accessor: 'points',
				Cell: (item) => (
					<VStack alignItems={'flex-end'} spacing={'5px'}>
						<Box fontSize={['14px']} fontWeight='500'>
							{item.row.original.points} LNT
						</Box>
						<Box>
							<TransactionStatusComponent status={item.row.original.status} />
						</Box>
					</VStack>
				),
			},
		],
		[],
	);

	return (
		<>
			<DataTable
				data={newData || []}
				columns={isMobile ? mobileColumns : columns}
				isLoading={isLoading || isFetching}
				isMobile={isMobile}
				showCheckboxes={false}
				showMenu={false} 
				tableTopCaption={
					<TableCaption
						placement='top'
						fontWeight={'500'}
						fontSize={'14px'}
						textAlign={'left'}
						color={'loopGrey.500'}
					>
						Withdrawal History
					</TableCaption>
				}	
				tableBottomCaption={
					<TableCaption
						fontSize={'12px'}
						textAlign={'left'}
						placement='bottom'
						fontWeight={'500'}
						color={'loop.500'}
						cursor={'pointer'}
						onClick={navigateToWithdrawalHistoryTable}
					>
						{transactionCount > 0 &&  'View more' }
						{!transactionCount && 'No transaction yet'}
					</TableCaption> 
				}	
			/>
		</>
	);
};
