import React from 'react';
import { Box, chakra, Flex, Heading } from '@chakra-ui/react';
import { brandIcons } from 'Constants';
import { BrandBox } from '../components';
import { SlidingItemsContainer } from 'Pages/LandingPage/Components/SlidingItemsContainer';

export const PartnersSection = () =>  (
	<Box py="16">
		<Heading fontWeight={'bold'} fontSize={['2xl','4xl']} textAlign='center' >
        Trusted by over 50,000+ businesses <br/> choose <chakra.span color={'#FF6F61'}>Loop</chakra.span>
		</Heading>
		<SlidingItemsContainer>
			<Flex  justifyContent={'space-between'} mt={10} alignItems={'center'} w='max-content'>
				{brandIcons.map(({ icon, key }) => (
					<BrandBox key={key} icon={icon} />
				))}
			</Flex>
		</SlidingItemsContainer>
		<SlidingItemsContainer direction='right'>
			<Flex justifyContent="space-between" alignItems={'center'} w='max-content'>
				{brandIcons.map(({ icon, key }) => (
					<BrandBox key={key} icon={icon} />
				))}
			</Flex>
		</SlidingItemsContainer>
	</Box>
);
