import { IFaqs } from 'Types/Interface/ILandingPage';

export const Faqs: IFaqs[] = [
	{
		id: 1,
		title: 'Does Loop perform a credit check, and will this affect my credit score?',
		subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lo rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lore m ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ips um dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ips um dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsu m dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna.'
	},
	{
		id: 2,
		title: 'What information do I need to sell on Loop?',
		subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lo rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lore m ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ips um dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ips um dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsu m dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna.'
	},
	{
		id: 3,
		title: 'Does Loop perform a credit check, and will this affect my credit score?',
		subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lo rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lore m ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ips um dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ips um dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsu m dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna.'
	},
	{
		id: 4,
		title: 'Does Loop perform a credit check, and will this affect my credit score?',
		subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lo rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lore m ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ips um dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ips um dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsu m dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna.'
	},
]; 