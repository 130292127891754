import React, { useEffect } from 'react';
import { Heading, Stack, Text } from '@chakra-ui/react';

import { useUserBasicProfile, useToast} from 'Hooks';
import { UserPreferenceFormName } from 'Types/Enums';
import { PreferenceItem } from './components/PreferenceItem';
import { useDefaultErrorHandler } from 'Utils/errorHandling';


export const Content = () => {
	const { alert } = useToast();
	const { setFieldValue, handleSubmit, values, isLoading, isSuccess, isError, error } =  useUserBasicProfile();
	const handleChange = (name: string, val: string | number) => {
		setFieldValue(name,val);
	};
	useEffect(() => {
		if(isSuccess){
			alert('success', 'Updated info successfully');
		}		
	}, [isSuccess]);

	useDefaultErrorHandler(isError, error);

	return (
		<Stack spacing={10} flex={1}>
			<Stack>
				<Heading size="sm">Basic information</Heading>
				<Text>Receive messages from brands about products...</Text>
			</Stack>
			<PreferenceItem 
				title={UserPreferenceFormName.Gender}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.gender}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Sexuality}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.sexuality}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Ethnicity}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.ethnicity}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			
			<PreferenceItem 
				title={UserPreferenceFormName.Children}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.children}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Hometown}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.hometown}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Age}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.age}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Industry}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.industry}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.School_location}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.school_location}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Education}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.education}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Religion}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.religion}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Politics}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.politics}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<Stack>
				<Heading size="sm">Habits</Heading>
				<Text>Receive messages from brands about products...</Text>
			</Stack>
			<PreferenceItem 
				title={UserPreferenceFormName.Alchohol}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.alchohol}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Smoke}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.smoke}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Marijuana}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.marijuana}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
			<PreferenceItem 
				title={UserPreferenceFormName.Drugs}
				onChange={handleChange}
				onSave={handleSubmit}
				value={values.drugs}
				isLoading={isLoading}
				canClose={isSuccess || isError}
			/>
		</Stack>
	);
};
