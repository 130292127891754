import React from 'react';
import { 
	Flex, 
	Heading, 
	Text 
} from '@chakra-ui/react';
import { CategoryHeader } from 'Pages/LandingPage/HomeSection/sections/CategoryHeader';
import { Beauty } from 'Constants/BeautyConstant';
import { Gallery } from 'Components/Gallery';
import { Categories } from 'Constants/CategoryConstant';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { GalleryMobile } from 'Components/Gallery/GalleryMobile';
import { SeeAllLink } from 'Pages/LandingPage/Components/SeeAllLink';

export const Brands = () => {
	const navigate = useNavigate();
	return (
		<Flex direction={'column'}>
			<Flex
				direction={'column'} 
				py={{ base: '10', tablet: '8' }} 
				// px={{ base: '6', tablet: '10', xxl:'16' }} 
			>
				<Heading
					my={6} 
					fontSize={{ base: '2xl', tablet: '2xl', xxl: '3xl' }}
					fontWeight={'600'}
					w={['70%', 'auto']}
				>
					The brands behind the buzz.
				</Heading>
				<Text
					w={{base:'auto', tablet:'auto', xxl:'3xl'}}
					fontSize={'sm'} 
					fontWeight={400} 
					color={'#586068'}
				>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
					sed do eius mod tempor incididunt ut labore et dolore magna. 
					Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eius mod 
				</Text>
			</Flex>
			<Flex
				direction={'column'}
				// px={{base:'0', xxl: 6 }}
				py={{ xxl: 6 }}
			>
				<CategoryHeader
					onClickLink={() => {
						navigate(AppRoutes.featuredBrands);
					}}
					title='Features brands'
				/>
				<Gallery 
					collection={Beauty} 
					visibility={true} 
					onClickImage={()=> navigate(AppRoutes.featuredBrands)} 
					display={'none'}
				/>
				<GalleryMobile 
					collection={Beauty} 
					visibility={true} 
					onClickImage={()=> navigate(AppRoutes.featuredBrands)} 
					display = {'none'}
				/>
				<SeeAllLink onClickLink={() => console.log('goto')}/>
			</Flex>
			<Flex
				direction={'column'}
				// px={{base:'0', xxl: 4 }}
				py={{ xxl: 6 }}
			>
				<CategoryHeader
					onClickLink={() => {
						console.log('goto');
					}}
					title='Features Categories'
				/>
				<Gallery 
					collection={Categories} 
					visibility={true} 
					onClickImage={()=> console.log('goto')} 
					display = {'none'}
				/>
				<GalleryMobile
					collection={Categories} 
					visibility={true} 
					onClickImage={()=> console.log('goto')} 
					display = {'none'}
				/>
				<SeeAllLink onClickLink={() => console.log('goto')}/>
			</Flex>
		</Flex>
	);
};