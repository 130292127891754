/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Flex, IconButton, Spacer, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';

interface ICarouselContainerProps {
  children: React.ReactNode;
  title: string;
  itemCount?: number;
  onRight?: () => void;
  onLeft?: () => void;
}

export const CarouselContainer = ({
	title,
	children,
	onLeft,
	onRight,
	itemCount
}: ICarouselContainerProps) => {
	const maxItems = 4;
	return (
		<Flex direction={'column'}>
			<Flex alignItems={'center'} py={{ base: '2', tablet: '8' }}>
				<Text
					fontSize={{ base: '18px', tablet: 'md', xxl: '2xl' }}
					fontWeight={'600'}
					lineHeight={'32px'}
				>
					{title}
				</Text>
				<Spacer />
				{ itemCount! >= maxItems && <Flex display={['flex', 'flex', 'flex']}> 
					<IconButton
						aria-label="Right button"
						color="#121B26"
						_selected={{ color: '#96999B' }}
						cursor={'pointer'}
						visibility={['hidden', 'visible']}
						icon={<ChevronLeftIcon boxSize={4} w={4} onClick={onRight} />}
					/>
					<IconButton
						color="#261712"
						_selected={{ color: '#96999B' }}
						aria-label="Left button"
						visibility={['hidden', 'visible']}
						icon={
							<ChevronRightIcon boxSize={4} w={4} onClick={onLeft} />
						}
					/>
				</Flex>}
			</Flex>
			<Flex
				direction={['row']}
				justifyContent={'space-between'}
				alignItems={'center'}
				py={{ base: '3', tablet: '3' }}
			>
				<Flex
					justifyContent={'space-between'}
					alignItems={'center'}
					w={['100%', '100%', '100%']}
					overflow={['', '', 'hidden']}
				>
					<MotionBox display={'flex'}>{children}</MotionBox>
				</Flex>
			</Flex>
		</Flex>
	);
};
