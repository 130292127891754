import React, { useEffect, useState } from 'react';
import { HomePageLayout } from 'Layouts/HomePageLayout';
import { DiscoverProducts } from './sections/DiscoverProducts';
import { DiscoverBrands } from './sections/DiscoverBrands';
// import { HomeFeaturedBrands } from './sections/HomeFeaturedBrands';
// import { ExploreSection } from 'Pages/LandingPage/HomeSection/sections/ExploreSection';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { setUserCoords, setQrCodeFromDynamicLink } from 'store/slices';
import { OngoingSurveyContainer } from './sections/OngoingSurveyContainer';
import { Flex, Spinner } from '@chakra-ui/react';
import { CodeType } from 'Types/Enums';
import { Header } from './sections/Header';
import { CustomModal } from 'Components';
import { DynamicProductScanErrorModal } from '../DynamicLinkProduct/sections/DynamicProductScanErrorModal';
import { useCodeScan } from 'Hooks/useCodeScan';
import { EmailVerification } from './Components/EmailVerification';
import { useRefreshUserProfile } from 'Utils/useRefreshUserProfile';

export const Home = () => {
	const dispatch = useAppDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const [errMsg, setErrMsg] = useState('');

	const { refreshUserProfile } = useRefreshUserProfile();

	useEffect(() => {
		refreshUserProfile();
	}, []);

	const { qrCodeFromDynamicLink, webCodeFromEnterCode } = useAppSelector((state) => state.survey);

	const { handleScan, isLoading: isScanning } = useCodeScan({
		onScanComplete: () => {
			dispatch(setQrCodeFromDynamicLink(''));
			onClose();
		},
		onError: (message) => {
			dispatch(setQrCodeFromDynamicLink(''));
			setErrMsg(message);
			setIsOpen(true);
		},
	});

	useEffect(() => {
		if (qrCodeFromDynamicLink) {
			handleScan(qrCodeFromDynamicLink, CodeType.qrCode);
		}
		if(webCodeFromEnterCode){
			handleScan(webCodeFromEnterCode, CodeType.Webcode);
		}
	}, [qrCodeFromDynamicLink, webCodeFromEnterCode]);

	useEffect(() => {
		const location = navigator.geolocation;
		location.getCurrentPosition((position) => {
			const lat = position.coords.latitude;
			const long = position.coords.longitude;
			dispatch(setUserCoords({ lat, long }));
		});
	}, []);

	const onClose = () => {
		setIsOpen(false);
	};

	if (isScanning) {
		return (
			<Flex
				justifyContent={'center'}
				alignItems={'center'}
				padding='6'
				boxShadow='lg'
				bg='white'
				h={'100vh'}
				w={'full'}
			>
				<Spinner size='xl' color='darkCoral' />
			</Flex>
		);
	}

	return (
		<HomePageLayout JumbotronSection={<Header />} StickyNotificationSection={<EmailVerification />}>
			<OngoingSurveyContainer />
			{/* <HomeFeaturedBrands /> */}
			<DiscoverProducts />
			<DiscoverBrands />
			{/* <ExploreSection /> */}
			<CustomModal
				content={<DynamicProductScanErrorModal errorText={errMsg} />}
				isOpen={isOpen}
				onClose={onClose}
				modalContentStyles={{ borderRadius: 10, boxShadow: '0px 0px 30px rgba(88, 96, 104, 0.1)' }}
				size='sm'
				isCentered
			/>
		</HomePageLayout>
	);
};
