import { Flex, Heading, Text, Stack } from '@chakra-ui/react';
import React from 'react';

type MainIntroPropsType = {
    heading: {
        mainHeader: string;
        subHeader: string;
    };
	icon: React.ReactNode;
}

export const MainIntro = ({ heading, icon }:MainIntroPropsType) => {
	
	return (
		<Stack spacing={10}>
			<Flex
				w={10}
				h={10}
				p={2}
				// mb={10}
				borderRadius="50%"
				borderWidth={1}
				borderColor="#264653"
				alignItems="center"
				justifyContent="center"
			>
				{icon}
			</Flex>
			<Stack  justify={'center'} spacing={[5,10]} >
				<Heading >{heading.mainHeader}</Heading>
				<Text pb={[0, 20]} fontSize={['13px','15px']} >{heading.subHeader}</Text>
			</Stack>
			
		</Stack>
	);
};
