import React, { useState, useCallback } from 'react';
import { DataTable, IPaginationConfig } from 'Components/DataTable';
import { Column } from 'react-table';
import { useGetBrandLoopsQuery } from 'services';
import { useMediaQuery, theme, Flex, Image, Button, Heading, Text, VStack, HStack } from '@chakra-ui/react';
import { BrandLoopStatusComponent, formatDateShort } from 'Utils/dataTableUtils';
import { IBrandLoop } from 'Types/Interface/IBrandLoop';
import WithdrawalIcon from 'Assets/svg/WithdrawalIcon.svg';
import { DashboardSettingLayout } from 'Layouts';
import { RoundedImage } from 'Components/RoundedImage';
import useNavigation from 'Utils/navigationUtils';

export const BrandLoops = () => {
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const [pageIndex, setPageIndex] = useState(1);
	const params = { pageIndex, pageSize: 10 };
	const { data, isLoading, isFetching } = useGetBrandLoopsQuery(params);
	const newData = data?.data?.map((item) => ({
		...item,
		id: item.brandId,
	}));

	const { navigateToBrandLoopDetails } = useNavigation();

	const columns: Column<IBrandLoop>[] = React.useMemo(
		() => [
			{
				Header: '',
				accessor: 'brandImageUrl',
				Cell: (item) => (
					<Flex justifyContent={'center'} w={'80px'}>
						<Image src={item.row.original.brandImageUrl} objectFit='contain' maxH={['16px', '30px']} />
					</Flex>
				),
			},
			{
				Header: 'Brand',
				accessor: 'brandName',
			},
			{
				Header: 'Status',
				accessor: 'isLooped',
				Cell: (item) => <BrandLoopStatusComponent isLooped={item.row.original.isLooped} showCircle={true} />,
			},
			{
				Header: 'Joined',
				accessor: 'joinedDate',
				Cell: (item) => <>{formatDateShort(item.row.original.joinedDate)}</>,
			},
			{
				Header: 'Events',
				accessor: 'userActivitiesCount',
			},
			{
				Header: 'Last Activity',
				accessor: 'lastActivityDate',
				Cell: (item) => <>{formatDateShort(item.row.original.lastActivityDate)}</>,
			},
			{
				Header: 'Action',
				accessor: 'brandId',
				Cell: (item) => (
					<Button
						variant={'greyBorder'}
						onClick={() => {
							navigateToBrandLoopDetails(item.row.original.brandId);
						}}
					>
						Manage
					</Button>
				),
			},
		],
		[],
	);

	const mobileColumns: Column<IBrandLoop>[] = React.useMemo(
		() => [
			{
				accessor: 'brandName',
				Cell: (item) => {
					const rowData = item.row.original;

					return (
						<HStack spacing={3} lineHeight={'18px'}>
							<RoundedImage src={item.row.original.brandImageUrl} />
							<VStack alignItems={'flex-start'} fontSize={'8px'} spacing={'0'}>
								<Flex fontSize={'10px'} alignItems={'center'}>
									{rowData.brandName} <Image w={'12px'} h={'12px'} ml={1} src={WithdrawalIcon} />
								</Flex>
								<Text>Joined {formatDateShort(rowData.joinedDate)}</Text>
								<Text>Events: {rowData.userActivitiesCount}</Text>
							</VStack>
						</HStack>
					);
				},
			},
			{
				accessor: 'isLooped',
				Cell: (item) => (
					<VStack alignItems='flex-end' fontSize={'8px'} spacing={'0'} lineHeight={'18px'}>
						<BrandLoopStatusComponent isLooped={item.row.original.isLooped} showCircle={true} fontSize={'10px'} />
						<Text>Last activity: {formatDateShort(item.row.original.lastActivityDate)}</Text>
					</VStack>
				),
			},
		],
		[],
	);

	const paginate = useCallback(({ pageIndex }: IPaginationConfig) => {
		setPageIndex(pageIndex + 1);
	}, []);

	return (
		<DashboardSettingLayout>
			<Heading fontSize={['lg', null, '3xl']} color={'loopGrey.500'} mb={6}>
				My{' '}
				<Text as='span' color='darkCoral'>
					Loops
				</Text>
			</Heading>
			<Text fontSize={['xs', null, null, 'sm']} lineHeight={['6']} mb={8}>
				Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo
				con sequat.{' '}
			</Text>
			<DataTable
				data={newData || []}
				columns={isMobile ? mobileColumns : columns}
				pageCount={data?.totalNoOfPages}
				paginate={paginate}
				initialPageIndex={0}
				isLoading={isLoading || isFetching}
				pageSize={params.pageSize}
				isMobile={isMobile}
				showCheckboxes={false}
				showMenu={false}
				onclickRow={(data: IBrandLoop) => {
					navigateToBrandLoopDetails(data.brandId);
				}}
			/>
		</DashboardSettingLayout>
	);
};
