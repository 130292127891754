import React from 'react';
import showdown from 'showdown';

import {
	Heading,
	VStack,
	Text,
	Center,
} from '@chakra-ui/react';
// import { IJumbotronContents } from 'Types/Interface/ILandingPage';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IBrand } from 'Types/Interface/IBrand';

interface IJumbotronProps{
	// contents: IJumbotronContents[];
	data: IJsonResponse<IBrand> | undefined;
}

export const BrandPageJumbotronSection = ({ data }: IJumbotronProps) => {
	const converter = new showdown.Converter();
	const text = data?.data.description || '';
	const parsedText = converter.makeMarkdown(text);
	
	return (
		<Center
			height={['500px','800px']}
		>
			<VStack 
				alignItems={'center'} 
				textAlign={'center'}
				spacing={4} 
			>
				<Heading fontSize={{ base: '2xl', tablet: '2xl', xxl: '3xl' }}
					color="white" 
					w={['auto', '615.45px', '100%']}
					fontWeight={'700'}
					lineHeight={['8', '40px']}
					letterSpacing={'-0.03em'}
				>
					{data?.data.name}
				</Heading>
				<Text fontSize={{ sm: 'xs', tablet: 'sm', xxl:'sm' }} 
					lineHeight={7} w={['auto','auto','auto','60%', '60%']} 
					color="white"
				>
					{parsedText}
				</Text>
			</VStack>
		</Center>
	);
};
