import React from 'react';
import { Box, Button, Flex, Heading, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
import { Icon } from '@chakra-ui/icons';
import { convertLNTToUsd, currencyFormatter } from 'Utils';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { useAppSelector } from 'Hooks';

interface IBalanceProps {
	isLoading: boolean;
}

export const Balance = ({ isLoading }: IBalanceProps) => {
	const navigate = useNavigate();
	const { userSummary } = useAppSelector((state) => state.authUser);

	const handleNavigation = () => {
		navigate(AppRoutes.reward, { state: { title: 'Withdraw LNT' } });
	};

	return (
		<Flex 
			direction={['column', null, 'row']}
			borderWidth={1} 
			borderRadius={'10px'} 
			h={['auto', null, '300px']}
			overflow={'hidden'}
		>
			<VStack p={8} w={['100%', null,'50%']} h={['290px', 'auto']}  align={'flex-start'} >
				<Text fontSize={'12px'} color="#586068" pb={'15px'}>Total balance</Text>
				<VStack
					pb={'20px'}
					align={'flex-start'}
				>
					<Heading 
						fontSize={['30px', null, '36px']}
						fontWeight='bold'
						pb={'8px'}
					>
						{(userSummary?.totalBalance ?? 0).toLocaleString()} LNT
						{isLoading && <Spinner color="loop.500" />}
					</Heading>
					<Text color="#586068" fontSize={'15px'}>
						{convertLNTToUsd(userSummary?.totalBalance ?? 0).toFixed(2)} USD
					</Text>
				</VStack>
				<Button
					variant="primary"
					width="160px"
					h={'55px'}
					onClick={handleNavigation}
				>
          Redeem
				</Button>
			</VStack>
			<Stack
				spacing={[12,10]}
				bg="#ECEFF2"
				w={['100%', null,'50%']}
				justify="center"
				p={[10, null,'72px']}
				
			>
				<Flex>
					<Icon mr={4}  alignSelf="flex-end" h={'30px'} w={'30px'} color="#009753" as={BsArrowDownCircle} />
					<Box w={'full'}>
						<Text fontSize={'12px'} color={'#586068'} mb={1}>Total received</Text>
						<Heading fontSize="30px">
							{currencyFormatter(userSummary?.totalRecieved ?? 0, 'USD')}
						</Heading>
					</Box>
				</Flex>
				<Flex>
					<Icon mr={4} alignSelf="flex-end" h={'30px'} w={'30px'} color="#D92121" as={BsArrowUpCircle} />
					<Box w={'full'}>
						<Text fontSize={'12px'} color={'#586068'} mb={1}>Total withdrawn</Text>
						<Heading fontSize="30px">
							{currencyFormatter(userSummary?.totalWithdraw ?? 0, 'USD')}
						</Heading>
					</Box>
				</Flex>
			</Stack>
		</Flex>
	);
};
