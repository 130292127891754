interface ISTAT {
    text: string;
    value: number;
    unit: string;
}

export const STAT: ISTAT[] = [
	{
		text: 'shoppers in the US (and growing).',
		value: 20,
		unit: 'M'
	},
	{
		text: 'are Gen Z and Millennial.',
		value: 70,
		unit: '%'
	},
	{
		text: 'higher purchase frequency than the average shopper.',
		value: 25,
		unit: '%'
	},
];