import React, { useState, useEffect } from 'react';
import {
	Button,
	CSSObject,
	Flex,
	HStack,
	Icon,
	IconButton,
	Stack,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';
import { LoopV2Icon } from 'Components/Icons';
import { links } from 'Constants';
import { NavbarLink } from './elements/NavbarLink';
import { MenuDropdown, NotificationWidget } from './elements';
import { CustomDrawer } from 'Components/CustomDrawer';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { useDebounce } from 'Hooks';
import { useSearchBrandAndProductsQuery } from 'services';
import { Autocomplete, IAutocompleteItem } from 'Components/Autocomplete';
import { IBrand } from 'Types/Interface/IBrand';
import { clearBrowserStorage } from 'Utils';

interface INavbarProps {
  linkColorMode?: 'light' | 'black';
  inputlinkColorMode?: 'light' | 'black';
  sx?: CSSObject;
}

export const Navbar = ({
	linkColorMode,
	inputlinkColorMode,
	sx,
}: INavbarProps) => {
	const navigate = useNavigate();
	const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
	const [searchString, setSearchString] = useState('');
	const debounceQuery = useDebounce<string>(searchString, 1000);
	const { data, isSuccess, isLoading, isFetching } = useSearchBrandAndProductsQuery(debounceQuery);
	const [searchResult, setSearchResult] = useState<IAutocompleteItem[]>([]);
	const [isIos, setIsIos] = useState(false);

	const handleSelect = (item: IAutocompleteItem) => {
		const selectedItem = item as unknown as IBrand;
		const isBrand = selectedItem?.products;
		if(isBrand){
			navigate(AppRoutes.brand.replace(':brandId', item.value));
		}else {
			navigate(AppRoutes.productPage.replace(':productId', item.value));
		}
	};

	useEffect(() => {
		if(isSuccess){
			const brands = data?.data?.brands?.map((item) => ({
				...item,
				value: item.id,
				label: item.name,
			})) as IAutocompleteItem[];
			const products = data?.data?.products?.map((item) => ({
				...item,
				value: item.id,
				label: item.name,
				imageUrl: item.images[0]?.imageUrl
			})) as IAutocompleteItem[];

			setSearchResult([...brands, ...products]);
		}
	}, [data, isSuccess]);

	useEffect(() => {
		const userAgent = window.navigator.userAgent.toLowerCase();
		setIsIos(userAgent.includes('iphone') || userAgent.includes('ipad'));
	}, []);  

	const renderLeftBarSection = () => {
		return (
			<Stack direction="row" alignItems="center" spacing={'25px'}>
				{links.slice(0,2).map(({ label, path }) => (
					<NavbarLink
						linkColorMode={linkColorMode}
						key={label}
						label={label}
						path={path}
					/>
				))}
			</Stack>
		);
	};

	const renderRightSection = () => {
		return (
			<HStack spacing={4} alignItems="center" justifyContent="space-around">
				<Autocomplete
					onChange={setSearchString}
					inputlinkColorMode={inputlinkColorMode}
					hideToggleButton
					data={searchResult}
					isLoading={isLoading || isFetching}
					onSelect={handleSelect}
				/>
				<NotificationWidget linkColorMode={linkColorMode} />
				<MenuDropdown linkColorMode={linkColorMode} />
			</HStack>
		);
	};

	const handleClickAppLogo = () => {
		navigate(AppRoutes.home);
	};

	const handleLogout = () => {
		clearBrowserStorage();
		window.location.reload();
	};

	return (
		<Flex justifyContent="space-between" sx={sx}>
			<Flex flex={1} alignItems='center'>
				<LoopV2Icon mr={20} height={'30px'} cursor="pointer" onClick={handleClickAppLogo} />
				{isLargerThan1000 && renderLeftBarSection()}
			</Flex>
			{isLargerThan1000 && renderRightSection()}

			{!isLargerThan1000 && (
				<CustomDrawer
					title={<LoopV2Icon height="25px" cursor="pointer" />}
					buttonContent={
						<IconButton
							icon={<Icon as={HiOutlineMenuAlt3} w={6} h={6} />}
							aria-label="menu"
							color={linkColorMode === 'light' ? 'white' : 'darkCoral'}
						/>
					}
					isFull
				>
					<Flex
						flexDirection="column"
						justifyContent="space-between"
						height="100%"
						pb='20'
					>
						<Stack spacing={8} mt={10}>
							{links.map(({ label, path }) => (
								<NavbarLink
									linkColorMode={linkColorMode}
									key={label}
									label={label}
									path={path}
									sx={{
										fontSize: '18px',
										fontWeight: 500,
									}}
								/>
							))}
							<Button variant="primary" borderRadius="full" width="max-content">
                Download app
							</Button>
						</Stack>
						<HStack paddingBottom={isIos ? '30px' : '0'} justify={'space-between'}>
							<NavbarLink
								linkColorMode={linkColorMode}
								label={'My account'}
								path={AppRoutes.dashboard}
								sx={{
									fontSize: '18px',
									fontWeight: 500,
								}}
							/>
							<Text 
								fontSize={'lg'} 
								fontWeight={500}
								onClick={handleLogout}
							>
								Sign out
							</Text>
						</HStack>
					</Flex>
				</CustomDrawer>
			)}
		</Flex>
	);
};
