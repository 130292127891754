/* eslint-disable max-lines */
import { IAuthenticateUserResponse } from 'context/AuthContext';
import { IImageUploadResponse } from 'Types/Interface/IApiRequestResponseBody';
import {
	INewUserRequestbody,
	IOtpVerificationRequestBody, IUserProfileQuestion,
} from 'Types/Interface/IUser';

import { routes } from './apiRoutes';
import { reqClient } from './reqClient';

export const DEFAULT_ERROR_MESSAGE = 'An error occurred, please try again';

export type ErrorType = {
  status: number;
  response: {
    data: {
      status: boolean;
      errors: Record<'message', string>[];
      message: string;
      title: string;
    };
  };
};

export const getErrorMsg = (error: ErrorType): string => {
	if (error) {
		if (error.response?.data) {
			if (error.response.data.message) {
				return error.response.data.message;
			}
			const data = error.response.data;
			const errors = data.errors?.map((error) => error?.message);
			return errors.length > 0
				? errors[0]
				: error.response.data?.title
					? error.response.data?.title
					: DEFAULT_ERROR_MESSAGE;
		}
		return DEFAULT_ERROR_MESSAGE;
	}

	return DEFAULT_ERROR_MESSAGE;
};

export const uploadImage = async (
	image: FormData
): Promise<IImageUploadResponse> => {
	try {
		const response = await reqClient.put(routes.imageUpload, image);

		if (response.data.status) {
			return response.data;
		} else {
			throw new Error(DEFAULT_ERROR_MESSAGE);
		}
	} catch (error) {
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
};

export async function uploadUserPhoto(image: FormData) {
	try {  
		const response = await reqClient.put(
			routes.userProfilePicture,
			image,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		if (response.data.status) {
			return response.data.data;
		} else {
			throw new Error(DEFAULT_ERROR_MESSAGE);
		}
	} catch (e) {
		const message = getErrorMsg(e as ErrorType);
		throw new Error(message);
	}
}

export const sendVerificationCode = async (
	userPhoneDetails: IOtpVerificationRequestBody
): Promise<string> => {
	try {
		const response = await reqClient.post(
			routes.requestAccountVerificationCode,
			userPhoneDetails
		);
		return response.data.data;
	} catch (error) {
		console.error('error', error);
		const message = getErrorMsg(error as ErrorType);
		console.error('error message', message);

		throw new Error(message);
	}
};

export const getResetCode = async (
	identifier: string
): Promise<string> => {
	try {
		const response = await reqClient.get(
			`${routes.getResetCode}?identifier=${identifier}`
		);
		return response.data.data;
	} catch (error) {
		console.error('error', error);
		const message = getErrorMsg(error as ErrorType);
		console.error('error message', message);

		throw new Error(message);
	}
};

export const verifyCode = async (
	userPhoneDetails: IOtpVerificationRequestBody
): Promise<string> => {

	try {
		const response = await reqClient.post(routes.verifyCode, userPhoneDetails);

		return response.data.data;
		
	} catch (error) {
		console.error('error', error);
		const message = getErrorMsg(error as ErrorType);
		console.error('error message', message);

		throw new Error(message);
	}
	
};


export const registerWithPhone = async (
	phoneNumber: string
): Promise<IAuthenticateUserResponse> => {

	try {
		const response = await reqClient.post(routes.createAccountWithPhone, {}, {
			params:{
				phoneNumber
			}
		});


		return response.data.data;
		
	} catch (error) {
		console.error('error', error);
		const message = getErrorMsg(error as ErrorType);
		console.error('error message', message);

		throw new Error(message);
	}
	
};


export const updateUserProfileQuestions = async (
	data: IUserProfileQuestion
): Promise<IImageUploadResponse> => {
	try {
		const response = await reqClient.put(routes.userProfileQuestion, data);

		if (response.data.status) {
			return response.data;
		} else {
			throw new Error(DEFAULT_ERROR_MESSAGE);
		}
	} catch (error) {
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
};


export const updateUserProfile = async (
	data: INewUserRequestbody
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => { // TODO : add proper type
	try {
		const response = await reqClient.put(routes.accountPhoneComplete, data);

		if (response.data.status) {
			return response.data;
		} else {
			throw new Error(DEFAULT_ERROR_MESSAGE);
		}
	} catch (error) {
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
};



export const getTiliaRedirectUrl = async (
): Promise<string> => {
	try {
		const response = await reqClient.get('/tilia/getUserRedirectUrl');
		return response.data.data;
	} catch (error) {
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
};