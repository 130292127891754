import { Flex, Text, Box, IconButton, Icon, Divider, HStack, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { GrFacebookOption } from 'react-icons/gr';
import { AiFillInstagram } from 'react-icons/ai';
import { FaTiktok, FaTwitter } from 'react-icons/fa';
import { TermsOfService } from 'Pages/RegisterPage/TermsOfService';

export const Footer = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	return(
		<Box px={{base:'8', lg:'14', '2xl': '80px'}} pt={'80px'}>
			<Divider
				borderColor={'#C3C5C7'}
				variant={'solid'}
				size={'1px'}
			/>
			<Flex direction={['column', null, null, 'row']} py={6} alignItems={'center'} justifyContent={'space-between'}>
				<Text mb={['20px',null]} textAlign={'center'} fontSize={['12px','16px']}>All rights reserved - Feed the Loop &copy; {new Date().getFullYear()}</Text>
				<HStack  mb={['20px',null]} spacing={['20px','50px']} >
					<Text  cursor={'pointer'} onClick={onOpen}  fontSize={['12px','16px']}>Privacy</Text>
					<Text cursor={'pointer'} onClick={onOpen}  fontSize={['12px','16px']}>Terms of use</Text>
				</HStack>
				<HStack spacing={'30px'} >
					<IconButton aria-label='facebook share' icon={<Icon as={GrFacebookOption} h={['14px', '20px']} w={['14px', '20px']} />} />
					<IconButton aria-label='insta share' icon={<Icon as={AiFillInstagram} h={['14px', '20px']} w={['14px', '20px']} />} />
					<IconButton aria-label='tiktok share' icon={<Icon as={FaTiktok } h={['14px', '20px']} w={['14px', '20px']} />} />
					<IconButton aria-label='twitter share' icon={<Icon as={FaTwitter} h={['14px', '20px']} w={['14px', '20px']} />} />
				</HStack>
			</Flex>
			<TermsOfService handleCheckbox={onClose} isOpen={isOpen}  onClose={onClose }/>
		</Box>
	);
};