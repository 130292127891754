import React from 'react';
import { 
	Flex, 
	Heading, 
	Text, 
	Image, 
	ListItem,
	UnorderedList,
	Box,
	VStack,
	Button
} from '@chakra-ui/react';

import GirlWithPhone from 'Assets/Images/GirlWithPhone.png';
import QrCode from 'Assets/svg/QrCode.svg';

export const ReviewCallToActionSection = () => {
	return (
		<Flex 
			direction={[ 'column-reverse', null,'column','row','row' ]}
			justifyContent={'space-between'}
			alignItems={'flex-start'}
			// px={{base:16, tablet:10, xxl:12}}
			py={{base:8, tablet:16, xxl:16}}
			mt={{base:0, tablet:10}} mb={16}
		>
			<Image 
				src={GirlWithPhone} 
				mt={[8, 0]} 
				w={['100%', '55%']}
				// h={['700px']}
				objectFit='cover'
			/>
			<VStack w={'100%'} align={'self-start'} spacing={'4'} ml={[0, 0, 0, 10]}>
				<Text
					fontSize={{ base: 'sm', tablet: 'md' }}
					fontWeight={400} 
					color={'#121B26'}
					mt={[null, 6, null]}
				>
					Loop app
				</Text>
				<Heading
					fontSize={{ base: '2xl', tablet: '2xl', xxl: '5xl' }}
					fontWeight={'600'}
					color='#2B2B2B'
					mb={8} mt={6}
				>
					Earn reviewing your <br/> favorite brands.
				</Heading>
				<UnorderedList
					fontSize={{ base: 'sm', tablet: 'md' }}
					fontWeight={400} 
					color={'#121B26'}
					spacing={4}
					pl={5} py={6}
				>
					<ListItem>Let your review(s) matter</ListItem>
					<ListItem>Access and review unlimited brands</ListItem>
					<ListItem>Earn while reviewing your favourites</ListItem>
				</UnorderedList>
				<Button 
					variant={'primary'} 
					w={'12rem'}
					fontSize={'16px'}
					display={['block', 'none']}
				>
					Get the app
				</Button>
				<Text display={['none', 'block']}>Get the free Loop app on your phone</Text>
				<Box 
					border={'1px solid #586068'}
					borderRadius={'5px'} 
					display={['none', 'block']}
				>
					<Image p={'2'}  src={QrCode}/>
				</Box>
			</VStack>
		</Flex>
	);
};