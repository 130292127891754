import React from 'react';
import { Box } from '@chakra-ui/react';
import { Navbar } from 'Pages/Dashboard/Components/Navbar/Navbar';
// import BoseSpeakers from 'Assets/Images/BoseSpeakers.png';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IBrand } from 'Types/Interface/IBrand';
import { ScrollUpBtn } from 'Components/ScrollUpBtn';
import { Footer } from 'Pages/LandingPage/HomeSection/sections/Footer';

interface IBrandPageLayoutProps {
	JumbotronSection: React.ReactNode,
	children: React.ReactNode;
    disableGradientBackground?: boolean;
	data: IJsonResponse<IBrand> | undefined;
}

export const BrandPageLayout = ({
	JumbotronSection, 
	children, 
	disableGradientBackground,
	data
}: IBrandPageLayoutProps) => {
	
	return (
		<Box overflowX="hidden">
			<Box 
				py='8'
				px={['5', '6', null, '20']}
				{...!disableGradientBackground && 
					({bgImage: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , 
					url('${data?.data.imageUrl}')`})
				}
				bgPosition='center'
				bgRepeat='no-repeat'
				bgSize='cover'
				minHeight={['80vh',null,null,'85vh']}
			>
				<Navbar linkColorMode={'light'} inputlinkColorMode={'black'}/>
				{JumbotronSection}
			</Box>
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					if (child.props.shouldIgnoreParentPadding) {
						return (
							<Box  maxW={'1440px'} m={'0 auto'}>
								{child}
							</Box>
						);
					}

					return (
						<Box maxW={'1440px'} m={'0 auto'} px={['8', '6', null, '20', ]} my={['8', null]}>
							{child}
						</Box>
					);
				}
				return child;
			})}
			<Footer />
			<ScrollUpBtn />
		</Box>
	);
};