import React from 'react';
import { Input, Flex} from '@chakra-ui/react';
import { useAppSelector } from 'Hooks';
import { InputName } from '../../AccountDetailList';

export interface IEditProfileDetail {
  onChange: (inputName: InputName, value: string | number) => void;
  inputName: InputName;
}

export const FullNameEdit = ({ onChange }: IEditProfileDetail) => {
	const { userInfo } = useAppSelector((state) => state.authUser);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		onChange(name as InputName, value);
	};
	return (
		<Flex justifyContent="space-between">
			<Input
				placeholder="first name"
				defaultValue={userInfo?.firstName}
				flex={0.48}
				name="firstName"
				onChange={handleChange} />
			<Input
				placeholder="last name"
				defaultValue={userInfo?.lastName}
				name="lastName"
				onChange={handleChange}
				flex={0.48} />
		</Flex>
	);
};
