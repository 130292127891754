import React, { useEffect } from 'react';
import { SplitLayout } from 'Pages/Dashboard/Components/SplitLayout';
import { Balance, CTA } from './Components';
import { useGetUserSummaryMutation } from 'services';
import { useAppDispatch } from 'Hooks';
import { convertLNTToUsd } from 'Utils';
import { setUserSummary } from 'store/slices';

export const Rewards = () => {
	const dispatch = useAppDispatch();
	const [getUserSummary,{ data: userSummary, isSuccess: isSummarySuccess, isLoading }] =
    useGetUserSummaryMutation();

	useEffect(() => {
		getUserSummary();
	}, []);

	useEffect(() => {
		if (isSummarySuccess && userSummary?.data) {
			const totalBalance = userSummary.data.availableRewardPoints;
			const totalWithdraw = convertLNTToUsd(
				userSummary.data.redeemedRewardPoints,
			);
			const totalRecieved = convertLNTToUsd(
				userSummary.data.redeemedRewardPoints +
          userSummary.data.availableRewardPoints,
			);
			dispatch(setUserSummary({ totalBalance, totalRecieved, totalWithdraw }));
		}
	}, [isSummarySuccess]);

	return (
		<SplitLayout display='none' width='72%'>
			<Balance isLoading={isLoading} />
			<CTA />
		</SplitLayout>
	);
};
