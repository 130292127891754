import React from 'react';
import { ProductsJumbotronSectionContents } from 'Constants';
import { BrandsLayout } from 'Layouts/BrandsLayout';
import { BrandsJumbotronSection } from 'Pages/LandingPage/Components/BrandsJumbotronSection';
import { AllProductsCategories } from './sections/AllProductsCategories';

export const AllProducts = () => {
	return (
		<BrandsLayout
			JumbotronSection={
				<BrandsJumbotronSection 
					contents={ProductsJumbotronSectionContents} 
					justify={'center'} textAlign={'center'}
				/> 
			}
			linkColorMode='black'
		>
			<AllProductsCategories />
		</BrandsLayout>
	);
};