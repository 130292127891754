import { Flex, Heading, Stack, Switch, Text } from '@chakra-ui/react';
import { NotificationSections } from 'Constants';
import React, { useState } from 'react';
import { NotificationFeature, NotificationOption } from './components';

interface IState {
  message: NotificationOption;
  reminder: NotificationOption;
  account_support: NotificationOption;
  promo: NotificationOption;
}

export const Content = () => {
	const [, setState] = useState<IState | Record<string, unknown>>();

	const handleChange = (name: string, value: NotificationOption) => {
		setState((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	return (
		<Stack spacing={10} flex={1}>
			{NotificationSections.map(({ header, subHeader, feature }) => (
				<NotificationFeature
					key={header}
					header={header}
					subHeader={subHeader}
					sectionName={feature?.toString()}
					onChange={handleChange}
				/>
			))}
			<Stack spacing={2}>
				<Flex flex={1} justifyContent="space-between">
					<Heading fontSize='sm' color="#264653">Unsubscribe from all marketing emails</Heading>
					<Switch size='lg' variant='primary'/>
				</Flex>
				<Text w="60%">Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut </Text>
			</Stack>
		</Stack>
	);
};
