import React from 'react';
import { Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetActiveSurvey } from 'store/slices';
import { AppRoutes } from 'Routes/AppRoutes';

export const HeaderSection = () => {
	const location = useLocation();
	const canClose = location.pathname !==  AppRoutes.dynamicLinkProduct;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		activeSurvey: { survey, responses },
		isStartSurvey
	} = useAppSelector((state) => state.survey);

	const headings = {
		surveyTitle: survey?.title,
		brandName: survey?.product.brand.name,
	};

	const isNewSurvey = (!responses || responses.length === 0) && !isStartSurvey;

	const handleCloseSurvey = () => {
		navigate(-1);
		setTimeout(() => {
			dispatch(resetActiveSurvey());
		}, 1000);
	};

	return (
		<Flex
			direction={['row-reverse', 'row']}
			px="10"
			color="white"
			bg={isNewSurvey ? '#ECEFF2' : '#264653'}
			h="100px"
			alignItems="center"
			justifyContent="space-between"
		>
			<Flex 
				direction={['column', 'column']}
				alignItems="flex-start"
			>
				{!isNewSurvey &&(
					<>
						<Heading mr={[0,2]} 
							fontSize={['xs','25px']}
							fontWeight={['400', '600']}
						>
							{headings.surveyTitle}
						</Heading>
						<Text fontSize={['xs','14px']}>By {headings.brandName}</Text>
					</> 
				)}
			</Flex>
			{canClose && <IconButton
				color={isNewSurvey ? '#899AA2' : 'white'}
				aria-label="close survey"
				icon={<CloseIcon />}
				onClick={handleCloseSurvey}
			/>}
		</Flex>
	);
};
