declare global {
interface String{
    toInternationalPhoneNumber() : string;
}
}

String.prototype.toInternationalPhoneNumber = function() : string{
	const number = String(this);

	if(number[0] != '+')
	{
		return '+'+String(this);
	}
	return number;
};

export{};