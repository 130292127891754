import { ChevronDownIcon} from '@chakra-ui/icons';
import { Button, chakra, Heading, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';

export const HomeJumbotronSection = () => {
	const navigate = useNavigate();
	return(
		<Stack py={['70px','100px']} spacing={'20px'} textAlign={['left','center']} justifyContent={'center'} alignItems={['start','center']}>
			<Heading fontSize={['50px', null, null, '60px', '70px']} w={'100%'}>
				Get paid for <chakra.span color={'darkCoral'}>feedback</chakra.span> on <br /> things you buy
			</Heading>
			<Text pb={['20px','34px']} fontSize={['16px','20px']}>
				Loop lets you earn cash for giving your favorite brands feedback on products you are <br /> already buying from them. 
				Everything is private and between just you and them. 
			</Text>
			<Button boxShadow={'0px 20px 40px 0px rgba(18, 27, 38, 0.14)'} variant={'primary'} 
				w={['160px','200px']} h={['50px','60px']} borderRadius={['10px','20px']} onClick={() => navigate(AppRoutes.register)}
			>Get Started</Button>
			<ChevronDownIcon display={['none', 'block']} boxSize={'30px'}/>
		</Stack>
	);
};