import React from 'react';

import { Button, chakra, Flex, Image, Text } from '@chakra-ui/react';
import completeIcon from 'Assets/svg/completeIcon.svg';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { resetActiveSurvey } from 'store/slices';

export const SurveyCompleted = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { codeScanData } = useAppSelector((state) => state.survey);

	const handleBtnClick = () => {
		dispatch(resetActiveSurvey());
		navigate(AppRoutes.home);
	};

	return (
		<Flex direction={'column'} alignItems={'center'} mt={12}>
			<Image src={completeIcon} mb={10} />
			<Text fontSize={'2xl'} fontWeight={600} color={'#264653'} mb={3}>
				Thanks for your feedback
			</Text>
			<Text fontSize={['12px', '20px']} fontWeight={400} color={'#586068'} mb={16} textAlign={'center'} w={'90%'}>
				Thank you for taking time to complete the survey, you have earned{' '}
				<chakra.span fontWeight='bold' color='loop.500'>
					{codeScanData?.latestEarnedPoints} LNT
				</chakra.span>
			</Text>
			<Button
				variant='primary'
				fontSize={'lg'}
				onClick={handleBtnClick}
				w={['315px', '250px']}
				h={['50px', '60px']}
				borderRadius={'10px'}
			>
				Return to website
			</Button>
		</Flex>
	);
};
