import React, { useState } from 'react';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';

import { Box, Flex, Image } from '@chakra-ui/react';
import { wrap } from 'popmotion';

import { IProductImage } from 'Types/Interface/IProduct';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';

interface ICarouselProps {
  images: IProductImage[];
}

const variants = {
	enter: (direction: number) => {
		return {
			x: direction > 0 ? 1000 : -1000,
			opacity: 0,
		};
	},
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction: number) => {
		return {
			zIndex: 0,
			x: direction < 0 ? 1000 : -1000,
			opacity: 0,
		};
	},
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
	return Math.abs(offset) * velocity;
};

interface IIndicatorProps {
	isSelected: boolean;
	onClick?: () => void;
  }

const Indicator = ({ isSelected, onClick }: IIndicatorProps) => {
	return (
		<Box flex={1} onClick={onClick} cursor="pointer">
			<Box
				className="Indicator"
				w="100%"
				h="2.5px"
				background="#fcfcfc"
				borderRadius="80px"
				position="relative"
			>
				{isSelected && (
				// By setting layoutId, when this component
				// is removed and a new one is added elsewhere,
				// the new component will animate out from the old one.
					<MotionBox
						layoutId="highlight"
						background="#ff6f61"
						borderRadius="80px"
						w="100%"
						h="2.5px"
						position="absolute"
						zIndex='overlay'
					/>
				)}
			</Box>
		</Box>
	);
};

interface IPaginationProps {
	currentPage: number; 
	paginate?: (page: number) => void; 
	pageCount: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Pagination = ({ currentPage, paginate, pageCount }: IPaginationProps) => {
	// Wrap all the pagination Indicators
	// with AnimateSharedPresence
	// so we can detect when Indicators
	// with a layoutId are removed/added
	return (
		<AnimateSharedLayout>
			<Flex 
				className="Indicators"
				justifyContent='center'
				marginTop='30px'
				position='absolute'
				left={0}
				right={0}
				bottom={4}
				zIndex='modal'
				overflow='hidden'
				gap={2}
				px={2}
			>
				{new Array(pageCount).fill('page').map((page, index) => (
					<Indicator
						key={`${page}-${index}`}
						// onClick={() => paginate(index)}
						isSelected={index === currentPage}
					/>
				))}
			</Flex>
		</AnimateSharedLayout>
	);
};

export const Carousel = ({ images }: ICarouselProps) => {
	const [[page, direction], setPage] = useState([0, 0]);

	const imageIndex = wrap(0, images?.length, page);

	const paginate = (newDirection: number) => {
		setPage([page + newDirection, newDirection]);
	};

	return (
		<Box position="relative" width="100%" h="450px">
			<AnimatePresence custom={direction}>
				<motion.div
					key={page}
					variants={variants}
					initial="enter"
					animate="center"
					exit="exit"
					drag="x"
					transition={{
						x: { type: 'spring', stiffness: 300, damping: 30 },
						opacity: { duration: 0.2 },
					}}
					custom={direction}
					dragElastic={1}
					dragConstraints={{ left: 0, right: 0 }}
					onDragEnd={(e, { offset, velocity }) => {
						const swipe = swipePower(offset.x, velocity.x);

						if (swipe < -swipeConfidenceThreshold) {
							paginate(1);
						} else if (swipe > swipeConfidenceThreshold) {
							paginate(-1);
						}
					}}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
					}}
				>
					<Image
						h="100%"
						w="100%"
						objectFit="cover"
						flex={1}
						src={images[imageIndex]?.imageUrl}
					/>
				</motion.div>
			</AnimatePresence>
			<Pagination pageCount={images?.length} currentPage={imageIndex} paginate={paginate} />
		</Box>
	);
};
