import React from 'react';

import { BrandsJumbotronSection } from '../Components/BrandsJumbotronSection';
import { BrandsGalleryLayout } from 'Layouts/BrandsGalleryLayout';
import {FeaturedBrandsJumbotronSectionContents} from 'Constants/JumbotronConstant';
import { BrandsCategorySection } from '../BrandsSection/sections/BrandsCategorySection';

export const FeaturedBrandsPage = () => {
	return (
		<BrandsGalleryLayout 
			JumbotronSection={
				<BrandsJumbotronSection 
					contents={FeaturedBrandsJumbotronSectionContents} 
					justify={'flex-start'} 
					textAlign={'left'}
				/> 
			}
			linkColorMode='black'
		>
			<BrandsCategorySection/>
		</BrandsGalleryLayout>	
	);
};
