import { Avatar, Button, Flex, HStack, Image, Spacer, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import scanIcon from 'Assets/svg/scanIcon.svg';
import { useAppSelector } from 'Hooks';
import { SunshineIcon } from 'Components/Icons/SunshineIcon';
import moonIcon from 'Assets/svg/MoonIcon.svg';
import { CustomModal } from 'Components';
import { SurveyModal } from 'Pages/HomePage/Components/SurveyModal';
import { Summary } from '../Components/Summary';
import { SummaryMobile } from '../Components/SummaryMobile';

interface IGreeting {
	greeting: string;
	icon: React.ReactNode;
}

export const Header = () => {
	const user = useAppSelector(state => state.authUser);
	const [greeting, setGreeting] = useState<IGreeting>();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const handleClick = () => {
		onOpen();
	};

	const timeOfDay = () => {
		const currentDate = new Date();
		const hrs = currentDate.getHours();
		if (hrs < 12) {
			setGreeting({
				greeting: 'Good Morning',
				icon: <SunshineIcon />,
			});
		} else if (hrs >= 12 && hrs <= 17) {
			setGreeting({
				greeting: 'Good Afternoon',
				icon: <SunshineIcon />,
			});
		} else if (hrs >= 17 && hrs <= 24) {
			setGreeting({
				greeting: 'Good Evening',
				icon: <Image src={moonIcon} h={'25px'} />,
			});
		}
	};

	useEffect(() => {
		timeOfDay();
	}, []);

	return (
		<>
			{/* <Stack > */}
			<Flex my={[8,12]}>
				<Flex alignItems={'center'}>
					<Text
						fontSize={['14px','20px']}
						fontWeight={600}
						color={'#2B2B2B'}
					>
						{greeting?.greeting},
					</Text>
					<Avatar
						size={'md'}
						bg={'white'}
						color={'#FF6F61'}
						fontWeight={'bold'}
						name={`${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`} />
					{greeting?.icon}
				</Flex>
				<Spacer />
				<HStack>
					<Button 
						display={['none',null, 'block']} 
						variant={'primary'}
					>
						Download loop app
					</Button>
					<Image 
						cursor={'pointer'} p={1.5} 
						borderRadius={'50%'} 
						bg={'#F6F6F6'} 
						h={['30px','40px']} w={['30px','40px']} 
						src={scanIcon} 
						onClick={handleClick}
					/>
				</HStack>
			</Flex>
			<CustomModal
				content={<SurveyModal onClose={onClose} />}
				isOpen={isOpen}
				onClose={onClose} 
				isCentered
				size={'xs'}
				hideFooter={true}
				modalContentStyles={{borderRadius: 10}}
			/>
			{isLargerThan768 && <Summary/>}
			{!isLargerThan768 && <SummaryMobile />}
			{/* </Stack> */}
		</>
	);
};