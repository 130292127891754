import { CardTypeEnums } from 'Types/Enums/CardTypeEnums';
import amex from 'Assets/svg/amex.svg';
import mastercard from 'Assets/svg/mastercard.svg';
import visa from 'Assets/svg/visa.svg';
import maestro from 'Assets/svg/maestro.svg';

export const cardIcons: Record<string, string> = {
	[CardTypeEnums.MasterCard]: mastercard,
	[CardTypeEnums.Visa]:visa,
	[CardTypeEnums.AmericanExpress]: amex,
	[CardTypeEnums.maestro]: maestro
};