import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/DrinkIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const DrinkIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const DrinkIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M14.3193 1.00018L10.7172 0.0195288C10.5713 -0.0201411 10.4156 0.00115968 10.2858 0.0785698C10.156 0.15598 10.0633 0.282857 10.0289 0.430033L9.41531 3.05675H5.36393C5.18941 3.05675 5.02498 3.13861 4.91977 3.27788C4.81457 3.41715 4.7808 3.59769 4.82852 3.76561C5.2424 5.22163 4.91558 6.53542 4.62724 7.69449C4.52226 8.11642 4.4231 8.51494 4.3635 8.89995C4.22939 9.76582 4.05405 11.9391 5.31918 13.4153C6.01223 14.224 7.0081 14.6821 8.28429 14.7813V17.8867H6.2078C5.90039 17.8867 5.65116 18.136 5.65116 18.4434C5.65116 18.7508 5.90039 19 6.2078 19H11.474C11.7814 19 12.0307 18.7508 12.0307 18.4434C12.0307 18.136 11.7814 17.8867 11.474 17.8867H9.39757V14.7795C10.6399 14.6791 11.6099 14.2405 12.2861 13.4715C12.9023 12.7707 13.5723 11.4342 13.2644 9.04702C13.2161 8.67254 13.122 8.26497 13.0224 7.83343C12.741 6.6142 12.422 5.23221 12.8806 3.78119C12.9341 3.61201 12.9037 3.42754 12.7989 3.28445C12.694 3.14136 12.5273 3.05678 12.3498 3.05678H10.5586L10.9817 1.24544L14.0269 2.07446C14.3235 2.15506 14.6295 1.98017 14.7102 1.68359C14.7909 1.3869 14.616 1.08097 14.3193 1.00018ZM9.15529 4.17003L8.837 5.53268H6.12994C6.15021 5.09735 6.13303 4.64258 6.0568 4.17003H9.15529ZM11.45 12.7364C11.1939 13.0276 10.8671 13.2547 10.4788 13.4112C10.022 13.5954 9.46649 13.6887 8.82776 13.6887C7.62798 13.6887 6.7319 13.353 6.16446 12.6908C5.8432 12.316 5.13178 11.2128 5.46365 9.07032C5.5156 8.73493 5.60885 8.36008 5.70757 7.96324C5.80887 7.55607 5.91824 7.11577 6.00147 6.64593H9.27723C9.27786 6.64593 9.27849 6.64604 9.27912 6.64604C9.27997 6.64604 9.28083 6.64593 9.28168 6.64593H11.6447C11.724 7.15763 11.8351 7.63942 11.9376 8.08373C12.0309 8.48774 12.119 8.86934 12.1602 9.18937C12.2753 10.0816 12.336 11.7288 11.45 12.7364ZM11.6391 4.17003C11.5535 4.63764 11.5272 5.09305 11.5392 5.53268H9.98027L10.2986 4.17003H11.6391Z" fill={color}/>
		</Icon>
	);
};