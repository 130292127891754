import React from 'react';
import { IProduct } from 'Types/Interface/IProduct';
import { ProductItems } from './productItems';
import { GalleryLayout } from 'Layouts/GalleryLayout';
import { Skeleton } from '@chakra-ui/react';

interface IProductsProps {
  products: IProduct[];
  isLoading?: boolean;
}

export const AllProductsGallery = ({ isLoading, products }: IProductsProps ) => {
	return (
		<GalleryLayout display='grid'>
			{isLoading
				? new Array(10)
					.fill('_')
					.map((_, index) => <Skeleton mb={[4, 4, null]} height="150px" key={index} />)
				: products?.map((product, index) => (
					<ProductItems 
						key={index} 
						product={product} 
					/>
				))}
		</GalleryLayout>
	);
};
