import React from 'react';

import { Flex, useMediaQuery } from '@chakra-ui/react';
import { ProductNavigation } from '../Components/ProductNavigation';
import { BrandGallery } from 'Components/Gallery/BrandGallery';
import { useGetAllBrandsQuery } from 'services';
import { AppRoutes } from 'Routes/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { MobileBrandsSlider } from '../Components/MobileBrandsSlider';

export const DiscoverBrands = () => {
	const params = {pageNumber:1, pageSize:4, categoryId: ''};
	const {data, isLoading}  = useGetAllBrandsQuery(params);
	const navigate = useNavigate();
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	return (
		<Flex
			direction={'column'}
		>
			<ProductNavigation
				onClickLink={() => {
					navigate(AppRoutes.allBrands);
				}}
				title='Discover Brands'
				linkText='see all brands'
			/>
			{isLargerThan768 && <BrandGallery
				visibility={true}
				brands={data?.data}	
				isLoading={isLoading}
			/>}
			{!isLargerThan768 && 
				<MobileBrandsSlider 
					brands={data?.data}
					isLoading={isLoading} 
				/>
			}
		</Flex>
	);
};