import { Heading, SimpleGrid, Stack, Text, Box, Button,Image } from '@chakra-ui/react';
import { InformationIcon, VaultIcon, DataManagementIcon } from 'Components/Icons';
import backdrop from 'Assets/Images/Backdrop.png';
import backdropMobile from 'Assets/Images/BackdropMobile.png';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyContent = [
	{
		title: 'Your personal information is yours',
		subText: 'Using the same encryption as your bank, your transactional and personal data is securely encrypted with both hardware and software encryption. ',
		icon:<InformationIcon/> 
	},
	{
		title : 'Control at every step',
		subText: 'Anonymized transaction spending data is shared with retail partners looking to understand general trends. No personal identifiable information shared. ',
		icon: <DataManagementIcon/>
	},
	{
		title : 'Bank-level security on payments',
		subText: 'We’ve partnered with Tilia to ensure the smoothest',
		icon: <VaultIcon/>
	}
];


export const PrivacySection = () => {
	const navigate = useNavigate();
	return(
		<Stack spacing={'30px'} >
			<Heading fontSize={['24px','50px']} fontWeight={600}>We take your privacy very seriously</Heading>
			<SimpleGrid columns={[1,3]} spacing={'30px'} pb={['50px','100px']} >
				{
					PrivacyContent.map((item) => {
						return(
							<Stack key={item.title}>
								<Box boxSize={['30px','50px']} mb={'20px'}>{item.icon}</Box>
								<Text fontWeight={600} fontSize={['18px','20px']}>{item.title}</Text>
								<Text fontSize={['16px','18px']} w={'100%'}>
									{item.subText}
								</Text>
							</Stack>
						);
					})
				}
			</SimpleGrid>
			<Stack 
				justify={'center'} 
				align={'center'} 
				pos={'relative'}

			>
				<Image display={['none', 'block']} src={backdrop} w={'100%'} h={'450px'} />
				<Image display={['block', 'none']} src={backdropMobile} w={'100%'} h={'427px'} />
				<Stack pos={'absolute'} align={'center'} textAlign={'center'} spacing={'20px'} px={4} >
					<Heading fontSize={['24px','40px']} color={'white'}>Have a business and want to <br /> sell with Loop?</Heading>
					<Text fontSize={['16px','18px']} color={'white'}>Build strong, safe and meaningful connections with your customers!</Text>
					<Stack direction={['column','row']} spacing={'20px'}>
						<Button border={'1px solid #fff'} w={'150px'} h={'60px'} borderRadius={'5px'} bg={'white'} color={'darkCoral'}>Contact us</Button>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};