import { IAccountSettingCard } from 'Constants';
import React from 'react';
import { RightSideDetails } from './RightSideDetails';
import { SplitLayout } from './SplitLayout';


interface IDashboardLayoutWithDescription {
	description: IAccountSettingCard[];
    content: React.ReactNode
}

export const DashboardLayoutWithDescription = ({description, content}:IDashboardLayoutWithDescription) => {
	return (
		<SplitLayout display='none'  width={'50%'}>
			{content}
			<RightSideDetails data={description}/>
		</SplitLayout>
	);
};
