import React, { useState } from 'react';
import { DataTable } from 'Components/DataTable';
import { Column } from 'react-table';
import { useGetPayoutRequestsQuery } from 'services';
import { Box, useMediaQuery, VStack, theme, Flex, Text, Image, Button, useDisclosure, Heading, Stack, Divider } from '@chakra-ui/react';
import WithdrawalIcon from 'Assets/svg/WithdrawalIcon.svg'; 
import { DashboardSettingLayout } from 'Layouts';
import { CustomModal } from 'Components';
import { IHistory } from 'Types/Interface/IHistory';
import { formatDateShort, TransactionStatusComponent } from 'Utils/dataTableUtils';

export const AllWithdrawalHistory = () => {
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const params = {pageNumber: 1, pageSize: 1000};
	const { data, isLoading, isFetching } = useGetPayoutRequestsQuery(params);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const newData = data?.data?.map((item) => ({
		...item,
		id: item.id,
	}));
	const [selectedRow, setSelectedRow] = useState<IHistory>();

	const handleClick = (data:IHistory) => {
		setSelectedRow(data);
		onOpen();
	};
	
	const columns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				Header: 'Amount',
				accessor: 'points',
				Cell: (item) => (
					<Box color={'brand.black'} fontWeight={400} fontSize={'14px'} textAlign={'left'}>
						{item.row.original.points} LNT
					</Box>
				),
			},
			{
				Header: 'Date',
				accessor: 'dateCreated',
				Cell: (item) => (
					<Box color={'brand.black'} fontWeight={400} fontSize={'14px'}>
						{formatDateShort(item.row.original.dateCreated)}
					</Box>
				),
			},
			{
				id: 'status',
				Header: 'Status',
				accessor: 'status',
				Cell: (item) => <TransactionStatusComponent status={item.row.original.status} />,
			},
			{
				Header: 'Action',
				Cell: (
					<Button
						fontSize={'14px'}
						fontWeight={400}
						color={'brand.black'}
						w={'70px'}
						h={'31px'}
						borderRadius={'56px'}
						border={'1px solid #586068'}
					>
						{'View'}
					</Button>
				),
			},
		],
		[],
	);

	const TransactionReceipt = () => {
		return(
			<Stack spacing={'30px'}  p={[6,10]}>
				<Heading textAlign={'center'} fontSize={['18px','22px']}>Transaction Receipt</Heading>
				<Divider border={'1px solid #121B26'} />
				<Stack spacing={'30px'}>
					<Flex justifyContent={'space-between'}>
						<Text fontSize={['14px','16px']} color={'brand.black'}>Date:</Text>
						<Text fontSize={['14px','16px']} color={'brand.black'}>{formatDateShort(selectedRow?.dateCreated ?? '')}</Text>
					</Flex>
					<Flex justifyContent={'space-between'}>
						<Text fontSize={['14px','16px']} color={'brand.black'}>Amount:</Text>
						<Text fontSize={['14px','16px']} color={'brand.black'}>{selectedRow?.points} LNT</Text>
					</Flex>
					<Flex justifyContent={'space-between'}>
						<Text fontSize={['14px','16px']} color={'brand.black'}>Transaction ID:</Text>
						<Text w={'50%'} textAlign={'right'} fontSize={['14px','16px']} color={'brand.black'}>{selectedRow?.referenceId}</Text>
					</Flex>
					<Flex justifyContent={'space-between'}>
						<Text fontSize={['14px','16px']} color={'brand.black'}>Status:</Text>
						<TransactionStatusComponent status={selectedRow?.status} />
					</Flex>
				</Stack>
				
			</Stack>
		);
	};

	const mobileColumns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				accessor: 'dateCreated',
				Cell: (item) => {
					return (
						<Flex alignItems={'center'}>
							<Image w={'40px'} height={'40px'} src={WithdrawalIcon} mr={'10px'} />
							<VStack align={'flex-start'} spacing={'5px'}>
								<Box fontSize='14px'>Payment</Box>
								<Box fontSize='12px' color='loopGrey.800'>
									{formatDateShort(item.row.original.dateCreated)}
								</Box>
							</VStack>
						</Flex>
					);
				},
			},
			{
				accessor: 'points',
				Cell: (item) => (
					<VStack alignItems='flex-end'>
						<Box fontWeight='400' fontSize={'14px'}>
							{item.row.original.points} LNT
						</Box>
						<TransactionStatusComponent status={item.row.original.status} />
					</VStack>
				),
			},
		],
		[],
	);

	return (
		<DashboardSettingLayout>
			<Text fontSize={['14px', '16px']} fontWeight={400} color={'brand.black'} pb={'30px'} w={['auto','980px']}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
			</Text>
			<DataTable
				data={newData || []}
				columns={isMobile ? mobileColumns : columns}
				isLoading={isLoading || isFetching}
				isMobile={isMobile}
				showCheckboxes={false}
				showMenu={false}
				onclickRow={handleClick}
			/>
			<CustomModal content={<TransactionReceipt />} isOpen={isOpen}
				onClose={onClose} modalContentStyles={{borderRadius : 10, h:392,  w: 457}}   />
		</DashboardSettingLayout>
	);
};
