import {useEffect} from 'react';

export const usePressEnter = (cb: () => void) => {
	useEffect(() => {
		const listener = (event: { code: string; preventDefault: () => void }) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				cb();
				event.preventDefault();
			}
		};
		document.addEventListener('keydown', listener);
		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, []);
};