import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/GraduationCapIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const GraduationCapIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const GraduationCapIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M18.837 13.0399L17.3653 11.5682V6.52019L18.3261 6.17381C18.5466 6.09426 18.6937 5.88509 18.6939 5.65058C18.6941 5.41615 18.5473 5.20668 18.3268 5.12687L9.53646 1.94341C9.41405 1.89907 9.27984 1.89907 9.15742 1.94341L0.367091 5.12687C0.147462 5.20642 0.000893968 5.41459 3.42804e-06 5.64817C-0.0008129 5.88175 0.144234 6.09099 0.363232 6.17214L3.60736 7.37389V11.042C3.60736 11.1559 3.64224 11.267 3.70736 11.3604C3.74316 11.4117 4.08235 11.8749 4.95077 12.312C6.04788 12.8643 7.48685 13.1443 9.22763 13.1443C10.9671 13.1443 12.4134 12.8649 13.5262 12.3137C14.4031 11.8793 14.7536 11.4209 14.7907 11.3701C14.8602 11.2749 14.8976 11.16 14.8976 11.0421V7.40981L16.2522 6.92146V11.5682L14.7805 13.0399C14.6761 13.1443 14.6174 13.2858 14.6174 13.4335C14.6174 13.5811 14.6761 13.7227 14.7805 13.827L16.2522 15.2987V16.5334C16.2522 16.8408 16.5014 17.09 16.8088 17.09C17.1161 17.09 17.3653 16.8408 17.3653 16.5334V15.2987L18.837 13.827C19.0544 13.6097 19.0544 13.2572 18.837 13.0399ZM9.34696 3.0587L16.4995 5.64902L9.23026 8.26977L2.17481 5.65611L9.34696 3.0587ZM13.7844 10.8202C11.8817 12.5323 6.22755 12.336 4.72053 10.8258V7.78628L9.03427 9.38428C9.10273 9.39512 9.16737 9.45415 9.41639 9.38599L13.7844 7.81122V10.8202H13.7844ZM16.8087 14.281L15.9611 13.4335L16.8087 12.5858L17.6563 13.4335L16.8087 14.281Z" fill={color}/>
		</Icon>
	);
};