import React, { useState } from 'react';
import { 
	Box,
	chakra,
	Flex, 
	Image, 
	Table, 
	TableContainer, 
	Tbody, 
	Td,
	Tr
} from '@chakra-ui/react';

import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IBrand } from 'Types/Interface/IBrand';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';


interface IBrandDetails{
	data: IJsonResponse<IBrand> | undefined
	shouldIgnoreParentPadding: boolean;
}

export const BrandDetails = ({data, shouldIgnoreParentPadding}:IBrandDetails) =>{
	dayjs.extend(relativeTime);
	const [showFullText, setShowFullText] =  useState(false);
	const maxLength = 300;
	let textLength = 0;
	if(data?.data?.purpose){
		textLength = data?.data?.purpose?.length;
	}
	const truncatedText =  showFullText ? data?.data.purpose : data?.data.purpose?.slice(0, maxLength);
	return (
		<Flex
			my={{ base: '10', tablet: '20' }}
			key={data?.data.id}
			{...(shouldIgnoreParentPadding && { px: ['0',null, 6,'20'] })}
		>
			<Flex
				direction={['column-reverse', 'row']}
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Image 
					src={data?.data.imageUrl} 
					w={['100%','50%']}
					minH={'500px'}
					h={'100%'}
					objectFit='cover'
					pb={[10, 0]}
					border={'1px'} borderColor={'#F9F9F9'}
				/>
				<Box 
					bg={'#F9F9F9'}
					w={['100%','50%']}
					minH={['auto','500px']}
					h={'100%'}
					display={'flex'} pt={6}
					justifyContent="center"
					alignItems={'center'}
				>
					<TableContainer>
						<Table size={'lg'} variant='unstyle'   >
							<Tbody color={'#586068'} display={[null,'flex']} flexDirection={'column'} fontSize={{base: 'sm', tablet: 'sm', xl:'lg'}}>
								<Tr display={'flex'} >
									<Td fontWeight={600}>Established:</Td>
									<Td fontWeight={400} ml={ -5}>{dayjs(data?.data.establishedAt).format('D MMMM, YYYY')}</Td>
								</Tr>
								<Tr display={'flex'}>
									<Td fontWeight={600}>HeadQuarters:</Td>
									<Td fontWeight={400} ml={-10}>{data?.data.hqCity}</Td>
								</Tr>
								<Tr display={['flex','flex']}>
									<Td fontWeight={600}>Purpose:</Td>
									<Td 
										whiteSpace={'break-spaces'}
										minW={['auto', '292px']}
										w={'auto'}
										fontWeight={400}
									>
										{truncatedText}
										{ !showFullText && textLength > maxLength && (
											<>
												<chakra.span>... </chakra.span>
												<chakra.span
													onClick={() => setShowFullText(!showFullText)}
													cursor={'pointer'}
													textDecor={'underline'}
													_hover={{ color: 'darkCoral' }}
												>show more</chakra.span>
											</>
										)}
										{ showFullText && textLength > maxLength && (
											<>
												<chakra.span>... </chakra.span>
												<chakra.span
													onClick={() => setShowFullText(!showFullText)}
													cursor={'pointer'}
													textDecor={'underline'}
													_hover={{ color: 'darkCoral' }}
												>show less</chakra.span>
											</>
										) }
									</Td>
								</Tr>
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Flex> 
		</Flex>
	);
};