import React from 'react';
import { Progress} from '@chakra-ui/react';

interface IOngoingSurveyProgressProps{
	currentQuestion: number;
	totalQuestion: number;
}

export const OngoingSurveyProgressIndicator = ({currentQuestion, totalQuestion}:IOngoingSurveyProgressProps) => {
	const percentage = totalQuestion ? +(100 / totalQuestion).toFixed(2) : 0;
	const progress = ((currentQuestion + 1 ) * percentage);

	return (
		<Progress
			w="100%"
			size="xs"
			value={progress}
			variant="primary"
			borderRadius="full"
		/>
	);
};
