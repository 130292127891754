import React from 'react';
import { Stack, Heading, Text, Flex } from '@chakra-ui/react';
import { IAccountSettingCard } from 'Constants';

interface IRightSideDetailsProps {
  data: IAccountSettingCard[];
}

export const RightSideDetails = ({ data }: IRightSideDetailsProps) => {
	return (
		<Stack
			ml={28}
			spacing={10}
			p={6}
			width={'410px'}
			borderWidth={1}
			flex={1}
			borderColor="#58606880"
			borderRadius={5}
			display={['none', null, null, 'block']}
		>
			{
				data.map(({title, icon, iconBgColor, description}) => (
					<Stack spacing={4} key={title}>
						<Flex
							bg={iconBgColor}
							h="35px"
							w="35px"
							borderRadius="50%"
							alignItems="center"
							justifyContent="center"
							p={2}
						>
							{icon}
						</Flex>
						<Heading fontSize="20px" color={'#121B26'}>{title}</Heading>
						<Text 
							fontSize={'14px'} 
							fontWeight={500}
							color={'#121B26'}
							lineHeight={'180%'} 
						>
							{description}
						</Text>
					</Stack>
				))
			}
		</Stack>
	);
};
