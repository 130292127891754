import React, { useState } from 'react';
import {
	Flex,
	Heading,
	Text,
	Box,
	UnorderedList,
	ListItem,
	useMediaQuery,
} from '@chakra-ui/react';

import { Carousels } from 'Constants/ImageSliderConstant';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { ILandingPageLayoutChild } from 'Layouts/LandingPageLayout';
import { HowItWorksDesktop } from './HowItWorksDesktop';
import { HowItWorksMobile } from './HowItWorksMobile';

export const HowItWorksSection = ({
	shouldIgnoreParentPadding,
}: ILandingPageLayoutChild) => {
	const [currentSlide, setcurrentSlide] = useState(0);
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const active = currentSlide;

	const onRight = () => {
		if (currentSlide < Carousels.length - 1) {
			setcurrentSlide(currentSlide + 1);
		}
	};

	const onLeft = () => {
		if (currentSlide > 0) {
			setcurrentSlide(currentSlide - 1);
		}
	};

	const Slidernavigation = (index: number) => {
		setcurrentSlide(index);
	};

	const activeCarousel = {
		desktop: <HowItWorksDesktop currentSlide={currentSlide} />,
		mobile: <HowItWorksMobile />
	};

	return (
		<Flex
			direction={'column'}
			maxW={'100%'}
			{...(shouldIgnoreParentPadding && { pl: ['8', '20'] })}
		>
			<Box py={10}>
				<Heading
					mb={6}
					fontSize={{ base: '2xl', tablet: '2xl', xxl: '50px' }}
					fontWeight={'600'}
				>
          How the Loop app works.
				</Heading>
				<Text fontSize={['16px', '18px']} fontWeight={400} color={'#2B2B2B;'}>
				Partnering brands insert QR codes inside the packaging of items they sell
				</Text>
			</Box>
			<Flex justifyContent={'space-between'}>
				<Flex direction={'column'} mt="8">
					<Flex
						cursor={'pointer'}
						ml={3}
						h={10}
						display={['none', 'none', 'block']}
					>
						<ArrowBackIcon
							onClick={onRight}
							boxSize={5}
							w={10}
							color="#586068"
						/>
						<ArrowForwardIcon
							onClick={onLeft}
							boxSize={5}
							w={10}
							color="#586068"
						/>
					</Flex>
					{Carousels.map((Carousel, index) => {
						return (
							<UnorderedList key={Carousel.id}>
								<ListItem
									mb={6}
									listStyleType={'none'}
									fontSize={'md'}
									fontWeight={active === index ? 'semibold' : 'normal'}
									color={active === index ? '#121B26' : '#96999B'}
									_hover={{ color: '#121B26', fontWeight: '600' }}
									cursor={'pointer'}
									onClick={() => Slidernavigation(index)}
									display={['none', 'none', 'block']}
								>
									{Carousel.label}
								</ListItem>
							</UnorderedList>
						);
					})}
				</Flex>
				<Flex
					justifyContent={'space-between'}
					alignItems={'center'}
					w={['100%', '80%']}
					overflow={['', '', 'hidden']}
				>
					{
						activeCarousel[isLargerThan768 ? 'desktop' : 'mobile']
					}
				</Flex>
			</Flex>
		</Flex>
	);
};