import React from 'react';
import { Heading, Stack } from '@chakra-ui/react';
import Image2 from 'Assets/Images/MarketingImg.png';
import Image1 from 'Assets/Images/LoopAppImage.png';
import { ISolution, MarketingSolutionLayout } from '../components';
import { FadeOnScroll } from 'Pages/LandingPage/Components/FadeOnScroll';

const MarketingSolution1: ISolution = {
	heading: 'Marketing Solutions.',
	description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lor em ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
	list: [
		'Let your review(s) matter',
		'Access and review unlimited brands',
		'Earn while reviewing your favourites',
	],
};

export const MarketingSolution = () => {
	return (
		<Stack spacing={20} py={['10',32]}>
			<Heading fontSize={['4xl','6xl']} >Payment and marketing solutions that make an impact.</Heading>
			<MarketingSolutionLayout image={Image1} solution={MarketingSolution1} />
			<FadeOnScroll><MarketingSolutionLayout layout='RL' image={Image2} solution={MarketingSolution1} /></FadeOnScroll>
		</Stack>
	);
};
