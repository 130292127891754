import { createApi } from '@reduxjs/toolkit/query/react';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { ICategory } from 'Types/Interface/IProduct';
import { baseQuery } from './history';

export const categoryApi = createApi({
	reducerPath: 'categoryApi',
	baseQuery,
	endpoints: (builder) => ({
		getCategories: builder.query<IJsonResponse<ICategory[]>, void>({
			query: () => '/categories',
		}),
	}),
});

export const { useGetCategoriesQuery  } = categoryApi;