import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { RegisterPage } from 'Pages/RegisterPage/RegisterPage';
import { SuccessPage } from 'Pages/RegisterPage/SuccessPage';
import { Verification } from 'Pages/RegisterPage/Verification';
import { IntroductionPage } from 'Pages/RegisterPage/IntroductionPage';
import { LoginPage } from '../Pages/LoginPage/LoginPage';
import { AppRoutes } from './AppRoutes';
import { BusinessSection } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { ResetPassword } from 'Pages/ResetPassword/ResetPassword';
import { AuthenticatedRoute } from './AuthenticatedRoutes';
import { ComingSoonPage } from 'Pages/ComingSoonPage/ComingSoonPage';
import { HomePage } from 'Pages/LandingPage/HomeSection/HomePage';
import { LoopAppPage } from 'Pages/LandingPage/LoopAppSection/LoopAppPage';
import { HowItWorksPage } from 'Pages/LandingPage/HowItWorksSection/HowItWorksPage';
import { BrandsPage } from 'Pages/LandingPage/BrandsSection/BrandsPage';
import { FeaturedBrandsPage } from 'Pages/LandingPage/FeaturedBrandsSection/FeaturedBrandsPage';
import { authRoutes } from './config';
import { useAppDispatch } from 'Hooks';
import { setQrCodeFromDynamicLink } from 'store/slices';
import { DynamicProductPage } from 'Pages/DynamicLinkProduct';
import { AuthContext } from 'context/AuthContext';
import { EnterCodePage } from 'Pages/DynamicLinkProduct/EnterCodePage';

export const MainRoute = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const isDev =
		window.location.href.includes('netlify') ||
		window.location.href.includes('ngrok') ||
		window.location.href.includes('localhost') ||
		window.location.href.includes('deploy') ||
		window.location.href.includes('ngrok'); // Temporary condition, to redirect visitor to coming soon page

	const authContext = useContext(AuthContext);

	const pathname = location.pathname;
	const isScanProduct = pathname.includes('/scan');
	if (isScanProduct) {
		const qrCode = pathname.split('/').pop();

		useEffect(() => {
			if (qrCode?.includes(':')) {
				dispatch(setQrCodeFromDynamicLink(qrCode));
			}
		}, []);

		const redirectRoute = authContext.isAuthenticated()
			? AppRoutes.home
			: qrCode
				? AppRoutes.dynamicLinkProduct.replace(':qrCode', qrCode)
				: undefined;

		if (redirectRoute) {
			return <Navigate to={redirectRoute} />;
		}
	}

	return (
		<Routes>
			{isDev ? (
				<>
					<Route path={AppRoutes.landingpage} element={<HomePage />} />
					<Route path={AppRoutes.login} element={<LoginPage />} />
					<Route path={AppRoutes.resetPassword} element={<ResetPassword />} />
					<Route path={AppRoutes.register} element={<RegisterPage />} />
					<Route path={AppRoutes.verify} element={<Verification />} />
					<Route path={AppRoutes.success} element={<SuccessPage />} />
					<Route path={AppRoutes.introduction} element={<IntroductionPage />} />

					<Route path={AppRoutes.comingSoon} element={<ComingSoonPage />} />
					<Route path={AppRoutes.business} element={<BusinessSection />} />
					<Route path={AppRoutes.loopApp} element={<LoopAppPage />} />
					<Route path={AppRoutes.howItWorks} element={<HowItWorksPage />} />
					<Route path={AppRoutes.brands} element={<BrandsPage />} />
					<Route path={AppRoutes.dynamicLinkProduct} element={<DynamicProductPage />} />
					<Route path={AppRoutes.featuredBrands} element={<FeaturedBrandsPage />} />
					<Route path={AppRoutes.EnterCode} element={<EnterCodePage />} /> 
					<Route path='*' element={<ComingSoonPage />} />

					{authRoutes.map(({ path, element }) => (
						<Route key={path} path={path} element={<AuthenticatedRoute component={element} />} />
					))}
				</>
			) : (
				<Route path='*' element={<ComingSoonPage />} />
			)}
		</Routes>
	);
};