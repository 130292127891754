import React, { useLayoutEffect, useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { History, MyAccount, Rewards, Settings } from './sections';
import { AccountLayout, DashBoardLayout } from 'Layouts';

// const localStorageTabIndex = localStorage.getItem('currentTab');

export const Dashboard = () => {
	const tabs = [
		{
			title: 'My Account',
			content: <MyAccount />,
		},
		{
			title: 'Activities',
			panelTitle: 'Account Activities',
			content: <History />,
		},
		{
			title: 'Rewards',
			content: <Rewards />,
		},
		{
			title: 'Account Settings',
			content: <Settings />,
		},
	];
	const [activeTab, setActiveTab] = useState(0);

	const handleTabIndex = (index: number) => {
		setActiveTab(index);
		localStorage.setItem('currentTab', `${index}`);
	};

	useLayoutEffect(() => {
		const localStorageTabIndex = localStorage.getItem('currentTab');
		if (localStorageTabIndex) {
			setActiveTab(parseInt(localStorageTabIndex));
		}
	}, []);

	return (
		<DashBoardLayout>
			<Tabs mt={5} index={activeTab}>
				<TabList borderBottom="none" overflow="auto" pb={['1', null, 2]} className='no-scrollbar'>
					{tabs.map(({ title }, index) => (
						<Tab
							key={title}
							color="#586068"
							fontSize="sm"
							px={0}
							mr={[4,10]}
							_selected={{ borderColor: 'loop.300', fontWeight: 600 }}
							_hover={{borderColor: 'loop.300',}}
							onClick={() => handleTabIndex(index)}
							whiteSpace="nowrap"
							_active={{border: 'none'}}
						>
							{title}
						</Tab>
					))}
				</TabList>

				<TabPanels>
					{tabs.map(({ title, panelTitle, content }) => (
						<TabPanel key={title} px={0}>
							<AccountLayout title={panelTitle ?? title}>{content}</AccountLayout>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		</DashBoardLayout>
	);
};
