import React, { useState } from 'react';
import {
	Flex,
} from '@chakra-ui/react';
import { CTACard } from './CTACard';

export const CTA = () => {
	const [currentSlide, setcurrentSlide] = useState(0);

	const navigateCarousel = (index: number) => {
		setcurrentSlide(index);
	};
	return (
		<Flex
			ml={5}
			borderRadius={5}
			h={'370px'}
			w={'300px'}
			overflow='hidden'
		>
			<Flex height='300px' width='100vw'>
				{
					new Array(3).fill('dot').map((value, index) => (
						<CTACard key={value + index} currentSlide={currentSlide} navigateCarousel={navigateCarousel}/>
					))
				}
			</Flex>
		</Flex>
	);
};
