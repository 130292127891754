import { ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Heading, IconButton, Link, Stack, Text } from '@chakra-ui/react';
import { AvatarUpload } from 'Components/AvatarUpload';
import { ProfileIcon } from 'Components/Icons';
import { UserIconSvg } from 'Components/Icons/UserIcon';
import { AuthLayout } from 'Layouts/AuthLayout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { MainIntro } from './MainIntro';


const pictureIntroHeading = {
	mainHeader: 'A picture speaks more than a thousand words',
	subHeader: 'Add a profile picture and secure your account',
};

enum CurrentScreen {
    INTRO = 1,
    UPLOAD = 2
}

export const PictureUploadStep = () => {
	const [currentScreen, setCurrentScreen] = useState(CurrentScreen.INTRO);
	const navigate = useNavigate();

	const renderUploadImageScreen = () => {
		return (
			<Stack spacing={8}>
				<Flex
					w={10}
					h={10}
					borderRadius="50%"
					borderWidth={1}
					borderColor="#264653"
					alignItems="center"
					justifyContent="center"
				>
					<UserIconSvg/>
				</Flex>
				<Heading size="md">
                Add a profile picture
				</Heading>
				<Text>
                Choose a photo for your profile. You can change this later
				</Text>
                
				<Flex
					alignItems="center"
					justifyContent="center"
				>
					<AvatarUpload/>
				</Flex>
			</Stack>
		);
	};

	const _renderCurrentScreen = () => {
		switch (currentScreen) {
		case CurrentScreen.INTRO:
			return  <MainIntro heading={pictureIntroHeading} icon={<ProfileIcon />} />;        
		case CurrentScreen.UPLOAD:
			return  renderUploadImageScreen();      
		}
	};

	const _navigateToDashboard = () => navigate(AppRoutes.home);

	const _handleNext = () => {
		setCurrentScreen(CurrentScreen.UPLOAD);
		if(currentScreen === CurrentScreen.UPLOAD){
			_navigateToDashboard();
		}
	};
	return (
		<AuthLayout>
			<Flex
				alignItems="center"
				h={['800px', '100vh']}
				justifyContent="center"
				bg="white"
				flex={1}
				p={['3', '10']}
			>
				<Flex
					shadow="lg"
					p="50"
					px="10"
					pb="10"
					borderRadius="md"
					boxShadow={['none','0px 0px 90px rgba(0, 0, 0, 0.1)']}
					w={['100%', '500px']}
					h={['70%', '500px']}
					flexDirection="column"
				>
					<Flex
						flexDirection="column"
						h="100%"
						justifyContent="space-between"
						w="100%"
					>

						{_renderCurrentScreen()}
						<Flex justifyContent="space-between" w="100%" alignItems="center">
							<Link color="#FF6F61" fontSize={13} onClick={_navigateToDashboard} >
                Skip
							</Link>

							<IconButton
								aria-label="next"
								w={10}
								h={10}
								mb={1}
								bgColor="#FFEEEC"
								borderRadius="full"
								alignContent="center"
								justifyContent="center"
								icon={<ChevronRightIcon color="#FF6F61" boxSize={6} />}
								onClick={_handleNext}
							/>
						</Flex>
					</Flex>

				</Flex>
			</Flex>

		</AuthLayout>
	);
};
