import React from 'react';
import { creditsAndCouponDetails } from 'Constants';
import {  DashboardSettingLayout } from 'Layouts';

import { DashboardLayoutWithDescription } from 'Pages/Dashboard/Components/LayoutWithDescription';
import { Content } from './Content';

export const Referral = () => {
	return (
		<DashboardSettingLayout>
			<DashboardLayoutWithDescription
				content={<Content/>}
				description={creditsAndCouponDetails}/>
		</DashboardSettingLayout>
	);
};
