import React from 'react';
import {  Button, Flex, Heading, Text, VStack, Switch, Spacer } from '@chakra-ui/react';
import { SuccessIcon } from 'Components/Icons';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';

export const SuccessPage = () => {
	const navigate = useNavigate();

	const handleNavigate = () => {
		navigate(AppRoutes.introduction);
	};

	return (
		<Flex alignItems={['flex-start', 'center']} justifyContent="center"  p={['4', '8']} my={'20'} >
			<VStack align={['flex-start', 'center']} spacing={8}>
				<SuccessIcon boxSize={'50px'} />
				<Heading fontWeight={600} fontSize={'22px'} textAlign={['left', 'center']}>Phone setup complete!</Heading>
				<Text fontSize={'16px'} textAlign={['left', 'center']}>
					Nice work! Your phone number is verified
				</Text>
				<VStack spacing={6} borderWidth={1} w="full"  p={['6', '8']} alignItems="flex-start" borderRadius={3}>
					<Flex alignItems="flex-start" justifyContent="space-between" w="full">
						<Text fontSize={'16px'}>
							Subscribe to weekly updates
						</Text>
						<Switch size='md' variant="primary" />
					</Flex>
					<Text fontSize={'14px'}>
						Email once a week about updates and new features
					</Text>
					<Spacer h={10}/>
					<Button 
						variant="secondary" 
						w="full"
						onClick={handleNavigate}
					>
						Complete your profile
					</Button>
				</VStack>
			</VStack>
		</Flex>
	);
};
