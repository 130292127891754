import { Box, CloseButton, Link, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { CustomModal } from 'Components';
import { useAppDispatch, useAppSelector } from 'Hooks';
import React, { useState } from 'react';
import { setHideVerifyEmailSection } from 'store/slices';
import { EmailVerificationModalContentEmail } from './EmailVerificationModalContentEmail';
import { EmailVerificationModalContentOtp } from './EmailVerificationModalContentOtp';
import { EmailVerificationModalContentSuccess } from './EmailVerificationModalContentSuccess';

export const EmailVerification = () => {
	const dispatch = useAppDispatch();

	const { isOpen: isOpenPopup, onOpen: onOpenPopup, onClose: onClosePopup } = useDisclosure();

	const [email, setEmail] = useState('');
	const [isVerified, setIsVerified] = useState(false);

	const { userInfo, hideVerifyEmailSection } = useAppSelector((state) => state.authUser);
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	const handleHideVerifyEmailSection = () => {
		dispatch(setHideVerifyEmailSection(true));
	};

	return hideVerifyEmailSection || !userInfo || userInfo.emailConfirmed ? (
		<></>
	) : (
		<>
			<Box
				backgroundColor={'extraLightCoral'}
				textAlign={'center'}
				mt={8}
				py={4}
				px={['5', null, 6, '20']}
				fontSize={['10px', '14px']}
			>
				Please verify your email to earn cashbacks{' '}
				<Link
					fontWeight={'400'}
					color='darkCoral'
					cursor={'pointer'}
					onClick={onOpenPopup}
					pl={1}
					textDecoration={'underline'}
					_hover={{ textDecoration: 'none' }}
				>
					Verify
				</Link>
				<CloseButton size='sm' fontSize={'9px'} float={'right'} onClick={handleHideVerifyEmailSection}></CloseButton>
			</Box>

			<CustomModal
				isOpen={isOpenPopup}
				onClose={onClosePopup}
				modalContentStyles={{ borderRadius: 10, boxShadow: '0px 0px 30px rgba(88, 96, 104, 0.1)' }}
				size={isLargerThan768 ? 'md' : 'sm'}
				hideFooter
				isCentered
				content={
					email == '' ? (
						<EmailVerificationModalContentEmail onChange={setEmail} />
					) : !isVerified ? (
						<EmailVerificationModalContentOtp
							email={email}
							onVerified={() => {
								setIsVerified(true);
							}}
						/>
					) : (
						<EmailVerificationModalContentSuccess onClose={onClosePopup} />
					)
				}
			/>
		</>
	);
};
