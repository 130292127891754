import React from 'react';
import { Input } from '@chakra-ui/react';
import { FadeInContainer } from '../FadeInContainer';

interface IFreeTextProps {
	name: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>
	value?: string;
}

export const FreeText = ({name, onChange, value}:IFreeTextProps) => {
	return (
		<FadeInContainer>
			<Input onChange={onChange} value={value} variant='flushed' name={name} placeholder='Type your answer' />
		</FadeInContainer>
	);
};
