import { VStack, Text, Heading, chakra } from '@chakra-ui/react';
import { RewardIcon } from 'Components/Icons';
import { useAppSelector } from 'Hooks';
import React from 'react';

export const SuccessScan = () => {
	const { codeScanData } = useAppSelector((state) => state.survey);
	const { latestEarnedPoints, pointsForCompletingSurvey } = codeScanData;

	return (
		<VStack p='5' py='8' spacing={6} textAlign='center'>
			<RewardIcon />
			<Heading size='sm'>This QR code has been successfully added to your account</Heading>

			<Text>
				You have earned{' '}
				<chakra.span fontWeight='bold' color='loop.500'>
					{latestEarnedPoints} LNT
				</chakra.span>
				.{' '}
				{((pointsForCompletingSurvey ?? 0) > 0) && (
					<>
						Complete the survey and earn{' '}
						<chakra.span fontWeight='bold' color='loop.500'>
							{pointsForCompletingSurvey} LNT
						</chakra.span>{' '}
						more.
					</>
				)}
			</Text>
		</VStack>
	);
};
