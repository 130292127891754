import * as Yup from 'yup';

import { INewUserRequestbody } from 'Types/Interface/IUser';

export const initialValues = {
	firstName: '', lastName: '', password: '', phoneNumber: '', country: '', countryCode: '', email: ''
} as INewUserRequestbody;

export const validationSchema = Yup.object().shape({
	firstName: Yup.string().min(3).required('Required'),
	lastName: Yup.string().min(3).required('Required'),
	phoneNumber: Yup.string().min(3).required('Required'),
	country: Yup.string().min(3).required('Required'),
	password: Yup.string().min(3).required('Required'),
	email: Yup.string().min(3).required('Required')
});
