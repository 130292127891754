import React from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { BrandCaoruselItems } from 'Components/Gallery/BrandCarouselItem';
import { SeeAllLink } from 'Pages/LandingPage/Components/SeeAllLink';
import { SwipeableGalleryContainer } from 'Pages/LandingPage/Components/SwipeableGalleryContainer';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { IBrand } from 'Types/Interface/IBrand';

interface IMobileDiscoverBrandsProps {
  brands?: IBrand[];
  isLoading: boolean;
}

export const MobileBrandsSlider = ({
	brands,
	isLoading,
}: IMobileDiscoverBrandsProps) => {
	const navigate = useNavigate();
	return (
		<>
			<SwipeableGalleryContainer>
				{isLoading
					? new Array(4)
						.fill('_')
						.map((_, index) => (
							<Skeleton w={'250px'} mr={2} height="250px" key={index} />
						))
					: brands?.map((brand, index) => {
						return (
							<Box key={index} w={['65%', '100%', null]} mr={[4]}>
								<BrandCaoruselItems visibility={true} brands={brand} />
							</Box>
						);
					})}
			</SwipeableGalleryContainer>
			<SeeAllLink
				onClickLink={() => navigate(AppRoutes.allBrands)}
			/>
		</>
	);
};
