import Appstore from './../../Assets/svg/AppleStore.svg';
import Playstore from './../../Assets/svg/PlayStore.svg';
import facebookIcon from './../../Assets/svg/facebookIcon.svg';
import instagramIcon from './../../Assets/svg/instagramIcon.svg';
import linkedin from './../../Assets/svg/linkedin.svg';
import tiktok from './../../Assets/svg/tik-tok.svg';

export const textVariant = {
	hidden:{
		opacity: 0,
		y: 0
	},
	visible: {
		opacity: 1,
		y:-10,
		transition: {
			delay: 2,
			duration: 2,
			type: 'spring'
		}
	}
};

export const svgVariant = {
	hidden:{
		rotate: -180
	},
	visible: {
		rotate: 0,
		transition: {
			duration: 1
		}
	}
};

export const logoTextVariant = {
	hidden: {
		opacity: 0,
		x: 0
	},
	visible:{
		opacity: 1,
		x: 10,
		transition: {
			delay: 1.5,
			duration: 1,
			type: 'spring'
		}
	}
};

export const leftFooterIcons: {label: string, src:string}[] = [
	{
		label: 'playstore',
		src: Playstore
	},
	{
		label: 'appstore',
		src: Appstore
	}
];

export const rightFooterIcons: {label: string, src:string}[] = [
	{
		label: 'facebook',
		src: facebookIcon
	},
	{
		label: 'instagram',
		src: instagramIcon
	},
	{
		label: 'linkedin',
		src: linkedin
	},
	{
		label: 'tiktok',
		src: tiktok
	}
];