export enum UserAgent {
  Iphone = 'iphone',
  Ipad = 'ipad',
  Chrome = 'chrome',
  Edge = 'edge',
  Firefox = 'firefox',
  Opera = 'opera',
  Safari = 'safari',
}

export const getUserAgent = (): UserAgent | null => {
	const userAgent = navigator.userAgent;
	let userAgentName;

	if (userAgent.match(/iphone/i)) {
		userAgentName = UserAgent.Iphone;
	} else if (userAgent.match(/ipad/i)) {
		userAgentName = UserAgent.Ipad;
	} else if (userAgent.match(/chrome|chromium|crios/i)) {
		userAgentName = UserAgent.Chrome;
	} else if (userAgent.match(/firefox|fxios/i)) {
		userAgentName = UserAgent.Firefox;
	} else if (userAgent.match(/safari/i)) {
		userAgentName = UserAgent.Safari;
	} else if (userAgent.match(/opr\//i)) {
		userAgentName = UserAgent.Opera;
	} else if (userAgent.match(/edg/i)) {
		userAgentName = UserAgent.Edge;
	} else {
		userAgentName = null;
	}

	return userAgentName;
};
