import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { MainRoute } from './Routes/MainRoute';
import { theme } from 'Theme/BaseTheme';
import '@fontsource/montserrat';
import { Global } from '@emotion/react';
import { GlobalStyles } from 'Theme/GlobalStyles';

export const App = () => (
	<ChakraProvider theme={theme}>
		<Global styles={GlobalStyles} />
		<MainRoute />
	</ChakraProvider>
);
