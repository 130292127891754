import { Flex } from '@chakra-ui/react';
import { useAppSelector } from 'Hooks';
import React from 'react';
import { FooterSection } from './FooterSection';
import { HeaderSection } from './HeaderSection';

interface ISurveyContainerProps {
	children: React.ReactNode;
}

export const SurveyContainer = ({ children }: ISurveyContainerProps) => {
	const {
		activeSurvey: { responses },
		isStartSurvey,
	} = useAppSelector((state) => state.survey);

	const isNewSurvey = !responses || responses.length === 0;
	return (
		<Flex direction='column' h='100vh'>
			<HeaderSection />
			<Flex direction='column' pt={['30px', '70px']} alignItems='center' flex={1} overflowY='scroll'>
				{children}
			</Flex>
			{(!isNewSurvey || isStartSurvey) && <FooterSection />}
		</Flex>
	);
};
