import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';

const useNavigation = () => {
	const navigate = useNavigate();

	const navigateToBrandLoopDetails = useCallback(
		(brandId: string) => {
			navigate(AppRoutes.brandLoop.replace(':brandId', brandId));
		},
		[navigate],
	);

	const navigateToProduct = useCallback(
		(productId: string) => {
			navigate(AppRoutes.productPage.replace(':productId', productId));
		},
		[navigate],
	);

	const navigateToWithdrawalHistoryTable = useCallback(
		() => navigate(AppRoutes.withdrawalHistory, { state: { title: 'Withdrawal history' } }),
		[navigate]	
	);

	return { navigateToBrandLoopDetails, navigateToProduct, navigateToWithdrawalHistoryTable  };
};

export default useNavigation;
