import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';
import { IBrandLoop, IBrandLoopDetails } from './../Types/Interface/IBrandLoop';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IAuthenticateUserResponse } from 'context/AuthContext';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import {
	IChangePasswordDto,
	IChangePhoneNumber,
	INewUserRequestbody,
	IPhoneVerificationRequestDetails,
	IResetPasswordDto,
} from 'Types/Interface/IUser';
import { baseQuery } from './history';
import { IPaginatedResponse } from './types';
import { IUserProductActivity } from 'Types/Interface/IUserProductActivity';
import { TagType } from 'Types/Enums';

type VerifySocial = {
	data: boolean;
	status: boolean;
};


export const accountApi = createApi({
	reducerPath: 'accountAPI',
	baseQuery,
	tagTypes: [TagType.BrandLoops],
	endpoints: (builder) => ({
		updatePassword: builder.mutation<unknown, IChangePasswordDto>({
			query(body) {
				return {
					url: 'account/change-password',
					method: 'PUT',
					body,
				};
			},
		}),
		resetPassword: builder.mutation<unknown, IResetPasswordDto>({
			query(body) {
				return {
					url: 'account/reset-password',
					method: 'POST',
					body,
				};
			},
		}),
		updatePhoneNumber: builder.mutation<unknown, IChangePhoneNumber>({
			query(body) {
				return {
					url: '/account/change-phonenumber',
					method: 'POST',
					body,
				};
			},
		}),
		createAccountWithPhoneNumber: builder.mutation<IJsonResponse<IAuthenticateUserResponse>, INewUserRequestbody>({
			query(body) {
				return {
					url: '/account/create-account-by-phone',
					method: 'POST',
					body,
				};
			},
		}),
		verifyGmailAccount: builder.mutation<VerifySocial, string>({
			query(userEmail) {
				return {
					url: `/account/verify-existing-gmailaccount?email=${userEmail}`,
					method: 'POST',
				};
			},
		}),
		verifyFacebookAccount: builder.mutation<VerifySocial, string>({
			query(accessToken) {
				return {
					url: `/account/verify-existing-facebookaccount?accessToken=${accessToken}`,
					method: 'POST',
				};
			},
		}),
		getResetCode: builder.mutation<unknown, IPhoneVerificationRequestDetails>({
			query(body) {
				return {
					url: '/account/request-reset-code',
					method: 'POST',
					body,
				};
			},
		}),
		getBrandLoops: builder.query<IPaginatedResponse<IBrandLoop[]>, { pageIndex: number; pageSize: number }>({
			query: ({ pageIndex, pageSize }) =>
				`userbrandpermission/GetBrandLoops?pageNumber=${pageIndex}&pageSize=${pageSize}`,
			providesTags: [TagType.BrandLoops],
		}),
		getBrandLoopDetails: builder.query<IJsonResponse<IBrandLoopDetails>, string>({
			query: (brandId) => `userbrandpermission/GetBrandLoopDetails/${brandId}`,
		}),
		getUserProductActivities: builder.query<
			IPaginatedResponse<IUserProductActivity[]>,
			{ brandId: string; pageIndex: number; pageSize: number }
		>({
			query: ({ brandId, pageIndex, pageSize }) =>
				`userbrandpermission/GetUserProductActivities?brandId=${brandId}&pageNumber=${pageIndex}&pageSize=${pageSize}`,
		}),
		updateUserBrandPermission: builder.mutation<unknown, IUserBrandPermission>({
			query(body) {
				return {
					url: '/userbrandpermission/AddOrUpdate',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: [TagType.BrandLoops],
		}),
	}),
});

export const {
	useUpdatePasswordMutation,
	useCreateAccountWithPhoneNumberMutation,
	useVerifyGmailAccountMutation,
	useVerifyFacebookAccountMutation,
	useGetResetCodeMutation,
	useResetPasswordMutation,
	useUpdatePhoneNumberMutation,
	useGetBrandLoopsQuery,
	useGetBrandLoopDetailsQuery,
	useUpdateUserBrandPermissionMutation,
	useGetUserProductActivitiesQuery,
} = accountApi;
