import React from 'react';
import { LandingPageLayout } from 'Layouts/LandingPageLayout';
import { HomeJumbotronSection } from '../Components/HomeJumbotronSection';
import { HowItWorksSection } from '../LoopAppSection/sections/HowItWorksSection';
import { EarnToSpend } from './sections/EarnToSpend';
import { PrivacySection } from './sections/PrivacySection';
// import { FaqSection } from '../HowItWorksSection/sections/FaqSection';
import { CTASection } from './sections/CTASection';

export const HomePage = () => {
	return (
		<LandingPageLayout
			JumbotronSection={
				<HomeJumbotronSection/> 
			}
		>
			<HowItWorksSection shouldIgnoreParentPadding />
			<EarnToSpend />
			<PrivacySection />
			{/* <FaqSection /> */}
			<CTASection />
		</LandingPageLayout>
	);
};