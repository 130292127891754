export const links: { label: string; path: string }[] = [
	{
		label: 'Home',
		path: '/home',
	},
	{
		label: 'Explore',
		path: '/explore',
	},
	// {
	// 	label: 'Deals',
	// 	path: '/deals',
	// },
	// {
	// 	label: 'Contact',
	// 	path: '/contact',
	// },
	{
		label: 'Notifications',
		path: '/notifications',
	},
	{
		label: 'Notifications',
		path: '/notifications',
	},
];