import Scan from 'Assets/Images/Scan.png';
import EarnPoints from 'Assets/Images/EarnPoints.png';
import AnswerSurvey from 'Assets/Images/AnswerSurvey.png';
import KeepEarning from 'Assets/Images/KeepEarning.png';
import { IImageSlider } from './../Types/Interface/ILandingPage';
import MobileAnswerSurvery from 'Assets/Images/MobileAnswerSurvery.png';
import MobileEarnPoints from 'Assets/Images/MobileEarnPoints.png';
import MobileKeepEarning from 'Assets/Images/MobileKeepEarning.png';
import MobileScan from 'Assets/Images/MobileScan.png';

export const Carousels: IImageSlider[] = [
	{
		id: 0,
		label: 'Scan the QR',
		src: Scan,
		mobileSrc:MobileScan,
		text: 'Find the QR code inserted inside select products from participating brands, scan and immediately earn cash just for scanning!'
	},
	{
		id: 1,
		label: 'Complete the Survey',
		src: AnswerSurvey,
		mobileSrc: MobileAnswerSurvery,
		text: 'When you are ready, opt to complete a quick survey for more cash and help the brand understand what you think about the product you have purchased.'
	},
	{
		id: 2,
		label: 'Redeem & spend ANYWHERE',
		src: EarnPoints,
		mobileSrc:MobileEarnPoints,
		text: 'When you are ready, you can easily transfer your earnings directly to your bank or Paypal account'
	},
	
	{
		id:3,
		label: 'Keep Earning',
		src: KeepEarning,
		mobileSrc: MobileKeepEarning,
		text: 'Everytime you re-purchase the product and find a Loop QR code inside, scan again and earn more cash just for scanning. Keep earning as you discover more products partnering with Loop organically or via the app.'
	}
];