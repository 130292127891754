import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Heading, Spacer } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { IRouteState } from 'Types/Interface';
import { DashBoardLayout } from './DashBoardLayout';

interface IDashboardSettingLayoutProps {
	children: React.ReactNode;
}

export const DashboardSettingLayout = ({ children }: IDashboardSettingLayoutProps) => {
	const location = useLocation();
	const state = location.state as IRouteState;
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(AppRoutes.dashboard);
	};
	return (
		<DashBoardLayout>
			<Spacer h={12} />
			<Button fontSize='xs' color='#586068' leftIcon={<ChevronLeftIcon />} w='max-content' onClick={handleGoBack}>
				Back
			</Button>
			<Spacer h={5} />
			{state?.title && (
				<>
					<Heading size='md' color='#586068'>
						{state.title}
					</Heading>
					<Spacer h={10} />
				</>
			)}
			{children}
		</DashBoardLayout>
	);
};
