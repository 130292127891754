import { createApi } from '@reduxjs/toolkit/query/react';
import { TagType } from 'Types/Enums';
import { INotification } from 'Types/Interface/INotification';
import { baseQuery } from './history';
import { IPaginatedResponse } from './types';

export const notificationsApi = createApi({
	reducerPath: 'notificationsApi',
	baseQuery,
	tagTypes: [TagType.Notifications],
	endpoints: (builder) => ({
		getAllNotifications: builder.query<IPaginatedResponse<INotification[]>, { pageNumber?: number; pageSize?: number }>({
			query: ({ pageNumber, pageSize }) => `/Notification?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			keepUnusedDataFor: 0,
			providesTags: [TagType.Notifications]
		}),
		readNotification: builder.mutation<unknown, string[] | undefined>({
			query(body){
				return{
					url: '/Notification/read',
					method: 'POST',
					body
				};
			},
			invalidatesTags:[TagType.Notifications]
		}),
		deleteNotification: builder.mutation<unknown, string>({
			query(notificationId){
				return{
					url: `/Notification/${notificationId}`,
					method: 'DELETE'
				};
			},
			invalidatesTags:[TagType.Notifications]
		})
	})
});

export const{
	useGetAllNotificationsQuery, 
	useReadNotificationMutation, 
	// useReadAllNotificationsMutation,
	useDeleteNotificationMutation
} = notificationsApi;