import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import lock from 'Assets/svg/lock.svg';
import vault from 'Assets/svg/vault.svg';
import information from 'Assets/svg/information.svg';
import dataManagement from 'Assets/svg/dataManagement.svg';

export const LockIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={lock} ref={ref} {...props} />;
});

export const VaultIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={vault} ref={ref} {...props} />;
});

export const InformationIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={information} ref={ref} {...props} />;
});

export const DataManagementIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={dataManagement} ref={ref} {...props} />;
});