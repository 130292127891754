import * as Yup from 'yup';
import valid from 'card-validator';

import { IPaymentMethods } from 'Types/Interface/IUser';

export const initialValues ={
	firstName: '', 
	lastName: '', 
	cardNumber: '', 
	cardType: '',
	default: false,
	expiryDate: '', 
	cvv: '',  
	country: '',
	address: '',
	city: '',
	state: '',
	zipCode: '',
}as IPaymentMethods;

export const validationSchema = Yup.object().shape({
	firstName: Yup.string().min(3).required('Required'),
	lastName: Yup.string().min(3).required('Required'),
	cardNumber: Yup.string()
		.test('test-number',
			'Card number is invalid', 
			value => valid.number(value).isValid)
		.required(),
	expiryDate: Yup.string().test('test-card-expiration-date',
		'Expiration date is invalid or card expired',
		value => valid.expirationDate(value).isValid
	).required(),
	cvv: Yup.string().test('test-card-cvv',
		'Cvv is invalid',
		value => valid.cvv(value).isValid
	).required(),
	country: Yup.string().min(3).required('Required'),
	address: Yup.string().min(3).required('Required'),
	city: Yup.string().min(3).required('Required'),
	state: Yup.string().min(3).required('Required'),
	zipCode: Yup.string().test('test-postal-code',
		'Invalid zip code!',
		value => valid.postalCode(value).isValid
	).required()
});