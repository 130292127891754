import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import vector from 'Assets/svg/Login/Vector.svg';

export const LoopVectorIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img  src={vector} ref={ref} {...props} />;
});
