import { createApi } from '@reduxjs/toolkit/query/react';
import { IImageUploadResponse, IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IUserInfo, IUserSummary } from 'Types/Interface/IUser';
import { baseQuery } from './history';

export const userAPI = createApi({
	reducerPath: 'userAPI',
	baseQuery,
	endpoints: (builder) => ({
		getUserProfile: builder.mutation<IJsonResponse<IUserInfo>, void>({
			query: () => {
				return {
					url: 'user/profile',
					method: 'GET',
				};
			}
		}),
		getAllUsers: builder.query<IJsonResponse<IUserInfo[]>, number>({
			query: (pageNumber) => `user/all?pageNumber=${pageNumber}`,
		}),
		getUserSummary: builder.mutation<IJsonResponse<IUserSummary>, void>({
			query() {
				return {
					url: 'user/summary',
					method: 'GET',
				};
			},
		}),
		redeemPoints: builder.mutation<IJsonResponse<IImageUploadResponse>, number>({
			query(totalPoints) {
				return {
					url: `user/redeem?totalPoints=${totalPoints}`,
					method: 'POST',
				};
			},
		}),
		sendEmailOtp: builder.mutation<IJsonResponse<void>, string>({
			query(email) {
				return {
					url: `user/account_identifier?accountIdentifier=${email}`,
					method: 'PUT',
				};
			},
		}),
		resendEmailOtp: builder.mutation<IJsonResponse<void>, void>({
			query() {
				return {
					url: 'user/resend_account_identifier',
					method: 'PUT',
				};
			},
		}),
		verifyEmailWithOtpCode: builder.mutation<IJsonResponse<void>, string>({
			query(code) {
				return {
					url: `user/verify_email?verificationCode=${code}`,
					method: 'PUT',
				};
			},
		}),
	}),
});

export const {
	useGetUserProfileMutation,
	useGetUserSummaryMutation,
	useRedeemPointsMutation,
	useGetAllUsersQuery,
	useSendEmailOtpMutation,
	useResendEmailOtpMutation,
	useVerifyEmailWithOtpCodeMutation
} = userAPI;
