import React from 'react';
import { useAppSelector } from 'Hooks';
import { SurveyContainer, SurveyDetails, SurveyQuestion } from './components';

export const NewSurvey = () => {
	const {
		activeSurvey: { currentQuestion: currentQuestionIndex, survey, responses },
		isStartSurvey
	} = useAppSelector((state) => state.survey);
	
	const isNewSurvey = !responses || responses.length === 0;
	const currentQuestion = survey?.questions[currentQuestionIndex];

	return (
		<SurveyContainer>
			{isNewSurvey && !isStartSurvey ? (
				<SurveyDetails />
			) : (
				<SurveyQuestion question={currentQuestion} />
			)}
		</SurveyContainer>
	);
};
