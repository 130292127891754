import { motion } from 'framer-motion';
import React from 'react';

interface IFadeInContainerProps {
  children: React.ReactNode;
}

export const FadeInContainer = ({ children }: IFadeInContainerProps) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1 }}
		>
			{children}
		</motion.div>
	);
};
