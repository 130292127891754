import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import {useAnimation} from 'framer-motion';

import { MotionBox } from '../BusinessSection/BusinessSection';

type FadeOnScrollPropType = {
    children: React.ReactNode
}

export const FadeOnScroll = ({children }: FadeOnScrollPropType) => {
	const [ref, inView] = useInView({
		threshold: .2,
		triggerOnce: false,
	});

	const animation = useAnimation();
	const FadeUpVariant = {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				duration: .5,
				ease: 'linear'
			},
		},
	};
	useEffect(() => {
		if(inView){
			animation.start('visible');
		}
		
	}, [inView]);

	return (
		<MotionBox
			ref={ref}
			variants={FadeUpVariant} 
			initial='hidden' 
			animate={animation}
		>
			{children}
		</MotionBox>
	);
};

