import React from 'react';
import { Box } from '@chakra-ui/react';
import { Navbar } from 'Pages/Dashboard/Components/Navbar/Navbar';
import { ScrollUpBtn } from 'Components/ScrollUpBtn';
import { Footer } from 'Pages/LandingPage/HomeSection/sections/Footer';
  interface IExplorePageLayoutProps {
	JumbotronSection: React.ReactNode,
	children: React.ReactNode;
	linkColorMode?: 'light' | 'black'
}

export const ExplorePageLayout = ({
	JumbotronSection, 
	children, 
	linkColorMode 
}:IExplorePageLayoutProps) => {
	return (
		<Box overflowX="hidden">
			<Box 
				py="8" px={['8', 6, '20']} 
				bgColor= '#FFF6F5'
				bgSize="cover"
				height={['40%','30%','60%']}
			>
				<Navbar linkColorMode={linkColorMode} inputlinkColorMode={'light'}/>
				{JumbotronSection}
			</Box>
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					if (child.props.shouldIgnoreParentPadding) {
						return (
							<Box  maxW={'1440px'} m={'0 auto'}>
								{child}
							</Box>
						);
					}

					return (
						<Box maxW={'1440px'} m={'0 auto'} px={['8', '6', null, '20', ]} my={['8', null]}>
							{child}
						</Box>
					);
				}
				return child;
			})}
			<Footer />
			<ScrollUpBtn />	
		</Box>
	);
};