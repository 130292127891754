import { Stack, Image, Text } from '@chakra-ui/react';
import React from 'react';
import errorIcon from 'Assets/svg/errorIcon.svg';

interface IFirstScanModalProp{
    errorText: string;
}

export const DynamicProductScanErrorModal = ({errorText}:IFirstScanModalProp) => {
	return(
		<Stack p="8" py='6'  align={'center'} justify={'center'} textAlign={'center'}>
			<Image src={errorIcon} w={'50px'} h={'50px'} />
			<Text color={'blackTextColor.200'} fontSize={'18px'} fontWeight={600}>{errorText}</Text>
			<Text color={'#525252'} fontSize={'16px'}>Please check the scanned QR code and try again</Text>
		</Stack>
	);
};