import React, { ReactNode } from 'react';
import {
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	Box,
} from '@chakra-ui/react';

type CustomDrawerProps = {
  children: React.ReactNode;
  buttonContent: React.ReactNode;
  title: ReactNode;
  isFull?: boolean;
};

export const CustomDrawer = ({
	children,
	buttonContent,
	title,
	isFull,
}: CustomDrawerProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef(null);
	return (
		<>
			<Box ref={btnRef} onClick={onOpen}>
				{buttonContent}
			</Box>
			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
				finalFocusRef={btnRef}
				{...isFull && ({size: 'full'})}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{title}</DrawerHeader>

					<DrawerBody>{children}</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
};
