import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/DrugIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const DrugIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const DrugIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M16.4085 2.5913C15.9635 2.14625 15.4353 1.79321 14.8538 1.55234C14.2724 1.31147 13.6493 1.1875 13.0199 1.1875C12.3906 1.1875 11.7674 1.31147 11.186 1.55234C10.6046 1.79321 10.0764 2.14625 9.63141 2.5913L2.59108 9.63163C1.69238 10.5303 1.1875 11.7492 1.1875 13.0202C1.1875 14.2911 1.69238 15.51 2.59108 16.4087C3.48977 17.3074 4.70866 17.8123 5.97961 17.8123C7.25055 17.8123 8.46944 17.3074 9.36814 16.4087L16.4085 9.36836C16.8535 8.92341 17.2066 8.39514 17.4474 7.81373C17.6883 7.23232 17.8123 6.60916 17.8123 5.97983C17.8123 5.3505 17.6883 4.72734 17.4474 4.14593C17.2066 3.56452 16.8535 3.03625 16.4085 2.5913ZM8.52857 15.5691C8.19384 15.9039 7.79645 16.1694 7.3591 16.3505C6.92175 16.5317 6.45299 16.6249 5.97961 16.6249C5.02356 16.6249 4.10667 16.2452 3.43064 15.5691C2.75461 14.8931 2.37482 13.9762 2.37482 13.0202C2.37482 12.0641 2.75461 11.1472 3.43064 10.4712L6.53102 7.37087L11.6289 12.4687L8.52857 15.5691ZM15.5689 8.5288L12.4685 11.6291L7.37064 6.53125L10.471 3.43086C10.8057 3.09613 11.2031 2.8306 11.6404 2.64944C12.0778 2.46829 12.5465 2.37505 13.0199 2.37505C13.4933 2.37505 13.9621 2.46829 14.3994 2.64944C14.8368 2.8306 15.2342 3.09613 15.5689 3.43086C15.9036 3.7656 16.1692 4.16299 16.3503 4.60034C16.5315 5.03769 16.6247 5.50644 16.6247 5.97983C16.6247 6.45322 16.5315 6.92197 16.3503 7.35932C16.1692 7.79668 15.9036 8.19406 15.5689 8.5288Z" fill={color}/>
		</Icon>
	);
};