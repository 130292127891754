import React from 'react';
import { Box, Divider, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { IHistory } from 'Types/Interface/IHistory';

interface IHistoryModalContentProps {
    data: IHistory
}

export const HistoryModalContent = ({ data }: IHistoryModalContentProps) => {
	return (
		<Box>
			{/* <Box
				backgroundImage={data.imageUrl}
				backgroundSize='cover'
				backgroundPosition='center'
				backgroundRepeat='no-repeat'
				height={350}/> */}
			<Image 
				src={data?.imageUrl} 
				alt='product image' 
				objectFit='cover'
				width='full'
				// fallback={<>Hello</>}
				fallbackSrc='https://via.placeholder.com/360'
				height={300} />
			<SimpleGrid columns={2}  py={5}>
				<Box p={4} pl={[14,24]}  height='80px'>
					<Stack borderRight={'1px'}  borderColor={'#E1E7EA'}>
						<Text  color="#586068" fontSize='sm'>Status</Text>
						<Text color="loop.500">Scanned</Text>
					</Stack>
				</Box>
				<Box p={4} pl={[4, 14]} height='80px'>
					<Stack>
						<Text color="#586068" fontSize='sm'>By</Text>
						<Text color="#121B26" fontWeight='bold'>{data?.brandName}</Text>
					</Stack>
				</Box>
			</SimpleGrid>
			<Divider borderBottomWidth={'4px'} bg='#ECEFF2' />
			<SimpleGrid columns={2} py={5}>
				<Box p={4} pl={[14, 24]}  height='80px'>
					<Stack  borderRight={'1px'} borderColor={'#E1E7EA'}>
						<Text color="#121B26" fontSize='sm' fontWeight='bold'>Earnings</Text>
						<Text color="#586068" fontSize='sm'>You earned</Text>
						<Text color="#586068" fontSize='sm'>{data?.points} LNT</Text>
					</Stack>
				</Box>
				<Box p={4} pl={[4, 14]}  height='80px'>
					<Stack>
						<Text color="#121B26" fontSize='sm' fontWeight='bold'>Data Sharing</Text>
						<Text color="#586068" fontSize='sm'>Privacy profile selected:</Text>
						<Text color="#586068" fontSize='sm'>{data?.privacyOption}</Text>
					</Stack>
				</Box>
			</SimpleGrid>
		</Box>
	);
};