import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/PoliticsIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const PoliticsIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const PoliticsIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M15.0881 3.35294H13.3262L12.7673 0H10.6175V1.11765H11.8205L12.1931 3.35294H6.80656L7.17911 1.11765H8.38218V0H6.23231L5.67349 3.35294H3.91159H2.79395V4.47059H3.91159V12.2941H5.02924V19H13.9704V12.2941H15.0881V4.47059H16.2057V3.35294H15.0881ZM12.8528 17.8824H6.14689V16.7647H12.8528V17.8824ZM12.8528 15.6471H6.14689V12.2941H12.8528V15.6471ZM7.26453 11.1765V6.70588H11.7351V11.1765H7.26453ZM13.9704 11.1765H12.8528V5.58824H6.14689V11.1765H5.02924V4.47059H13.9704V11.1765Z" fill={color}/>
		</Icon>
	);
};