/* eslint-disable max-lines */
import React, { useContext, useEffect } from 'react';
import {
	Box,
	Button,
	Flex,
	IconButton,
	VStack,
	Text,
	// Checkbox,
	Link,
	Divider,
	Heading,
	Switch,
	HStack,
} from '@chakra-ui/react';
import { Link as RLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { CustomInput } from 'Components/FormInput/CustomInput';
import { AuthLayout } from 'Layouts/AuthLayout';
import { AppRoutes } from 'Routes/AppRoutes';
import { useLogin } from 'Hooks';
import { useToast } from 'Hooks/useToast';
import { AuthContext, IAuthenticateUserResponse } from 'context/AuthContext';
import { useSocialLogin } from 'Hooks/useSocialLogin';
import { initialValues, validationSchema } from './utils';
import { GoogleLogin, GoogleLoginResponseOffline } from 'react-google-login';
import {  GoogleIcon, LoopVectorIcon } from 'Components/Icons';
// import { ReactFacebookLoginInfo } from 'react-facebook-login/dist/facebook-login-render-props';
import { PhoneNumberInput } from 'Components/FormInput/PhoneNumberInput';
import { ILoginDetails } from 'Types/Interface/IUser';
import { useDefaultErrorHandler } from 'Utils/errorHandling';

export const LoginPage = () => {
	const { alert } = useToast();
	const navigate = useNavigate();
	const auth = useContext(AuthContext);
	const { handleGoogleLogin, isLoading: isSocialLoginLoading } = useSocialLogin(); // return this (handleFacebookLogin)

	const {
		mutate: login,
		isLoading,
		isSuccess: isPhoneLoginSuccess,
		data,
		isError,
		error
	} = useLogin();

	const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			const LoginData: ILoginDetails = {
				password: values.password,
				phoneNumber: `+${values.phoneNumber}`
			};
			login(LoginData);
		},
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onGoogleSuccess = (response: any) => {
		// TODO : add proper types
		const { tokenObj, profileObj } = response;
		handleGoogleLogin(tokenObj?.id_token, profileObj?.email);
	};
	const onFailure = (response: GoogleLoginResponseOffline) => {
		console.error('error', response);
	};

	// const responseFacebook = (userInfo: ReactFacebookLoginInfo) => {
	// 	handleFacebookLogin(userInfo?.accessToken);
	// };

	useEffect(() => {
		if (isPhoneLoginSuccess) {
			alert('success', 'Login was successful');
			setTimeout(() => {
				navigate(AppRoutes.home);
			}, 500);
			auth.setAuthInfo(data as IAuthenticateUserResponse);
		}
	}, [isPhoneLoginSuccess]);

	useDefaultErrorHandler(isError, error);

	useEffect(() => {
		if (data?.accessToken) {
			auth.setAuthInfo(data as IAuthenticateUserResponse);
		}
	},[data]);

	return (
		<AuthLayout isLoading={isSocialLoginLoading}>
			<Flex
				alignItems={['flex-start', 'center']}
				h={'auto'}
				justifyContent={['center', 'center']}
				flex={1}
				w={['auto','100%']}
				m={['20px', '10']}
				position={['absolute', 'relative']}
				left={0}
				right={0}
				bgColor={['#FAFAFA', 'white']}
				border={['2px solid rgba(88, 96, 104, 0.07)', 'none']}
				borderRadius={['20px', 'none']}
				direction={['column', 'row']}
				py={['30px', 0]}
			>
				<LoopVectorIcon display={['flex', 'none']} pl={['5', '0']} mb={'30px'} />
				<Box
					p={[0,'10']}
					px={['5','10']}
					borderRadius='10px'
					boxShadow={['none','0px 0px 90px rgba(0, 0, 0, 0.1)']}
					w={['100%', '450px']}
				>
					<Heading alignSelf='flex-start' fontSize='xl' mb='4'>
            Sign in
					</Heading>
					<Text fontSize='sm' mb='10'>
            Welcome, enter your credentials
					</Text>
					<VStack w={'100%'} spacing={3} mb='7' alignItems='flex-start'>
						<PhoneNumberInput
							onChange={(value) => setFieldValue('phoneNumber', value)}
							value={values.phoneNumber}
							error={errors.phoneNumber}
						/>

						<CustomInput
							onChange={handleChange}
							name='password'
							type='password'
							placeholder='Password'
							error={errors.password}
						/>
					</VStack>
					<Button
						isLoading={isLoading}
						variant='primary'
						w={'full'}
						mb='20px'
						onClick={() => handleSubmit()}
					>
            Login
					</Button>
					<Flex w={'100%'} mb={'30px'} justifyContent='space-between'>
						<HStack>
							<Switch variant={'primary'} />
							<Text fontSize='xs'>Stay signed in</Text>
						</HStack>
						
						<Link
							as={RLink}
							to={AppRoutes.resetPassword}
							ml='1'
							fontSize='sm'
						>
							Forget password?
						</Link>
					</Flex>
					<Flex alignItems='center'>
						<Text fontSize='xs'>Don&apos;t have an account? </Text>
						<Link
							as={RLink}
							to={AppRoutes.register}
							ml='1'
							fontSize='xs'
							fontWeight={600}
							color='darkCoral'
						>
              Sign up
						</Link>
					</Flex>
					<Flex my={'20px'} justifyContent={'space-between'} alignItems={'center'}>
						<Divider w={['150px','195px']} />
						<Text mx={['9px','20px']}> OR </Text>
						<Divider w={['150px','195px']} />
					</Flex>
					
					<Flex 
						border={'0.5px solid rgba(88, 96, 104, 0.50)'} 
						borderRadius={'100px'} 
						h={['48px','50px']} w={'100%'}
						justifyContent={'center'}
						alignItems={'center'}
						cursor={'pointer'}
					>
						<GoogleLogin
							clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
							render={(renderProps) => (
								<Flex justifyContent={'center'} alignItems={'center'}>
									<IconButton
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
										borderRadius='50%'
										h='26px'
										mr='10px'
										aria-label={'google'}
										icon={<GoogleIcon h='100%' />}
									/>
									<Text onClick={renderProps.onClick}>Continue using Google</Text>
								</Flex>
							)}
							onSuccess={onGoogleSuccess}
							onFailure={onFailure}
							cookiePolicy={'single_host_origin'}
						/>
							
					</Flex>

					{/* <FacebookLogin
						appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
						callback={responseFacebook}
						fields='name,email,picture'
						scope="public_profile,email"
						render={(renderProps: RenderProps) => (
							<IconButton
								onClick={renderProps.onClick}
								borderRadius='50%'
								h='30px'
								mr='4'
								aria-label={'facebook'}
								icon={<FacebookIcon h='100%' />}
							/>
						)}
					/> */}
				</Box>
			</Flex>
		</AuthLayout>
	);
};
