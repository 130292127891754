import React, { useEffect, useState } from 'react';
import {
	Box,
	Divider,
	Heading,
	HStack,
	Link as ChakraLink,
	Skeleton,
	Stack,
	Switch,
	Text,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';
import { DashboardSettingLayout } from 'Layouts';
import { useGetBrandLoopDetailsQuery, useUpdateUserBrandPermissionMutation } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { AppRoutes } from 'Routes/AppRoutes';
import { BrandLoopStatusComponent, formatDateShort } from 'Utils/dataTableUtils';
import { BrandPermissions } from 'Pages/Survey/components/BrandPermissions';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';
import { useAppSelector } from 'Hooks';
import { ProductActivities } from './ProductActivities';

interface ICellProps {
	label: string;
	data: number | string | JSX.Element;
}

export const BrandLoopDetails = () => {
	const location = useParams();
	const brandId = location?.brandId || '';

	const { userInfo } = useAppSelector((state) => state.authUser);

	const { data: brandLoopDetailsData, error, isError, isLoading: isDataLoading } = useGetBrandLoopDetailsQuery(brandId);
	const [updateUserBrandPermission, { error: updateError, isError: isUpdateError }] =
		useUpdateUserBrandPermissionMutation();

	useDefaultErrorHandler(isError, error);
	useDefaultErrorHandler(isUpdateError, updateError);

	const [cells, setCells] = useState<ICellProps[]>([]);

	const [userBrandPermissions, setUserBrandPermissions] = useState<IUserBrandPermission>({
		userId: userInfo?.id || '',
		brandId: brandId,
		isBrandLooped: false,
		allowContactViaEmail: false,
		allowContactViaSms: false,
		allowContactViaWhatsapp: false,
		allowAdTargeting: false,
		allowViewPurchaseHistory: false,
		allowContactViaLoop: false,
	});

	useEffect(() => {
		if (brandLoopDetailsData?.data?.userBrandPermission) {
			setUserBrandPermissions(brandLoopDetailsData.data.userBrandPermission);
		}
	}, [brandLoopDetailsData]);

	useEffect(() => {
		if (brandLoopDetailsData) {
			const brandLoopDetails = brandLoopDetailsData.data;
			const updatedCells = [
				{ label: 'Total scanned', data: brandLoopDetails.scansCount },
				{ label: 'Surveys completed', data: brandLoopDetails.surveysCount },
				{ label: 'Total earned', data: brandLoopDetails.totalEarned + ' LNT' },
				{
					label: 'Highest engagement',
					data: (
						<ChakraLink
							as={ReactRouterLink}
							to={AppRoutes.productPage.replace(':productId', brandLoopDetails.highestEngagementProduct?.id ?? '')}
							textDecoration={'underline'}
							_hover={{ textDecoration: 'none' }}
						>
							{brandLoopDetails.highestEngagementProduct?.name}
						</ChakraLink>
					),
				} as ICellProps,
				{ label: 'Last activity', data: formatDateShort(brandLoopDetails.lastActivityDate) },
			];

			setCells(updatedCells);
		}
	}, [brandLoopDetailsData]);

	const handlePermissionChange = (permissions: IUserBrandPermission) => {
		setUserBrandPermissions(permissions);
		updateUserBrandPermission(permissions);
	};

	const handleLoopedChange = (looped: boolean) => {
		const permissions: IUserBrandPermission = { ...userBrandPermissions, isBrandLooped: looped };

		if (!looped) {
			permissions.allowAdTargeting = false;
			permissions.allowContactViaEmail = false;
			permissions.allowContactViaSms = false;
			permissions.allowContactViaWhatsapp = false;
			permissions.allowAdTargeting = false;
			permissions.allowViewPurchaseHistory = false;
			permissions.allowContactViaLoop = false;
		}

		handlePermissionChange(permissions);
	};

	return (
		<DashboardSettingLayout>
			<Heading fontSize={['lg', null, '3xl']} color={'loopGrey.500'} mb={6}>
				Your{' '}
				<Text as='span' color='darkCoral'>
					Loop
				</Text>{' '}
				with {brandLoopDetailsData?.data?.brandName}
			</Heading>
			<Text fontSize={['xs', null, 'sm']} lineHeight={['6']} mb={[8, null, null, 10]}>
				Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo
				con sequat.
			</Text>
			{isDataLoading && (
				<Box w='100%'>
					<Stack spacing={5} w='100%'>
						{new Array(2).fill('_').map((_, index) => (
							<Skeleton height='80px' key={index} />
						))}
					</Stack>
				</Box>
			)}
			<Wrap spacing={5}>
				{cells.map((cell) => (
					<WrapItem key={cell.label} w='30%'>
						<Stack>
							<Text fontSize={['sm', null, 'lg']}>{cell.label}</Text>
							<Box fontSize={['sm', null, '2xl']} fontWeight={600}>
								{cell.data}
							</Box>
						</Stack>
					</WrapItem>
				))}
			</Wrap>
			<Divider my={8} />
			<Heading fontSize={['md', null, '2xl']} mb={5}>
				Loop status
			</Heading>
			<Text fontSize={['xs', null, 'sm']} lineHeight={['6']} mb={8}>
				Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo
				con sequat.
			</Text>{' '}
			<HStack spacing={3}>
				<Switch
					variant='primary'
					isChecked={userBrandPermissions.isBrandLooped}
					onChange={(event) => handleLoopedChange(event.target.checked)}
				/>
				<BrandLoopStatusComponent
					fontSize={['md', null, 'lg']}
					showCircle={false}
					isLooped={userBrandPermissions.isBrandLooped}
				/>
				{userBrandPermissions.isBrandLooped && brandLoopDetailsData?.data.userBrandPermission?.loopedDate && (
					<Box color={'loopGrey.500'} opacity={'30%'} fontStyle={'italic'} fontSize={['md', null, 'lg']} pl={4}>
						{formatDateShort(brandLoopDetailsData?.data.userBrandPermission?.loopedDate)}
					</Box>
				)}
			</HStack>
			<Divider my={8} />
			{brandLoopDetailsData?.data && (
				<>
					<Heading fontSize={['md', null, '2xl']} mb={5}>
						Contact preferences
					</Heading>
					<BrandPermissions
						brandName={brandLoopDetailsData.data.brandName}
						permissions={userBrandPermissions}
						isDisabled={!userBrandPermissions.isBrandLooped}
						onUpdate={handlePermissionChange}
					/>
				</>
			)}
			<Heading fontSize={['md', null, '2xl']} mt={7} mb={[0, null, 7]}>
				Your activity with {brandLoopDetailsData?.data.brandName}
			</Heading>
			<ProductActivities brandId={brandId} />
		</DashboardSettingLayout>
	);
};
