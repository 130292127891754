import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/cubesIcon.svg';

export const CubesIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
}); 