import { createApi } from '@reduxjs/toolkit/query/react';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IBrandHistory } from 'Types/Interface/IBrand';
// import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IProduct, IProductDetails } from 'Types/Interface/IProduct';
import { baseQuery } from './history';
import { IPaginatedResponse } from './types';

export const productApi = createApi({
	reducerPath: 'productApi',
	baseQuery,
	tagTypes: ['Product'],
	endpoints: (builder) => ({
		getProductDetails: builder.query<IProductDetails, string>({
			query: (productId) => `product/${productId}`,
			providesTags: ['Product']
		}),
		getProductsByBrandNAme: builder.query<IProductDetails[], string>({
			query: (brandName) => `product/getProductsByBrandName/${brandName}`,
		}),
		getAllProducts: builder.query<IPaginatedResponse<IProduct[]>, {pageNumber?:number, pageSize?:number, categoryId?:string}>({
			query: ({pageNumber, pageSize, categoryId}) =>  `/product/getproducts?categoryId=${categoryId}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
		}),
		getProductByCategory: builder.query<IJsonResponse<IProduct[]>, string>({
			query: (categoryId) => `categories/${categoryId}/products`,
		}),
		getAvailableSurveysQrCodesForProduct: builder.query<IJsonResponse<string[]>, string>({
			query: (productId) => `product/available-surveys-qrcodes/${productId}`,
		}),
		favoriteProduct: builder.mutation<unknown, string | undefined>({
			query(productId) {
				return {
					url: `product/${productId}/favorite`,
					method: 'POST',
				};
			},
			invalidatesTags: ['Product']
		}),
		unFavoriteProduct: builder.mutation<unknown, string>({
			query(productId) {
				return {
					url: `product/${productId}/unfavorite`,
					method: 'POST',
				};
			},
			invalidatesTags: ['Product']
		}),
		getProductScanHistory: builder.query<IJsonResponse<IBrandHistory>, string>({
			query: (productId) => `survey/${productId}/productscanhistory`
		})
	}),
});

export const {
	useGetProductDetailsQuery,
	useGetAllProductsQuery,
	useGetProductByCategoryQuery,
	useFavoriteProductMutation,
	useUnFavoriteProductMutation,
	useGetProductScanHistoryQuery,
	useGetAvailableSurveysQrCodesForProductQuery,
} = productApi;