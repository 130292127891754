import React, { useEffect, useRef } from 'react';
import { Avatar, CircularProgress, IconButton, Input } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useToast } from 'Hooks';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useAvatarUpload } from 'Hooks/useAvatarUpload';

export const AvatarUpload = () => {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const { alert } = useToast();

	const {
		handleImageUpload, 
		isImageUploadError, 
		isImageUploadSuccess, 
		imageUploadError, 
		imageUploadLoading,
		data
	} = useAvatarUpload();
	
	useEffect(() => {
		if (isImageUploadSuccess) {
			alert('success', 'Profile picture uploaded');
		}
	}, [isImageUploadSuccess]);

	useDefaultErrorHandler(isImageUploadError, imageUploadError);

	return (
		<Avatar size="2xl" src={data} loading="lazy">
			<Input
				type="file"
				display="none"
				onChange={handleImageUpload}
				ref={fileInputRef}
			/>
			<IconButton
				aria-label=""
				position="absolute"
				icon={imageUploadLoading ? <CircularProgress isIndeterminate color='#FF6F61' size='15px'/> : <AddIcon w={3} h={3} color="#FF6F61" />}
				bg="#ECEFF2"
				borderRadius="50%"
				p={2}
				bottom={0}
				right={0}
				borderColor="white"
				borderWidth={1}
				onClick={() => fileInputRef && fileInputRef.current?.click()}

			/>
		</Avatar>
	);
};
