import React from 'react';
import { Box, Button, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { MegaPhoneIcon } from 'Components/Icons';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';

interface ICTACardProps {
	currentSlide: number;
	navigateCarousel: (index: number) => void;
}

interface IDotProps {
	onClick: (index: number) => void;
	index: number;
	current: number;
}

const Dot = ({ index, onClick, current }: IDotProps) => {
	const isActive = index === current;
	return (
		<Box
			cursor='pointer'
			w={2}
			h={2}
			bg={`${isActive ? '#fff' : 'rgba(88, 96, 104, 0.4)'}`}
			borderRadius='50%'
			onClick={() => onClick(index)}
		/>
	);
};

export const CTACard = ({
	currentSlide,
	navigateCarousel,
}: ICTACardProps) => {
	const sliderVariant = {
		initial: { x: 0 },
		animate: {
			x: `-${currentSlide * 300}px`,
			transition: {
				type: 'spring',
				stiffness: 250,
				damping: 30,
			},
		},
	};
	return (
		<MotionBox
			bg='#F28482'
			variants={sliderVariant}
			initial='initial'
			animate='animate'
			p={'40px'}
			h={'370px'}
			w={'300px'}
			borderRadius={8}
			position='relative'
			overflow='hidden'
		>
			<Box
				height={52}
				w={52}
				borderRadius='50%'
				bg='#FF6F61'
				position='absolute'
				right={-10}
				bottom={-10}
			/>
			<Stack height='300px' width='200px' spacing={4} justify='center' >
				<MegaPhoneIcon height={'40px'} width={'40px'} />
				<Heading fontWeight={700} fontSize={'30px'} color='#fff'>
					Refer & Earn
				</Heading>
				<Text color='#fff' lineHeight={'25px'} zIndex={999999} >
					Lorem ipsum dolor sit amet, ets consectetur adipiscing elit, sed do
					eiusmod tempor incididunt
				</Text>
				<Button
					w='max-content'
					color='#fff'
					borderColor='#fff'
					p={3}
					borderRadius='full'
					borderWidth={1}
				>
					Refer and Earn
				</Button>
				<HStack spacing={'10px'}>
					{new Array(3).fill('dot').map((value, index) => (
						<Dot
							key={value + index}
							index={index}
							current={currentSlide}
							onClick={navigateCarousel}
						/>
					))}
				</HStack>
			</Stack>
		</MotionBox>
	);
};
