import React from 'react';
import { preferenceDetails } from 'Constants';
import {  DashboardSettingLayout } from 'Layouts';
import { DashboardLayoutWithDescription } from 'Pages/Dashboard/Components/LayoutWithDescription';
import { Content } from './Content';

export const Preferences = () => {

	return (
		<DashboardSettingLayout>
			<DashboardLayoutWithDescription
				content={<Content/>}
				description={preferenceDetails}/>
		</DashboardSettingLayout>
	);
};
