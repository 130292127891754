import React from 'react';
import {
	Box
} from '@chakra-ui/react';
import { Navbar } from 'Pages/Dashboard/Components/Navbar/Navbar';
import { ScrollUpBtn } from 'Components/ScrollUpBtn';
import { QrCodeScanner } from 'Components/QrCodeScanner';
import { Footer } from 'Pages/LandingPage/HomeSection/sections/Footer';

interface IHomePageLayoutProps {
  JumbotronSection: React.ReactNode;
	StickyNotificationSection: React.ReactNode;
  children: React.ReactNode;
  disableGradientBackground?: boolean;
}

export const HomePageLayout = ({
	JumbotronSection,
	StickyNotificationSection,
	children,
}: IHomePageLayoutProps) => {
	
	return (
		<Box overflow='hidden'>
			<QrCodeScanner />
			<Box pt='8' pb='0' px={['5', null, 6, '20']}>
				<Navbar linkColorMode={'black'} inputlinkColorMode={'light'} />
			</Box>
			{StickyNotificationSection}
			<Box pt='0' pb='8' px={['5', null, 6, '20']}>
				<Box maxW={'1440px'} m={'0 auto'} px={{ '2xl': '20' }}>
					{JumbotronSection}
				</Box>
			</Box>
			<Box maxW={'1440px'} m={'0 auto'} px={['8', '6', null, '20']}>
				{children}
			</Box>
			<Footer />
			<ScrollUpBtn />
		</Box>
	);
};
