import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import check from '../../Assets/svg/check.svg';

export const RequestSuccessfulIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={check} ref={ref} {...props} />;
}); 