import React from 'react';

import { Flex, Heading, Text, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { steps } from 'Constants/EarnStepsConstant';


export const EarnSection = () => {
	return (
		<Flex 
			direction={{base:'column', tablet:'column', xxl:'column'}}   
		>
			<Flex
				direction={{base:'column', tablet:'column', xxl:'column'}}  
				py={{ base: '10', tablet: '8' }} 
				// px={{ base: '6', tablet: '10', xxl:'16' }} 
			>
				<Heading
					my={6} 
					fontSize={{ base: '2xl', tablet: '2xl', xxl: '2xl' }}
					fontWeight={'600'}
				>
					Earning with Loop
				</Heading>
				<Text
					w={{base:'auto', tablet:'auto', xxl:'3xl'}}
					fontSize={'sm'} 
					fontWeight={400} 
					color={'#586068'}
				>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
					sed do eius mod tempor incididunt ut labore et dolore magna. 
					Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eius mod 
				</Text>
			</Flex>
			<Flex 
				// mx={{sm:6,tablet:10,xxl:16}}
				// ml={{md:-6, lg: -10, xxl:-20, xl:-32}}
				py={8}
				justifyContent={'space-between'}
				direction={{base: 'column', tablet:'row', xxl:'row'}}  
			>
				{steps.map(step => {
					return(
						<Flex 
							direction={{base:'column', tablet:'column', xxl:'column'}}  
							mr={6}
							key={step.title}>
							<Image  mb={4} w={10} src={step.src} />
							<Text 
								mb={4}
								fontSize={'md'}
								fontWeight={'bold'}
							>
								{step.title}
							</Text>
							<Text
								// px={'4'}
								w={'auto'} mb={6}
								textAlign={'left'}
								color={'#121B26'}
								fontWeight={'400'}
								fontSize={'sm'}
								lineHeight={'6'}
							>
								{step.subtext}
							</Text>
						</Flex>
					);
				})}
			</Flex>
			<Text 
				py={10}
				// px= {{base:2, tablet:0}}
				w={'auto'} 
				// px={{ base: '6', tablet: '10', xxl:'16' }} 
				color={'#121B26'}
				fontWeight={'400'}
				fontSize={'md'}
				display={['none', 'block']}
			>
				* Loop lorem ipsum dolor sit amet agreements are not regulated by the FCA. 
				Not following the terms and conditions may affect your ability to use Loop in the future. 18+, UK residents only. 
				Subject to status. T&Cs apply. 
				<Link to={'https://www.feedtheloop.com/uk/trms-and-conditions/'}>
					https://www.feedtheloop.com/uk/terms-and-conditions/
				</Link>
			</Text>
		</Flex>
	);
};