import { useUpdateUserAvatar } from 'Hooks';


export const useAvatarUpload = () => {
	const {
		mutate: uploadImage,
		data,
		isLoading:imageUploadLoading,
		isSuccess:isImageUploadSuccess,
		isError:isImageUploadError,
		error:imageUploadError,
	} = useUpdateUserAvatar();

	const handleImageUpload = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const files = event.target.files;
    
		if (files && files[0]) {
			const img = files[0];
			const formData = new FormData();
			formData.append('file', img);
			formData.append('name', img.name);
    
			try {
				uploadImage(formData);
			} catch (error) {
				console.error('error', error);
			}
		}
	};

	return{handleImageUpload, isImageUploadError, isImageUploadSuccess, imageUploadError, imageUploadLoading, data};
};
