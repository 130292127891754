import React from 'react';
import { TermsConstant } from 'Constants/TermsConstant';
import  {useInView}  from 'react-intersection-observer';
import {  Box, Button,  
	Heading, 
	Modal, 
	ModalBody,  
	ModalContent, 
	ModalFooter,  
	ModalOverlay,  
	Stack, 
	Text, 
} from '@chakra-ui/react';


interface ITermsOfServiceProps{
	isOpen: boolean;
	onClose: () => void;
	handleCheckbox: () => void;
}

export const TermsOfService = ({isOpen,onClose, handleCheckbox}:ITermsOfServiceProps) => {
	const { ref, inView } = useInView({
		threshold: 0.5,
	});

	return(
		<Modal closeOnOverlayClick={false} size={'4xl'} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent  height={['','620px']} overflow={'auto'}>
				<ModalBody>
					<Stack  spacing={6}  p={[3,10]} mt={8}>
						<Heading 
							alignSelf={['flex-start','center']} 
							fontSize={['20px','28px']} 
							fontWeight={600} 
							color={'#264653'}
						>
							Terms of Service
						</Heading>
						<Text 
							fontSize={'14px'} 
							fontWeight={600} 
							color={'#121B26'}
						>
							Effective Date of this Current Policy:
						</Text>
						<Text fontSize={'14px'} fontWeight={500} color={'#121B26'}>
					November 1st, 2018
						</Text>
						{
							TermsConstant.map((term) => {
								return(
									<Box key={term.id} >
										<Text  
											ref={ref} key={term.id} fontSize={'14px'} fontWeight={500} color={'#121B26'}>
											{term.terms}
										</Text>
									</Box>
									
								);
							})
						}
				
					</Stack>
				</ModalBody>

				<ModalFooter
					justifyContent={'center'} 
					alignItems={'center'}
					position={['fixed','sticky']} 
					left={['7%','35%']} 
					bottom={['20',0]} 
					bgGradient={inView ? '' : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 61.98%)'} 
				>
					<Button 
						border={'1px solid #91979D'}
						p={'3'}
						borderRadius={'10px'}
						w={'150px'} h={'50px'}
						fontSize={'16px'}
						color={'#586068'}
						mr={4}
						onClick={onClose}
					>
						Decline
					</Button>
					
					<Button 
						variant={'primary'}
						borderRadius={'10px'}
						w={'150px'} h={'50px'}
						fontSize={'16px'}
						onClick={handleCheckbox}
					>
						Accept
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};