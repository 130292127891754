import React from 'react';
import {
	Box,
	Flex,
	Heading,
	VStack,
	Text,
	Image,
	Button,
	chakra,
	Spacer,
} from '@chakra-ui/react';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import {
	textVariant,
	leftFooterIcons,
	rightFooterIcons,
	svgVariant,
	logoTextVariant,
} from './utils';

import Vector from './../../Assets/svg/Vector.svg';
import Loop from './../../Assets/svg/Loop.svg';
import loopIcon from './../../Assets/svg/loopIcon.svg';

import shareIcon from './../../Assets/svg/share.svg';

export const ComingSoonPage = () => {
	return (
		<Flex
			flexDirection={{ base: 'column', md: 'column' }}
			justifyContent={'space-between'}
			bg='darkCoral'
			h={'100vh'}
			overflowX={'hidden'}
			p={{ base: 5, sm: 10 }}
		>
			<Flex alignItems={'center'}>
				<MotionBox variants={svgVariant} initial='hidden' animate='visible'>
					<Image
						src={Vector}
						w={{ base: '30px', tablet: '30px', xxl: '30px' }}
					/>
				</MotionBox>
				<MotionBox
					variants={logoTextVariant}
					initial='hidden'
					animate='visible'
				>
					<Image
						src={Loop}
						w={{ base: '40px', tablet: '40px', xxl: '45px' }}
						ml={-1}
					/>
				</MotionBox>
			</Flex>
			<MotionBox variants={textVariant} initial='hidden' animate='visible'>
				<VStack
					spacing={6}
					alignItems={{ base: 'flex-start' }}
					m='0 auto'
					w={{ base: '100%', sm: '100%', md: '50%' }}
				>
					<Image
						src={loopIcon}
						w={{ base: '80px', tablet: '130px', xxl: '130px' }}
						alignSelf='flex-start'
					/>
					<Text
						color='white'
						fontSize={{ base: 'sm', tablet: 'sm' }}
						lineHeight={6}
						textAlign={{ base: 'left' }}
						alignSelf={{ base: 'left' }}
					>
						<chakra.b>
						Connect with your verified customers, get direct product feedback and grow your community.
						</chakra.b>
						<br />
						<Spacer height={2}/>
						At Loop we are creating a platform that lets any brand launch and scale insights campaigns, regardless of size, location or sales channel.<br/>
						<Spacer height={2}/>
						<chakra.p> Putting the power of direct relationships with your customers back in your hands.</chakra.p>
					</Text>
					<Heading
						color='white'
						fontSize={{ base: 'xl', tablet: 'xl', xxl: '2xl' }}
						alignSelf={{ base: 'left', sm: 'center' }}
					>
            COMING SOON
					</Heading>
					<Text
						color='white'
						mt={4}
						fontSize={{ base: 'md', tablet: 'md', xxl: 'lg' }}
						alignSelf={{ base: 'left', sm: 'center' }}
					>
            Q1.2024
					</Text>
				</VStack>
			</MotionBox>
			<MotionBox variants={textVariant} initial='hidden' animate='visible'>
				<Flex justifyContent={['space-between']} alignItems='center'>
					<Box>
						<Text
							color='white'
							fontSize={{ base: '8px', tablet: 'sm', xxl: 'sm' }}
							fontWeight='200'
							mb='4'
						>
              Launching soon on:
						</Text>
						<Flex>
							{leftFooterIcons.map((icon) => {
								return (
									<Image
										key={icon.label}
										src={icon.src}
										border='1px'
										rounded='50%'
										w={{ base: '25px', md: '30px' }}
										h={{ base: '25px', md: '30px' }}
										borderColor='white'
										p={{ base: '1.5', xxl: '2' }}
										mr={{ base: '2', tablet: '4', xxl: '4' }}
									/>
								);
							})}
						</Flex>
					</Box>
					<Box>
						<Text
							color='white'
							fontSize={{ base: '8px', tablet: 'sm', xxl: 'sm' }}
							fontWeight='200'
							mb='4'
						>
              Connect with us:
						</Text>
						<Flex justifyContent={'space-between'}>
							{rightFooterIcons.map((icon) => {
								return (
									<Image
										key={icon.label}
										src={icon.src}
										border='1px'
										rounded='50%'
										w={{ base: '25px', md: '30px' }}
										h={{ base: '25px', md: '30px' }}
										borderColor='white'
										p={{ base: '1.5', xxl: '2' }}
										mr={{ base: '2', tablet: '4', xxl: '4' }}
									/>
								);
							})}
							<Image
								src={shareIcon}
								border='1px'
								rounded='50%'
								w={{ base: '25px', md: '30px' }}
								h={{ base: '25px', md: '30px' }}
								borderColor='white'
								p={{ base: '1.5', xxl: '2' }}
								mr={{ base: '2', tablet: '4', xxl: '4' }}
								display={{
									base: 'block',
									md: 'block',
									tablet: 'none',
									xxl: 'none',
								}}
							/>
							<Button
								size='sm'
								color='white'
								variant='outline'
								borderRadius='20px'
								fontSize='xs'
								fontWeight='thin'
								_hover={{ bg: 'transparent' }}
								display={{
									base: 'none',
									md: 'none',
									tablet: 'block',
									xxl: 'block',
								}}
							>
                Share
							</Button>
						</Flex>
					</Box>
				</Flex>
			</MotionBox>
		</Flex>
	);
};
