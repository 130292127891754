import React from 'react';
import { Image, Text, Flex } from '@chakra-ui/react';
import { ICollection } from 'Types/Interface/ICollection';
import { ChevronRightIcon } from '@chakra-ui/icons';

interface IGalleryItemProps {
  item: ICollection;
  visibility: true | false;
  onClickImage: () => void; 
}


export const GalleryItem = ({ item, visibility, onClickImage}: IGalleryItemProps) => {
	return (
		<Flex
			direction={'column'}
			alignItems={{ base: 'flex-start', md: 'flex-start', xxl: 'flex-start' }}
			justifyContent={'center'}
		>
			<Flex
				direction={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				onClick = {onClickImage}
				cursor={'pointer'}
			>
				<Image
					src={item?.coverImage}
					w={'100vw'}
					filter='auto'
					brightness='60%'
					rounded={4}
				/>
				<Image
					src={item?.brandLogo}
					position='absolute'
					justifyContent={'center'}
					w={[ '4rem', '3rem', '3rem', '4rem', '6rem', '10rem' ]}
				/>
			</Flex>
			<Flex alignItems={'center'} py={4} >
				<Text
					fontSize={{ base: 'sm', tablet: 'sm', xxl: '.9rem' }}
					fontWeight={'semibold'}
					cursor={'pointer'}
					textAlign='left'
					onClick={onClickImage}
				>
					{item?.brandName}
				</Text>
				{visibility === true && <ChevronRightIcon w={4} h={3} color={'gray.700'} />}
			</Flex>
		</Flex>
	);
};
