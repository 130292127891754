import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
	PartnersSection,
	JumbotronSection,
	STATSection,
	MarketingSolution,
} from './sections';
import { CallToActionSection } from '../Components';
import { TestimonialSection } from '../LoopAppSection/sections/TestimonialSection';
import { LandingPageLayout } from 'Layouts/LandingPageLayout';
import { FeaturedBrandsSlider } from './components/FeaturedBrandsSlider';

export const MotionBox = motion<BoxProps>(Box);

export const BusinessSection = () => {
	const callToActions = {
		actionOne: {
			text: 'Get Started',
			onClick: () => {
				console.log('Get Started');
			},
		},
		actionTwo: {
			text: 'Contact us',
			onClick: () => {
				console.log('Contact us');
			},
		},
	};
	return (
		<LandingPageLayout
			disableGradientBackground
			JumbotronSection={<JumbotronSection />}
			linkColorMode='black'
		>
			<PartnersSection />
			<STATSection shouldIgnoreParentPadding/>
			<MarketingSolution/>
			<FeaturedBrandsSlider />
			<TestimonialSection />

			<CallToActionSection
				actionBtnLeft={callToActions.actionOne}
				actionBtnRight={callToActions.actionTwo}
				heading="Explore our resources for your business"
			/>
		</LandingPageLayout>
	);
};
