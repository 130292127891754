/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Button, Flex, Input, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { InputName } from '../AccountDetailList';
import { renderEditForm } from './utils';
import { Gender, InputNameEnums } from 'Types/Enums';
import { RewardModal } from '../../../Rewards/Components/RewardModal';
import { ChangePhoneNumberVerification } from './Components/ChangePhoneNumberVerification';
import { useSendVerification, useToast } from 'Hooks';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useSendEmailOtpMutation } from 'services';
import { EmailVerificationModalContentOtp } from 'Pages/HomePage/Components/EmailVerificationModalContentOtp';
import { useRefreshUserProfile } from 'Utils/useRefreshUserProfile';

interface IAccountDetail {
	label: string;
	placeholder: string;
	inputName: InputName;
	defaultValue: string | number;
	onChange: (inputName: InputName, value: string | number) => void;
	onSubmit: () => void;
	isLoading: boolean;
	isSuccess: boolean;
	handleActiveForm: (value: string) => void;
	activeForm: string;
}

const genderLabel: Record<string, string> = {
	[Gender.Male]: 'Male',
	[Gender.Female]: 'Female',
	[Gender.NoInput]: 'NoInput',
	[Gender.Others]: 'Others',
	[Gender.NonBinary]: 'Non-binary',
};

export const AccountDetail = ({
	label,
	placeholder,
	inputName,
	defaultValue,
	onChange,
	onSubmit,
	isLoading,
	isSuccess,
	handleActiveForm,
	activeForm,
}: IAccountDetail) => {
	const [isEditing, setIsEditing] = useState(false);
	const { isOpen: isPhoneModalOpen, onOpen: onPhoneModalOpen, onClose: onPhoneModalClose } = useDisclosure();
	const { isOpen: isEmailModalOpen, onOpen: onEmailModalOpen, onClose: onEmailModalClose } = useDisclosure();
	const { alert } = useToast();

	const { refreshUserProfile } = useRefreshUserProfile();

	const {
		mutate: sendPhoneVerificationCode,
		isSuccess: isSendPhoneCodeSuccess,
		isError: isSendPhoneCodeError,
		error: sendPhoneCodeError,
	} = useSendVerification();

	const [
		sendEmailVerificationCode,
		{ isSuccess: isSendEmailCodeSuccess, isError: isSendEmailCodeError, error: sendEmailCodeError },
	] = useSendEmailOtpMutation();

	const handleCancel = () => {
		setIsEditing(false);
	};

	const handleSubmit = (inputName: string) => {
		handleActiveForm(inputName);
		if (inputName == InputNameEnums.phoneNumber) {
			handleSendPhoneCode();
		} else if (inputName == InputNameEnums.emailAddress) {
			handleSendEmailCode();
		} else {
			onSubmit();
		}
	};

	const handleSendPhoneCode = () => {
		if (defaultValue) {
			const data = {
				phoneNumber: defaultValue.toString().toInternationalPhoneNumber(),
				countryCode: '',
			};
			sendPhoneVerificationCode(data);
		}
	};

	const handleSendEmailCode = () => {
		if (defaultValue) {
			sendEmailVerificationCode(defaultValue.toString());
		}
	};

	const handleEdit = () => {
		setIsEditing(!isEditing);
	};

	
	useEffect(() => {
		if (isSuccess) {
			setIsEditing(false);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isSendPhoneCodeSuccess) {
			onPhoneModalOpen();
		}
	}, [isSendPhoneCodeSuccess]);

	useEffect(() => {
		if (isSendEmailCodeSuccess) {
			onEmailModalOpen();
		}
	}, [isSendEmailCodeSuccess]);

	useDefaultErrorHandler(isSendPhoneCodeError, sendPhoneCodeError);
	useDefaultErrorHandler(isSendEmailCodeError, sendEmailCodeError);

	const handlePhoneVerified = () => {
		alert('success', 'Phone number updated successfully');
		refreshUserProfile();
		onPhoneModalClose();
		setIsEditing(false);
	};

	const handleEmailVerified = () => {
		alert('success', 'Email updated successfully');
		onEmailModalClose();
		setIsEditing(false);
	};

	return (
		<>
			<Stack spacing={3}>
				<Flex alignItems='center' justifyContent='space-between'>
					<Text fontWeight='semibold'>{label}</Text>
					<Button
						color='loop.500'
						_hover={{
							textDecoration: 'underline',
						}}
						//display={InputNameEnums.emailAddress === inputName ? 'none': 'block'}
						onClick={() => (isEditing ? handleCancel() : handleEdit())}
					>
						{isEditing ? 'Cancel' : 'Edit'}
					</Button>
				</Flex>
				{isEditing ? (
					<Stack spacing={2}>
						{renderEditForm(inputName, onChange)}
						<Button
							isLoading={isLoading}
							onClick={() => handleSubmit(inputName)}
							alignSelf='left'
							variant='primary'
							w='max-content'
							p={2}
							borderRadius={['20px', '5']}
						>
							Save
						</Button>
					</Stack>
				) : (
					<Input
						w={'100%'}
						variant='flushed'
						placeholder={placeholder}
						defaultValue={genderLabel[defaultValue] || defaultValue}
						disabled
						_placeholder={{
							color: '#586068',
							fontSize: 'sm',
						}}
					/>
				)}
			</Stack>
			{activeForm === InputNameEnums.phoneNumber && (
				<RewardModal
					content={
						<ChangePhoneNumberVerification
							defaultValue={defaultValue}
							onClose={onPhoneModalClose}
							onVerified={handlePhoneVerified}
							handleSendCode={handleSendPhoneCode}
							isSendCodeError={isSendPhoneCodeError}
							sendCodeError={sendPhoneCodeError}
						/>
					}
					isOpen={isPhoneModalOpen}
					onClose={onPhoneModalClose}
					modalContentStyles={{ borderRadius: '10px' }}
				/>
			)}
			{activeForm === InputNameEnums.emailAddress && (
				<RewardModal
					content={
						<EmailVerificationModalContentOtp email={defaultValue.toString()} onVerified={handleEmailVerified} />
					}
					isOpen={isEmailModalOpen}
					onClose={onEmailModalClose}
					modalContentStyles={{ borderRadius: '10px' }}
				/>
			)}
		</>
	);
};
