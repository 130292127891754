import React from 'react';
import { Grid } from '@chakra-ui/react';


interface IGalleryLayoutProps {
    children: React.ReactNode; 
	display: 'block' | 'none' | 'grid'
}


export const GalleryLayout = ({children, display }: IGalleryLayoutProps) => {
	return (
		<Grid
			templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)','repeat(4, 1fr)', 'repeat(4, 1fr)']}
			justifyContent='space-between'
			gap={[6, 4, 4, 6]}
			flex={1}
			display={[display, 'Grid']}
		>
			{children}
		</Grid>
	);
};