import React from 'react';
import { AccountDetailList } from './Components';
import { myAccountSectionDetails } from 'Constants';
import { DashboardLayoutWithDescription } from 'Pages/Dashboard/Components/LayoutWithDescription';

export const MyAccount = () => {
	return (
		<DashboardLayoutWithDescription 
			content={<AccountDetailList />}
			description={myAccountSectionDetails}
		/>
	);
};
