import React from 'react';
import { 
	Flex, 
	Heading, 
	VStack, 
	Link, 
	Box, 
	Text, 
	Accordion, 
	AccordionButton, 
	AccordionIcon, 
	AccordionItem, 
	AccordionPanel, 
	Divider, 
	Spacer
} from '@chakra-ui/react';
import { InternetIcon, NavigationIcon } from 'Components/Icons';
import { LandingPageFooterLinks } from 'Constants/FooterLinksConstants';
import { IFooterSectionProps, footerBg, BgEnum} from './FooterSection';

export const FooterSectionMobile = ({bgType}: IFooterSectionProps) => {
	return (
		<Flex
			py='10'
			px={['8', '14']}
			bg={`${footerBg[bgType ?? BgEnum.landing]}`}
			flexWrap='wrap'
			direction={'column'}
		>
			<VStack spacing={5} flex={[1, 0.5]} alignItems='flex-start'>
				<Heading color='darkCoral' size='lg'>
          Loop
				</Heading>
				<Link d='flex' alignItems='center'>
					<NavigationIcon mr='2' /> United States
				</Link>
				<Link d='flex' alignItems='center'>
					<InternetIcon mr='2' /> English
				</Link>
				<Text>Loop &copy; {new Date().getFullYear()}</Text>
				<Divider orientation='horizontal' bgColor={'#BFBFBF'} />
				<Spacer/>
			</VStack>
			<Accordion allowMultiple >
				{LandingPageFooterLinks.map((link) => {
					return (
						<AccordionItem 
							key={link.title}
							mb={3}
							border={'none'}	
						>
							<AccordionButton
								_hover={{outline: 'none'}}
								px={0}
							>
								<Box 
									flex='1' 
									textAlign='left'
									fontWeight={'600'}
									color={'#121B26'}
									fontSize={{ base: 'sm', tablet: 'sm' }}
								>
									{link.title}
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel 
								fontSize={{ base: 'sm', tablet: 'sm' }}
								fontWeight={400}
								color={'#121B26'}
								lineHeight={'8'}
								pb={4} py={'6'}
								display={'flex'}
								flexDirection={'column'}
							>
								{link.links.map((link) => (
									<Link mb={4} key={link.label}>{link.label}</Link>
								))}
							</AccordionPanel>
						</AccordionItem>
					);
				})}
			</Accordion>
		</Flex>
	);
};
