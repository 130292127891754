import { Stack } from '@chakra-ui/react';
import React from 'react';
import { ChangePassword } from './components';

export const Content = () => {
	return (
		<Stack spacing={16} flex={1}>
			<ChangePassword/>
			{/* <ConnectSocial/> */}
		</Stack>
	);
};
