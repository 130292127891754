/* eslint-disable linebreak-style */
export const routes = {
	accountLoginPhone: '/account/login',
	dynmaiclinkqrCode: 'survey/dynmaiclinkqrCode',
	requestAccountVerificationCode: '/account/request-verification-code',
	verifyCode: '/account/verify-code',
	userProfile: '/user/profile',
	companyList: '/company/GetAllCompaniesAsync',
	productList: '/product/getproducts',
	product: '/product',
	productTags: '/product/tags',
	company: '/company',
	surveyList: '/survey',
	imageUpload: '/image/upload',
	addCompany: '/company/addcompany',
	users: '/user/all',
	addBrand: '/brand/addbrand',
	brandList: '/brand/GetAllBrandsAsync',
	deleteProduct: '/product/deleteproduct',
	addProduct: '/product/addproduct',
	searchBrand: '/search/getBrandsAndProducts',
	editProduct: '/product/editproduct',
	brand: '/brand/GetByBrandId',
	deleteBrand: '/brand/delete',
	editBrand: '/brand/editbrand',
	editCompany: '/company/update/:companyId',
	rewardHistory: '/reward/history',
	addSurvey: '/survey/AddSurvey',
	deleteSurvey: '/survey/DeleteSurvey',
	duplicateSurvey: '/survey/DuplicateSurvey',
	editSurvey: '/survey/editSurvey',
	updateSurvey: '/survey/UpdateSurvey',
	user: '/user',
	searchCompany: '/company/GetAllCompaniesAsync',
	categories: '/categories',
	qrCode: 'survey/:surveyId/qrCode',
	webCode: 'survey/:surveyId/webCode',
	qrCodeLink: 'survey/:surveyId/qrCodeLink',
	userRoles: '/user/roles',
	accountLoginGoogle: '/account/google',
	accountLoginFacebook: '/account/facebook',
	createAccountWithPhone: '/account/create-account-by-phone',
	userProfileQuestion: '/user/question',
	accountPhoneComplete: '/account/phone/complete',
	userProfilePicture: '/user/avatar',
	ongoingFeedBack: '/feedback/ongoing',
	getResetCode: '/account/get-reset-code'

};