import React, { useEffect, useState } from 'react';
import {
	Divider,
	Flex,
	Heading,
	Stack,
	Text,
	Box,
	Switch,
} from '@chakra-ui/react';

interface SectionProps {
  header?: string;
  subHeader?: string;
  onChange?: (name: string, value: NotificationOption) => void;
  sectionName?: string;
}

export interface NotificationOption {
  text: boolean;
  email: boolean;
}

const initialState = {
	text: false,
	email: false,
};

export const NotificationFeature = ({
	header,
	subHeader,
	onChange,
	sectionName,
}: SectionProps) => {
	const [state, setState] = useState<NotificationOption>(initialState);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked, name } = event.target;
		setState((prev) => ({
			...prev,
			[name]: checked,
		}));
	};

	useEffect(() => {
		if (onChange && sectionName) onChange(sectionName, state);
	}, [state]);

	return (
		<Stack spacing={6} w="100%" flex={1}>
			<Stack>
				<Heading color="#264653" size="sm">
					{header}
				</Heading>
				<Text fontSize="xs">{subHeader}</Text>
			</Stack>
			<Box>
				<Flex mb={4} flex={1} justifyContent="space-between">
					<Text color="#264653" fontWeight="medium" fontSize="md">
            Text messages
					</Text>
					<Switch
						name="text"
						onChange={handleChange}
						variant="primary"
						size="lg"
					/>
				</Flex>
				<Divider />
			</Box>
			<Box>
				<Flex mb={4} flex={1} justifyContent="space-between">
					<Text color="#264653" fontWeight="medium" fontSize="md">
            Emails
					</Text>
					<Switch
						name="email"
						onChange={handleChange}
						variant="primary"
						size="lg"
					/>
				</Flex>
				<Divider />
			</Box>
		</Stack>
	);
};
