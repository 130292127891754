import React from 'react';
import { Box, Skeleton, Stack } from '@chakra-ui/react';

export const Loader = () => {
	return (
		<Box w="100%">
			<Stack spacing="10px">
				{new Array(10).fill('_').map((_, index) => (
					<Skeleton height="40px" key={index} />
				))}
			</Stack>
		</Box>
	);
};
