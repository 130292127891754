import React from 'react';
import { MotionBox } from '../BusinessSection/BusinessSection';

interface ISlidingItemsContainerProps {
    children: React.ReactNode;
    direction?: 'left' | 'right'
}

export const SlidingItemsContainer = ({children, direction = 'left'}:ISlidingItemsContainerProps) => {
	const SliderVariant = {
		hidden: {
			x: direction === 'left' ? '0%' : '-200px',
		},
		visible: {
			x: direction === 'left' ? '-200px' : '0',
			transition: {
				duration: 15,
				ease: 'linear',
				repeat: Infinity,
			},
		},
	};


	return (
		<MotionBox maxW={'1440px'} m={'0 auto'} variants={SliderVariant} initial='hidden' animate='visible'>
			{children}
		</MotionBox>
	);
};
