import { css } from '@emotion/react';

export const GlobalStyles = css`
  html {
    scroll-behavior: smooth;
  }
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
  
  body {
    font-size: 12px;
    font-family: 'Montserrat' sans-serif;
  }
  .phone-input-container {
    height: 43px;
    width: 100% !important;
  }
  .phone-input-field {
    height: 100% !important;
    width: 100% !important;
  }

  .phone-input-button {
    background-color: transparent !important;
  }

  .phone-input-search input {
    border: none !important;
  }
  .star-rating-container > *:not(:last-child) {
    margin-right: 30px;
  }
  .custom-modal::-webkit-scrollbar {
    display: none;
  }
  .modal-text {
    color: #121B26;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: .5rem;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }

  .no-scrollbar::-webkit-scrollbar {
    width: 0px;
  }
  .no-scrollbar::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    background-color: #FFEFEF;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #FF6F61;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #FF6F61;
  }
`;
