import React from 'react';
import { Box, Heading, HStack, Stack, Text, useRadioGroup } from '@chakra-ui/react';
import { useAppSelector } from 'Hooks';
import { CustomRadioButton } from './CustomRadioButton';

interface IPrivacySelectionProps {
	onChange: (value: string) => void;
	privacyLevelId: string;
}

export const PrivacySelection = ({ onChange, privacyLevelId }: IPrivacySelectionProps) => {
	const {
		activeSurvey: { survey },
	} = useAppSelector((state) => state.survey);

	const { getRootProps, getRadioProps } = useRadioGroup({
		name: 'privacy',
		defaultValue: privacyLevelId,
		onChange,
	});

	const group = getRootProps();

	return (
		<Stack spacing={[8, 4]}>
			<Heading color={'blackTextColor.100'} size='lg'>
				Data sharing permission
			</Heading>
			<Text color={'placeholderText'} fontSize='md'>
				Select from the options below the information you wish to share with the brand
			</Text>
			<Stack spacing={4} {...group}>
				{survey.privacyLevels.map(({ id, title, description, extraPoints }) => {
					const radio = getRadioProps({ value: id });
					return (
						<CustomRadioButton key={id} {...radio}>
							<HStack justifyContent={'space-between'}>
								<Box>
									<Heading fontSize={['sm', 'xl']} fontWeight={500} mb={2}>
										{title}
									</Heading>
									<Text fontSize={['xs', 'sm']}>{description}</Text>
								</Box>
								{extraPoints && <Box fontSize={['sm', 'md']}>+ {extraPoints} LNT</Box>}
							</HStack>
						</CustomRadioButton>
					);
				})}
			</Stack>
		</Stack>
	);
};
