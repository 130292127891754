import React, { useEffect } from 'react';
import {
	Button,
	Divider,
	Flex,
	Heading,
	Stack,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	FormControl,
	FormLabel,
	ModalFooter,
} from '@chakra-ui/react';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUpdatePasswordMutation } from 'services';
import { IChangePasswordDto } from 'Types/Interface/IUser';
import { useToast } from 'Hooks';


export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{6,}$/;

const validationSchema = yup.object().shape({
	newPassword: yup
		.string()
		.min(6, 'Password too short should be atleast 6 characters long')
		.matches(PASSWORD_REGEX,'Minimum six characters, at least one upper case English letter, one lower case English letter, one number and one special character')
		.required('Password required'),
	confirmPassword: yup
		.string()
		.required('Password required')
		.when('newPassword', {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			is: (val: string | any[]) => (val && val.length > 0 ? true : false),
			then: yup
				.string()
				.oneOf([yup.ref('newPassword')], 'password does not match'),
		}),
	currentPassword:  yup.string().required('Current password is required'),
});

export const ChangePassword = () => {
	const { alert } = useToast();
	const [updatePassword, {isLoading, isSuccess}] = useUpdatePasswordMutation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { handleChange, handleSubmit, errors } = useFormik({
		initialValues: {
			newPassword: '',
			confirmPassword: '',
			currentPassword: ''
		},
		validationSchema,
		onSubmit: async (values) => {
			const data: IChangePasswordDto = {
				password: values.currentPassword,
				newPassword: values.newPassword
			};
			updatePassword(data);
		},
	});
	useEffect(() => {
		if(isSuccess){
			alert('success', 'password updated successfully');
		}
	},[isSuccess]);
	return (
		<>
			<Stack spacing={10} w="100%" flex={1}>
				<Heading size="sm">Login</Heading>
				<Flex flex={1} justifyContent="space-between">
					<Text color="#586068" fontSize="md">Password</Text>
					<Button
						color="loop.500"
						_hover={{
							textDecoration: 'underline',
						}}
						onClick={onOpen}
					>
            Change Password
					</Button>
				</Flex>
				<Divider />
			</Stack>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						Update password 
						{/* fake hidden input to stop google from autofilling username in other inputs on page */}
						<input autoComplete="on" style={{ width: '0', maxHeight: '0' }}></input>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>						
						<Stack spacing={6}>							
							<FormControl>
								<FormLabel fontSize='sm'>Current password</FormLabel>
								<CustomInput
									onChange={handleChange}
									name='currentPassword'
									type='password'
									placeholder='Password'
									error={errors.currentPassword}
								/>
							</FormControl>
							<FormControl>
								<FormLabel fontSize='sm'>New password</FormLabel>
								<CustomInput
									onChange={handleChange}
									name='newPassword'
									type='password'
									placeholder='newPassword'
									error={errors.newPassword}
								/>
							</FormControl>
							<FormControl>
								<FormLabel fontSize='sm'>Confirm password</FormLabel>
								<CustomInput
									onChange={handleChange}
									name='confirmPassword'
									type='password'
									placeholder='Password'
									error={errors.confirmPassword}
								/>
							</FormControl>
							<Button
								variant='primary'
								onClick={() => handleSubmit()}
								isLoading={isLoading}
							>
								Save
							</Button>
						</Stack>
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
