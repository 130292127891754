import React from 'react';
import { Divider, Flex, Heading, Stack, Text } from '@chakra-ui/react';

export const Content = () => {
	return (
		<>
			<Stack spacing={8} w="100%" flex={1}>
				<Stack spacing={8} w="100%" flex={1}>
					<Heading size="sm">People you’ve invited</Heading>
					<Flex flex={1} justifyContent="space-between">
						<Text fontSize="sm">Completed referrals</Text>
						<Text>0</Text>
					</Flex>
					<Divider />
					<Flex flex={1} justifyContent="space-between">
						<Text fontSize="sm">Sign ups</Text>
						<Text>0</Text>
					</Flex>
					<Divider />
				</Stack>
			</Stack>
		</>
	);
};
