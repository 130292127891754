import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { ArrowUpIcon } from '@chakra-ui/icons';


export const ScrollUpBtn = () => {
	const [showButton, setShowButton] = useState(false);
	const PAGE_Y_OFFSET = 800;

	const scrollUp = () => {
		window.scroll(0,0);
	};

	const checkScrollPosition =  () => {
		if (window.scrollY > PAGE_Y_OFFSET) {
			setShowButton(true);
		} else {
			setShowButton(false);
		}
	};
	
	useEffect(() => {
		window.addEventListener('scroll',checkScrollPosition);
		return () => {  // cancels subscription to scroll event when components unmounts to fix memory leak warning
			window.removeEventListener('scroll',checkScrollPosition); 
		};
	}, []);

	return (
		<Box>
			{ showButton &&
				<Box 
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
					position={'fixed'} 
					w={'35px'}
					h={'35px'} 
					right={5} 
					top={'90%'}
					bg={'white'}
					borderRadius={'50%'}
					filter="auto"
					dropShadow={'0px 0px 25px rgba(0, 0, 0, 0.29)'}
					onClick={scrollUp}
				>
					<IconButton
						color='#4C4C4C'
						aria-label='Back To Top'
						size='lg'
						icon={<ArrowUpIcon />}
					/>
				</Box>
			}
		</Box>
	);
};