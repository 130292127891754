import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getApiEnv } from 'Api/config';
import { IHistory } from 'Types/Interface/IHistory';
import { getAuthToken } from 'Utils';
import { IPaginatedResponse } from './types';

export const baseQuery = fetchBaseQuery({
	baseUrl: getApiEnv('develop'),
	prepareHeaders: (headers,) => {
		const authToken = getAuthToken();
		if (authToken) {
			headers.set('authorization', `Bearer ${authToken}`);
		}
		headers.set('content-type', 'application/json; charset=utf-8');

		return headers;
	},
});

export const historyApi = createApi({
	reducerPath: 'historyApi',
	baseQuery,
	endpoints: (builder) => ({
		getRewardHistory: builder.query<IPaginatedResponse<IHistory[]>, {pageIndex:number, pageSize:number}>({
			query: ({pageIndex, pageSize}) => `reward/history?pageNumber=${pageIndex}&pageSize=${pageSize}`,
		}),
	}),
});

export const { useGetRewardHistoryQuery } = historyApi;
