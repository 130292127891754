import React from 'react';
import { Box } from '@chakra-ui/react';
import { MainMenu } from './sections';

export const Settings = () => {
	return (
		<Box>
			<MainMenu/>
		</Box>
	);
};
