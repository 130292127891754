import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/sexualityIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const SexualityIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const SexualityIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M14.5469 0.59375V1.78125H16.3281L12.817 5.29239C12.3468 5.06815 11.8186 4.99507 11.3052 5.08321C10.7918 5.17135 10.3183 5.41638 9.94977 5.78461L9.5 6.23438L9.05023 5.78491C8.68174 5.41668 8.20822 5.17165 7.69478 5.08351C7.18135 4.99537 6.65322 5.06844 6.18302 5.29269L5.19531 4.30469L6.23438 3.26562L5.34375 2.375L4.30469 3.41406L2.67188 1.78125H4.45312V0.59375H0.59375V4.45312H1.78125V2.67188L3.41406 4.30469L2.375 5.34375L3.26562 6.23438L4.30469 5.19531L5.2158 6.10642C4.912 6.53247 4.74913 7.04289 4.75 7.56616C4.75007 8.61442 5.16654 9.61973 5.90781 10.3609L8.90625 13.3594V15.7344H7.42188V16.9219H8.90625V18.4062H10.0938V16.9219H11.5781V15.7344H10.0938V13.3594L13.0922 10.3609C13.8335 9.61973 14.2499 8.61442 14.25 7.56616C14.2509 7.04289 14.088 6.53247 13.7842 6.10642L17.2188 2.67188V4.45312H18.4062V0.59375H14.5469ZM12.3838 9.28803L9.5 12.1719L6.61616 9.28803C6.40093 9.07291 6.23021 8.81749 6.11376 8.53635C5.99731 8.25521 5.93742 7.95388 5.9375 7.64958C5.93749 7.35874 6.02709 7.07497 6.1941 6.83687C6.36112 6.59876 6.59743 6.4179 6.87089 6.31889C7.14435 6.21987 7.44168 6.20751 7.72242 6.28349C8.00315 6.35947 8.25366 6.5201 8.43986 6.74352L9.5 8.01562L10.5601 6.74381C10.7463 6.52041 10.9968 6.35979 11.2775 6.28381C11.5582 6.20782 11.8555 6.22015 12.129 6.31913C12.4024 6.41811 12.6387 6.59893 12.8058 6.83698C12.9728 7.07503 13.0624 7.35877 13.0625 7.64958C13.0626 7.95388 13.0027 8.25521 12.8862 8.53635C12.7698 8.81749 12.5991 9.07291 12.3838 9.28803Z" fill={color}/>

		</Icon>
	);
};