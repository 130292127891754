export const  reactQueryUniqueKeys = {
	company:'company',
	companies: 'companies',
	brand: 'brand',
	brands:'brands',
	products:'products',
	product:'product',
	surveys: 'surveys',
	survey: 'survey',
	editSurvey: 'editSurvey',
	users:'users',
	brandProducts: 'brandProducts',
	searchCompany: 'searchCompany',
	categories: 'categories',
	qrCode: 'qrCode',
	productTags: 'productTags',
	userRoles: 'userRoles',
	profile: 'profile'
};