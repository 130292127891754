import { ArrowDownIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import React from 'react';

export const Pointer = () => {
	const PointerVariant = {
		hidden: {
			opacity: 0,
			y: 0
		},
		visible: {
			opacity: 1,
			y: 10,
			transition: {
				duration: 1,
				ease: 'easeIn',
				repeat: Infinity
			},
		},
	};
	return (
		<Box
			visibility={['visible', 'hidden']}
			border={'1px solid #FFFFFF'}
			borderRadius={'80px'}
			w={'20px'}
			h={'30px'}
			textAlign={'center'}
			alignSelf={'center'}
		>
			<MotionBox
				variants={PointerVariant}
				initial= 'hidden'
				animate = {'visible'}
			>
				<ArrowDownIcon
					color={'white'}
					w={4} h={4}
					textAlign={'center'}
				/>
			</MotionBox>
		</Box>
	);
};