import React, { useEffect } from 'react';
import { Link, Text, Stack, Button, HStack, Spacer, chakra } from '@chakra-ui/react';
import { OTPInput } from 'Components/FormInput/OTPInput';
import { EmailVerificationIcon } from 'Components/Icons/EmailVerificationIcon';
import { useFormik } from 'formik';
import { useResendEmailOtpMutation, useVerifyEmailWithOtpCodeMutation } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useToast } from 'Hooks';
import { useRefreshUserProfile } from 'Utils/useRefreshUserProfile';

interface EmailVerificationModalContentOtpProps {
	email: string;
	onVerified: () => void;
}

export const EmailVerificationModalContentOtp = ({ email, onVerified }: EmailVerificationModalContentOtpProps) => {
	const [resendEmailOtp, { isSuccess: isResendSuccess, isError: isResendError, error: resendError }] =
		useResendEmailOtpMutation();

	const [
		verifyEmail,
		{ isSuccess: isVerifySuccess, isLoading: isVerifyLoading, isError: isVerifyError, error: verifyError },
	] = useVerifyEmailWithOtpCodeMutation();

	useDefaultErrorHandler(isResendError, resendError);
	useDefaultErrorHandler(isVerifyError, verifyError, () => {
		setFieldValue('otp', '');
	});

	const { refreshUserProfile } = useRefreshUserProfile();

	const { alert } = useToast();

	useEffect(() => {
		if (isResendSuccess) {
			alert(
				'success',
				'We have sent you another verification code. Please check you messages or spam folder if it is not in your inbox',
			);
		}
	}, [isResendSuccess]);

	useEffect(() => {
		if (isVerifySuccess) {
			refreshUserProfile();
			onVerified();
		}
	}, [isVerifySuccess]);

	const { values, setFieldValue, handleSubmit } = useFormik({
		initialValues: { otp: '' },
		onSubmit: async (values) => {
			verifyEmail(values.otp);
		},
	});

	return (
		<Stack py={10} px={['20px', '30px']} spacing={5} alignItems={'center'}>
			<EmailVerificationIcon boxSize={14} />
			<Text fontSize={['16px','30px']} fontWeight={'600'}>
				Verify code
			</Text>
			<Text fontSize={['12px','16px']} textAlign={'center'} pb={3}>
				Please enter the 6 digits code sent to <chakra.span textDecor={'underline'}>{email}</chakra.span>
			</Text>
			<OTPInput value={values.otp} onChange={(value) => setFieldValue('otp', value)} />
			<Spacer h={1} />
			<Button
				variant={'primary'}
				width={'100%'}
				h={['50px','60px']}
				fontSize={['14px', '18px']}
				isLoading={isVerifyLoading}
				onClick={() => handleSubmit()}
			>
				Continue
			</Button>
			<HStack pt={3}>
				<Text fontSize={['12px', '16px']} textAlign={'center'}>
					Didn&apos;t get a code?
				</Text>{' '}
				<Link
					fontWeight={'400'}
					color='darkCoral'
					cursor={'pointer'}
					onClick={() => {
						resendEmailOtp();
					}}
					fontSize={['12px', '16px']}
					textDecoration={'underline'}
					_hover={{ textDecoration: 'none' }}
				>
					Resend
				</Link>
			</HStack>
		</Stack>
	);
};
