import React, { useState } from 'react';

import { 
	useMediaQuery
} from '@chakra-ui/react';

import { TestimonialSectionMobile } from './TestimonialSectionMobile';
import { panels } from 'Constants';
import { TestimonialItems } from './TestimonialItems';
import { CarouselContainer } from 'Pages/ExplorePage/Components/CarouselContainer';

export const TestimonialSection = () =>{
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const [currentSlide, setcurrentSlide] = useState(0);

	const onRight = () => {
		if (panels.length !== undefined && currentSlide < panels.length - 1) {
			setcurrentSlide(currentSlide + 1);
		}
	};
	const onLeft = () => {
		if (currentSlide > 0) {
			setcurrentSlide(currentSlide - 1);
		}
	};
	
	return (
		<>
			{ isLargerThan768 &&(
				<CarouselContainer title={'We are making users happy'} onLeft={onLeft} onRight={onRight} itemCount={panels.length}>
					<TestimonialItems panels={panels} currentSlide={currentSlide} />
				</CarouselContainer>
			)}

			{ !isLargerThan768 &&(
				<TestimonialSectionMobile/>
			)}
		</>
	);
};