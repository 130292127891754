import { useToast } from 'Hooks';
import { useEffect } from 'react';
import { IErrorPayload } from 'Types/Interface';

export function useDefaultErrorHandler(isError: boolean, error: unknown, onError?: () => void) {
	const { alert } = useToast();

	useEffect(() => {
		if (isError) {
			const errorMessage = getErrorMessage(error);
			alert('error', errorMessage);
			if (onError) onError();
		}
	}, [isError, error]);
}

export const getErrorMessage = (error: unknown) => {
	const errorMessage = error instanceof Error ? error.message : (error as IErrorPayload)?.data?.errors?.[0]?.message;
	return errorMessage || 'Something went wrong, please try again';
};
