import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import { Navbar } from 'Pages/Dashboard/Components/Navbar';
import { QrCodeScanner } from 'Components/QrCodeScanner';
import { Footer } from 'Pages/LandingPage/HomeSection/sections/Footer';

interface IDashBoardLayoutProps {
  children: React.ReactNode;
}

export const DashBoardLayout = ({ children }: IDashBoardLayoutProps) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			h="100vh"
		>
			<QrCodeScanner />
			<Box>
				<Navbar
					sx={{
						py: 5,
						px: [4, 6, null, 14],
					}}
					linkColorMode={'black'}
					inputlinkColorMode={'light'}
				/>
				<Divider />
				{/* <Box px={[8, 10, 20, 28]} mt={5} pb={10}>
					{children}
				</Box> */}
				{React.Children.map(children, (child) => {
					if (React.isValidElement(child)) {
						if (child.props.shouldIgnoreParentPadding) {
							return <Box>{child}</Box>;
						}

						return <Box maxW={'1440px'} m={'0 auto'}  px={[4, 20, 6, 36]}>{child}</Box>;
					}
					return <Box maxW={'1440px'} m={'0 auto'} >{child}</Box>;
				})}
			</Box>
			<Footer />
		</Box>
	);
};
