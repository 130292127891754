import ProfileImg from 'Assets/Images/ProfileImg.png';
import { IPanels } from '../Types/Interface/ILandingPage';

export const panels:IPanels[] = [
	{
		id: 0,
		name: 'Lim chi',
		role: 'Custom IT Solutions Firm',
		profilePicture: ProfileImg,
		subtext: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
		sed do eius mod tempor incididunt ut labore et dolore magna.
		Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor 
		incididunt ut labore et dolore magna.`,
	},
	{
		id: 1,
		name: 'Lim chi',
		role: 'Custom IT Solutions Firm',
		profilePicture: ProfileImg,
		subtext: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
		sed do eius mod tempor incididunt ut labore et dolore magna.
		Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor 
		incididunt ut labore et dolore magna.`,
	},
	{
		id: 2,
		name: 'Lim chi',
		role: 'Custom IT Solutions Firm',
		profilePicture: ProfileImg,
		subtext: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
		sed do eius mod tempor incididunt ut labore et dolore magna.
		Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor 
		incididunt ut labore et dolore magna.`,
	},
	{
		id: 3,
		name: 'Lim chi',
		role: 'Custom IT Solutions Firm',
		profilePicture: ProfileImg,
		subtext: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
		sed do eius mod tempor incididunt ut labore et dolore magna.
		Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor 
		incididunt ut labore et dolore magna.`,
	}
];