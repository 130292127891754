import React, { useRef, useState } from 'react';
import {
	Circle,
	Flex,
	Image,
	Skeleton,
	Text,
	Stack,
	useMediaQuery
} from '@chakra-ui/react';
import { ILandingPageLayoutChild } from 'Layouts/LandingPageLayout';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { useGetCategoriesQuery } from 'services';

export const SwipeableSlider = ({
	shouldIgnoreParentPadding,
}: ILandingPageLayoutChild) => {
	const [currentSlide, setcurrentSlide] = useState(0);
	const { data, isLoading } = useGetCategoriesQuery();
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const maxLength = 10;
	const maxMobileLength = 3;
	const boxRef = useRef<HTMLImageElement>(null);
	const clientWidth = boxRef.current?.clientWidth || 0;
	const dataLength = data?.data.length ? data.data.length: 0;
	
	const onLeft = () => {
		if (currentSlide < dataLength) {
			setcurrentSlide(currentSlide + 1);
		}
	};

	const onRight = () => {
		if (currentSlide > 0) {
			setcurrentSlide(currentSlide - 1);
		}
	};

	const sliderVariant = {
		initial: { x: 0 },
		animate: () => ({
			x: `-${currentSlide * clientWidth}px`,
			transition: {
				type: 'spring',
				stiffness: 250,
				damping: 30,
			},
		}),
	};

	return (
		<Flex
			direction={'row'}
			justifyContent={'space-between'}
			alignItems={'center'}
			mt={{ base: '10', tablet: '8' }}
			{...(shouldIgnoreParentPadding && { pl: ['6', null,null, '20'] })}
		>
			<Flex alignItems={'center'} >
				{ currentSlide > 0 && <Circle
					size="25px"
					bg="#ffffff"
					left={'8%'}
					onClick={onRight}
					color="darkCoral"
					position={'absolute'}
					cursor={'pointer'}
					zIndex={'99999'}
					_hover={{outline: 'none'}}
				>
					<ChevronLeftIcon />
				</Circle>}
				{dataLength > maxLength && currentSlide !== dataLength && <Circle
					size="25px"
					bg="#ffffff"
					left={['90%', '92%']}
					onClick={onLeft}
					color="darkCoral"
					position={'absolute'}
					cursor={'pointer'}
					zIndex={'99999'}
					_hover={{outline: 'none'}}
				>
					<ChevronRightIcon  />
				</Circle>}
				{ !isLargerThan768 && dataLength > maxMobileLength && currentSlide !== dataLength && <Circle
					size="25px"
					bg="#ffffff"
					left={['90%', '92%']}
					onClick={onLeft}
					color="darkCoral"
					position={'absolute'}
					cursor={'pointer'}
					zIndex={'99999'}
					_hover={{outline: 'none'}}
				>
					<ChevronRightIcon  />
				</Circle>}
			</Flex>
			<Flex
				justifyContent={'space-between'}
				alignItems={'center'}
				w={['100%', '100%']}
				overflow={['', '', 'hidden']}
			>
				{isLoading ?new Array(10).fill('a').map((_,index) => (
					<Stack direction='row' key={index} mr={2}>
						<Skeleton
							w={['100px', '75px', '100px', '100px', '100px', '200px']}
							h={['100px', '75px', '100px', '100px', '100px', '200px']}
							borderRadius={'10px'}
						/>
					</Stack>
				))
					:
					<MotionBox display={'flex'}>
						{
							data?.data.map((category, index) => {
								return (
									<MotionBox
										w={['27vw', '15vw', '15vw', '9vw']}
										mr={2}
										custom={index}
										key={index}
										variants={sliderVariant}
										initial="initial"
										animate="animate"
									>
										<Flex
											key={category.id}
											direction={'column'}
											justifyContent={'flex-end'}
											alignItems={'center'}
											position='relative'
										>
											<Image
												src={category.imageUrl}
												boxSize="100%"
												objectFit="cover"
												position='relative'
												ref={boxRef}
												w={[
													'100px',
													'75px',
													'100px',
													'100px',
													'100px',
													'200px',
												]}
												h={[
													'100px',
													'75px',
													'100px',
													'100px',
													'100px',
													'200px',
												]}
												borderRadius={'10px'}
												filter={'auto'}
												brightness={'70%'}
											/>
											<Text
												color={'white'}
												fontSize={['xs', 'xs', 'xs', 'xs', 'xs', 'md']}
												position='absolute'
												bottom={'5%'}
											>
												{category.name}
											</Text>
										</Flex>
									</MotionBox>
								);
							})
						}
					</MotionBox>
						
				}
			</Flex>
		</Flex>
	);
};
