export const TermsConstant: {id: number, terms:string}[] = [
	{
		id: 0,
		terms: 'Thank you for visiting www.archibaldlondon.com (the “site”). Caesar Pro Limited, which does business as Archibald London, operates the site and provides services through the site. For convenience, we will refer to ourselves as “Archibald”, “we” or “us” and thereby keep this simple.'
	},
	{
		id: 1,
		terms: 'We have made our best effort to ensure this document is as easy to read as possible. For simplicity sake, we will refer to both the Site and the services available through the Site as the “Services.” '
	},
	{
		id: 2,
		terms:'Before making use of any of our services, please read our Terms of Use and any other notices and policies on the Site (the “Terms”) and our Privacy Policy at www.archibaldlondon.com/privacy-policy.'
	},
	{
		id: 3,
		terms: 'In their entirety, these materials contain terms, rules, and other guidelines that are related to your use of the Services. When you use the Services, you are agreeing to these terms, so please do not use any of the Services if there is anything within this document that you disagree with. Some parts of the Service will carry with them additional terms, guidelines or rules and if you should choose to use those parts, the additional terms will also apply. '
	},
	{
		id: 4,
		terms: 'The terms in this section are legally binding and because of this, it is important that you fully understand them so should you have any questions please contact us at anytime at info@archibaldlondon.com. '
	},
	{
		id: 5,
		terms: 'We reserve the right to update these Terms of Use at Any Time. '
	},
	{
		id: 6,
		terms: 'The world these days moves at a much faster pace than the one we entered. Things take place quickly and particularly as a young brand trying to establish our unique idea in this world – Archibald London moves quickly – and we will on occasion review and update these Terms to make sure they accurately reflect developments in our current business operations and the law. We reserve the right to update and revise these terms at any time. The effective date at the top of this document serves a good indication for you to know if the Terms have changed since you last visited the portal.'
	},
	{
		id: 7,
		terms: 'We ask for the sake of completeness and diligence that these Terms are reviewed regularly because following any changes to the Terms being posted – continued usage of the Service constitutes your acceptance of the revised Terms.'
	}
];