import { useMutation } from 'react-query';
import { updateUserProfile, updateRegisteredUserProfile } from 'Api';
import { INewUserRequestbody, IUserProfile } from 'Types/Interface/IUser';

export const useUpdateUserProfile = () => {
	return useMutation((data: INewUserRequestbody) => updateUserProfile(data));
};

export const useUpdateRegisteredUserProfile = () => {
	return useMutation((data: IUserProfile) => updateRegisteredUserProfile(data));
};
