import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/GlobeIcon.svg';
import Icon from '@chakra-ui/icon';
import { IIcon } from 'Types/Interface/IIcon';

export const GlobeIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});

export const GlobeIconSvg = ({
	size = '20px',
	color = '#264653',
	...props
}: IIcon) => {
	return (
		<Icon
			width={size}
			height={size}
			viewBox="0 0 19 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M8.58926 16.4663C6.25384 16.4663 3.95801 15.5955 2.17676 13.8143L2.73092 13.2601C5.97676 16.5059 11.2018 16.5059 14.4476 13.2601C16.0309 11.6768 16.8622 9.61842 16.8622 7.40176C16.8622 5.18509 15.9913 3.12676 14.4476 1.54342L15.0018 0.989258C16.7038 2.69134 17.6538 4.98717 17.6538 7.40176C17.6538 9.81634 16.7038 12.1122 15.0018 13.8143C13.2205 15.5955 10.8851 16.4663 8.58926 16.4663Z" fill={color}/>
			<path d="M8.58942 14.6456C4.63109 14.6456 1.38525 11.3998 1.38525 7.44147C1.38525 3.48314 4.63109 0.237305 8.58942 0.237305C12.5478 0.237305 15.7936 3.48314 15.7936 7.44147C15.7936 11.3998 12.5478 14.6456 8.58942 14.6456ZM8.58942 0.989388C5.0665 0.989388 2.17692 3.87897 2.17692 7.40189C2.17692 10.9248 5.0665 13.8144 8.58942 13.8144C12.1123 13.8144 15.0019 10.9248 15.0019 7.40189C15.0019 3.87897 12.1123 0.989388 8.58942 0.989388Z" fill={color}/>
			<path d="M9.38086 15.9912H10.1725V18.4058H9.38086V15.9912Z" fill={color}/>
			<path d="M7.4812 18.0098H12.1124V18.8014H7.4812V18.0098Z" fill={color}/>
		</Icon>
	);
};