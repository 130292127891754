import React from 'react';
import { Flex, Heading, Image, Stack, Box, Tag, Button, TagLeftIcon, TagLabel } from '@chakra-ui/react';
import loopIcon from 'Assets/svg/loopIcon2.svg';

import { useAppDispatch, useAppSelector } from 'Hooks';
import { BsPencil } from 'react-icons/bs';
import { setIsStartSurvey } from 'store/slices';
import { useGetAvailableSurveysQrCodesForProductQuery } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';

export const SurveyDetails = () => {
	const {
		activeSurvey: { survey },
	} = useAppSelector((state) => state.survey);

	const dispatch = useAppDispatch();

	const { product, questions, title, rewardPointsPerFeedback } = survey;

	const {
		data: qrCodesForAvailableSurveys,
		isError: isAvailableSurveysError,
		error: availableSurveysError,
	} = useGetAvailableSurveysQrCodesForProductQuery(product.id);
	
	useDefaultErrorHandler(isAvailableSurveysError, availableSurveysError);

	const handleStartSurvey = () => {
		dispatch(setIsStartSurvey(true));
	};

	return (
		<>
			<Stack alignItems='center' justifyContent={'center'}>
				<Image src={product?.brand?.logoUrl} objectFit='contain' boxSize='90px' />
				<Heading color={'#2B2B2B'} size='lg'>
					{title}
				</Heading>
				<Box h={['2', '8']} />
				<Flex alignItems='center'>
					<Image src={loopIcon} h={6} mr={4} />
					<Heading color='loop.500' size='sm'>
						{rewardPointsPerFeedback} LNT
					</Heading>
				</Flex>
				<Box h={['40', '8']} />
				<Flex direction={['column-reverse', 'column']} alignItems={'center'}>
					{qrCodesForAvailableSurveys && qrCodesForAvailableSurveys.data.length > 0 && (
						<Button
							variant='primary'
							borderRadius={['40px', '5px']}
							px={20}
							w={['315px']}
							h={['50px']}
							onClick={handleStartSurvey}
						>
							Start Survey
						</Button>
					)}
					<Box h='8' />
					{qrCodesForAvailableSurveys && qrCodesForAvailableSurveys.data.length > 0 && (
						<Tag borderRadius={'40px'} w={'143px'} h={'36px'} alignItems={'center'} p={4}>
							<TagLeftIcon boxSize='12px' as={BsPencil} />
							<TagLabel>{questions?.length} Questions</TagLabel>
						</Tag>
					)}
				</Flex>
			</Stack>
		</>
	);
};
