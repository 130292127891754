import React from 'react';
import { 
	Flex, 
	Heading, 
	Text 
} from '@chakra-ui/react';
// import { Brands } from 'Constants/BrandConstant';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IBrand } from 'Types/Interface/IBrand';

interface IPurposeOnLoopProps{
	data: IJsonResponse<IBrand> | undefined
}

export const PurposeOnLoop = ({data}: IPurposeOnLoopProps) => {
	return (
		<Flex
			direction={'column'}
			justifyContent={'space-between'}
			// alignItems={'center'}
			py={{ base: '10', tablet: '20' }}
		>
			{/* {Brands.map(brand => {
				return ( */}
			<Flex 
				// key={data?.data.name}
				direction={'column'}
				alignItems={['flex-start','center']}
			>
				<Heading
					fontSize={'lg'}
					fontWeight={600}
					color={'#586068'}
					borderBottom={'2.5px solid #586068'}
					pb={4} mb={6}
					position="relative"
					// right={['', '10%', '16%','16%','17%']}
				>
					Why are we on Loop?
				</Heading>
				<Text
					w={['335px','50%']}
					fontSize={{ base: 'sm', tablet: 'sm', xxl: 'sm' }}
					lineHeight={1.8}
					color={'#586068'}
				>
					{data?.data.purposeOnLoop}
				</Text>
			</Flex>
			{/* );
			})} */}
		</Flex>
	);
};