import { Box} from '@chakra-ui/react';
import React from 'react';
import { Footer } from 'Pages/LandingPage/HomeSection/sections/Footer';
import { Navbar } from 'Pages/LandingPage/Components';

interface IDynamicProductPageLayoutProps{
    JumbotronSection: React.ReactNode;
  children: React.ReactNode;
  linkColorMode?: 'light' | 'black';
}

export const DynamicProductPageLayout = ({JumbotronSection,
	children
}:IDynamicProductPageLayoutProps) => {
	return(
		<Box overflow={'hidden'}>
			<Box
				py="8"
				px={['5', null, 8, '20']}
			>
				<Navbar linkColorMode={'black'}  />
			</Box>
			<Box maxW={'1440px'} m={'0 auto'}  >
				<Box maxW={'1440px'} m={'0 auto'} px={['5', null, 6, '150px']} mb={'80px'} >{JumbotronSection}</Box>
				{children}
			</Box>
			<Footer />
		</Box>
	);
};