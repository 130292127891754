import { Button, Heading, Stack, Text, chakra, Alert, AlertIcon } from '@chakra-ui/react';
import { OTPInput } from 'Components/FormInput/OTPInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { useUpdatePhoneNumberMutation } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';

interface IChanePhoneNumberVerificationProps {
	defaultValue: string | number;
	onClose: () => void;
	onVerified: () => void;
	handleSendCode: () => void;
	isSendCodeError: boolean;
	sendCodeError: unknown;
}

const validationSchema = Yup.object().shape({
	otp: Yup.string().min(6).max(6).required('OTP is required'),
});

export const ChangePhoneNumberVerification = ({
	defaultValue,
	handleSendCode,
	isSendCodeError,
	sendCodeError,
	onClose,
	onVerified,
}: IChanePhoneNumberVerificationProps) => {
	const [changePhoneNumber, { isLoading, isSuccess, isError, error }] = useUpdatePhoneNumberMutation();

	const { values, handleSubmit, errors, setFieldValue } = useFormik({
		initialValues: {
			otp: '',
		},
		validationSchema,
		onSubmit: (values) => {
			const data = {
				code: values.otp,
				phoneNumber: defaultValue.toString().toInternationalPhoneNumber(),
				countryCode: '',
			};
			changePhoneNumber(data);
		},
	});

	const handleVerificationError = () => {
		setFieldValue('otp', '');
	};

	useDefaultErrorHandler(isError, error, handleVerificationError);
	useDefaultErrorHandler(isSendCodeError, sendCodeError, onClose);

	useEffect(() => {
		if (isSuccess) {
			onVerified();
		}
	}, [isSuccess]);

	return (
		<Stack p={['20px', '40px']} spacing={['30px', '35px']} justify={'center'} align={'center'}>
			<Heading fontSize={['16px', '30px']} color={''}>
				Verify Code
			</Heading>
			<Text textAlign={'center'} w={['275px', '400px']} fontSize={['12px', '16px']}>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
			</Text>
			{errors.otp && (
				<Alert status='error'>
					<AlertIcon />
					{errors.otp}
				</Alert>
			)}
			<OTPInput onChange={(value) => setFieldValue('otp', value)} value={values.otp} />
			<Button
				w={['280px', '397px']}
				h={['40px', '60px']}
				variant={'primary'}
				isLoading={isLoading}
				onClick={() => handleSubmit()}
			>
				Confirm
			</Button>
			<Text fontSize={'16px'}>
				Didn’t get a code?
				<chakra.span
					color={'darkCoral'}
					cursor={'pointer'}
					_hover={{ textDecoration: 'underline' }}
					onClick={handleSendCode}
				>
					Resend
				</chakra.span>
			</Text>
		</Stack>
	);
};
