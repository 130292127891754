import React, { useEffect, useRef, useState } from 'react';
import {  Grid, Flex, Text, Image } from '@chakra-ui/react';
import { IPanels } from 'Types/Interface/ILandingPage';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';

interface ITestimonialItemsProps{
	panels: IPanels[];
	currentSlide: number;
}

export const  TestimonialItems = ({panels, currentSlide}:ITestimonialItemsProps) => {
	const boxRef = useRef<HTMLElement>(null);
	const [boxWidth, setBoxWidth] = useState<number>();

	useEffect(() => {
		if(boxRef?.current){
			setBoxWidth(boxRef?.current?.clientWidth);
		}
	}, [boxRef]);

	const defaultWidth = 400;
	const sliderVariant = {
		initial: { x: 0 },
		animate: () => ({
			x: `-${currentSlide * (boxWidth ? boxWidth : defaultWidth)}px`,
			transition: {
				type: 'spring',
				stiffness: 250,
				damping: 30,
			},
		}),
	};

	return (
		<Grid  
			templateColumns={'repeat(4, 1fr)'}
			justifyContent='space-between'
			flex={1}
			gap={[5,8]}
		>
			{panels.map((panel, index) => {
				return(
					<MotionBox  
						display={'flex'}
						flexDirection={'column'} 
						justifyContent={'space-evenly'} 
						key={index}
						ref={boxRef}
						variants={sliderVariant}
						initial="initial"
						animate="animate"
						border={'1px solid #E1E7EA'}
						borderRadius='10px'
						bgColor={'#FCFCFC'}
						px={8} py={8} h={'350px'}
						w={['300px', '300px',  '400px', '400px']} 
					>
						<Text 
							fontSize={{ sm: 'xs', tablet: 'sm' }}
							fontWeight={400} 
							color={'#586068'}
							letterSpacing='-0.03em'
							lineHeight={7}
							
						>
							{panel.subtext}
						</Text>
						<Flex alignItems={'center'}  py={6}>
							<Image src={panel.profilePicture} w={'4rem'} />
							<Flex direction={'column'} ml={4}>
								<Text 
									fontSize={'sm'} 
									fontWeight={600} 
									color={'#121B26'}
									mb={2}
								>
									{panel.name}
								</Text>
								<Text
									fontSize={'sm'} 
									fontWeight={600} 
									color={'#586068'}
								>
									{panel.role}
								</Text>
							</Flex>
						</Flex>
					</MotionBox>
				);
			})}
		</Grid>
	);
};