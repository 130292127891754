import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import verified from '../../Assets/svg/KycVerified.svg';
import unverified from '../../Assets/svg/KycUnverified.svg';
import denied from '../../Assets/svg/KycDenied.svg';

export const KycVerifiedIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={verified} ref={ref} {...props} />;
});

export const KycUnverifiedIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={unverified} ref={ref} {...props} />;
});

export const KycDeniedIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={denied} ref={ref} {...props} />;
});