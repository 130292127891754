export enum InputNameEnums {
  avatarUrl =  'avatarUrl',
  dateOfBirth = 'dateOfBirth',
  emailAddress =  'emailAddress',
  fullName = 'fullName',
  gender = 'gender',
  phoneNumber = 'phoneNumber',
  genderName = 'genderName',
  lastName =  'lastName',
  firstName = 'firstName'
}