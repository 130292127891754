import React from 'react';
import { chakra, Flex } from '@chakra-ui/react';

interface IBrandBoxProps {
  icon: React.ReactNode;
}

export const BrandBox = ({ icon }: IBrandBoxProps) => {
	return (
		<Flex 
			alignItems='center' 
			justifyContent='center' 
			bg="#FAFAFA" 
			mb={4} 
			mr={4} 
			h="70px" 
			w="200px">
			<chakra.span>
				{icon}
			</chakra.span>
		</Flex>
	);
};
