import Artisan from 'Assets/Images/Artisan.png';
import SephoraModel from 'Assets/Images/SephoraModel.png';
import Drinks from 'Assets/Images/Drinks.png';
import Shoes from 'Assets/Images/Shoes.png';
import ArchibaldLogo from 'Assets/svg/ArchibaldLogo.svg';
import NestleLogo from 'Assets/svg/nestleLogo.svg';
import NikeSwoosh from 'Assets/svg/nikeSwoosh.svg';
import SephoraLogo from 'Assets/svg/Sephora-logo.svg';
import { ICollection } from 'Types/Interface/ICollection';

export const FoodAndBeverages:ICollection[] = [
	{
		label:'Archibald',
		coverImage: Artisan,
		brandLogo: ArchibaldLogo,
		brandName: 'Archibald',
	},
	{
		label:'Nestle',
		coverImage: Drinks,
		brandLogo: NestleLogo,
		brandName: 'Nestle',
	},
	{
		label:'Nike',
		coverImage: Shoes,
		brandLogo: NikeSwoosh,
		brandName: 'Nike',
	},
	{
		label:'Sephora',
		coverImage: SephoraModel,
		brandLogo: SephoraLogo,
		brandName: 'Sephora',
	},
];