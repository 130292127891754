import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Heading, Text, Flex, Image, Spinner} from '@chakra-ui/react';
import { DashBoardLayout } from 'Layouts';
import dayjs from 'dayjs';
import { useDeleteNotificationMutation, useGetAllNotificationsQuery } from 'services';
import { notificationIcons } from 'Constants/NotificationIcons';

export const NotificationPage = () => {
	const params = { pageNumber:1, pageSize:20 };
	const {data, isLoading} = useGetAllNotificationsQuery(params);
	const [deleteNotification] = useDeleteNotificationMutation();

	const handleDeleteNotification = (id:string) => {
		deleteNotification(id);
	};
	
	return(
		<>
			{ isLoading ? 
				<Flex 
					justifyContent={'center'}
					alignItems={'center'}
					padding='6' 
					boxShadow='lg' 
					bg='white'
					h={'100vh'}
					w={'full'}
				>
					<Spinner size='xl' color='darkCoral' />
				</Flex> :
				<DashBoardLayout>
					<Heading color={'#586068'} fontSize={'20px'} my={16}>Notifications</Heading>
					{
						data?.data.map((item) => {
							return(
								<Flex my={10}  justifyContent={'space-between'} key={item.id}>
									<Image w={['40px','70px']} h={['40px','70px']} src={notificationIcons[item.notificationType]} />
									<Box  w={['80%','75%']}   >
										<Text 
											fontSize={['12px','16px']}
											color={item.isRead ? 'rgba(88, 96, 104, 0.7)':'brand.black'} 
											mb={2}
										>
											{item.message}
										</Text>
										<Text fontSize={'12px'} color={'#96999B'}>
											{dayjs(item.dateCreated).format('MMMM D, YYYY')}
										</Text>
									</Box>
									<CloseIcon 
										cursor={'pointer'} 
										color={'rgba(38, 70, 83, 0.5)'} 
										onClick={() => handleDeleteNotification(item.id)}
									/>
								</Flex>
								
							);
						})
					}
					{!data?.data.length && <Text textAlign={'center'} color={'#96999B'}>You have 0 notifications</Text>}
				</DashBoardLayout>
			}
		</>
	);
};