import React from 'react';

import { BrandsLayout } from 'Layouts/BrandsLayout';
import { BrandsJumbotronSection } from 'Pages/LandingPage/Components/BrandsJumbotronSection';
import { BrandsJumbotronSectionContents } from 'Constants';
import { AllBrandsCategory } from './sections/AllBrandsCategories';

export const AllBrands = () =>{	
	return (
		<BrandsLayout
			JumbotronSection={
				<BrandsJumbotronSection 
					contents={BrandsJumbotronSectionContents} 
					justify={'center'} textAlign={'center'}
				/> 
			}
			linkColorMode='black'
		>
			<AllBrandsCategory />
		</BrandsLayout>
	);
};