import React, { useEffect, useState} from 'react';
import { useAppSelector } from 'Hooks';
import { useGetSurveyByCodeQuery, useGetSurveyBySurveyIdQuery } from 'services';
import { Spinner, Flex, Box } from '@chakra-ui/react';
import { IProduct } from 'Types/Interface/IProduct';
import { DynamicProductPageLayout } from 'Layouts/DynamicProductPageLayout';
import { DynamicProductPageContents } from './sections/DynamicProductPageContents';
import { Faq } from './sections/Faq';

export const DynamicProductPage = () => {
	const { qrCodeFromDynamicLink, webCodeFromEnterCode } = useAppSelector((state) => state.survey);
	const surveyId = qrCodeFromDynamicLink.split(':')[0];
	const { data, isLoading, isSuccess } = useGetSurveyBySurveyIdQuery(surveyId, {'skip': !surveyId});
	const {data:surveyByCode, isLoading:isSurveyByCodeLoading, isSuccess:isSurveyByCodeSuccess} = useGetSurveyByCodeQuery(webCodeFromEnterCode);
	const [productDetails, setProductDetails] = useState<IProduct>();

	useEffect(() => {
		if(data && isSuccess){
			setProductDetails(data?.data?.product);
		}
		if(surveyByCode && isSurveyByCodeSuccess){
			setProductDetails(surveyByCode?.data?.product);
		}
	}, [data, surveyByCode]);


	return (
		<Box>
			{

				isLoading || isSurveyByCodeLoading ? (<Flex
					justifyContent={'center'}
					alignItems={'center'}
					// padding="6"
					boxShadow="lg"
					bg="white"
					h={'100vh'}
					w={'full'}
				>
					<Spinner size="xl" color="darkCoral" />
				</Flex>):(
					<DynamicProductPageLayout 
						JumbotronSection={<DynamicProductPageContents 
							reward={data?.data?.rewardPointsPerFeedback} 
							data={productDetails} 
						/>} 
					>
						<Faq />
					</DynamicProductPageLayout>
				)
			}
		</Box>
	);
};
