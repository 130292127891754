import React from 'react';
import { SwipeableGalleryContainer } from 'Pages/LandingPage/Components/SwipeableGalleryContainer';
import { panels } from 'Constants';
import { TestimonialItems } from './TestimonialItems';
import { Text } from '@chakra-ui/react';

export const TestimonialSectionMobile = () => {
	return (
		<>
			<Text
				fontSize={{ base: '24px', tablet: 'md', xxl: '1.3rem' }}
				fontWeight={'600'}
				w={'60%'}
				py={8}
			>
        We are making users happy
			</Text>
			<SwipeableGalleryContainer>
				<TestimonialItems panels={panels} currentSlide={0} />
			</SwipeableGalleryContainer>
		</>
	);
};
