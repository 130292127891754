import React, { useRef, useState } from 'react';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { IProduct } from 'Types/Interface/IProduct';
import { CarouselContainer } from '../Components/CarouselContainer';
import { ProductItems } from 'Components/Products/productItems';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { MobileProductsSlider } from 'Pages/HomePage/Components/MobileDiscoverProductsSlider';
import { SkeletonLoader } from '../Components/SkeletonLoader';

interface IProductTabProps{
	products?: IProduct[];
	title?: string;
	isLoading?: boolean;
}

export const ProductCarousel = ({products, title, isLoading}:IProductTabProps) =>{
	const [currentSlide, setcurrentSlide] = useState(0);
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const boxRef = useRef<HTMLImageElement>(null);
	const clientWidth = boxRef.current?.clientWidth || 0;

	const onRight = () => {
		if (currentSlide > 0 ) {
			setcurrentSlide(currentSlide - 1);
		}
	};
	const onLeft = () => {
		if ( products?.length !== undefined  && currentSlide < products?.length - 1 ) {
			setcurrentSlide(currentSlide + 1);
		}
	};
	const sliderVariant = {
		initial: { x: 0 },
		animate: () => ({
			x: `-${currentSlide * clientWidth}px`,
			transition: {
				type: 'spring',
				stiffness: 250,
				damping: 30,
			},
		}),
	};
	return (
		<>
			{	isLoading ? <SkeletonLoader /> :
				<><CarouselContainer title={title ?? 'New Products'} onLeft={onLeft} onRight={onRight} itemCount={products?.length}>
					{isLargerThan768 && products?.map((product, index) => {
						return (
							<MotionBox
								w={['30vw', null, '30vw', '22vw', null, '15vw']}
								ref={boxRef}
								custom={index}
								key={index}
								variants={sliderVariant}
								initial="initial"
								animate="animate"
								mr={[6, 5]}
							>
								<ProductItems
									key={product.id}
									product={product} />
							</MotionBox>
						);
					})}
				</CarouselContainer><Box>
					{!isLargerThan768 && <MobileProductsSlider isLoading={isLoading} products={products} />}
				</Box></>
			}
		</>
	);
};