import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/react';

interface IPaymentModalContainerProps {
    children: React.ReactNode;
    handleSubmit: () => void;
    onClose: () => void;
}

export const PaymentModalContainer = ({children, handleSubmit, onClose}: IPaymentModalContainerProps) => {
	return (
		<Flex direction={'column'} px={12} py={10}>
			<Text 
				mb={4}
				fontSize={'md'}
				fontWeight={600}
				color={'#586068'}
			>
            Add payment method
			</Text>
			{children}
			<Flex>
				<Button variant={'primary'} mr={4} onClick={() =>handleSubmit()}>Done</Button>
				<Button 
					onClick={onClose}
					border={'1px solid #ADB3B9'}
					borderRadius={5}
					color={'#ADB3B9'}
					p={3} px={4}
					_hover= {{
						color: '#000'
					}}
				>
                Cancel
				</Button>
			</Flex>
		</Flex>
	);
};