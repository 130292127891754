import React from 'react';

import { BrandsJumbotronSection } from '../Components/BrandsJumbotronSection';
import { BrandsGalleryLayout } from 'Layouts/BrandsGalleryLayout';
import {BrandsJumbotronSectionContents} from 'Constants/JumbotronConstant';
import { BrandsCategorySection } from './sections/BrandsCategorySection';

export const BrandsPage = () => {
	return (
		<BrandsGalleryLayout 
			JumbotronSection={
				<BrandsJumbotronSection 
					contents={BrandsJumbotronSectionContents} 
					justify={'center'} textAlign={'center'}
				/> 
			}
			linkColorMode='black'
		>
			<BrandsCategorySection/>
		</BrandsGalleryLayout>	
	);
};