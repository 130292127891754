import { Flex, useMediaQuery, Progress } from '@chakra-ui/react';
import { LoopLogo } from 'Components/Icons';
import React from 'react';
import { SplitScreen } from './SplitScreen';

type AuthLayoutPropType = {
  children: React.ReactNode;
  isLoading?: boolean;
};

export const AuthLayout = ({ children, isLoading }: AuthLayoutPropType) => {
	const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
	return (
		<>
			{isLoading && (
				<Progress
					size='xs'
					isIndeterminate
					position='absolute'
					top={0}
					left={0}
					right={0}
					colorScheme='linkedin'
				/>
			)}
			<SplitScreen hideLeft={isLargerThan900}>
				{isLargerThan900 && (
					<Flex flex={1} alignItems='center' justifyContent='center'>
						<LoopLogo h={'3xs'} />
					</Flex>
				)}
				{children}
			</SplitScreen>
		</>
	);
};
