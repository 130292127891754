import React from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Flex, Text, Spacer, Link } from '@chakra-ui/react';

interface ICategoryHeaderProps {
  onClickLink: () => void;
  title: string;
}

export const CategoryHeader = ({
	title,
	onClickLink,
}: ICategoryHeaderProps) => {
	return (
		<Flex
			alignItems={'center'}
			// px={{ base: '4', tablet: '10', xxl:'10' }}
			py={{ base: '10', tablet: '8' }}
		>
			<Text
				fontSize={{ base: 'sm', tablet: 'md', xxl: '1.3rem' }}
				fontWeight={'600'}
				lineHeight={'32px'}
			>
				{title}
			</Text>
			<Spacer />
			<Link
				fontSize={{ base: 'xs', tablet: 'sm', xxl: '16px' }}
				fontWeight={'semibold'}
				color='darkCoral'
				cursor={'pointer'}
				// mr={{base: 4, tablet:10, xxl: 16}}
				onClick={onClickLink}
				display={['none', 'block']}
			>
        See all
				<ArrowForwardIcon ml={2} h={10} />
			</Link>
		</Flex>
	);
};
