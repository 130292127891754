import React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import logo from '../../Assets/svg/megaphone.svg';


export const MegaPhoneIcon = forwardRef<ImageProps, 'svg'>((props, ref) => {
	return <chakra.img src={logo} ref={ref} {...props} />;
});