import { createApi } from '@reduxjs/toolkit/query/react';
import { IBrand, IBrandHistory } from 'Types/Interface/IBrand';
import { baseQuery } from './history';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IPaginatedResponse, ISearchBrandAndProductResult } from './types';

export const brandApi = createApi({
	reducerPath: 'brandApi',
	baseQuery,
	endpoints: (builder) => ({
		getBrandById: builder.query<IJsonResponse<IBrand>, string>({
			query: (brandId) => `brand/GetByBrandId/?brandId=${brandId}`,
		}),
		getAllBrands: builder.query<IPaginatedResponse<IBrand[]>,{ pageNumber?: number; pageSize?: number; categoryId?: string }>({
			query: ({ pageNumber, pageSize, categoryId }) => `brand/GetAllBrandsAsync?categoryId=${categoryId}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
		}),
		getBrandByCategory: builder.query<IPaginatedResponse<IBrand[]>, string>({
			query: (categoryId) => `categories/${categoryId}/brands`,
		}),
		searchBrandAndProducts: builder.query<IJsonResponse<ISearchBrandAndProductResult>, string>({
			query: (arg) => {
				return {
					url: '/search/getBrandsAndProducts',
					params: { searchString: arg },
				};
			},
		}),
		getBrandScanHistory: builder.query<IJsonResponse<IBrandHistory>, string>({
			query: (brandId) => `survey/${brandId}/brandscanhistory`
		})
	}),
});

export const {
	useGetBrandByIdQuery,
	useGetAllBrandsQuery,
	useGetBrandByCategoryQuery,
	useSearchBrandAndProductsQuery,
	useGetBrandScanHistoryQuery,
} = brandApi;
