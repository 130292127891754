import React from 'react';
import { HStack, PinInput, PinInputField } from '@chakra-ui/react';

type OTPInputPropType = {
  onChange: (value: string) => void;
  value: string;
};

export const OTPInput = ({ onChange, value }: OTPInputPropType) => {
	return (
		<HStack>
			<PinInput
				mask
				size="lg"
				type="alphanumeric"
				value={value}
				onChange={onChange}
				placeholder=""
				autoFocus={true}
        
			>
				<PinInputField borderColor={'rgba(88, 96, 104, 0.4)'} borderRadius={'3px'} fontSize="2xl" h={['48px', '14']} w={['48px', '14']} />
				<PinInputField borderColor={'rgba(88, 96, 104, 0.4)'} borderRadius={'3px'} fontSize="2xl" h={['48px', '14']} w={['48px', '14']}/>
				<PinInputField borderColor={'rgba(88, 96, 104, 0.4)'} borderRadius={'3px'} fontSize="2xl" h={['48px', '14']} w={['48px', '14']}/>
				<PinInputField borderColor={'rgba(88, 96, 104, 0.4)'} borderRadius={'3px'} fontSize="2xl" h={['48px', '14']} w={['48px', '14']}/>
				<PinInputField borderColor={'rgba(88, 96, 104, 0.4)'} borderRadius={'3px'} fontSize="2xl" h={['48px', '14']} w={['48px', '14']}/>
				<PinInputField borderColor={'rgba(88, 96, 104, 0.4)'} borderRadius={'3px'} fontSize="2xl" h={['48px', '14']} w={['48px', '14']}/>
			</PinInput>
		</HStack>
	);
};
