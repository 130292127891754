
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOncompletePayload } from 'Types/Interface/ITilia';
import { IUserInfo, IPaymentMethods } from 'Types/Interface/IUser';

interface IUserSummary {totalBalance:number, totalRecieved: number, totalWithdraw: number}
type UserCoords =  {lat: number; long: number;}
interface UserInfoSlice {
  userInfo: IUserInfo | null;
  authToken: string;
  paymentMethods: IPaymentMethods[];
  tiliaPaymentMethods: IOncompletePayload | null;
  userSummary: IUserSummary | null;
  userCoords: UserCoords | null;
	hideVerifyEmailSection: boolean;
}

const initialState: UserInfoSlice = {
	paymentMethods: [],
	tiliaPaymentMethods: null,
	userInfo: null,
	authToken: '',
	userSummary: null,
	userCoords: null,
	hideVerifyEmailSection: false,
};


export const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState,
	reducers: {
		setUserInfo: (state, action: PayloadAction<IUserInfo>) => {
			state.userInfo = {...state.userInfo, ...action.payload};
		},
		setUserSummary: (state, action: PayloadAction<IUserSummary>) => {
			state.userSummary = {...state.userSummary, ...action.payload};
		},
		setAuthToken: (state, action: PayloadAction<string>) => {
			state.authToken = action.payload;
		},
		setPaymentMethod: (state, action: PayloadAction<IPaymentMethods>) => {
			state.paymentMethods?.push(action.payload);		
		},
		setTiliaPaymentMethod:(state, action: PayloadAction<IOncompletePayload | null>) => {
			state.tiliaPaymentMethods = action.payload;
		},
		setDefaultPaymentMethod: (state, action: PayloadAction<IPaymentMethods> ) => {
			state.paymentMethods.map(card => {
				if(card.id === action.payload.id){
					card.default = action.payload.default;
				}
			});
		},
		setUserCoords: (state, action: PayloadAction<UserCoords>) => {
			state.userCoords = action.payload;
		},
		deletePaymentMethod: (state, action: PayloadAction<IPaymentMethods>) => {
			state.paymentMethods = state.paymentMethods.filter((card) => card.id !== action.payload.id);
		},
		setHideVerifyEmailSection: (state, action: PayloadAction<boolean>) => {
			state.hideVerifyEmailSection = action.payload;
		}
	},
});


export const {
	setUserInfo,
	setAuthToken,
	setPaymentMethod,
	setTiliaPaymentMethod,
	setDefaultPaymentMethod,
	setUserSummary,
	setUserCoords,
	deletePaymentMethod,
	setHideVerifyEmailSection,
} = userInfoSlice.actions;

export default userInfoSlice.reducer;
