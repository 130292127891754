import React, { useEffect, useRef, useState } from 'react';

import {
	Flex,
	Heading,
	Text,
	Image,
	VStack,
} from '@chakra-ui/react';

import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { Carousels } from 'Constants/ImageSliderConstant';

interface IHowItWorksDesktopProp {
    currentSlide: number;
}

export const HowItWorksDesktop = ({currentSlide}: IHowItWorksDesktopProp) => {
	const boxRef = useRef<HTMLElement>(null);
	const [boxWidth, setBoxWidth] = useState<number>();

	useEffect(() => {
		if(boxRef?.current){
			setBoxWidth(boxRef?.current?.clientWidth);
		}
	}, [boxRef]);

	const defaultWidth = 500;
	const sliderVariant = {
		initial: { x: 0 },
		animate: (index: number) => ({
			x: `-${currentSlide * (boxWidth ? boxWidth : defaultWidth)}px`,
			scale: index === currentSlide ? 1 : 0.8,
			transition: {
				type: 'spring',
				stiffness: 250,
				damping: 30,
			},
		}),
	};

	return (
		<MotionBox display={'flex'}>
			{Carousels.map((Carousel, index) => {
				return (
					<MotionBox
						w={{lg:'48vw', xl:'48vw', '2xl':'30vw'}}
						custom={index}
						key={index}
						variants={sliderVariant}
						initial="initial"
						animate="animate"
						ref={boxRef}
					>
						<Flex 
							alignItems={'center'}
							border={'0.5px solid #cbcfd3'}
							flexDirection={['column', null,null, null, 'row']}
							w="100%"
							pb={[10, null, null, null, 0]}
						>
							<Image  boxSize={{ base: '100%', md: '100%', lg: '50%' }} mr={[0, null, null, 20, 6]} mb={10} src={Carousel.src} />

							<VStack minWidth="200px" flex={1} alignItems={['center', null, null, null, 'flex-start']}>
								<Heading fontSize={'xl'}>{Carousel.label}</Heading>
								<Text 
									w={'90%'}
									fontSize={['14px', '16px']} 
									lineHeight={'28px'}
									color={'#586068'}
								>
									{Carousel.text}
								</Text>
							</VStack>
						</Flex>
					</MotionBox>
				);
			})}
		</MotionBox>
	);
};
