import React, { useState, useCallback } from 'react';
import { DataTable, IPaginationConfig } from 'Components/DataTable';
import { Column } from 'react-table';
import { useGetRewardHistoryQuery } from 'services';
import { IHistory } from 'Types/Interface/IHistory';
import { CustomModal } from 'Components';
import { Box, useDisclosure, useMediaQuery, VStack, theme, Flex, Image } from '@chakra-ui/react';
import { HistoryModalContent } from './HistoryModalContent';
import { UserActivityType } from 'Types/Enums';
import WithdrawalIcon from 'Assets/svg/WithdrawalIcon.svg';
import { formatDateLong, getStatusDescription, TransactionStatusComponent } from 'Utils/dataTableUtils';

export const History = () => {
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [pageIndex, setPageIndex] = useState(1);
	const params = { pageIndex, pageSize: 10 };
	const { data, isLoading, isFetching } = useGetRewardHistoryQuery(params);
	const newData = data?.data?.map((item) => ({
		...item,
		id: item.imageUrl,
	}));

	const [selectedRow, setSelectedRow] = useState<IHistory>();

	const columns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'dateCreated',
				Cell: (item) => <>{formatDateLong(item.row.original.dateCreated)}</>,
			},
			{
				Header: 'Brand',
				accessor: 'brandName',
			},
			{
				Header: 'Product',
				accessor: 'productName',
			},
			{
				Header: 'Type',
				accessor: 'type',
				Cell: (item) => <>{getStatusDescription(item.row.original.type)}</>,
			},
			{
				Header: 'Amount',
				accessor: 'points',
				Cell: (item) => <Box textAlign={'left'}>{item.row.original.points} LNT</Box>,
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: (item) => <TransactionStatusComponent status={item.row.original.status} />,
			},
		],
		[],
	);

	const mobileColumns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				accessor: 'dateCreated',
				Cell: (item) => {
					const rowData = item.row.original;
					const isWithdrawal = rowData.type === UserActivityType.Withdrawal;

					return (
						<Flex alignItems={'center'}>
							<Image w={'40px'} height={'40px'} src={WithdrawalIcon} mr={'10px'} />
							<VStack align={'flex-start'} spacing={'5px'}>
								<Box fontSize='14px'>{isWithdrawal ? 'Withdrawal' : rowData.productName}</Box>
								<Box fontSize='12px' color='loopGrey.800'>
									{formatDateLong(item.row.original.dateCreated)}
								</Box>
							</VStack>
						</Flex>
					);
				},
			},
			{
				accessor: 'points',
				Cell: (item) => (
					<VStack alignItems='flex-end'>
						<Box fontWeight='400' fontSize={'14px'}>
							{item.row.original.points} LNT
						</Box>
						<TransactionStatusComponent status={item.row.original.status} />
					</VStack>
				),
			},
		],
		[],
	);

	const handleRowClick = (data: IHistory) => {
		setSelectedRow(data);
		onOpen();
	};

	const paginate = useCallback(({ pageIndex }: IPaginationConfig) => {
		setPageIndex(pageIndex + 1);
	}, []);

	return (
		<>
			<DataTable
				data={newData || []}
				columns={isMobile ? mobileColumns : columns}
				onclickRow={handleRowClick}
				pageCount={data?.totalNoOfPages}
				paginate={paginate}
				initialPageIndex={0}
				isLoading={isLoading || isFetching}
				pageSize={params.pageSize}
				isMobile={isMobile}
				showCheckboxes={false}
				showMenu={false}
			/>
			<CustomModal
				content={selectedRow ? <HistoryModalContent data={selectedRow} /> : <>Empty</>}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	);
};
