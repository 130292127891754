import { IJumbotronContents } from 'Types/Interface/ILandingPage';

export const HomeJumbotronSectionContents: IJumbotronContents[] = [
	{
		heading: 'Increase brand loyalty Accelerate your growth.',
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim consectetur ',
		subText: 'Get the free Loop app on your phone'
	}
];

export const LoopAppJumbotronSectionContents: IJumbotronContents[] = [
	{
		heading: 'The complete survey mobile app.',
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim consectetur ',
		subText: 'Get the free Loop app on your phone'
	}
];

export const HowItWorksJumbotronSectionContents: IJumbotronContents[] = [
	{
		heading: 'Get paid for your honest thoughts.',
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim consectetur ',
		subText: 'Get the free Loop app on your phone'
	}
];

export const BrandsJumbotronSectionContents: IJumbotronContents[] = [
	{
		heading: 'Loop Brands.',
		text: 'Online. And in real life. Discover all the stores where you can safely shop and earn with Loop.',
		subText: ''
	}
];

export const ProductsJumbotronSectionContents: IJumbotronContents[] = [
	{
		heading: 'Loop Products.',
		text: 'Online. And in real life. Discover all the products you can safely shop and earn with Loop.',
		subText: ''
	}
];

export const FeaturedBrandsJumbotronSectionContents: IJumbotronContents[] = [
	{
		heading: 'Featured Brands.',
		text: 'Online. And in real life. Discover all the stores where you can safely shop and earn with Loop.',
		subText: ''
	}
];

export const HomePageJumbotronSectionContents: IJumbotronContents =	{
	heading: 'Increase brand loyalty Accelerate your growth.',
	text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip. ',
	subText: ''
};

export const ExplorePageJumbotronSectionContents: IJumbotronContents[] = [
	{
		heading: 'Explore Stores & Products.',
		text: 'Online. And in real life. Discover all the stores where you can safely shop and pay with Loop. ',
		subText: ''
	}
];

export const BrandPageJumbotronSectionContents: IJumbotronContents[] = [
	{
		heading: 'Bose.com',
		text: 'Bose Corporation is an American manufacturing company that predominantly sells audio equipment. The company was established by Amar Bose in 1964 and is based in Framingham, Massachusetts. Bose is best known for its home audio systems and speakers, noise-cancelling headphones, professional audio products and automobile sound systems. Bose has a reputation for being particularly protective of its patents, trademarks, and brands. ',
		subText: ''
	}
];