import { extendTheme } from '@chakra-ui/react';
import {
	ButtonTheme as Button,
	SwitchTheme as Switch,
	ProgressTheme as Progress,
	CheckboxTheme as Checkbox,
	AccordionTheme as Accordion,
} from './Components';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
	sm: '30em',
	md: '48em',
	lg: '62em',
	xl: '80em',
	xxl: '900px',
	tablet: '820px'
});

export const theme = extendTheme({
	breakpoints,
	colors: {
		success: '#17bd62',
		error: '#D92121',
		warning: '#DD6B20',
		extraLightCoral: '#FFEEEC',
		lightCoral: '#F28482',
		darkCoral: '#FF6F61',
		maxYellow: '#F6BD60',
		placeholderText: '#9E9E9E',
		lightGrey: '#FAFAFA',
		loopGrey: {
			100: '#F7F7F7',
			200: '#F1F1F1',
			300: '#ABAFB3',
			400: '#264653',
			500: '#586068',
			600: '#E5E5E5',
			700: '#818181',
			800: '#6E7072',
			900: '#EDEDED',
		},
		brand: {
			black: '#121B26',
			red: '#D92121',
			green: '#00C96F',
		},
		kyc: {
			green: '#026C3D',
			yellow: '#FF9E00',
			red: '#D92121',
		},
		blackTextColor: {
			100: '#264653',
			200: '#2B2B2B',
		},
		loop: {
			50: '#ffb7b0',
			100: '#ffa9a0',
			200: '#ff9a90',
			300: '#ff8c81',
			400: '#ff7d71',
			500: '#ff6f61',
			600: '#e66457',
			700: '#cc594e',
			800: '#b34e44',
			900: '#99433a',
		},
	},
	fonts: {
		heading: 'Montserrat',
		body: 'Montserrat',
	},
	components: {
		Button,
		Switch,
		Progress,
		Checkbox,
		Accordion
	},
});
